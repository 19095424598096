<div class="row">
  <div class="col-12">
    <h6>{{ 'o365.assignlicense1' | translate }}</h6>
    <p>{{ 'o365.assignlicense2' | translate }}</p>
    <form [formGroup]="newUserLicensesForm" style="overflow-y: visible">
      <div class="row">
        <div class="col-12">
          <div class="group form-group">
            <label for="usageLocation">
              <span>{{ 'o365.usagelocation' | translate }}</span>
              <span class="ml-1 mr-1 text-danger">*</span>
              <i class="fa fa-spinner fa-spin" *ngIf="usageLocationLoading"></i>
            </label>
            <div class="input-group">
              <select id="usageLocation" formControlName="usageLocation" class="form-control" (change)="onUsageLocationChange($event)">
                <option *ngFor="let usageLocation of usageLocations" [selected]="usageLocation.CountryCode == 'MY'" [value]="usageLocation.CountryCode">{{ usageLocation.DisplayName }}</option>
              </select>
            </div>

            <div *ngIf="usageLocationFormControl.touched">
              <span class="text-danger" *ngIf="usageLocationFormControl.hasError('required')">{{ 'error.selectusagelocation' | translate }}</span>
            </div>
          </div>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-12">
          <h6>{{ 'word.licenses' | translate }} ({{selectedSubscribedSkusIds ? selectedSubscribedSkusIds.length : '0'}})<span class="ml-1 mr-1 text-danger">*</span></h6>
          <div class="group form-group">
            <div class="col-12 mb-2">
              <input id="withLicense" name="createUserWithLicense" type="radio" [value]="true" formControlName="createUserWithLicense" (change)="onCreateUserWithLicenseChange()">
              <label for="withLicense">
                <span class="ml-2">{{ 'o365.assignuserlicense' | translate }}</span>
                <i class="ml-1 fa fa-spinner fa-spin" *ngIf="subscribedSkuLoading"></i>
              </label>

              <div class="col-12" *ngIf="createUserWithLicenseFormControl.value">
                <div *ngFor="let control of subscribedSkusFormArray?.controls; let i = index">
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" type="checkbox" id="subsribedSku-{{subscribedSkus[i].skuId}}" [formControl]="control" [disabled]="subscribedSkus[i].prepaidUnits.enabled === subscribedSkus[i].prepaidUnits.consumedUnits" (change)="onSubscribedSkuChange()">
                    <label class="custom-control-label" for="subsribedSku-{{subscribedSkus[i].skuId}}">
                      <span class="font-weight-bold ml-2">{{ subscribedSkus[i].friendlyName }}</span>
                    </label>
                  </div>

                  <div>
                    <span class="ml-4">
                      {{ subscribedSkus[i].prepaidUnits.enabled - (subscribedSkus[i].consumedUnits + (control.value ? 1 : 0)) }} of {{ subscribedSkus[i].prepaidUnits.enabled }} available
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <input id="withoutLicense" name="createUserWithLicense" type="radio" [value]="false" formControlName="createUserWithLicense" (change)="onCreateUserWithLicenseChange()">
              <label for="withoutLicense">
                <span class="ml-2">{{ 'o365.createuserwopermission' | translate }} ({{ 'word.notrecommended' | translate }})</span>
              </label>
              <div>
                <span style="margin-left: 20px">
                  {{ 'warning.limitedaccesstoO365' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr>

      <button type="button" class="btn btn-secondary m-3" (click)="onBackStep()">
        {{ 'button.back' | translate }}
      </button>

      <button type="button" class="btn btn-primary m-3" (click)="onCompleteStep()" [disabled]="newUserLicensesForm.invalid || usageLocationLoading || subscribedSkuLoading">
        {{ 'button.next' | translate }}
      </button>

    </form>
  </div>
</div>
