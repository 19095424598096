import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { O365HttpService } from 'src/app/service/o365-http.service';
import { CountriesService } from 'src/app/service/countries.service';
import { MicrosoftCSPCountryAddressValidation } from 'src/app/model/microsoftcsp/microsoftcsp-address-validation.model';
import { MicrosoftCSPMarketCategory } from 'src/app/model/microsoftcsp/microsoftcsp-market-category.model';
import { AlertService } from 'src/app/service/alert.service';
import { RandomUtil } from 'src/app/util/random-util';
import { ScrollUtil } from 'src/app/util/scroll-util';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';

@Component({
  selector: 'microsoftcsp-tenant-create',
  templateUrl: './microsoftcsp-tenant-create.component.html',
  styleUrls: ['./microsoftcsp-tenant-create.component.css']
})
export class MicrosoftCSPTenantCreateComponent implements OnInit, AfterViewInit {

  tenantType:string;
  isTransitTenant:boolean = false;
  breadcrumbTitle:string = '';

  currentTab: string = 'main';

  loading:boolean = false;
  tenantForm: UntypedFormGroup;

  tenantFormStates:any; // custom object to store form states
  hidePassword:boolean = true;

  defaultDomainPrefixLoading: boolean = false;
  defaultDomainPrefixDebounceTimer: any;
  defaultDomainPrefixValidateSuccess: boolean = false;
  defaultDomainPrefixErrorMessage: string = 'Failed to validate domain prefix. Try again later.';

  domainPrefixRegex = '^[0-9a-z]{1,27}$';

  marketCategories: MicrosoftCSPMarketCategory[] = [];
  showAcademicQualificationInfoMessage: boolean = false;
  showNonProfitQualificationInfoMessage: boolean = false;

  marketCategoryLoading: boolean = false;

  countriesContact:any[] = [];
  statesContact:any[] = [];
  citiesContact:any[] = [];
  countriesBilling:any[] = [];
  statesBilling:any[] = [];
  citiesBilling:any[] = [];

  contactCountryLoading: boolean = false;
  contactStateLoading: boolean = false;
  contactCityLoading: boolean = false;
  billingCountryLoading: boolean = false;
  billingStateLoading: boolean = false;
  billingCityLoading: boolean = false;

  countryAddressValidations:MicrosoftCSPCountryAddressValidation[] = [];
  selectedContactAddressValidation:MicrosoftCSPCountryAddressValidation;
  selectedBillingAddressValidation:MicrosoftCSPCountryAddressValidation;

  showContactAddressFormInputs: boolean = false;
  showBillingAddressFormInputs: boolean = false;

  useContactAddressAsBillingAddress: boolean = true;

  contactOtherCitySelected = false;
  billingOtherCitySelected = false;

  createTenantSubmitBtnPressed: boolean = false;

  errorCheck: boolean = false;
  errors: any[] = [];

  successCheck: boolean = false;
  successMessage: string = 'Create tenant successfully';

  tenantId:number = null;
  partnerConnectUrl:string = '';

  lang = "eng";
  tipLang = {
    eng: "Username is for WebNIC system identification purpose only. It does not refer or has any relation to the user created in Microsoft tenant.",
    cn: "用户名只用于WEBNIC系统认证，与在微软用户创建的用户无关。",
    zh: "用戶名只用於WEBNIC系統認證，與在微軟用戶創建的用戶無關。",
  }

  constructor(
    private o365ApiService: O365HttpService,
    private formBuilder:UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private countriesService: CountriesService,
    private alertService: AlertService,
    private cdf: ChangeDetectorRef,
    private translateService: LanguageTranslateService
  ) { }

  ngOnInit() {
    this.lang = this.translateService.userLanguage$.value;
    this.route.queryParams.subscribe((params) => {
      this.tenantType = params['type'] || 'create';
    });

    this.loading = true;
    this.createTenantForm();

    this.contactCountryLoading = true;
    this.billingCountryLoading = true;
    this.countriesService.getCountriesCsp().subscribe(response => {
      this.countriesBilling = response["data"];
      this.countriesContact = response["data"];

      this.contactCountryLoading = false;
      this.billingCountryLoading = false;
    }, err => {
        console.error(err);
    });

    this.initData().then(()=>{
      this.loadTranslatedWordData();
      this.loading = false;
    });
  }

  translate(key:string):string{
    return this.translateService.translate(key);
  }

  ngAfterViewInit() {
    // change to transit tenant form type
    if(this.tenantType == 'transit'){

      // disable defualt domain prefix form input
      this.defaultDomainPrefixFormControl.disable();
      this.includePartnerFormControl.disable();

      this.cdf.detectChanges();
    }
  }

  createTenantForm(){
    this.tenantForm= this.formBuilder.group({

      default_domain_prefix: ['', [Validators.required, Validators.pattern(this.domainPrefixRegex)]],
      microsoft_market_category_id: [1, [Validators.required] ],
      company_name: [''],
      company_registration_number: [''],
      username: ['', Validators.required],
      password: ['', [Validators.required,Validators.minLength(8)]],
      contact_email_address: ['', [Validators.required, Validators.email]],
      alt_email_address: ['', [Validators.email]],
      contact_first_name: ['', Validators.required],
      contact_last_name: ['', Validators.required],
      contact_phone_no: ['',  Validators.required],

      contact_address_street1: ['', [Validators.required, Validators.maxLength(70)]],
      contact_address_street2: ['', [Validators.maxLength(70)]],
      contact_address_street3: ['', [Validators.maxLength(70)]],
      contact_address_country_id: [0, [Validators.required,Validators.min(1)] ],
      contact_address_state_id: [0, [Validators.required,Validators.min(1)]],
      contact_address_city_id: [0, [Validators.required,Validators.min(1)]],
      contact_address_city_other: [''],
      contact_address_postcode: [''],

      billing_address_street1: [{value: '',disabled: true}, [Validators.required, Validators.maxLength(70)]],
      billing_address_street2: [{value: '',disabled: true}, [Validators.maxLength(70)]],
      billing_address_street3: [{value: '',disabled: true}, [Validators.maxLength(70)]],
      billing_address_country_id: [{ value: 0,disabled: true}, [Validators.required,Validators.min(1)] ],
      billing_address_state_id: [{ value: 0, disabled: true}, [Validators.required,Validators.min(1)]],
      billing_address_city_id: [{ value: 0, disabled: true}, [Validators.required,Validators.min(1)]],
      billing_address_city_other: [{value: null, disabled: true}],
      billing_address_postcode: [{value: '',disabled: true}],

      is_marketing_promo: [false],
      include_partner: [false],

      mcua_agreement: [false,Validators.requiredTrue]
    });

    // custom store the tenant form state value
    this.tenantFormStates = {};
    // set contact_address_postcode's required to false
    this.tenantFormStates.contactPostcodeRequired = false;
    // set contact_address_postcode's required to false
    this.tenantFormStates.billingPostcodeRequired = false;
    this.tenantFormStates.companyRegistrationNumberRequired = false;
  }

  get defaultDomainPrefixFormControl(){return this.tenantForm.get('default_domain_prefix');}
  get microsoftMarketCategoryIdFormControl(){return this.tenantForm.get('microsoft_market_category_id');}
  get includePartnerFormControl() {return this.tenantForm.get('include_partner');}
  get usernameFormControl(){return this.tenantForm.get('username');}
  get passwordFormControl(){return this.tenantForm.get('password');}
  get companyRegistrationNumberFormControl(){return this.tenantForm.get('company_registration_number');}
  get contactEmailFormControl(){return this.tenantForm.get('contact_email_address');}
  get contactAltEmailFormControl() {return this.tenantForm.get('alt_email_address');}
  get contactFirstNameFormControl(){return this.tenantForm.get('contact_first_name');}
  get contactLastNameFormControl(){return this.tenantForm.get('contact_last_name');}
  get contactPhoneNoFormControl(){return this.tenantForm.get('contact_phone_no');}

  get contactStreet1FormControl(){return this.tenantForm.get('contact_address_street1');}
  get contactStreet2FormControl(){return this.tenantForm.get('contact_address_street2');}
  get contactStreet3FormControl(){return this.tenantForm.get('contact_address_street3');}
  get contactCountryIdFormControl(){return this.tenantForm.get('contact_address_country_id');}
  get contactStateIdFormControl(){return this.tenantForm.get('contact_address_state_id');}
  get contactCityIdFormControl(){return this.tenantForm.get('contact_address_city_id');}
  get contactOtherCityFormControl(){return this.tenantForm.get('contact_address_city_other');}
  get contactPostcodeFormControl(){return this.tenantForm.get('contact_address_postcode');}

  get billingStreet1FormControl(){return this.tenantForm.get('billing_address_street1');}
  get billingStreet2FormControl(){return this.tenantForm.get('billing_address_street2');}
  get billingStreet3FormControl(){return this.tenantForm.get('billing_address_street3');}
  get billingCountryIdFormControl(){return this.tenantForm.get('billing_address_country_id');}
  get billingStateIdFormControl(){return this.tenantForm.get('billing_address_state_id');}
  get billingCityIdFormControl(){return this.tenantForm.get('billing_address_city_id');}
  get billingOtherCityFormControl(){return this.tenantForm.get('billing_address_city_other');}
  get billingPostcodeFormControl(){return this.tenantForm.get('billing_address_postcode');}



  async initData(){
    await this.o365ApiService.getAddressValidations().toPromise().then((res:any)=>{
      this.countryAddressValidations = res.data;
    }).catch(err=>{
      console.error(err);
    });

    this.marketCategoryLoading = true;
    await this.o365ApiService.getMarketCategories().toPromise().then((res:any)=>{
      this.marketCategories = res.data;
      this.marketCategoryLoading = false;
    }).catch(err=>{
      console.error(err);

    })
  }

  loadTranslatedWordData() {
    if(this.tenantType == 'transit') {
      this.isTransitTenant = true;
      this.breadcrumbTitle = this.translate('o365.transittenant');
    }
    else {
      this.breadcrumbTitle = this.translate('o365.createnewtenant');
    }
  }

  onCountryChanged(countryId:any, addressType:string){
    if(countryId == undefined || countryId == null || countryId < 1){
      return;
    }

    if(addressType == 'contact'){
      // reset
      this.statesContact.length = 0;
      this.citiesContact.length = 0;
      this.contactStateIdFormControl.patchValue(0);
      this.contactCityIdFormControl.patchValue(0);

      // find contact address validation
      this.selectedContactAddressValidation = this.countryAddressValidations.find(cav=>cav.countryId == countryId);
      // not exists then set error to invalid the form
      if(!this.selectedContactAddressValidation){
        this.contactCountryIdFormControl.setErrors({ invalidAddressValidation: true });
        this.alertService.showError('Something went wrong. Please try again later.')
        return;
      }
      this.updateAddressValidation(addressType);

      // show contact address form
      this.showContactAddressFormInputs = true;



      this.contactStateLoading = true;
      this.countriesService.getStates(countryId).subscribe((res:any) => {
        this.statesContact = res.data;
        this.contactStateLoading = false;

      });
    }else if(addressType == 'billing'){
      // reset
      this.statesBilling.length = 0;
      this.citiesBilling.length = 0;
      this.billingStateIdFormControl.patchValue(0);
      this.billingCityIdFormControl.patchValue(0);

      // find billing address validation
      this.selectedBillingAddressValidation = this.countryAddressValidations.find(cav=>cav.countryId == countryId);
      // not exists then set error to invalid the form
      if(!this.selectedBillingAddressValidation){
        this.billingCountryIdFormControl.setErrors({ invalidAddressValidation: true });
        this.alertService.showError('Something went wrong. Please try again later.')
        return;
      }

      this.updateAddressValidation(addressType);

       // show billing address form
       this.showBillingAddressFormInputs = true;

       this.billingStateLoading = true;
      this.countriesService.getStates(countryId).subscribe((res:any) => {
        this.statesBilling = res.data;
        this.billingStateLoading = false;
      });
    }
  }

  onStateChanged(stateId:any, addressType:string){
    if(stateId == undefined || stateId == null || stateId < 1){
      return;
    }

    if (addressType == 'contact') {
      this.citiesContact.length = 0;
      this.contactCityIdFormControl.patchValue(0);
      this.contactCityLoading = true;
      this.countriesService.getCities(stateId).subscribe((res: any) => {
        this.citiesContact = res.data;
        this.contactCityLoading = false;
      });
    } else if (addressType == 'billing') {
      this.citiesBilling.length = 0;
      this.billingCityIdFormControl.patchValue(0);
      this.billingCityLoading = true;
      this.countriesService.getCities(stateId).subscribe((res: any) => {
        this.citiesBilling = res.data;
        this.billingCityLoading = false;
      });
    }
  }

  onCityChanged(cityId:any, addressType:string){
    if(cityId == undefined || cityId == null || cityId < 1){
      return;
    }

    if (addressType == 'contact') {
      if(Number(cityId) == 50000){
        this.tenantForm.get('contact_address_city_other').setValidators([Validators.required]);
        this.tenantForm.get('contact_address_city_other').updateValueAndValidity();
        this.tenantFormStates.contactOtherCityRequired = true;
        this.contactOtherCitySelected = true;
      }else{
        this.contactOtherCityFormControl.patchValue('');
        this.tenantForm.get('contact_address_city_other').clearValidators();
        this.tenantForm.get('contact_address_city_other').updateValueAndValidity();
        this.tenantFormStates.contactOtherCityRequired = false;
        this.contactOtherCitySelected = false;
      }
    }else if(addressType == 'billing'){
      if(Number(cityId) == 50000){
        this.tenantForm.get('billing_address_city_other').setValidators([Validators.required]);
        this.tenantForm.get('billing_address_city_other').updateValueAndValidity();
        this.tenantFormStates.billingOtherCityRequired = true;
        this.billingOtherCitySelected = true;
      }else{
        this.billingOtherCityFormControl.patchValue(null);
        this.tenantForm.get('billing_address_city_other').clearValidators();
        this.tenantForm.get('billing_address_city_other').updateValueAndValidity();
        this.tenantFormStates.billingOtherCityRequired = false;
        this.billingOtherCitySelected = false;
      }
    }
  }

  updateAddressValidation(addressType:string){
    if(addressType == 'contact'){
      let postcodeFormControl = this.contactPostcodeFormControl;

      // set 'postcode' validation if true
      if(this.selectedContactAddressValidation.isPostCodeRequired){
        let regex = this.selectedContactAddressValidation.postalCodeRegex;
        postcodeFormControl.setValidators([Validators.required,Validators.pattern(regex)]);
        this.tenantFormStates.contactPostcodeRequired = true;
      }else{
        postcodeFormControl.clearValidators();
        this.tenantFormStates.contactPostcodeRequired = false;
      }

      // if 'postcodeFormControl' have value already then mark as touched
      if(postcodeFormControl.value){
        postcodeFormControl.markAsTouched();
      }
      // alway reset it
      postcodeFormControl.patchValue('');

      if(this.selectedContactAddressValidation.isCompanyRegistrationNumberRequired){
        let regex = this.selectedContactAddressValidation.companyRegistrationNumberRegex;
        this.companyRegistrationNumberFormControl.setValidators([Validators.required, Validators.pattern(regex)]);
        this.tenantFormStates.companyRegistrationNumberRequired = true;
      }else{
        this.companyRegistrationNumberFormControl.clearValidators();
        this.tenantFormStates.companyRegistrationNumberRequired = false; 
      }

      if(this.companyRegistrationNumberFormControl.value){
        this.companyRegistrationNumberFormControl.markAsTouched();
      }
      this.companyRegistrationNumberFormControl.patchValue('');
    }
    else if(addressType == 'billing'){
      let postcodeFormControl = this.billingPostcodeFormControl;

      // enable 'postcode' validation if true
      if(this.selectedBillingAddressValidation.isPostCodeRequired){
        let regex = this.selectedBillingAddressValidation.postalCodeRegex;
        postcodeFormControl.setValidators([Validators.required,Validators.pattern(regex)]);
        this.tenantFormStates.billingPostcodeRequired = true;
      }else{
        postcodeFormControl.clearValidators();
        this.tenantFormStates.billingPostcodeRequired = false;
      }

      // if 'postcodeFormControl' have value already then mark as touched
      if(postcodeFormControl.value){
        postcodeFormControl.markAsTouched();
      }
      // alway reset it
      postcodeFormControl.patchValue('');
    }

  }

  onDefaultDomainPrefixKeyUpEvent(event:any){
    this.defaultDomainPrefixValidateSuccess = false;

    let domainPrefixFormControl = this.defaultDomainPrefixFormControl;

    if (domainPrefixFormControl.hasError('required') || domainPrefixFormControl.hasError('pattern')) {
      // dont check input if has other errors
      domainPrefixFormControl.markAsTouched();
      return;
    }

    clearTimeout(this.defaultDomainPrefixDebounceTimer);

    this.defaultDomainPrefixDebounceTimer = setTimeout(() => {
      this.defaultDomainPrefixLoading = true;
      let value: string = event.target.value;

      // validate domain prefix
      this.o365ApiService.validateDomainPrefix(value).pipe(finalize(()=>{
        this.defaultDomainPrefixLoading = false;
      })).subscribe((res: any) => {
        domainPrefixFormControl.setErrors(null);
        if (res.data.data.message === true) {
          this.defaultDomainPrefixValidateSuccess = true;
        } else {
          this.defaultDomainPrefixValidateSuccess = false;
          domainPrefixFormControl.setErrors({ invalidDomain: true });
        }
      }, err => {
        console.error(err);
        domainPrefixFormControl.setErrors({ validateDomainPrefixError: true })
      });

    }, 1500);

  }

  onUseContactAsBillingChanged(event:any){
    let checked:boolean = event.target.checked;

    // alway disable 1st
    this.tenantForm.get('billing_address_country_id').disable();
    this.tenantForm.get('billing_address_state_id').disable();
    this.tenantForm.get('billing_address_city_id').disable();
    this.tenantForm.get('billing_address_city_other').disable();
    this.tenantForm.get('billing_address_postcode').disable();
    this.tenantForm.get('billing_address_street1').disable();
    this.tenantForm.get('billing_address_street2').disable();
    this.tenantForm.get('billing_address_street3').disable();

    if(!checked){
      this.tenantForm.get('billing_address_country_id').patchValue(0);
      this.tenantForm.get('billing_address_state_id').patchValue(0);
      this.tenantForm.get('billing_address_city_id').patchValue(0);
      this.tenantForm.get('billing_address_city_other').patchValue(null);

      this.tenantForm.get('billing_address_country_id').enable();
      this.tenantForm.get('billing_address_state_id').enable();
      this.tenantForm.get('billing_address_city_id').enable();
      this.tenantForm.get('billing_address_city_other').enable();
      this.tenantForm.get('billing_address_postcode').enable();
      this.tenantForm.get('billing_address_street1').enable();
      this.tenantForm.get('billing_address_street2').enable();
      this.tenantForm.get('billing_address_street3').enable();
    }

    this.useContactAddressAsBillingAddress = checked;
  }

  onMicrosoftQualificationChanged(value:any){
    if(this.isTransitTenant){
      // if is transit tenant type, then do nothing
      return;
    }

    this.showAcademicQualificationInfoMessage = false;
    this.showNonProfitQualificationInfoMessage = false;

    let category = this.marketCategories.find(c=>c.id == value);
    if(category){
      if(category.name == 'Academic'){
        this.showAcademicQualificationInfoMessage = true;
        this.microsoftMarketCategoryIdFormControl.setErrors({notAllowed:true});
      }else if(category.name == 'NotForProfit'){
        this.showNonProfitQualificationInfoMessage = true;
        this.microsoftMarketCategoryIdFormControl.setErrors({notAllowed:true});
      }else{
        this.microsoftMarketCategoryIdFormControl.setErrors(null);

        this.microsoftMarketCategoryIdFormControl.markAsTouched();
        this.microsoftMarketCategoryIdFormControl.updateValueAndValidity();
      }
    }
  }

  generateRandomPassword(){
   let generatedPassword:string = RandomUtil.generateChars(20);
   this.passwordFormControl.patchValue(generatedPassword);
   this.passwordFormControl.markAsTouched();
  }

  submit(){
    this.createTenantSubmitBtnPressed = true;
    this.errorCheck = false;

    if(this.tenantForm.invalid){
      this.alertService.showError('Invalid form.');
      this.createTenantSubmitBtnPressed = false;
      return;
    }

    let data: any = {};

    data.use_transit_tenant= this.isTransitTenant;

    data.username = this.tenantForm.value.username;
    data.password = this.tenantForm.value.password;
    data.company_name = this.tenantForm.value.company_name;
    data.company_registration_number = this.tenantForm.value.company_registration_number;
    data.contact_email_address= this.tenantForm.value.contact_email_address;
    data.alt_email_address= this.tenantForm.value.alt_email_address;
    data.contact_first_name= this.tenantForm.value.contact_first_name;
    data.contact_last_name= this.tenantForm.value.contact_last_name;
    data.contact_phone_no= this.tenantForm.value.contact_phone_no;
    data.default_domain_prefix= this.tenantForm.value.default_domain_prefix;
    data.microsoft_market_category_id= this.tenantForm.value.microsoft_market_category_id;

    data.contact_address_country_id= this.tenantForm.value.contact_address_country_id;
    data.contact_address_state_id= this.tenantForm.value.contact_address_state_id;
    data.contact_address_city_id= this.tenantForm.value.contact_address_city_id;
    data.contact_address_city_other= this.tenantForm.value.contact_address_city_other;
    data.contact_address_postcode= this.tenantForm.value.contact_address_postcode;
    data.contact_address_street1= this.tenantForm.value.contact_address_street1;
    data.contact_address_street2= this.tenantForm.value.contact_address_street2;
    data.contact_address_street3= this.tenantForm.value.contact_address_street3;

    data.billing_address_country_id= this.tenantForm.value.billing_address_country_id;
    data.billing_address_state_id= this.tenantForm.value.billing_address_state_id;
    data.billing_address_city_id= this.tenantForm.value.billing_address_city_id;
    data.billing_address_city_other= this.tenantForm.value.billing_address_city_other;
    data.billing_address_postcode= this.tenantForm.value.billing_address_postcode;
    data.billing_address_street1= this.tenantForm.value.billing_address_street1;
    data.billing_address_street2= this.tenantForm.value.billing_address_street2;
    data.billing_address_street3= this.tenantForm.value.billing_address_street3;

    data.is_marketing_promo= this.tenantForm.value.is_marketing_promo;
    data.include_partner = this.tenantForm.value.include_partner;

    // override billing address using contact address
    if(this.useContactAddressAsBillingAddress){
      data.billing_address_country_id = data.contact_address_country_id;
      data.billing_address_state_id = data.contact_address_state_id;
      data.billing_address_city_id = data.contact_address_city_id;
      data.billing_address_city_other = data.contact_address_city_other;
      data.billing_address_postcode = data.contact_address_postcode;
      data.billing_address_street1 = data.contact_address_street1;
      data.billing_address_street2 = data.contact_address_street2;
      data.billing_address_street3 = data.contact_address_street3;
    }

    let phoneNumber = data.contact_phone_no["internationalNumber"];
    data.contact_phone_no = phoneNumber.replace(" ", ".");

    console.log('tenant form data', data);

    this.createTenant(data);
  }

  createTenant(data: any){

    // for create new tenant
    if(!this.isTransitTenant){
      // validate data inputs 1st
      this.o365ApiService.createTenantValidate(data).subscribe((validationResult: any) => {
        // then post to create tenant endpoint
        this.o365ApiService.createTenant(data).subscribe((res:any)=>{
          this.successMessage = res.message;
          this.successCheck = true;
          ScrollUtil.scrollToTop();

          setTimeout(() => {
            let tenantId: number = res.data.response.data.tenant_id;
            if(tenantId){
              this.router.navigate(['/microsoftcsp/tenants', tenantId]);
            }
          }, 3000);

        },err =>{
          this.handleCreateTenantErrors(err);
          this.createTenantSubmitBtnPressed = false;
          this.errorCheck = true;
          ScrollUtil.scrollToTop();
        });
      }, err => {
        this.handleValidateTenantErrors(err);
        this.createTenantSubmitBtnPressed = false;
        this.errorCheck = true;
        ScrollUtil.scrollToTop();
      });
    }else{
      // else for create transit tenant

      // validate data inputs 1st
      this.o365ApiService.transitTenantValidate(data).subscribe((validationResult: any) => {
        // then post to transit tenant endpoint
        this.o365ApiService.transitTenant(data).subscribe((res:any)=>{
          console.log(res);
          this.successMessage = 'Initiated transit tenant.';
          this.successCheck = true;
          ScrollUtil.scrollToTop();

          this.tenantId = res.data.tenant.webnic_tenant_id;
          this.partnerConnectUrl = res.data.transit_url.data.partner_connect_link;

          this.currentTab = 'partnerConnect';

        },err =>{
          this.handleCreateTenantErrors(err);
          this.createTenantSubmitBtnPressed = false;
          this.errorCheck = true;
          ScrollUtil.scrollToTop();
        });
      }, err => {
        this.handleValidateTenantErrors(err);
        this.createTenantSubmitBtnPressed = false;
        this.errorCheck = true;
        ScrollUtil.scrollToTop();
      });
    }
  }

  openPartnerConnectUrl(){
    window.open(this.partnerConnectUrl, '_blank');
  }

  goToTenantDetails(){
    if(this.tenantId){
      this.router.navigate(['/microsoftcsp/tenants', this.tenantId]);
    }
    else{
      // tenantId is null then navigate back to tenant management
      this.router.navigate(['/microsoftcsp/tenants']);
    }
  }


  handleValidateTenantErrors(err:any){
    if (err.error.message == 'The given data was invalid.') {
      let er = [];
      let ers = err.error.errors;
      console.log('errors', ers);

      Object.keys(ers).forEach((key) => {
        er.push(ers[key]);
      });
      this.errors = er;
    } else {
      let er = [];
      let ers = err.error.error;
      ers.forEach((e: any) => {
        er.push(e.detail);
      });
      this.errors = er;
      console.log('this.errors', ers);
    }
  }

  handleCreateTenantErrors(err:any){
    console.error('create tenant error', err);

    if(err.error && err.error.message){
      this.errors.push(err.error.message);
    }
  }

  errorClosePressed(){
    this.errorCheck = false;
    this.errors.length = 0;
  }
  successClosePressed(){
    this.successCheck = false;
    this.successMessage = 'Create tenant successfully';
  }

  goToPartnerConnectGuidelines(event: any) {
    event.preventDefault();
    window.open('/microsoftcsp/tenants/partner-connect/guidelines', '_blank');
  }

}
