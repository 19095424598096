<div class="row">
  <div class="col-12">
    <h3>{{ 'o365.addadomain' | translate }}</h3>
    <p>{{ 'o365.ifowndomain' | translate }}</p>
    <form [formGroup]="newDomainNameForm" style="overflow-y: visible">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="group form-group">
            <label for="domainName">
              <span>{{ 'word.domainname' | translate }}</span>
            </label>
            <input id="domainName" name="domainName" type="text" class="form-control" formControlName="domainName" placeholder="webnic.com" />

            <div *ngIf="domainNameFormControl.touched">
              <span class="text-danger" *ngIf="domainNameFormControl.hasError('pattern')">{{ 'error.domain.pattern' | translate }}</span>
            </div>
            <div *ngIf="domainNameFormControl.touched">
              <span class="text-danger" *ngIf="domainNameFormControl.hasError('invalidDomain')">{{ 'error.invaliddomain' | translate }}</span>
            </div>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-primary m-3" (click)="onCompleteStep()" [disabled]="newDomainNameForm.invalid || createDomainLoading">
        <span>{{ 'button.usethisdomain' | translate }}</span>
        <i class="ml-1 fa fa-spinner fa-spin" *ngIf="createDomainLoading"></i>
      </button>

    </form>
  </div>
</div>
