<main id="manage-order" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'word.orders' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.orderdetails' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row" *ngIf="errorCheck">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{{ 'word.error.oops' | translate }}</strong>&nbsp;{{ errorMessage }}
            <button type="button" class="close" (click)="closeErrorPressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="successCheck">
        <div class="col-12">
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>{{ 'word.success' | translate }}</strong>&nbsp;{{ successMessage }}
            <button type="button" class="close" (click)="closeSuccessPressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-7">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="2">{{ 'word.productinfo' | translate }}</th>
                </tr>
              </thead>
              <tbody class="bg-white" *ngIf="order">
                <tr>
                  <td>{{ 'word.products' | translate }}</td>
                  <td>{{ order?.order_items[0].product }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.productcategory' | translate }}</td>
                  <td>{{ order?.category }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.orderdate' | translate }}</td>
                  <td>{{ order?.order_date | date:'medium' }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.expirationdate' | translate }}</td>
                  <td>{{ subscription?.dtend | date:'medium' }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.orderstatus' | translate }}</td>
                  <td>{{order?.status}}</td>
                </tr>
                <tr>
                  <td>{{ 'o365.subscriptionstatus' | translate }}</td>
                  <td>{{subscription?.status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-5">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="2">{{ 'word.productdetails' | translate }}</th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr>
                  <td>{{ 'o365.tenantdomainprefix' | translate }}</td>
                  <td>{{ order?.order_items[0].item_detail.tenant_domain }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.licensecount' | translate }}</td>
                  <td>{{ order?.order_items[0].license_count }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.subscribedmonth' | translate }}</td>
                  <td>{{ order?.order_items[0].subscribed_month }}</td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-7">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="3">{{ 'word.action' | translate }}</th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr *ngIf="subscription?.product_id != '1'">
                  <td>{{ 'o365.button.setupdomain' | translate }}</td>
                  <td colspan="2">
                    <button class="btn btn-block btn-primary" (click)="setupDomainPressed()" [disabled]="setupDomainBtnPressed">{{ 'o365.button.setupdomain' | translate }}<span *ngIf="setupDomainBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span></button>
                  </td>
                </tr>
                <tr *ngIf="subscription?.status == 'Active' || subscription?.status == 'Expired'">
                  <td>{{ 'o365.renewsubscription' | translate }}</td>
                  <td colspan="2">
                    <button class="btn btn-block btn-primary" (click)="renewPressed()"
                            [disabled]="renewBtnPressed">{{ 'button.renew' | translate }}<span *ngIf="renewBtnPressed">&nbsp;<i
                            class="fa fa-spinner fa-spin"></i></span></button>
                  </td>
                </tr>
                <tr *ngIf="subscription?.status == 'Active'">
                  <td>{{ 'o365.suspendsubscription' | translate }}</td>
                  <td colspan="2">
                    <button class="btn btn-block btn-primary" (click)="suspendPressed()"
                            [disabled]="suspendBtnPressed">{{ 'button.suspend' | translate }}<span *ngIf="suspendBtnPressed">&nbsp;<i
                           class="fa fa-spinner fa-spin"></i></span></button>
                  </td>
                </tr>
                <tr *ngIf="subscription?.status == 'Suspended'">
                  <td>{{ 'o365.resumesubscription' | translate }}</td>
                  <td colspan="2">
                    <button class="btn btn-block btn-primary" (click)="resumePressed()"
                            [disabled]="resumeBtnPressed">{{ 'button.resume' | translate }}<span *ngIf="resumeBtnPressed">&nbsp;<i
                           class="fa fa-spinner fa-spin"></i></span></button>
                  </td>
                </tr>
                <tr *ngIf="subscription?.status == 'Active'">
                  <td>{{ 'o365.updatelicense' | translate }}</td>
                  <td>
                    <input type="number" step="1" min="1" [(ngModel)]="quantity"
                           value="{{ subscription.license_count }}" class="form-control">
                  </td>
                  <td>
                    <button class="btn btn-block btn-primary" (click)="updateQuantityPressed()"
                            [disabled]="updateQuantityBtnPressed">{{ 'button.update' | translate }}<span *ngIf="updateQuantityBtnPressed">&nbsp;<i
                           class="fa fa-spinner fa-spin"></i></span></button>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'word.autorenew' | translate }}</td>
                  <td colspan="2">
                    <button class="btn btn-block btn-primary" *ngIf="!subscription?.is_auto_renew"
                            (click)="enableAutoRenewPressed()"
                            [disabled]="enableAutoRenewBtnPressed">{{ 'button.enable' | translate }}<span
                            *ngIf="enableAutoRenewBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span></button>
                    <button class="btn btn-block btn-primary" *ngIf="subscription?.is_auto_renew"
                            (click)="disableAutoRenewPressed()"
                            [disabled]="disableAutoRenewBtnPressed">{{ 'button.disable' | translate }}<span
                            *ngIf="disableAutoRenewBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span></button>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'o365.historicalorderref' | translate }}</td>
                  <td colspan="2">
                    <button (click)="historicalOrderPressed()" [disabled]="historicalOrderBtnPressed"
                            class="btn btn-block btn-primary">{{ 'button.go' | translate }}</button>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'o365.openportal' | translate }}</td>
                  <td colspan="2">
                    <a class="btn btn-block btn-primary" href="https://login.microsoftonline.com" target="_blank">{{ 'button.go' | translate }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="float-right">
            <a routerLink="/o365-order-management" class="btn btn-dark btn-lg px-5 mb-4">{{ 'button.back' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->

  <!-- Modal -->
  <div class="modal fade" id="historicalOrderModal" tabindex="-1" role="dialog" aria-labelledby="historicalOrderModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="historicalOrderModalTitle">{{ 'o365.historicalorderref' | translate }}</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-bordered table-sm">
            <thead>
              <th>{{ 'word.orderid' | translate }}</th>
              <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let order of history">
                <td>{{ order_id }}</td>
                <td><a (click)="historicalOrderView(order_id)" style="cursor: pointer;color: white;" class="btn btn-sm btn-block btn-primary">{{ 'button.view' | translate }}</a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'button.close' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</main>
