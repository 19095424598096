import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { StoreFrontService } from '../../service/store-front.service';
import { StoreService } from '../../service/store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;
declare var jQuery: any;
declare var appJS: any;
@Component({
  selector: 'app-storefront-create-two',
  templateUrl: './storefront-create-two.component.html',
  styleUrls: ['./storefront-create-two.component.css']
})
export class StorefrontCreateTwoComponent implements OnInit {

  // @Viewchild

  themes = [];
  covers = [];
  storeTheming: UntypedFormGroup;
  selectedTheme;
  selectedPic;
  errorCheck = false;
  errors = [];
  files;
  filestring;
  logo;
  title;
  text;
  selectedThemePreview
  themeClass = {
    "Autumn": "bg-autumn",
    "Marble": "bg-marble",
    "Ocean": "bg-ocean",
    "Forest": "bg-forest"
  }
  customCoverPreview;

  storefrontId: string;
  edited: boolean = false;
  updateCustomPhoto = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public storeFrontService: StoreFrontService,
    public storeService: StoreService,
    public router: Router,
    public domSanitizer: DomSanitizer,
    public route: ActivatedRoute
  ) {
      this.storeTheming = this.formBuilder.group({
        theme_id: ['1', Validators.required],
        cover_preview_url: ['', Validators.required],
      });
      
    }
    
    ngOnInit() {
      
    if (this.route.snapshot.paramMap.get('id')) {
      this.storefrontId = this.route.snapshot.paramMap.get('id')
      this.edited = true

      this.storeFrontService.getAllThemes().subscribe(resTheme => {
        this.themes = resTheme['data'];
        this.selectedTheme = this.themes[(this.storeService.themeId-1)];
        this.selectedThemePreview = this.themeClass[this.themes[(this.storeService.themeId - 1)]["theme_name"]]
        console.log(this.selectedThemePreview)

        this.storeFrontService.getAllCovers().subscribe(resCover => {
          this.covers = resCover['data'];
          this.selectedPic = this.covers[0]
          
          this.selectedPic = this.storeService.coverObject;
          
          if(this.storeService.updatedLogo) {
            this.logo = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + this.storeService.logo);
          }else {
            this.logo = this.storeService.logo;
          }
          this.title = this.storeService.coverTitle['en']
          this.text = this.storeService.coverText['en']

        });

      });

    }
    else {
      this.edited = false;

      this.storeFrontService.getAllThemes().subscribe(resp => {
        this.themes = resp['data'];
        this.selectedTheme = this.themes[0];
        this.selectedThemePreview = this.themeClass[this.themes[0]["theme_name"]]
        console.log(this.selectedThemePreview)
      });
      this.storeFrontService.getAllCovers().subscribe(resp => {
        this.covers = resp['data'];
        this.selectedPic = this.covers[0];
      });
      this.logo = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + this.storeService.logo);
      this.title = this.storeService.coverTitle['en']
      this.text = this.storeService.coverText['en']
    }
    // appJS.init()

  /*   $('#upload-photo').bind('change', function() {
            alert('This file size is: ' + this.files[0].size/1024/1024 + "MB");
      });*/

  }

  changeTheme(theme) {
    this.selectedTheme = theme;
    this.storeTheming.patchValue({
      cover_preview_url: theme.id,
    });
    this.selectedThemePreview = this.themeClass[theme.theme_name]

  }

  thumbnailSelected(thumbnail) {
    event.stopPropagation();
    this.filestring = "";
    this.selectedPic = thumbnail;
    this.storeTheming.patchValue({
      cover_preview_url: thumbnail.preview_url,
    });
  }

  nextPressed() {
    // if (this.storeTheming.valid)
    //{
    this.storeService.setupThemeAndCover(this.selectedTheme.id, this.selectedPic.id, this.selectedPic.cover_url, this.filestring, this.selectedThemePreview);
    if(this.edited) {
      this.router.navigate(['/manage-storefront-3/'+this.storefrontId])
    } else {
      this.router.navigate(['/create-storefront-3'])
    }
  }

  getFiles(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.filestring = btoa(binaryString);

    console.log("asdasda", this.filestring)
    // this.customCoverPreview = this.domSanitizer.bypassSecurityTrustUrl(`url('data:image/jpeg;base64, ${this.filestring}')`);
    this.customCoverPreview = `url('data:image/jpeg;base64, ${this.filestring}')`;
    console.log(this.customCoverPreview)
  }
}
