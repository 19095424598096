import { LanguageTranslateService } from './../../../../service/language-translate.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { O365HttpService } from 'src/app/service/o365-http.service';
import { AlertService } from 'src/app/service/alert.service';
import { ScrollUtil } from 'src/app/util/scroll-util';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'microsoftcsp-subscription-detail',
  templateUrl: './microsoftcsp-subscription-detail.component.html',
  styleUrls: ['./microsoftcsp-subscription-detail.component.css']
})
export class MicrosoftCSPSubscriptionDetailComponent implements OnInit {

  tenantId: number;
  subscriptionId: number;
  loading: boolean = false;

  subscriptionInfo: any;

  errorCheck: boolean = false;
  errors: any[] = [];

  successCheck: boolean = false;
  successMessage: string = '';

  modalRef: BsModalRef;

  updateLicenseQuantityForm: UntypedFormGroup;
  currentSubcriptionProductInfo: any;
  displaySubscriptionMonthly: boolean = false;
  displaySubscriptionAnnually: boolean = false;

  enableResumeSubscription: boolean = false;
  enableSuspendSubscription: boolean = false;
  enableUpdateLicenseQuantity: boolean = false;
  enableRenewSubscription: boolean = false;
  enableActionEnableAutoRenew: boolean = false;
  enableActionDisableAutoRenew: boolean = false;

  resumeSubscriptionBtnPressed: boolean = false;
  suspendSubscriptionBtnPressed: boolean = false;
  updateLicenseQuantityBtnPressed: boolean = false;
  renewSubscriptionBtnPressed: boolean = false;
  enableAutoRenewBtnPressed: boolean = false;
  disableAutoRenewBtnPressed: boolean = false;

  allBtnDisable: boolean = false;
  updateAllBtnDisableToFalse = () => {this.allBtnDisable = false;}

  productRules: any[] = [];
  currentProductRule: any;

  maxAddQuantityAllowed: number = 0;

  subscriptionStatusValues = [
    {code:'0',name:'Pending', value:'PENDING'},
    {code:'1',name:'Active', value:'ACTIVE'},
    {code:'3',name:'In Progress', value:'INPROGRESS'},
    {code:'4',name:'Suspended', value:'SUSPENDED'},
    {code:'5',name:'Expired', value:'EXPIRED'}
  ];

  lang = "eng";
  constructor(
    private o365ApiService: O365HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private languageTranslateService: LanguageTranslateService,
  ) { }

  ngOnInit() {
    this.lang = this.languageTranslateService.userLanguage$.value;
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;
      this.subscriptionId = +params['subscriptionId'] || null;

      this.getSubscriptionDetails();
    });
  }

  getSubscriptionDetails() {
    this.o365ApiService.getSubscriptionsDetails(this.subscriptionId)
      .pipe(finalize(this.updateAllBtnDisableToFalse))
      .subscribe((res: any) => {
        this.subscriptionInfo = res.data;
        console.log(this.subscriptionInfo);

        this.checkAllowedActions();
      }, err => {
        console.error(err);
      });
  }

  checkAllowedActions() {
    // reset
    this.enableResumeSubscription = false;
    this.enableSuspendSubscription = false;
    this.enableUpdateLicenseQuantity = false;
    this.enableRenewSubscription = false;
    this.enableActionEnableAutoRenew = false;
    this.enableActionDisableAutoRenew = false;

    this.checkResumeOrSuspendSubscriptionAction();
    this.checkUpdateLicenseQuantity();
    this.checkRenewSubscriptionAction();
    this.checkEnableOrDisableAutoRenew();
  }

  checkResumeOrSuspendSubscriptionAction() {
    if (this.getCurrentStatus() == 'ACTIVE' && this.subscriptionInfo.billingCycle == 'Monthly') {
      this.enableSuspendSubscription = true;
    } else if (this.getCurrentStatus() == 'SUSPENDED') {
      this.enableResumeSubscription = true;
    }
  }

  checkUpdateLicenseQuantity() {
    if (this.getCurrentStatus() == 'ACTIVE') {
      this.enableUpdateLicenseQuantity = true;
    }
  }

  checkRenewSubscriptionAction() {
    if (this.getCurrentStatus() == 'ACTIVE' || this.getCurrentStatus() == 'EXPIRED') {
      this.enableRenewSubscription = true;
    }
  }

  checkEnableOrDisableAutoRenew() {
    // check auto renew flag
    if(this.subscriptionInfo.isAutoRenew){
      this.enableActionDisableAutoRenew = true;
    }else{
      this.enableActionEnableAutoRenew = true;
    }
  }

  openUpdateLicenseQuantityModal(template:TemplateRef<any>){
    this.updateLicenseQuantityBtnPressed = true;
    // create form if not exist
    if (!this.updateLicenseQuantityForm) {
      this.updateLicenseQuantityForm = this.formBuilder.group({
        licenseQuantity: [0,[Validators.required,Validators.min(1)]],
        totalPrice:[{value:'0.00',disabled:true}]
      });
    }
    let productSearch:any = {
      product_id: this.subscriptionInfo.productId
    }

    this.o365ApiService.getTenantSubscriptionProducts(this.tenantId,productSearch).subscribe((res:any)=>{
      this.currentSubcriptionProductInfo = res.data[0];

      this.o365ApiService.getProductRule(this.subscriptionInfo.productId).subscribe((ruleRes:any)=>{
        this.productRules = ruleRes.data;
        this.updateProductValidationRules();
        this.updateAddLicenseTotalPrice();

        this.openModal(template);
        this.updateLicenseQuantityBtnPressed = false;
      }, err => {
        console.error('getProductRule error', err);
      });
    },err=>{
      console.error(err);
      this.alertService.showError('Something went wrong. Please try again later.');
      this.handleError(err);
    });


  }

  get licenseQuantityFormControl(){return this.updateLicenseQuantityForm.get('licenseQuantity');}
  get addLicenseTotalPriceFormControl(){return this.updateLicenseQuantityForm.get('totalPrice');}


  updateProductValidationRules(){
    if (this.productRules.length < 1) {
      return;
    }
    // reset
    this.currentProductRule = null;

    let currentSubscriptionType:string = this.subscriptionInfo.billingCycle;
    // find rule based on billingCycle
    this.currentProductRule = this.productRules.find(r => {
      let ruleBillingCycle: string = r.billingCycle;
      if (ruleBillingCycle && ruleBillingCycle.toLowerCase() == currentSubscriptionType.toLowerCase()) {
        return true;
      }
      return false;
    });

    // not found then do nothing
    if (!this.currentProductRule) {
      console.error('Product rule not found !!');
      return;
    }
    // max quantity rule not found then do nothing
    if(!this.currentProductRule.maxQuantity){
      console.error('Min quantity or max quantity product rule not found !!');
      return;
    }

    // calculate the max license quantity allowed to add after minus current subscription licenses
    this.maxAddQuantityAllowed = Number(this.currentProductRule.maxQuantity) - Number(this.subscriptionInfo.licenseCount);

    this.licenseQuantityFormControl.setValidators([
      Validators.required,
      Validators.min(1),
      Validators.max(this.maxAddQuantityAllowed)
    ]);

    this.licenseQuantityFormControl.patchValue(1);
    this.licenseQuantityFormControl.markAsTouched();
    this.licenseQuantityFormControl.updateValueAndValidity();
  }

  suspendSubscription() {
    let callback = () => {
      this.suspendSubscriptionBtnPressed = true;
      this.allBtnDisable = true;
      this.o365ApiService.suspendSubscription(this.subscriptionId).subscribe((res: any) => {
        this.getSubscriptionDetails();
        this.alertService.showSuccess(res.message);
        this.suspendSubscriptionBtnPressed = false;
        ScrollUtil.scrollToTop();
      }, err => {
        console.error('suspend subscription error', err);
        let message: string = 'Failed to suspend subscription.';
        if (err.error && err.error.message) {
          message = err.error.message;
        }
        this.alertService.showError(message);
        this.handleError(err);
      })
    }

    let cLang = {
      eng: 'Are you sure to suspend the subscription?',
      cn: '确认停止订阅？',
      zh: '確認停止訂閱？'
    }

    this.alertService.showComfirmation(
      { message: cLang[this.lang] }
      , callback);
  }

  resumeSubscription() {
    let callback = () => {
      this.resumeSubscriptionBtnPressed = true;
      this.allBtnDisable = true;
      this.o365ApiService.resumeSubscription(this.subscriptionId).subscribe((res: any) => {
        console.log(res);
        this.getSubscriptionDetails();
        this.alertService.showSuccess(res.message);
        this.resumeSubscriptionBtnPressed = false;
        ScrollUtil.scrollToTop();
      }, err => {
        console.error('resume subscription error', err);
        let message: string = 'Failed to resume subscription.';
        if (err.error && err.error.message) {
          message = err.error.message;
        }
        this.alertService.showError(message);
        this.handleError(err);
      })
    }

    let cLang = {
      eng: 'Are you sure to resume the subscription?',
      cn: '确认继续订阅？',
      zh: '確認繼續訂閱？'
    }

    this.alertService.showComfirmation(
      { message: 'Are you sure to resume the subscription?' }
      , callback);
  }

  updateLicenseQuantity() {
    let callback = () => {
      this.updateLicenseQuantityBtnPressed = true;
      this.allBtnDisable = true;

      let addLicenseCount = Number(this.licenseQuantityFormControl.value);
      this.o365ApiService.updateSubscriptionLicenseQuantity(this.subscriptionId,addLicenseCount)
      .pipe(finalize(() => {this.modalRef.hide()} ))
      .subscribe((res: any) => {
        console.log(res);
        this.getSubscriptionDetails();
        this.updateLicenseQuantityBtnPressed = false;
        this.alertService.showSuccess(res.message);
        ScrollUtil.scrollToTop();
      }, err => {
        console.error('update license quantity subscription error', err);
        let message: string = 'Failed to update license quantity subscription.';
        if (err.error && err.error.message) {
          message = err.error.message;
        }
        this.alertService.showError(message);
        this.handleError(err);
      })
    }

    let cLang = {
      eng: 'Are you sure to add the license quantity of the subscription?',
      cn: '您确定要添加订阅的许可数量吗？',
      zh: '您確定要添加訂閱的許可數量嗎？'
    }

    this.alertService.showComfirmation(
      { message: cLang[this.lang] }
      , callback);
  }

  renewSubscription() {
    let callback = () => {
      this.renewSubscriptionBtnPressed = true;
      this.allBtnDisable = true;
      this.o365ApiService.renewSubscription(this.subscriptionId).subscribe((res: any) => {
        console.log(res);
        this.getSubscriptionDetails();
        this.alertService.showSuccess(res.message);
        this.renewSubscriptionBtnPressed = false;
        ScrollUtil.scrollToTop();
      }, err => {
        console.error('renew subscription error', err);
        let message: string = 'Failed to renew subscription.';
        if (err.error && err.error.message) {
          message = err.error.message;
        }
        this.alertService.showError(message);
        this.handleError(err);
      })
    }

    let cLang = {
      eng: 'Are you sure to renew the subscription?',
      cn: '您确定要更新订阅吗？',
      zh: '您確定要更新訂閱嗎？'
    }

    this.alertService.showComfirmation(
      { message: cLang[this.lang] }
      , callback);
  }

  enableAutoRenew() {
    let callback = () => {
      this.enableAutoRenewBtnPressed = true;
      this.allBtnDisable = true;
      this.o365ApiService.enableAutoRenewSubscription(this.subscriptionId).subscribe((res: any) => {
        console.log(res);
        this.getSubscriptionDetails();
        this.alertService.showSuccess(res.message);
        this.enableAutoRenewBtnPressed = false;
        ScrollUtil.scrollToTop();
      }, err => {
        console.error('enable auto renew error', err);
        let message: string = 'Failed to enable auto renew feature.';
        if (err.error && err.error.message) {
          message = err.error.message;
        }
        this.alertService.showError(message);
        this.handleError(err);
      })
    }

    let cLang = {
      eng: 'Are you sure to enable auto renew feature?',
      cn: '您确定启用自动续订功能吗？',
      zh: '您確定啟用自動續訂功能嗎？'
    }

    this.alertService.showComfirmation(
      {message: cLang[this.lang]},
       callback);
  }

  disableAutoRenew() {
    let callback = () => {
      this.disableAutoRenewBtnPressed = true;
      this.allBtnDisable = true;
      this.o365ApiService.disableAutoRenewSubscription(this.subscriptionId).subscribe((res: any) => {
        console.log(res);
        this.getSubscriptionDetails();
        this.alertService.showSuccess(res.message);
        this.disableAutoRenewBtnPressed = false;
        ScrollUtil.scrollToTop();
      }, err => {
        console.error('disable auto renew error', err);
        let message: string = 'Failed to disable auto renew feature.';
        if (err.error && err.error.message) {
          message = err.error.message;
        }
        this.alertService.showError(message);
        this.handleError(err);
      })
    }

    let cLang = {
      eng: 'Are you sure to disable auto renew feature?',
      cn: '您确定要关闭自动续订功能吗？',
      zh: '您確定要關閉自動續訂功能嗎？'
    }

    this.alertService.showComfirmation(
      {message: cLang[this.lang]},
       callback);
  }

  increaseOrDecreaseLicenseCount(type: string) {
    let currentLicenseCount: number = this.licenseQuantityFormControl.value;
    if (type == 'increase') {
      this.licenseQuantityFormControl.patchValue(currentLicenseCount + 1);
      this.updateAddLicenseTotalPrice();
    } else if (type == 'decrease' && currentLicenseCount - 1 > 0) {
      this.licenseQuantityFormControl.patchValue(currentLicenseCount - 1);
      this.updateAddLicenseTotalPrice();
    }
    this.licenseQuantityFormControl.markAsTouched();
  }

  updateAddLicenseTotalPrice(){
    this.displaySubscriptionMonthly = false;
    this.displaySubscriptionAnnually = false;

    // dont update if license quantity form is not valid
    if(this.licenseQuantityFormControl.invalid){
      this.addLicenseTotalPriceFormControl.patchValue('n/a');
      return;
    }

    // check currentSubcriptionProductInfo is exist
    if(!this.currentSubcriptionProductInfo){
      this.alertService.showError('Subscription price info not found. Please try again later.');
      this.licenseQuantityFormControl.setErrors({priceInfoNotFound: true});
      this.addLicenseTotalPriceFormControl.patchValue('n/a');
      return;
    }
    let productPrice = this.currentSubcriptionProductInfo.product_price;

    // check currentSubcriptionProductInfo.product_price is exist
    if(!productPrice){
      this.alertService.showError('Subscription price info not found. Please try again later.');
      this.licenseQuantityFormControl.setErrors({priceInfoNotFound: true});
      this.addLicenseTotalPriceFormControl.patchValue('n/a');
      return;
    }

    if (this.subscriptionInfo.billingCycle == 'Monthly') {
      // for monthly cycle
      if (!productPrice.monthly) {
        this.alertService.showError('Subscription price info not found. Please try again later.');
        this.addLicenseTotalPriceFormControl.patchValue('n/a');
        return;
      }
      let monthlyPrice = Number(productPrice.monthly);
      let licenseCount = Number(this.licenseQuantityFormControl.value);

      let totalPrice = monthlyPrice * licenseCount;
      this.addLicenseTotalPriceFormControl.patchValue(totalPrice.toFixed(2));
      this.displaySubscriptionMonthly = true;

    } else if (this.subscriptionInfo.billingCycle == 'Annual') {
      // for annually cycle
      if (!productPrice.annually) {
        this.alertService.showError('Subscription price info not found. Please try again later.');
        this.addLicenseTotalPriceFormControl.patchValue('n/a');
        return;
      }
      let annuallyPrice = Number(productPrice.annually);
      let licenseCount = Number(this.licenseQuantityFormControl.value);

      let totalPrice = annuallyPrice * licenseCount;
      this.addLicenseTotalPriceFormControl.patchValue(totalPrice.toFixed(2));
      this.displaySubscriptionAnnually = true;
    } else {
      // unknown
      this.alertService.showError('Subscription price info not found. Please try again later.');
      this.licenseQuantityFormControl.setErrors({priceInfoNotFound: true});
      this.addLicenseTotalPriceFormControl.patchValue('n/a');
    }
  }

  getCurrentStatus(): string {
    return this.getStatusValueByCode(this.subscriptionInfo.status);
  }

  getStatusValueByCode(code: any) {
    if (!code) {
      return '';
    }

    let codeAsString = String(code);
    let status = this.subscriptionStatusValues.find(status => status.code == codeAsString);

    if (status == undefined) {
      return '';
    }
    return status.value;
  }

  openModal(template: TemplateRef<any>, modalSize?: string) {
    if (this.modalRef) {
      this.modalRef.hide();
    }

    let modalConfig: any = {
      backdrop: true,
      ignoreBackdropClick: true
    };

    if (modalSize && modalSize == 'lg') {
      modalConfig.class = 'modal-lg';
    }

    this.modalRef = this.modalService.show(template, modalConfig);
  }

  handleError(err: any) {
    if(err.error && err.error.error){
      if(err.error.error.error && err.error.error.error.errors){
        try {
          let errs:any[] = err.error.error.error.errors;
          errs.forEach((e:any) => {
            this.errors.push(e.detail);
          });
        } catch (tryErr) {
          this.errors.push(String(err.error.message));
        }
      }else{
        try {
          let errs:any[] = err.error.error;
          errs.forEach((e:any) => {
            this.errors.push(e.detail);
          });
        } catch (tryErr) {
          // not array then should be single string
          this.errors.push(String(err.error.error));
        }
      }
    } else if (err.error && err.error.message) {
      this.errors.push(err.error.message);
    } else {
      this.errors.push(err.message);
    }
    this.errorCheck = true;
    ScrollUtil.scrollToTop();
  }

  errorClosePressed() {
    this.errorCheck = false;
    this.errors.length = 0;
  }
  successClosePressed() {
    this.successCheck = false;
    this.successMessage = '';
  }
}
