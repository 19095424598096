import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { StepModel } from '../../../../model/step.model';
import { Subscription } from 'rxjs';
import { StepsService } from '../../../../service/steps.service';
import { MicrosoftGraphService } from '../../../../service/microsoftcsp/microsoft-graph.service';
import { UserBasics } from 'src/app/model/microsoftcsp/new-user/user-basics.model';
import { UserLicenses } from 'src/app/model/microsoftcsp/new-user/user-licenses.model';
import { UserOptional } from 'src/app/model/microsoftcsp/new-user/user-optional.model';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';

@Component({
  selector: 'app-microsoftcsp-user-new',
  templateUrl: './microsoftcsp-user-new.component.html',
  styleUrls: ['./microsoftcsp-user-new.component.css'],
  providers: [StepsService]
})
export class MicrosoftCSPUserNewComponent implements OnInit, OnDestroy {
  currentStep: StepModel;
  currentStepSub: Subscription;

  domains: any[] = [];
  tenantId: number;

  _userBasics: UserBasics = null;
  _userLicenses: UserLicenses = null;
  _userOptional: UserOptional = null;

  newUserBasicsForm: UntypedFormGroup = null;
  newUserLicensesForm: UntypedFormGroup = null;
  newUserOptionalForm: UntypedFormGroup = null;

  constructor(
    private stepsService: StepsService,
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService,
    private microsoftGraphService: MicrosoftGraphService,
    private translateService: LanguageTranslateService
  ) { }

  ngOnInit(): void {
    this._userBasics = new UserBasics();
    this._userLicenses = new UserLicenses();
    this._userOptional = new UserOptional();

    this.currentStepSub = this.stepsService.getCurrentStep().subscribe((step: StepModel) => {
      this.currentStep = step;
    });

    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      if (!this.microsoftGraphService.adminConsent === true) {
        this.goToAdminConsentPage();
      }
    });
  }

  onNextStep() {
    if (this.validateWizardStepsValidity()) {
      if (!this.stepsService.isLastStep()) {
        this.stepsService.moveToNextStep();
      } else {
        this.onSubmit();
      }
    }
  }

  onPreviousStep() {
    this.stepsService.moveToPreviousStep();
  }

  onCustomStep(stepIndex: number) {
    this.stepsService.moveToStep(stepIndex);
  }

  async validateWizardStepsValidity() {
    await this.stepsService.getCurrentStep().toPromise().then((step: StepModel) => {
      const currentStep = step.stepIndex;
      switch (currentStep) {
        case 1:
          if (this.newUserBasicsForm.valid) {
            return true;
          }
          break;
        case 2:
          if (this.newUserLicensesForm.valid) {
            return true;
          }
          break;
        case 3:
          if (this.newUserOptionalForm.valid) {
            return true;
          }
          break;
        case 4:
          if (this.newUserBasicsForm.valid && this.newUserLicensesForm.valid && this.newUserOptionalForm.valid) {
            return true;
          }
          break;
        default:
          return false;
      }
    });
  }

  showButtonLabel() {
    return !this.stepsService.isLastStep() ? 'Continue' : 'Finish';
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks and unexpected angular errors
    if (this.currentStepSub) {
      this.currentStepSub.unsubscribe();
    }
  }

  onSubmit(): void {
    this.router.navigate(['/microsoftcsp/tenants', this.tenantId, 'users']);
  }

  onNewUserBasicsFormChanged(updatedNewUserBasicsForm: UntypedFormGroup) {
    this.newUserBasicsForm = updatedNewUserBasicsForm;
    this.onNextStep();
  }

  onNewUserLicensesFormChanged(updatedNewUserLicensesForm: UntypedFormGroup) {
    this.newUserLicensesForm = updatedNewUserLicensesForm;
    this.onNextStep();
  }

  onNewUserOptionalFormChanged(updatedNewUserOptionalForm: UntypedFormGroup) {
    this.newUserOptionalForm = updatedNewUserOptionalForm;
    this.onNextStep();
  }

  onFinishPageChanged() {
    this.onNextStep();
  }

  goToAdminConsentPage() {
    this.router.navigate(['/microsoftcsp/tenants/', this.tenantId, 'adminconsent']);
  }

  get userBasics() {
    return this._userBasics;
  }

  set userBasics(value: UserBasics) {
    this._userBasics = value;
  }

  get userLicenses() {
    return this._userLicenses;
  }

  set userLicenses(value: UserLicenses) {
    this._userLicenses = value;
  }

  get userOptional() {
    return this._userOptional;
  }

  set userOptional(value: UserOptional) {
    this._userOptional = value;
  }
}
