import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { MicrosoftGraphService } from '../../../../service/microsoftcsp/microsoft-graph.service';

@Component({
  selector: 'app-admin-consent',
  templateUrl: './admin-consent.component.html',
  styleUrls: ['./admin-consent.component.css']
})
export class AdminConsentComponent implements OnInit {

  tenantId: number;
  admin_consent = false;
  microsoftTenantId: string;
  state: string;
  adminConsentUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService,
    private microsoftGraphService: MicrosoftGraphService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      if (this.microsoftGraphService.wnTenantId === undefined || this.microsoftGraphService.wnTenantId == null) {
        this.goToTenantManagement();
      }

      this.getAdminConsentUrl();
    });  
  }

  getAdminConsentUrl() {
    localStorage.removeItem('tenant');

    this.microsoftGraphApiService.getAdminConsentUrl(this.tenantId.toString())
      .subscribe((res: any) => {
        this.adminConsentUrl = res.adminConsentUrl;

        const responseParam = new URL(res.adminConsentUrl).searchParams;
        this.state = responseParam.get('state');
        this.microsoftGraphService.state = this.state;

        const tenant: any = {
          wnTenantId: this.microsoftGraphService.wnTenantId,
          state: this.microsoftGraphService.state
        };

        localStorage.setItem('tenant', JSON.stringify(tenant));
      }, err => {
        console.error(err);
      });
  }

  goToTenantManagement() {
    this.router.navigate(['/microsoftcsp/tenants']);
  }

  onGrantAdminConsentPressed() {
    window.open(this.adminConsentUrl, '_self');
  }

}
