import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  apiUrl = environment.apiUrl;

  httpHeaders = {
    headers : new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
  }

  constructor(
    private http:HttpClient
  ) { }

  getAllClients() {
    return this.http.get(this.apiUrl+'customer/get-all-customers-by-reseller', this.httpHeaders)
  }

  getCustomerById(customerId) {
    return this.http.get(this.apiUrl+'customer/get-customer/'+customerId, this.httpHeaders)
  }
  sendVerificationEmail(data){
    return this.http.post(this.apiUrl+'customer/resend-verification-email',data, this.httpHeaders)
  
  }

updateCustomerData(data){
    return this.http.post(this.apiUrl+'customer/update-customer-data',data, this.httpHeaders)
  
  }
  
}
