<main id="partner-connect-guidelines" class="main">

  <ol class="breadcrumb"></ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">

        <ng-template #loadingSpinner>
          <div class="col-12 text-center">
            <div class="card card-body" style="min-height: 400px;">
              <div class="lds-roller" style="margin: auto">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <form style="width: 100%;">

                  <div class="col-12 mb-4">
                    <h3>{{ 'o365.partnerconnectguide' | translate }}</h3>
                    <!-- <a href="#" _target="blank" (click)="downloadPartnerConnectGuidelines($event)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3"/></svg>
                    </a> -->
                  </div>

                  <div class="col-12">
                    <h4>{{ 'o365.connecttenantprocess' | translate }}</h4>
                    <p>
                      <i>{{ 'o365.ctp.title' | translate }}</i>
                    </p>
                    <ol>
                      <li>{{ 'o365.ctp.stepone' | translate }}</li>
                      <li>{{ 'o365.ctp.steptwo' | translate }}</li>
                      <li>{{ 'o365.ctp.stepthree' | translate }}</li>
                      <li>{{ 'o365.ctp.stepfour' | translate }}</li>
                      <li>{{ 'o365.ctp.stepfive' | translate }}</li>
                      <li>{{ 'o365.ctp.stepsix' | translate }}</li>
                      <li>{{ 'o365.ctp.stepseven' | translate }}</li>
                    </ol>
                  </div>

                  <div class="col-12">
                    <h4>{{ 'o365.ttpscene' | translate }}</h4>
                    <p>
                      <i>{{ 'o365.ttpscene.title' | translate }}</i>
                      <br>
                    </p>
                    <ol>
                      <!-- Scenario during tenant transitioning process one  -->
                      <li>
                        <h5>{{ 'o365.ttpsceneone' | translate }}</h5>
                        <p>
                          {{ 'o365.ttpsceneone.title' | translate }}
                        </p>
                      </li>
                      <br>

                      <!-- Scenario during tenant transitioning process two  -->
                      <li>
                        <h5>{{ 'o365.ttpscenetwo' | translate }}</h5>
                        <p>
                          {{ 'o365.ttpscenetwo.title' | translate }}
                          <br>
                          <br>
                          <strong>{{ 'o365.customerauthorization' | translate }}:</strong>
                          <br>
                          <br>
                          {{ 'o365.ca.content1' | translate }}
                        </p>
                        <ol>
                          <li>{{ 'o365.customerdetails' | translate }}
                            <ul>
                              <li>{{ 'word.organizationname' | translate }}</li>
                              <li>{{ 'o365.anytenantdomain' | translate }}</li>
                              <li>{{ 'o365.authorizecontactname' | translate }}</li>
                              <li>{{ 'o365.authorizeposition' | translate }}</li>
                              <li>{{ 'o365.authorizecontactemail' | translate }}</li>
                              <li>{{ 'o365.authorizecontactnumber' | translate }}</li>
                            </ul>
                          </li>
                          <br>
                          <li>
                            {{ 'o365.ca.content2' | translate }}
                          </li>
                          <br>
                          <li>
                            {{ 'o365.ca.content3' | translate }}
                          </li>
                          <br>
                          <li>
                            {{ 'o365.ca.content4' | translate }}
                          </li>
                          <br>
                          <li>
                            {{ 'o365.ca.content5' | translate }} <a href="https://www.webnic.cc" _target="blank" (click)="openWebNICUrl($event)">{{ 'o365.webcommerceltd' | translate }}</a> {{ 'o365.ca.content6' | translate }}
                          </li>
                        </ol>
                      </li>
                      <br>

                      <!-- Scenario during tenant transitioning process three  -->
                      <li>
                        <h5>
                          {{ 'o365.ttpscenethree1' | translate }}
                          <a href="https://docs.microsoft.com/en-us/partner-center/regional-authorization-overview#asia-and-pacific-region-and-market" _target="blank" (click)="openMicrosoftAsiaPacificMarketRegionUrl($event)">{{ 'o365.asiapacific' | translate }}</a>
                          {{ 'o365.ttpscenethree2' | translate }}.
                        </h5>
                        <p>
                          {{ 'o365.ttpscenethree.title' | translate }}
                          <br><br>
                          {{ 'word.as' | translate }} <a href="https://www.webnic.cc" _target="blank" (click)="openWebNICUrl($event)">{{ 'o365.webcommerceltd' | translate }}</a> {{ 'o365.ttpscenethree.title2' | translate }}
                          {{ 'word.under' | translate }} <a href="https://docs.microsoft.com/en-us/partner-center/regional-authorization-overview#asia-and-pacific-region-and-market" _target="blank" (click)="openMicrosoftAsiaPacificMarketRegionUrl($event)">{{ 'o365.asiapacific' | translate }}</a>.
                        </p>
                      </li>
                    </ol>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</main>
