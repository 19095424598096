import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailApiService } from 'src/app/service/email-api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EmailProductPrice } from 'src/app/model/email/email-product-price.model';
import { EmailProduct } from 'src/app/model/email/email-product.model';
import { EmailOrderAction } from 'src/app/model/email/email-order-action.model';
import { ScrollUtil } from 'src/app/util/scroll-util';

declare var $: any;

@Component({
  selector: 'app-email-order-detail',
  templateUrl: './email-order-detail.component.html',
  styleUrls: ['./email-order-detail.component.css']
})
export class EmailOrderDetailComponent implements OnInit, OnDestroy {
  
  oid:number;
  order: any;

  successCheck:boolean = false;
  successMessage:string;
  errorCheck:boolean = false;
  errors: string[] = [];  

  modifyLicensesForm:UntypedFormGroup;
  renewOrderForm:UntypedFormGroup;
  changeOrReleaseDomainForm:UntypedFormGroup;
  modifyPaymentStatusForm: UntypedFormGroup;
  

  modifyLicensesBtnPressed:boolean = false;
  renewOrderBtnPressed:boolean = false;
  changeOrReleaseDomainBtnPressed:boolean = false;
  cancelOrderBtnPressed:boolean = false;
  modifyPaymentStatusBtnPressed: boolean = false;
  
  modalRef: BsModalRef;
 
  emailProduct: EmailProduct;
  emailProductPriceList: EmailProductPrice[] = []; 

  domainPatternReg = /^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/i ;

  domainOptionsValueChangeSubscription: Subscription;
  emailDomainValueChangeSubscription: Subscription;

  enableModifyLicenses: boolean = false;
  enableRenewOrder: boolean = false;
  enableChangeOrReleaseDomain: boolean = false;
  enableCancelOrder:boolean = false;
  enableModifyPaymentStatus: boolean = false;

  orderActions: EmailOrderAction[] = null;

  pendingPaymentAddLicensesOAList: EmailOrderAction[] = [];
  enableModifyAddLicensesOAPaymentStatus: boolean = false;
  modifyAddLicensesOAPaymentStatusForm: UntypedFormGroup;
  modifyAddLicensesOAPaymentStatusBtnPressed: boolean = false;

  isClientOrder: boolean = false; 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emailApiService:EmailApiService,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
          this.oid = +params['id'];
          this.getOrderDetails();
      }
    );
  }

  getOrderDetails(){
    this.emailApiService.getEmailOrderDetails(this.oid).subscribe((res:any)=>{
        this.order = res;

        this.isClientOrder = false;
        if(this.order.isClientOrder && this.order.isClientOrder == true){
          this.isClientOrder = true;
        }

        this.checkAllowedAction();
        this.checkSpecialOrderActions();
    },err=>{
        this.handleError(err);
    });
  }

  checkAllowedAction(){
    // reset
    this.enableModifyLicenses = false;
    this.enableRenewOrder = false;
    this.enableChangeOrReleaseDomain = false;
    this.enableCancelOrder = false;
    this.enableModifyPaymentStatus = false;

    let status = this.order.status;
    let paymentStatus = this.order.paymentStatus;

    if(paymentStatus == 'PENDING'){
      this.enableModifyPaymentStatus = true;
    }else if(paymentStatus == 'COMPLETE'){
      this.enableModifyPaymentStatus = false;
    }
    
    if(status == 'NEW'){
      this.enableCancelOrder = true;
    }else if(status == 'PENDING'){
      
    }else if (status == 'ACTIVE'){
      this.enableModifyLicenses = true;
      this.enableRenewOrder = true;
      this.enableChangeOrReleaseDomain = true;
    }else if (status == 'EXPIRED'){
      this.enableModifyLicenses = true;
      this.enableRenewOrder = true;
      this.enableChangeOrReleaseDomain = true;
    }else if (status == 'NOEMAILDOMAIN'){
      this.enableChangeOrReleaseDomain = true;
    }
  }

  async checkSpecialOrderActions(){
    let status = this.order.status;
    
    // check for special order actions if current order is sf-client order
    if(!this.isClientOrder){
      return;
    }

    // check for order actions which payment status is 'Pending'
    if(status == 'ACTIVE'){
      let searchData : any = {
        oid: this.order.oid.toString(),
        paymentStatus: 'PENDING'
      }

      await this.emailApiService.orderActionSearch(searchData).toPromise().then((res:any)=>{
        this.orderActions = res;
      }).catch(err=>{
        console.error(err);
      });

      if(this.orderActions && this.orderActions.length > 0 ){

        // add licenses order actions
        this.pendingPaymentAddLicensesOAList = this.orderActions.filter(oa=>oa.action == 'ADDLICENSE');
        if(this.pendingPaymentAddLicensesOAList.length>0){
          // enable option to update add licenses order action payment status if exists
          this.enableModifyAddLicensesOAPaymentStatus = true;
        }

      }
    }
    
  }

  openModifyLicensesModal(template: TemplateRef<any>) {
    // notes: currently this action doing add licenses to existing order
    
    this.modifyLicensesBtnPressed = true;

    // create form if not exist
    if (!this.modifyLicensesForm) {
      this.modifyLicensesForm = this.formBuilder.group({
        licenses: [1]
      });
    }

    if(this.emailProduct && this.emailProduct != null) {
      // data fetched already, then straight openModal 
      this.openModal(template);
      this.modifyLicensesBtnPressed = false;
      return;
    }

    this.emailApiService.getEmailProductRule(this.order.productid).subscribe((res: any) => {
      this.emailProduct = res;

      // minimum licenses to add
      let minlicenses = 1; 
      // maximum licenses to add pre-include the existing licenses amount
      let maxlicenses = this.emailProduct.maxlicenses - Number(this.order.licenses);

      this.licensesFormControl.setValidators([Validators.required,Validators.min(minlicenses),
        Validators.max(maxlicenses)
      ]);

      this.openModal(template);
      this.modifyLicensesBtnPressed = false;
    }, err => {
      this.handleError(err);
      this.modifyLicensesBtnPressed = false;
    });
  }

  get licensesFormControl(){
    return this.modifyLicensesForm.get('licenses');
  }

  modifyLicensesPressed(){
    // notes: currently this action doing add licenses to existing order, the amount inputed
    //        will be added in the backend system, so no need to pass as total licenses with 
    //        the addition

    this.modifyLicensesBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    if(this.modifyLicensesForm.invalid){
      this.modifyLicensesBtnPressed = false;
      return;
    }
    let addLicenses:number = this.licensesFormControl.value;

    this.emailApiService.updateOrderLicenses(this.oid,addLicenses).subscribe((res:any)=>{
      let status:boolean = res.status;
      if(status){
        this.successMessage = res.message;
        this.successCheck = true;
      }else{
        if(res.message){
          this.errors.push(res.message);
        }
        if(res.errors){
          let errors:any[] = res.errors;
          errors.forEach(e=>this.errors.push(e.message));
        }
        this.errorCheck = true;
      }
      this.getOrderDetails();
      this.modifyLicensesBtnPressed = false;
      this.modalRef.hide();
      ScrollUtil.scrollToTop();
    },err=>{
      this.modifyLicensesBtnPressed = false;
      this.modalRef.hide();
      this.handleError(err);
    });
  }

  openRenewOrderModal(template: TemplateRef<any>) {
    this.renewOrderBtnPressed = true;
    // create form if not exist
    if(!this.renewOrderForm){
      this.renewOrderForm = this.formBuilder.group({
        terms: [this.order.terms, Validators.required],
      });
    }
    
    if (this.emailProductPriceList && this.emailProductPriceList.length > 0) {
      // data fetched already, then straight openModal 
      this.openModal(template);
      this.renewOrderBtnPressed = false;
      return;
    }

    this.emailApiService.getProductPlanPrice(this.order.productid).subscribe((res: any) => {
      this.emailProductPriceList = res.plans;
      this.openModal(template);
      this.renewOrderBtnPressed = false;
    }, err => {
      this.handleError(err);
      this.renewOrderBtnPressed = false;
    });
  }

  get termsFormControl() {
    return this.renewOrderForm.get('terms');
  }

  renewOrderPressed(){
    this.renewOrderBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;
   
    if(this.renewOrderForm.invalid){
      this.renewOrderBtnPressed = false;
      return;
    }

    let terms:number = this.termsFormControl.value;

    this.emailApiService.updateOrderTerms(this.oid,terms).subscribe((res:any)=>{
      let status:boolean = res.status;
      if(status){
        this.successMessage = res.message;
        this.successCheck = true;
      }else{
        if(res.message){
          this.errors.push(res.message);
        }
        if(res.errors){
          let errors:any[] = res.errors;
          errors.forEach(e=>this.errors.push(e.message));
        }
        this.errorCheck = true;
      }

      this.renewOrderBtnPressed = false;
      this.modalRef.hide();
      ScrollUtil.scrollToTop();

      setTimeout(() => {
        if(res.oid){
          let oid:number = res.oid;
          this.router.navigate(['/email/email-order-detail', oid]);
        }
      }, 500);

    },err=>{
      this.renewOrderBtnPressed = false;
      this.modalRef.hide();
      this.handleError(err);
    });
  }

  openChangeOrReleaseDomainModal(template: TemplateRef<any>){
    this.changeOrReleaseDomainBtnPressed = true;

    // create form 
    this.changeOrReleaseDomainForm = this.formBuilder.group({
      domainOptions: [1, Validators.required],
      emailDomain: ['', [Validators.required, Validators.pattern(this.domainPatternReg)]]
    });

    this.subscribeEmailDomainFormControl();
    
    // got active subscription, unsubscribe it
    if (this.domainOptionsValueChangeSubscription) {
      this.domainOptionsValueChangeSubscription.unsubscribe();
    }
      
    this.domainOptionsValueChangeSubscription = this.changeOrReleaseDomainForm.get('domainOptions').valueChanges.subscribe(v => {
      if (v == 1) {
        // change domain option

        this.emailDomainFormControl.enable();
        this.emailDomainFormControl.setValidators([Validators.required, Validators.pattern(this.domainPatternReg)]);
        this.emailDomainFormControl.patchValue('');
        this.emailDomainFormControl.markAsUntouched();

        // subscribe emailDomain valueChange if not exist
        this.subscribeEmailDomainFormControl();

      } else if (v == 2) {
        // release domain option

        this.emailDomainFormControl.clearValidators();
        // unsubscribe emailDomain valueChange if exist
        if (this.emailDomainValueChangeSubscription) {
          this.emailDomainValueChangeSubscription.unsubscribe();
        }
        this.emailDomainFormControl.disable();
      }
    });

    this.changeOrReleaseDomainBtnPressed = false;
    this.openModal(template);
  }

  get emailDomainFormControl(){
    return this.changeOrReleaseDomainForm.get('emailDomain');
  }

  subscribeEmailDomainFormControl(){
    if (this.emailDomainValueChangeSubscription) {
      this.emailDomainValueChangeSubscription.unsubscribe();
    }

    this.emailDomainValueChangeSubscription = this.emailDomainFormControl.valueChanges.subscribe((d: string) => {
      if (!this.emailDomainFormControl.errors || this.emailDomainFormControl.errors.length == 0) {
        this.emailDomainFormControl.markAsPending();
      }
    });
  }

  onEmailDomainBlur(domain:string){
    let domainFormControl = this.emailDomainFormControl;

    if(domainFormControl.hasError('required') || domainFormControl.hasError('pattern')){
      domainFormControl.markAsTouched();
      return;
    }
    
    domainFormControl.markAsPending();
    this.emailApiService.checkEmailDomainAvailability(domain).subscribe((res: any) => {
      let status: boolean = res.status;
      
      if (status) {
        domainFormControl.setErrors(null);
      } else {
        domainFormControl.setErrors({ notAvailable: true })
      }
    }, err => {
      this.handleError(err);
      domainFormControl.setErrors({ unableToCheck: true })
    }, () => {
      domainFormControl.markAsTouched();
    });
  }

  changeOrReleaseDomainPressed(){
    this.changeOrReleaseDomainBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    if(this.changeOrReleaseDomainForm.invalid || this.changeOrReleaseDomainForm.pending){
      this.changeOrReleaseDomainBtnPressed = false;
      return;
    }

    let emailDomain:string = '';

    if(this.emailDomainFormControl.enabled){
      emailDomain = this.emailDomainFormControl.value;
    }

    this.emailApiService.updateOrderEmailDomain(this.oid,emailDomain).subscribe((res:any)=>{
      let status:boolean = res.status;
      if(status){
        this.successMessage = res.message;
        this.successCheck = true;
      }else{
        if(res.message){
          this.errors.push(res.message);
        }
        if(res.errors){
          let errors:any[] = res.errors;
          errors.forEach(e=>this.errors.push(e.message));
        }
        this.errorCheck = true;
      }
      this.getOrderDetails();
      this.changeOrReleaseDomainBtnPressed = false;
      this.modalRef.hide();
      ScrollUtil.scrollToTop();
    },err=>{
      this.changeOrReleaseDomainBtnPressed = false;
      this.modalRef.hide();
      this.handleError(err);
    });
  }

  openCancelOrderModal(template: TemplateRef<any>) {
    this.cancelOrderBtnPressed = true;

    this.openModal(template);

    this.cancelOrderBtnPressed = false;
  }

  cancelOrderConfirmationPressed(){
    this.cancelOrderBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    let status:string = 'CANCEL';

    this.emailApiService.updateEmailOrderStatus(this.oid,status).subscribe((res:any)=>{
      let status:boolean = res.status;
      if(status){
        this.successMessage = res.message;
        this.successCheck = true;
      }else{
        if(res.message){
          this.errors.push(res.message);
        }
        if(res.errors){
          let errors:any[] = res.errors;
          errors.forEach(e=>this.errors.push(e.message));
        }
        this.errorCheck = true;
      }
      this.getOrderDetails();
      this.cancelOrderBtnPressed = false;
      this.modalRef.hide();
      ScrollUtil.scrollToTop();
    },err=>{
      this.cancelOrderBtnPressed = false;
      this.modalRef.hide();
      this.handleError(err);
    });
  }

  openModifyPaymentStatusModal(template: TemplateRef<any>) {
    this.modifyPaymentStatusBtnPressed = true;

    if (!this.modifyPaymentStatusForm) {
      this.modifyPaymentStatusForm = this.formBuilder.group({
        referenceno: [this.order.referenceno, [Validators.required]]
      });
    }
    this.openModal(template);
    this.modifyPaymentStatusBtnPressed = false;
  }

  get paymentStatusFormControl(){
    return this.modifyPaymentStatusForm.get('paymentStatus');
  }

  get referencenoFormControl() {
    return this.modifyPaymentStatusForm.get('referenceno');
  }

  modifyPaymentStatusPressed(){
    this.modifyPaymentStatusBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    if(this.modifyPaymentStatusForm.invalid) {
      this.modifyPaymentStatusBtnPressed = false;
      return;
    }

    let orderUpdateRequest: any = {
      oid: this.oid,
      referenceno: this.referencenoFormControl.value,
      paymentmethodId: '2',
      paymentmethodName: 'Bank Transfer', // ** important, currently hardcoded always use 'Bank Transfer'
      failureReason: '', // set empty string will trigger update payment status to 'Complete'
    };

    this.emailApiService.updateEmailOrderReferenceNo(orderUpdateRequest).subscribe((res: any) => {
      let status: boolean = res.status;
      if(status){
        this.successMessage = res.message;
        this.successCheck = true;
      } else {
        if(res.message){
          this.errors.push(res.message);
        }
        if(res.errors){
          let errors: any[] = res.errors;
          errors.forEach(e => this.errors.push(e.message));
        }
        this.errorCheck = true;
      }
      this.getOrderDetails();
      this.modifyPaymentStatusBtnPressed = false;
      this.modalRef.hide();
      ScrollUtil.scrollToTop();
    }, err => {
      this.modifyPaymentStatusBtnPressed = false;
      this.modalRef.hide();
      this.handleError(err);
    });
  }

  openModifyAddLicensesOAPaymentStatusModal(template: TemplateRef<any>) {
    this.modifyAddLicensesOAPaymentStatusBtnPressed = true;

    if (!this.modifyAddLicensesOAPaymentStatusForm) {
      this.modifyAddLicensesOAPaymentStatusForm = this.formBuilder.group({
        oaid: [null, [Validators.required] ],
        referenceno: ['', [Validators.required] ]
      });
    }
    this.openModal(template);
    this.modifyAddLicensesOAPaymentStatusBtnPressed = false;
  }

  get oaReferencenoFormControl() {
    return this.modifyAddLicensesOAPaymentStatusForm.get('referenceno');
  }

  modifyAddLicensesOAPaymentStatusPressed(){
    this.modifyAddLicensesOAPaymentStatusBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    if(this.modifyAddLicensesOAPaymentStatusForm.invalid) {
      this.modifyAddLicensesOAPaymentStatusBtnPressed = false;
      return;
    }

    console.log(this.modifyAddLicensesOAPaymentStatusForm.value);
    
    let updateRequest: any = {
      oid: this.order.oid.toString(),
      oaid: this.modifyAddLicensesOAPaymentStatusForm.value.oaid,
      referenceno: this.modifyAddLicensesOAPaymentStatusForm.value.referenceno,
      paymentmethodname: 'Bank Transfer',
      markPaymentAsComplete: true
    }

    this.emailApiService.updateOrderActionPaymentReferenceNo(updateRequest).subscribe((res: any) => {
      console.log(res);
      let status: boolean = res.status;
      if(status){
        this.successMessage = res.message;
        this.successCheck = true;
      } else {
        if(res.message){
          this.errors.push(res.message);
        }
        if(res.errors){
          let errors: any[] = res.errors;
          errors.forEach(e => this.errors.push(e.message));
        }
        this.errorCheck = true;
      }

      // reset orderActions
      this.orderActions.length = 0;
      this.pendingPaymentAddLicensesOAList.length = 0;
      this.enableModifyAddLicensesOAPaymentStatus = false;

      this.getOrderDetails();
      this.modifyAddLicensesOAPaymentStatusBtnPressed = false;
      this.modalRef.hide();
      this.modifyAddLicensesOAPaymentStatusForm.reset();
      ScrollUtil.scrollToTop();
    }, err => {
      this.modifyAddLicensesOAPaymentStatusBtnPressed = false;
      this.modalRef.hide();
      this.modifyAddLicensesOAPaymentStatusForm.reset();
      this.handleError(err);
    });
    
  }

  openModal(template: TemplateRef<any>, modalSize?: string) {
    if(this.modalRef){
      this.modalRef.hide();
    }

    let modalConfig : any = {
      backdrop: true,
      ignoreBackdropClick: true
    };

    if(modalSize && modalSize == 'lg'){
      modalConfig.class = 'modal-lg';
    }

    this.modalRef = this.modalService.show(template, modalConfig);
  }
 
  handleError(err:any) {
    console.log(err);
    this.errors.length = 0;
    if (err.error instanceof ErrorEvent) {
      // client-side error
      let errorMessage = `Error: ${err.error.message}`;
      this.errors.push(errorMessage);
    } else {
      // server-side error
      let responseBodyErrors: any[] = err.error.errors;

      if (responseBodyErrors && responseBodyErrors.length>0) {
        this.errors = responseBodyErrors.map(e=> e.message).filter(msg => msg && msg != '' );
      }else{
        if(err.status == 0) {
          this.errors.push('Connection error. Please try again later.');
        }else{
          this.errors.push(`${err.message}`);
        }
      }
    }
    this.errorCheck = true;
    ScrollUtil.scrollToTop();
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  closeErrorPressed(){this.errorCheck = false;}
  closeSuccessPressed(){this.successCheck = false;}

  ngOnDestroy() {
    // close all active subscriptions
    if(this.domainOptionsValueChangeSubscription){
      this.domainOptionsValueChangeSubscription.unsubscribe();
    }
    if(this.emailDomainValueChangeSubscription){
      this.emailDomainValueChangeSubscription.unsubscribe();
    }
  }
  
}
