<main id="storefront-product-promotion-step-two" class="main">
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'word.products' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.promotion' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row">
        <ng-template #loadingSpinner>
          <div class="col-12 text-center">
            <div class="card card-body" style="min-height: 400px;">
              <div class="lds-roller" style="margin: auto">
                  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="col-12" *ngIf="!loading; else loadingSpinner">
          <div class="card" style="min-height: 320px;">
            <div class="card-body">

              <div class="row">

                <div class="col-12 mb-4">
                  <span style="font-weight: bolder; font-size: large;">Microsoft CSP</span>
                </div>

                <div class="col-lg-6 col-md-6 col-12 mb-2" *ngFor="let product of o365Products">
                  <div class="card card-body product-item-card h-100 " (click)="editPromotion('o365', product)"
                  [ngClass]="product.activePromotion? 'active-promotion':'' ">
                    <div class="row h-100 align-items-center">
                      <div class="col-12">
                        <span>{{product.product_name}} &nbsp;
                          <span *ngIf="product.activePromotion;else noPromotionBadge" class="badge badge-danger" style="padding: 0.4rem;">
                            {{ 'word.ongoingpromotion' | translate }}
                          </span>
                          <ng-template #noPromotionBadge>
                            <span class="badge badge-secondary" style="padding: 0.4rem;">
                              {{ 'word.nopromotion' | translate }}
                            </span>
                          </ng-template>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</main>
