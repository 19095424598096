
    <main id="storefront" class="main create-storefront">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item">{{ 'sf.storefrontsettings' | translate }}</li>
        <li class="breadcrumb-item active" *ngIf="!edited">{{ 'sf.createstorefront' | translate }}</li>
        <li class="breadcrumb-item active" *ngIf="edited">{{ 'sf.editstorefront' | translate }}</li>

        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
            aria-valuemin="0" aria-valuemax="100" style="width: 90%">
          </div>
        </div>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">

          <div class="row">
            <div class="col-sm-12">
               <div class="alert alert-danger alert-dismissible fade show" style="display:none">
                  <button type="button" class="close" id="close">&times;</button>
                  <strong>{{ 'word.error' | translate }}</strong> {{ 'error.selectpaymentmethod' | translate }}
                </div>
            </div>
          </div>

          <div class="row">

            <div class="col-xl-6">
              <h2>{{ 'sf.commonpaymentgateway' | translate }}</h2>

              <div class="payment-container mt-4 mb-5">
                <div *ngFor="let payment of payments">
                  <label class="image-checkbox" style="width:100%;">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3 col-12">
                            <img src="{{ payment.pic_url }}" alt="{{ payment.name }}" class="img-fluid">
                          </div>

                          <div class="col-sm-9 col-12">
                            <h5>{{payment.payment_name}}</h5>
                            <p class="mb-0">{{ payment.payment_description }}</p>
                          </div>
                        </div>
                      </div>
                      <input id="{{ 'payment' + payment.id }}" type="checkbox" [formControl]="paymentMethodFormGroup?.controls[payment.id]" (click)="onClickCheckbox($event)"/>
                    </div>
                  </label>

                </div>

              </div>
            </div>


            <div class="col-lg-12">
              <div class="float-right">
                <label class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()">
                  {{ 'button.next' | translate }}
                </label>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
