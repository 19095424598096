import { Component, OnInit } from '@angular/core';
import {StoreFrontService} from '../../service/store-front.service';

declare var $: any;
declare var jQuery: any;
declare var appJS: any;

@Component({
  selector: 'app-storefront-create',
  templateUrl: './storefront-create.component.html',
  styleUrls: ['./storefront-create.component.css']
})
export class StorefrontCreateComponent implements OnInit {

  constructor(
    public storefrontService: StoreFrontService
  ) { }

	storefronts;
  ngOnInit() {
    this.storefrontService.getAllStoreFrontByReseller().subscribe(data => {
  	  this.storefronts = data['data'];
  	});
    // appJS.init()
  }

}
