import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResellerService {

  apiUrl: string = environment.reseller.apiUrl

  constructor(
    private httpClient: HttpClient
  ) { }

  getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .append('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
      .append('Content-Type', 'application/json');
  }

  public getResellerByRescode(): Observable<any> {
    const endpoint = `${this.apiUrl}/sfadmin/resellers/me`;
    return this.httpClient.get(endpoint, { headers: this.getHeaders() });
  }
}
