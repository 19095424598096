import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { TranslateUtil } from 'src/app/util/translate-util';
import { MicrosoftGraphService } from '../../../../service/microsoftcsp/microsoft-graph.service';

@Component({
  selector: 'app-admin-consent-auth',
  templateUrl: './admin-consent-auth.component.html',
  styleUrls: ['./admin-consent-auth.component.css']
})
export class AdminConsentAuthComponent implements OnInit {

  pageLoading = false;
  grantProcessComplete = false;
  admin_consent = false;
  microsoftTenantId: string;
  state: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService,
    private microsoftGraphService: MicrosoftGraphService
  ) { }

  ngOnInit() {
    this.pageLoading = true;
    if (localStorage.getItem('tenant')) {
      this.route.queryParams.subscribe(queryParams => {
        this.state = queryParams['state'];
        this.microsoftTenantId = queryParams['tenant'];
        this.admin_consent = queryParams['admin_consent'] == 'True' ? true : false;

        const tenant: any = JSON.parse(localStorage.getItem('tenant'));
        this.microsoftGraphService.adminConsent = tenant.adminConsent;
        this.microsoftGraphService.wnTenantId = tenant.wnTenantId;
        this.microsoftGraphService.state = tenant.state;

        if (this.microsoftGraphService.state == this.state) {
          this.updateMicrosoftTenantId();
        }
        this.pageLoading = false;
      });
    }
  }

  updateMicrosoftTenantId() {
    this.microsoftGraphApiService.updateMicrosoftTenantId(this.microsoftGraphService.wnTenantId.toString(), this.microsoftTenantId)
      .subscribe((res: any) => {
        const message = res.message;
        if (message === true) {
          this.grantProcessComplete = true;
          localStorage.removeItem('tenant');
        } else {
          this.grantProcessComplete = false;
        }
      }, err => {
        console.error(err);
      });
  }

  goToTenantManagement() {
    this.router.navigate(['/microsoftcsp/tenants']);
  }

  goToTenantDetails() {
    this.router.navigate(['/microsoftcsp/tenants', this.microsoftGraphService.wnTenantId]);
  }

}
