import { Component, OnInit, ViewChildren, QueryList, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SortDirection, SortableTableHeader, SortEvent } from 'src/app/directive/sortable.directive';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { MicrosoftGraphService } from 'src/app/service/microsoftcsp/microsoft-graph.service';

@Component({
  selector: 'app-microsoftcsp-domain',
  templateUrl: './microsoftcsp-domain.component.html',
  styleUrls: ['./microsoftcsp-domain.component.css']
})
export class MicrosoftCSPDomainComponent implements OnInit {

  errorCheck: boolean = false;
  errors: any[] = [];

  tableLoading: boolean = false;

  modalRef: BsModalRef;

  domainSearchParams: any;
  domains: any[] = [];
  tenantId: number;

  filterForm: UntypedFormGroup;

  sortColumn: string = '';
  sortDirection: SortDirection = '';
  nextPage: any = {};

  @ViewChildren(SortableTableHeader) sortableHeaders: QueryList<SortableTableHeader>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private microsoftGraphApiService: MicrosoftGraphApiService,
    private microsoftGraphService: MicrosoftGraphService
  ) { }

  ngOnInit() {
    this.domainSearchParams = {
      // $select: 'id,isDefault,isVerified',
    };

    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      if (!this.microsoftGraphService.adminConsent === true) {
        this.goToAdminConsentPage();
      } else {
        this.getMicrosoftDomains(this.tenantId);
      }
    });
  }

  getMicrosoftDomains(tenantId: number, isMore: boolean = false) {
    this.tableLoading = true;
    if (!isMore) {
      this.domains.length = 0;
    }

    let queryOptions: any = {};

    queryOptions = { ...queryOptions, ...this.domainSearchParams };

    this.microsoftGraphApiService
      .getDomains(this.tenantId.toString(), queryOptions)
      .subscribe((res: any) => {
        if (isMore) {
          this.domains.push(...res.value);
        } else {
          this.domains = res.value;
        }

        if (res['@odata.nextLink']) {
          const responseParam = new URL(res['@odata.nextLink']).searchParams;
          this.nextPage = { $skiptoken: encodeURIComponent(responseParam.get('$skiptoken')) };
        } else {
          this.nextPage = undefined;
        }

        delete this.domainSearchParams['$skiptoken'];

        this.tableLoading = false;
      }, err => {
        console.error('getMicrosoftDomains error', err);
        // this.handleError(err);

        // reset items
        this.domains.length = 0;
        this.tableLoading = false;
      });
  }

  onMorePressed() {
    if (this.tableLoading) {
      return;
    }
    if (this.nextPage === undefined) {
      return;
    }
    this.domainSearchParams = { ...this.domainSearchParams, ...this.nextPage };
    this.getMicrosoftDomains(this.tenantId, true);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.sortableHeaders.forEach(header => {
      if (header.column !== column) {
        header.direction = '';
      }
    });

    // always reset current sorting to empty 1st
    this.sortColumn = '';
    this.sortDirection = '';

    // update the current sorting if direction event is either 'asc' or 'desc'
    if (direction === 'asc' || direction === 'desc') {
      this.sortColumn = column;
      this.sortDirection = direction;
    }

    const newSorting = {
      $orderby: this.sortColumn + ' ' + this.sortDirection
    }
    // update userSearchParams with new sorting values
    this.domainSearchParams = { ...this.domainSearchParams, ...newSorting };
    // get users by updated userSearchParams
    this.getMicrosoftDomains(this.tenantId);
  }

  goToDomainDetails(domain: any) {
    this.microsoftGraphService.domain = domain;
    this.router.navigate(['/microsoftcsp/tenants/', this.tenantId, 'domains', domain.id]);
  }

  goToAdminConsentPage() {
    this.router.navigate(['/microsoftcsp/tenants/', this.tenantId, 'adminconsent']);
  }

  errorClosePressed() {
    this.errorCheck = false;
    this.errors.length = 0;
  }

}
