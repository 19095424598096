<div class="sidebar">
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/dashboard"><i class="icon-speedometer"></i> {{ 'word.dashboard' | translate }}</a>
      </li>

      <li class="nav-item nav-dropdown" id="storefront-nav-dropdown">
        <a class="nav-link nav-dropdown-toggle" id="storefront-nav" href="#"><i class="icon-cursor-move"></i> {{ 'sf.storefrontsettings' | translate }}</a>
        <ul class="nav-dropdown-items" >
          <li class="nav-item">
            <a class="nav-link" routerLink="/create-storefront"><i class="icon-basket-loaded"></i> {{ 'sf.createstorefront' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/manage-storefront"><i class="icon-basket-loaded"></i> {{ 'word.managestorefront' | translate }}</a>
          </li>
        </ul>
      </li>

      <li class="nav-item nav-dropdown" id="product-nav-dropdown">
        <a class="nav-link nav-dropdown-toggle cursor-pointer" id="product-nav" >
          <i class="fa fa-shopping-bag"></i>
          {{ 'word.products' | translate }}
        </a>
        <ul class="nav-dropdown-items" >
          <li class="nav-item">
            <a class="nav-link cursor-pointer" routerLink="/product/pricing"><i class="fa fa-usd"></i>
              {{ 'word.pricing' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link cursor-pointer" routerLink="/product/promotion"><i class="fa fa-bullhorn"></i>
              {{ 'word.promotion' | translate }}
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item">
        <a class="nav-link" routerLink="/orders"><i class="icon-basket-loaded"></i>{{ 'word.ordermanagement' | translate }}</a>
      </li>


      <li class="nav-item">
        <a class="nav-link" routerLink="/clients"><i class="icon-people"></i>{{ 'word.clientmanagement' | translate }}</a>
      </li>

      <li class="nav-item nav-dropdown" id="notification-nav-dropdown">
        <a class="nav-link nav-dropdown-toggle" id="notification-nav" href="#"><i class="icon-cursor-move"></i> {{ 'sf.notificationsettings' | translate }}</a>
        <ul class="nav-dropdown-items" id="dropdown-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="/create-notification"><i class="icon-bell"></i> {{ 'sf.createnotification' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/manage-notification"><i class="icon-bell"></i> {{ 'sf.managenotification' | translate }}</a>
          </li>
        </ul>
      </li>
      
    </ul>
  </nav>
  <button class="sidebar-minimizer brand-minimizer" type="button"></button>
</div>
