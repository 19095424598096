<style>
.bg-ocean {
  background: linear-gradient(to right, #144687, #5d9cec);

}
.bg-autumn {
 background-color: #f5f5f5;
 margin-bottom: 0;
}

.bg-forest {
  background-color: #74b3b3;
}
.bg-marble {
  background-color: #fff;
}
.bg--overlay {
  position: relative;
  z-index: 0
}
.bg--overlay:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=97)";
  z-index: -1
}

.bg-ocean .nav-link {
  color: #fff !important;
}
.bg-autumn .nav-link {
  color: #333;
}

.bg-forest .nav-link {
  color: #fff;
}

</style>
    <main id="storefront" class="main create-storefront">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item">{{ 'sf.storefrontsettings' | translate }}</li>
        <li class="breadcrumb-item active" *ngIf="!edited">{{ 'sf.createstorefront' | translate }}</li>
        <li class="breadcrumb-item active" *ngIf="edited">{{ 'sf.editstorefront' | translate }}</li>

        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 30%">
          </div>
        </div>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">
          <div class="row">
            <form class="form" [formGroup]="storeTheming">
              <div class="col-lg-12">

                <h2>{{ 'sf.selecttheme' | translate }}</h2>

                <div class="mt-4">
                  <p id="selected_theme_label">{{ 'sf.selectedtheme' | translate }} {{selectedTheme.theme_name}}</p>
                </div>

                <div class="mt-4 mb-lg-4 mb-md-3 mx-auto btn-container">
                  <span *ngFor='let theme of themes' class="mr-1">
                    <a (click)='changeTheme(theme)' class="btn btn-dark btn-theme btn-lg btn-theme" style="color:white" >{{theme.theme_name}}</a> 
                  </span>
                  <!-- <input type="hidden" name="theme_id" id="theme_id" value="1"> -->
                </div>
              </div>

              <div class="col-lg-12 mt-5">
                <h2>{{ 'sf.choosecoverpic' | translate }}</h2>

                <div class="row my-4">
                  <div class="col-lg-3 col-md-12">
                    <div class="select-img-container">
                      <div class="d-flex flex-row flex-wrap justify-content-center">
                        <div *ngFor="let cover of covers">
                          <a  id="cover-thumbnail" (click)="thumbnailSelected(cover)" class="card-img-link">
                            <img id='img_cover_{{cover.id}}' src="{{ cover?.cover_url }}" alt="" class="img-fluid">
                          </a>
                        </div>
                        <input id="upload-photo" type="file" name="custom_cover" accept="image/*" (change)="getFiles($event)" #fileInput>

                        <a id="upload-photo-link" class="card-link mt-lg-0 mt-md-2 mt-sm-2 mt-xs-2" (click)="fileInput.click()" style="cursor: pointer;">
                          <div class="card border-dashed bg-transparent">
                            <div class="card-table mx-auto" style="height: 100px">
                              <div class="card-table-row">
                                <div class="card-table-cell v-align-middle">
                                  <h5 class="card-title text-center">
                                    <i class="fa fa-plus-circle"></i>
                                    <p>{{ 'sf.customphoto' | translate }}</p>
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-9 col-md-12 pl-lg-5">
                    <div class="selected-img-container">
                      <nav  class="{{selectedThemePreview}} navbar navbar-expand-lg">
                        <div class="container">
                          <a class="navbar-brand" style="cursor: pointer;">
                            <img class="logo" [src]="logo"  alt="" height="20px">
                          </a>
                          <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ml-auto" style="font-size: 10px;">
                              <li class="active">
                                <a  class="nav-link" style="cursor: pointer;">{{ 'word.home' | translate }} <span class="sr-only">(current)</span></a>
                              </li>
                              <li>
                                <a class="nav-link" style="cursor: pointer;">{{ 'word.aboutcompany' | translate }}</a>
                              </li>
                              <li>
                                <a class="nav-link" style="cursor: pointer;">{{ 'word.contactus' | translate }}</a>
                              </li>
                              <li>
                                <a class="nav-link" style="cursor: pointer;">{{ 'word.custompage' | translate }}</a>
                              </li>
                              <li>
                                <a class="nav-link" style="cursor: pointer;">{{ 'word.login' | translate }}</a>
                              </li>
                              <li>
                                <a class="nav-link" style="cursor: pointer;">{{ 'word.signup' | translate }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </nav>
                      <div class="jumbotron jumbotron-fluid bg--overlay" [ngStyle]="{'background-image': 'url(' + selectedPic?.cover_url + ')'}" style="height:400px; background-size: cover; " *ngIf="!this.filestring">
                        <div class="container text-light col-md-4" style="margin-left: 65px">
                          <h3 class="display-4" style="font-weight: 700; text-transform: uppercase; font-size: 40px">{{title}}</h3>
                          <p class="lead">{{text}}</p>
                        </div>
                      </div>

                      <div class="jumbotron jumbotron-fluid bg--overlay text-light" *ngIf="filestring" style="height:400px; background-size: cover;" [ngStyle]="{'background-image': customCoverPreview}" >
                        <div class="container text-light col-md-4" style="margin-left: 65px">
                          <h3 class="display-4" style="font-weight: 700; text-transform: uppercase; font-size: 40px">{{title}}</h3>
                          <p class="lead">{{text}}</p>
                        </div>
                      </div>
                      </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">

                
                <div class="float-right">


                  <label class="btn-primary" ngbButtonLabel class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()">
                    {{ 'button.next' | translate }}
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>