
    <main id="storefront" class="main create-storefront">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item">{{ 'sf.storefrontsettings' | translate }}</li>
        <li class="breadcrumb-item active" *ngIf="!edited">{{ 'sf.createstorefront' | translate }}</li>
        <li class="breadcrumb-item active" *ngIf="edited">{{ 'sf.editstorefront' | translate }}</li>

        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
            aria-valuemin="0" aria-valuemax="100" style="width: 60%">
          </div>
        </div>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">

            <div class="row">
                <div class="col-sm-12">
                   <div class="alert alert-danger alert-dismissible fade show">
                      <button type="button" class="close" id="close">&times;</button>
                      <strong>{{ 'word.error' | translate }}</strong> {{ 'error.selectproduct' | translate }}
                    </div>
               </div>
            </div>

          <div class="row">

            <div class="col-lg-12 mb-1">

              <h2>{{ 'word.selectyourplan' | translate }}</h2>

              <div class="d-flex flex-row flex-wrap justify-content-start justify-content-center mt-4">
                <div>

                  <label class="image-checkbox" *ngFor="let category of categories">
                    <div class="card">
                      <div class="card-body text-center">
                        <img src="{{ category?.pic_url }}" alt="{{category?.category_name}}" class="img-fluid" style="min-height: 83px; max-height: 83px;">
                        <h6 class="text-center">{{category?.category_name}}</h6>
                      </div>
                      <input id="{{ 'category' + category.id }}" type="checkbox" [formControl]="categoriesFormGroup?.controls[category.id]" (click)="onClickCheckbox($event)"/>
                    </div>
                  </label>

                </div>

              </div>
            </div>

            <div class="col-lg-12">
              <div class="float-right">
                <label class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()">
                  {{ 'button.next' | translate }}
                </label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </main>
