import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';
import { O365HttpService } from 'src/app/service/o365-http.service';

@Component({
  selector: 'app-microsoftcsp-product-promotion-step-one',
  templateUrl: './microsoftcsp-product-promotion-step-one.component.html',
  styleUrls: ['./microsoftcsp-product-promotion-step-one.component.css']
})
export class MicrosoftCSPProductPromotionStepOneComponent implements OnInit {

  storefrontId: number = null;
  categoryId: number = null;

  loading: boolean = false;

  o365Products: any[] = [];
  
  constructor(
    private o365HttpService: O365HttpService,
    private router: Router,
    private alertService: AlertService,
    private translateService: LanguageTranslateService
  ) {}

  ngOnInit() {
    this.loadMicrosoftCSPProducts();
  }

  translate(key:string):string{
    return this.translateService.translate(key);
  }

  loadMicrosoftCSPProducts() {
    this.loading = true;

    this.loadO365Products().then(() => {
      this.loading = false;
    })
  }

  async loadO365Products() {

    const options: any = {
      category_name: 'Microsoft Office 365'
    };
    await this.o365HttpService.getAllProductsV2(options).toPromise().then((res: any) => {
      this.o365Products = this.mapActivePromotionFlagToItems(res.data);
    }).catch(err => {
      console.error('getAllProductsV2 error', err);
      this.alertService.showError('Something went wrong. Please try again later.');
    });

    await this.o365HttpService.getAllProductsPromotionsProductIds().toPromise().then((res: any) => {
      this.checkAndSetActivePromotionToList('o365', res.data.product_promotions);
    }).catch(err => {
      console.error('getAllProductsPromotionsProductIds error', err);
      this.alertService.showError('Something went wrong. Please try again later.');
    });
  }

  mapActivePromotionFlagToItems(items: any[]): any[] {
    if (!items) {
      return [];
    }
    return items.map((item: any) => {
      let newItem: any = { ...item };
      newItem.activePromotion = false;
      return newItem;
    });
  }

  checkAndSetActivePromotionToList(productCategoryName: string, productPromotions: any[]) {
    if (!productPromotions || productPromotions.length < 1) {
      this.alertService.showInfo(this.translate('o365.alert.notfoundpromotion'));
      return;
    }

    switch (productCategoryName) {
      case 'o365': {
        this.o365Products.forEach(p => {
          let productPromotion: any = productPromotions.find(promo => promo == p.product_id);
          if (productPromotion) {
            p.activePromotion = true;
          } else {
            p.activePromotion = false;
          }
        })
        break;
      }
      default:
        break;
    }
  }

  editPromotion(productCategoryName: string, product: any) {
    if (!product.activePromotion) {
      // no promotion then do nothing
      return;
    }

    if (productCategoryName == 'o365') {
      this.router.navigate(['/microsoftcsp/products/promotion', product.product_id]);
    }
  }
}
