import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftGraphService {

  private _adminConsent = false;
  private _wnTenantId: number;
  private _state: string = null;
  private _userBasics: UserBasics = null;
  private _userLicenses: UserLicenses = null;
  private _userOptional: UserOptional = null;
  private _domain: any;

  private _createServiceRecordsRequest: any;
  private _hasConflictRecords: boolean = false;
  private _conflictRecords: any[];

  constructor() { }

  get adminConsent(): boolean {
    return this._adminConsent;
  }

  set adminConsent(adminConsent: boolean) {
    this._adminConsent = adminConsent;
  }

  get wnTenantId(): number {
    return this._wnTenantId;
  }

  set wnTenantId(wnTenantId: number) {
    this._wnTenantId = wnTenantId;
  }

  get state(): string {
    return this._state;
  }

  set state(state: string) {
    this._state = state;
  }

  get userBasics() {
    return this._userBasics;
  }

  set userBasics(value: UserBasics) {
    this._userBasics = value;
  }

  get userLicenses() {
    return this._userLicenses;
  }

  set userLicenses(value: UserLicenses) {
    this._userLicenses = value;
  }

  get userOptional() {
    return this._userOptional;
  }

  set userOptional(value: UserOptional) {
    this._userOptional = value;
  }

  get createServiceRecordsRequest() {
    return this._createServiceRecordsRequest;
  }

  set createServiceRecordsRequest(value: any) {
    this._createServiceRecordsRequest = value;
  }

  get hasConflictRecords() {
    return this._hasConflictRecords;
  }

  set hasConflictRecords(value: boolean) {
    this._hasConflictRecords = value;
  }

  get conflictRecords() {
    return this._conflictRecords;
  }

  set conflictRecords(value: any[]) {
    this._conflictRecords = value;
  }

  reset(): void {
    this.adminConsent = undefined;
    this.wnTenantId = undefined;
    this.state = undefined;
  }

  get domain() {
    return this._domain;
  }

  set domain(value: any) {
    this._domain = value;
  }
}

/**
 * New User Basics
 */

export interface UserBasics {
  firstName: string;
  lastName: string;
  displayName: string;
  username: string;
  domain: string;
  generatePassword: boolean;
  forceChangePassword: boolean;
}

/**
 * New User License
 */

// {
//   "addLicenses": [
//     {
//       "disabledPlans": [ "11b0131d-43c8-4bbb-b2c8-e80f9a50834a" ],
//       "skuId": "guid"
//     }
//   ],
//   "removeLicenses": [ "bea13e0c-3828-4daa-a392-28af7ff61a0f" ]
// }

export interface GUIDs extends Array<String> {}

export interface AddLicense {
  disabledPlan: GUIDs;
  skuId: string;
}

export interface AddLicenses extends Array<AddLicense> {}

export interface RemoveLicenses extends Array<String> {}

export interface UserLicenses {
  addLicenses: AddLicenses;
  removeLicenses: RemoveLicenses;
}

/**
 * New User Optional
 */

 export interface UserOptional {

 }
