
<main id="storefront" class="main create-storefront">

    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item">{{ 'sf.storefrontsettings' | translate }}</li>
        <li class="breadcrumb-item active" *ngIf="!edited">{{ 'sf.createstorefront' | translate }}</li>
        <li class="breadcrumb-item active" *ngIf="edited">{{ 'sf.editstorefront' | translate }}</li>

      <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
        aria-valuemin="0" aria-valuemax="100" style="width: 100%">
      </div>
    </div>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn" style="min-height: 350px;">
      <div class="row">
        <div class="col-12">

          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage != ''">
            <strong>{{ 'word.error.oops' | translate }} {{ 'word.error.somethingwrong' | translate }}</strong>

            {{errorMessage}}
            <button type="button" class="close" (click)="closePressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <h2>{{ 'sf.configurepaymentmethod' | translate }}</h2>

          <ng-template #paymentMethodConfigLoadingSpinner>
            <div class="row">
              <div class="col-12" style="min-height: 150px">
                <div class="lds-roller" style="margin: auto"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
              </div>
            </div>
          </ng-template>
          <div *ngIf="!paymentMethodConfigLoading; else paymentMethodConfigLoadingSpinner">

            <div class="row"  *ngIf="(!paymentMethods || paymentMethods.length < 1)">
              <div class="col-12 mx-0">
                <div class="card card-body" style="color:#813838; background-color: #fee2e1; border-color: #fdd6d6;">
                  <span class="mb-2">{{ 'word.error.oops' | translate }} {{ 'word.error.somethingwrong' | translate }}</span>
                  <span style="font-weight: bolder;">{{ 'error.nopaymentselected' | translate }} </span>
                </div>
              </div>
            </div>

          </div>

          <div class="row" *ngFor="let paymentMethod of paymentMethods">

            <div class="col-12" *ngIf="paymentMethod.id === 1">
              <div class="card collapsable">
                <div class="card-header" (click)="isPayPalCollapsed = !isPayPalCollapsed">
                  <!-- <div class="row">
                    <div class="col-sm-3 col-12">
                      <div class="card-big-img">
                        <img class="img-fluid" src="{{ paymentMethod.pic_url }}" alt="{{ paymentMethod.payment_name }}">
                      </div>
                    </div>
                    <div class="col-sm-9 col-12"> -->
                      <span class="card-header-text">{{paymentMethod.payment_name}}</span>
                    <!-- </div>
                  </div> -->
                  <div class="card-action-collapse">
                    <a>
                      <i style="font-size: 20px;" [ngClass]="isPayPalCollapsed? 'fa fa-caret-down': 'fa fa-caret-up' "></i>
                    </a>
                  </div>
                </div>
                <div class="bank-transfer-panel" class="card-body" [@smoothCollapse]="isPayPalCollapsed?'hide':'show'">
                  <ng-template #paypalLoadingSpinner>
                    <div class="row" style="min-height: 150px">
                      <div class="lds-roller" style="margin: auto"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                      </div>
                    </div>
                  </ng-template>
                  <div class="row" *ngIf="!paypalLoading; else paypalLoadingSpinner">

                    <div class="col-12">
                      <div class="custom-control-lg custom-control custom-checkbox">
                        <input class="custom-control-input" id="checkbox-recurring" name="paypal_recurring"
                        type="checkbox" [(ngModel)]="acceptRecurring" />
                        <label class="custom-control-label" for="checkbox-recurring">
                          {{ 'sf.allowrecurringpayment' | translate }}
                        </label>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <!-- END PayPal -->

            <div class="col-12" *ngIf="paymentMethod.id === 2">
              <div class="card collapsable">
                <div class="card-header" (click)="isBankTransferCollapsed = !isBankTransferCollapsed">
                  <!-- <div class="row">
                    <div class="col-sm-3 col-12">
                      <div class="card-big-img">
                        <img class="img-fluid" src="{{ paymentMethod.pic_url }}" alt="{{ paymentMethod.payment_name }}">
                      </div>
                    </div>
                    <div class="col-sm-9 col-12"> -->
                      <span class="card-header-text">{{paymentMethod.payment_name}}</span>
                    <!-- </div>
                  </div> -->
                  <div class="card-action-collapse">
                    <a>
                      <i style="font-size: 20px;" [ngClass]="isBankTransferCollapsed? 'fa fa-caret-down': 'fa fa-caret-up' "></i>
                    </a>
                  </div>
                </div>
                <div class="bank-transfer-panel" class="card-body" [@smoothCollapse]="isBankTransferCollapsed?'hide':'show'">
                  <ng-template #bankTransferLoadingSpinner>
                    <div class="row" style="min-height: 150px">
                      <div class="lds-roller" style="margin: auto"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                      </div>
                    </div>
                  </ng-template>
                  <div class="row" *ngIf="!bankTransferLoading; else bankTransferLoadingSpinner">

                    <form class="col-12" [formGroup]="paymentMethodForm">
                      <div formArrayName="regions">
                        <div *ngFor="let region of regions().controls; let regionIndex = index">

                          <div class="card card-body mb-2">
                            <div class="row h-100 align-items-center">
                              <div class="col-12" [formGroupName]="regionIndex">
                                <div>
                                  <label>
                                    <span class="ml-1 mr-1">&#9642;</span>
                                    {{ 'word.countryname' | translate }}
                                    <span class="ml-1 mr-1 text-danger">*</span>
                                    <a class="btn" (click)="deleteBankTransferRegionRow(regionIndex)"><i class="fa fa-trash-o" style="font-size: 20px;"></i></a>
                                  </label>
                                  <div *ngIf="region.touched">
                                    <p class="text-danger" *ngIf="region.invalid">{{ 'error.countryrequired' | translate }}</p>
                                  </div>

                                  <input type="text" formControlName="region_name" class="form-control" name="region-{{regionIndex}}">
                                  <label for="region-{{regionIndex}}" class="">{{ region[regionIndex]?.region_name }}</label>
                                </div>

                                <div formArrayName="bank_details">
                                  <div *ngFor="let detail of bankDetails(regionIndex).controls; let detailIndex = index">
                                    <div [formGroupName]="detailIndex">
                                      <hr/>
                                      <div class="row">
                                        <div class="col-6">
                                          <label>{{ 'word.bankname' | translate }}
                                            <a class="btn" (click)="deleteBankTransferDetailsRow(regionIndex, detailIndex)"><i class="fa fa-trash-o" style="font-size: 20px;"></i></a>
                                          </label>
                                        </div>
                                        <div class="col-6">
                                          <input type="text" formControlName="bank_name" class="form-control" name="bank_name-{{detailIndex}}">
                                          <label for="bank_name-{{detailIndex}}" class="">{{ detail[detailIndex]?.bank_name }}</label>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-6">
                                          <label>{{ 'word.bankaccno' | translate }}</label>
                                        </div>
                                        <div class="col-6">
                                          <input type="text" formControlName="bank_account_no" class="form-control" name="bank_account_no-{{detailIndex}}">
                                          <label for="bank_account_no-{{detailIndex}}" class="">{{ detail[detailIndex]?.bank_account_no }}</label>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-6">
                                          <label>{{ 'word.bankaccname' | translate }}</label>
                                        </div>
                                        <div class="col-6">
                                          <input type="text" formControlName="bank_account_name" class="form-control" name="bank_account_name-{{detailIndex}}">
                                          <label for="bank_account_name-{{detailIndex}}" class="">{{ detail[detailIndex]?.bank_account_name }}</label>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-6">
                                          <label>{{ 'word.bankacccontact' | translate }}</label>
                                        </div>
                                        <div class="col-6">
                                          <input type="text" formControlName="bank_account_contact" class="form-control" name="bank_account_contact-{{detailIndex}}">
                                          <label for="bank_account_contact-{{detailIndex}}" class="">{{ detail[detailIndex]?.bank_account_contact }}</label>
                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-6">
                                          <label>{{ 'word.remarks' | translate }}</label>
                                        </div>
                                        <div class="col-6">
                                          <textarea formControlName="remarks" class="form-control" name="remarks-{{detailIndex}}"></textarea>
                                          <label for="remarks-{{detailIndex}}" class="">{{ detail[detailIndex]?.remarks }}</label>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>

                                <div>
                                  <label>{{ 'word.overallremarks' | translate }}</label>
                                  <textarea formControlName="remarks" class="form-control" name="remarks-{{regionIndex}}"></textarea>
                                  <label for="remarks-{{regionIndex}}" class="">{{ region[regionIndex]?.remarks }}</label>
                                </div>
                              </div>
                              <div class="col-12 my-2">
                                <button class="btn btn-primary" (click)="addBankTransferDetailsRow(regionIndex)">{{ 'button.addbankdetails' | translate }}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 my-2">
                        <button class="btn btn-primary" (click)="addBankTransferRegionRow()">{{ 'button.addcountry' | translate }}</button>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>

            <!-- END Bank Transfer -->

          </div>

          <div class="col-lg-12">
            <div class="float-right">
              <button class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()" [disabled]="completeCreationLoad">
                {{ 'button.next' | translate }}
                <i class="fa fa-cog fa-spin" style="font-size:24px;color: lightgreen" *ngIf='completeCreationLoad'></i>
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>

</main>
