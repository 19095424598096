import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';


@Injectable({
  providedIn: 'root'
})
export class AlertService {
  lang: string = 'eng';
  confirmButtonLan = {
    eng: "Confirm",
    cn: "确认",
    zh: "確認",
  }
  cancelButtonLan = {
    eng: "Cancel",
    cn: "取消",
    zh: "取消",
  }
  successLang = {
    eng: "Successful",
    cn: "成功",
    zh: "成功",
  }
  errorLang = {
    eng: "Error",
    cn: "错误",
    zh: "錯誤",
  }
  areyousureLang = {
    eng: "Are you sure?",
    cn: "确认？",
    zh: "確認？",
  }

  constructor(
    private translateService: LanguageTranslateService
  ) {  }

  showError(message: string) {
    Swal.fire({
      icon: 'error',
      title: 'Oops..',
      text: message,
      confirmButtonColor: '#20a8d8',
    });
  }

  showErrorWithHandler(config: any, handler: any) {
    let message: string = '';

    if (config && config.message) {
      message = config.message;
    }

    Swal.fire({
      icon: 'error',
      title: 'Oops..',
      text: message,
      confirmButtonColor: '#20a8d8',
    }).then((result) => {
      if (result.value) {
        handler();
      }
    });
  }

  showSuccess(message: string) {
    Swal.fire({
      icon: 'success',
      title: this.successLang[this.lang],
      text: message,
      confirmButtonColor: '#20a8d8',
    });
  }

  showSuccessWithHandler(config: any, handler: any) {
    let message: string = '';

    if (config && config.message) {
      message = config.message;
    }

    Swal.fire({
      icon: 'success',
      title: this.successLang[this.lang],
      text: message,
      confirmButtonColor: '#20a8d8',
    }).then((result) => {
      if (result.value) {
        handler();
      }
    });
  }

  showInfo(message: string) {
    Swal.fire({
      icon: 'info',
      text: message,
      confirmButtonColor: '#20a8d8',
    });
  }

  showComfirmation(config: any, handler: any) {
    this.lang = this.translateService.userLanguage$.value;
    let message: string = this.areyousureLang[this.lang];
    

    if (config && config.message) {
      message = config.message;
    }

    Swal.fire({
      text: message,
      icon: 'warning',
      confirmButtonColor: '#20a8d8',
      confirmButtonText: this.confirmButtonLan[this.lang],
      showCancelButton: true,
      cancelButtonColor: '#455860',
      cancelButtonText: this.cancelButtonLan[this.lang],
    }).then((result) => {
      if (result.value) {
        handler();
      }
    })
  }

}
