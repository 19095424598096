import { LanguageTranslateService } from './../../service/language-translate.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from '../../service/client.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';

//  declare var $: any;
//   declare var jQuery: any;
  declare var appJS: any;

@Component({
  selector: 'app-client-management',
  templateUrl: './client-management.component.html',
  styleUrls: ['./client-management.component.css']
})
export class ClientManagementComponent implements OnInit {

  clients:any
  dataTable: any;
  lang = "eng";

  constructor(
    private router:Router,
    private clientService:ClientService,
    private chRef: ChangeDetectorRef,
    private languageTranslateService: LanguageTranslateService,
  ) { }

  ngOnInit() {
    this.lang = this.languageTranslateService.userLanguage$.value;
    this.getAllCustomer();
    // appJS.init()
  }

  getAllCustomer(){
    this.clientService.getAllClients().subscribe(
      result => {
        this.clients = result['data']
        console.log(this.clients)
        this.chRef.detectChanges();


      $(document).ready(function() {
            // Setup - add a text input to each footer cell
            $('#example tfoot th').each( function (index) {
              if (index == 2) {
                $(this).html( '<select class="form-control">'+
                                '<option value="active">Active</option>'+
                                '<option value="pending">Pending</option>'+
                                '<option value="suspend">Suspend</option>'+
                              '</select>' );
              } else {
                var title = $(this).text();
                $(this).html( '<input type="text" class="form-control" placeholder="Search '+title+'" />' );
              }

            } );

            // DataTable
            var table : any = $('#example')
            this.dataTable =  table.DataTable( ({
              "language": {
                "url" : "assets/json/datatable_"+ this.lang +".json"
              }
            }),
            {sDom: 'lrtip'});


            // Apply the search
            this.dataTable.columns().every( function (index) {
                var that = this;
                console.log(index)

                if (index == 2) {
                  $( 'select', this.footer() ).on('change', function () {
                      if ( that.search() !== $(this).val() ) {
                          that
                              .search( $(this).val() )
                              .draw();
                      }
                  });
                } else {
                  $( 'input', this.footer() ).on( 'keyup change', function () {
                      if ( that.search() !== $(this).val() ) {
                          that
                              .search( $(this).val() )
                              .draw();
                      }
                  });
                }
            } );
        } );
      }, error => {

      }
    )
  }

  show(client){
    this.router.navigateByUrl('/client-show/'+client.id)
  }

}
