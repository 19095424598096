import { AssignedLicense } from './assigned-license.model';

export class UserLicenses {
  createUserWithLicense: boolean;
  addLicenses: AssignedLicense[];
  removeLicenses: string[];
  usageLocation: string;

  constructor() { }

  addLicense(assignedLicense: AssignedLicense) {
    return this.addLicenses.push(assignedLicense);
  }
}
