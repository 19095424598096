import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StepModel } from '../../../../../model/step.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MicrosoftGraphService } from '../../../../../service/microsoftcsp/microsoft-graph.service';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';

@Component({
  selector: 'app-microsoftcsp-domain-verification',
  templateUrl: './microsoftcsp-domain-verification.component.html',
  styleUrls: ['./microsoftcsp-domain-verification.component.css']
})
export class MicrosoftCSPDomainVerificationComponent implements OnInit {

  @Input() step: StepModel;
  @Output() stepChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() verifiedDomain: EventEmitter<any> = new EventEmitter<any>();

  domainVerificationForm: UntypedFormGroup;

  microsoftAddDNSrecordsUrl: string = 'https://go.microsoft.com/fwlink/?LinkId=824396';
  tenantId: number;
  showDomainVerificationSection: boolean = true;
  showVerifyDomainSection: boolean = false;
  dnsRecords: any[] = [];
  dnsRecord: any = {};
  domain: any;

  dnsRecordLoading: boolean = false;
  verifyDomainLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService,
    private microsoftGraphService: MicrosoftGraphService,
    private translateService: LanguageTranslateService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      this.createDomainVerificationForm();
      this.domain = this.microsoftGraphService.domain;
    });
  }

  async onCompleteStep() {
    this.verifyDomainLoading = true;

    if (this.domain.isVerified === true) {
      if (this.domain.supportedServices.length === 0) {
        const body: any = {
          'supportedServices': [
            "Email",
            "OfficeCommunicationsOnline"
          ]
        };
        await this.microsoftGraphApiService.updateDomain(this.tenantId.toString(), this.domain.id, body)
          .toPromise()
          .then((_res: any) => {
            this.verifyDomainLoading = false;
          })
          .catch(err => {
            this.verifyDomainLoading = false;
            console.error('updateDomain error', err);
          });
      }

      this.verifiedDomain.emit(this.domain);
      this.step.isComplete = true;
      return;
    }

    await this.microsoftGraphApiService.verifyDomain(this.tenantId.toString(), this.domain.id)
      .toPromise()
      .then((res: any) => {

        this.domain.isVerified = res.isVerified;
        this.domain.isRoot = res.isRoot;

        const body: any = {
          'isDefault': true,
          'supportedServices': [
            "Email",
            "OfficeCommunicationsOnline"
          ]
        };

        this.microsoftGraphApiService.updateDomain(this.tenantId.toString(), this.domain.id, body)
          .subscribe((_res: any) => {
            this.verifyDomainLoading = false;
            this.verifiedDomain.emit(this.domain);
            this.step.isComplete = true;
          }, err => {
            this.verifyDomainLoading = false;
            console.error('updateDomain error', err);
          });

      })
      .catch(err => {
        this.verifyDomainLoading = false;
        console.error('verifyDomain error', err);
      });
  }

  createDomainVerificationForm() {
    this.domainVerificationForm = this.formBuilder.group({
      recordType: ['Txt', [Validators.required]],
    })
  }

  onBackStep() {
    this.step.isComplete = false;
    this.stepChanged.emit();
  }

  async onContinueStep() {
    this.dnsRecordLoading = true;

    if (this.domain.isVerified === true) {
      this.onCompleteStep();
      return;
    }

    await this.microsoftGraphApiService.getDomainVerificationDnsRecords(this.tenantId.toString(), this.domain.id)
      .toPromise()
      .then((res: any) => {
        this.dnsRecordLoading = false;

        this.dnsRecords = res.value;
        this.dnsRecord = this.dnsRecords.find(_dnsRecord => _dnsRecord.recordType == this.recordTypeFormControl.value);

        this.showDomainVerificationSection = false;
        this.showVerifyDomainSection = true;
      })
      .catch(err => {
        this.dnsRecordLoading = false;
        this.showDomainVerificationSection = true;
        this.showVerifyDomainSection = false;
        console.error('getDomainVerificationDnsRecords error', err);
      });
  }

  onVerifyDomainBackStep() {
    this.showVerifyDomainSection = false;
    this.showDomainVerificationSection = true;
  }

  openStepByStepInstructions(event: any) {
    event.preventDefault();
    window.open(this.microsoftAddDNSrecordsUrl, '_blank');
  }

  copyToClipboard(text: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  get recordTypeFormControl() { return this.domainVerificationForm.get('recordType'); }

}
