import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { LanguageTranslateService } from '../service/language-translate.service';

@Injectable({
    providedIn: 'root'
})
export class TranslateResolver implements Resolve<any> {

    constructor(private translateService: LanguageTranslateService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        return this.translateService.initLanguageProperties();
    }

}
