import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftGraphApiService {

  apiUrl: string = environment.reseller.apiUrl;
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient
  ) { }

  getHeaders(): HttpHeaders {
    return new HttpHeaders()
      .append('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
      .append('Content-Type', 'application/json');
  }

  public createUserAndAddLicenses(tenantId: string, data: any) {
    const endpoint = `${this.apiUrl}/sfadmin/microsoftgraph/tenants/${tenantId}/users`;
    return this.httpClient.post(endpoint, data, { headers: this.getHeaders() });
  }

  public getUsers(tenantId: string, queryOptions?: any) {
    const endpoint = `${this.apiUrl}/sfadmin/microsoftgraph/tenants/${tenantId}/users`;
    return this.httpClient.get(endpoint, { headers: this.getHeaders(), params: queryOptions });
  }

  public getUserById(tenantId: string, msUserId: string) {
    const endpoint = `${this.apiUrl}/sfadmin/microsoftgraph/tenants/${tenantId}/users/${msUserId}`;
    return this.httpClient.get(endpoint, { headers: this.getHeaders() });
  }

  public getDomains(tenantId: string, queryOptions?: any): Observable<any> {
    const endpoint = `${this.apiUrl}/sfadmin/microsoftgraph/tenants/${tenantId}/domains`;
    return this.httpClient.get(endpoint, { headers: this.getHeaders() });
  }

  public getDomainVerificationDnsRecords(tenantId: string, domain: string): Observable<any> {
    const endpoint = `${this.apiUrl}/sfadmin/microsoftgraph/tenants/${tenantId}/domains/${domain}/verificationDnsRecords`;
    return this.httpClient.get(endpoint, { headers: this.getHeaders() });
  }

  public getDomainServiceConfigurationRecords(tenantId: string, domain: string): Observable<any> {
    const endpoint = `${this.apiUrl}/sfadmin/microsoftgraph/tenants/${tenantId}/domains/${domain}/serviceConfigurationRecords`;
    return this.httpClient.get(endpoint, { headers: this.getHeaders() });
  }

  public verifyDomain(tenantId: string, domain: string): Observable<any> {
    const endpoint = `${this.apiUrl}/sfadmin/microsoftgraph/tenants/${tenantId}/domains/${domain}/verify`;
    return this.httpClient.post(endpoint, null, { headers: this.getHeaders() });
  }

  /**
   * https://docs.microsoft.com/en-us/graph/api/resources/domain?view=graph-rest-1.0#properties
   * refer to supportedServices for available values
   * @since 2020-09-18
   */
  public updateDomain(tenantId: string, domain: string, body: any): Observable<any> {
    const endpoint = `${this.apiUrl}/sfadmin/microsoftgraph/tenants/${tenantId}/domains/${domain}`;
    return this.httpClient.patch(endpoint, body, { headers: this.getHeaders() });
  }

  public getSubscribedSkus(tenantId: string): Observable<any> {
    const endpoint = `${this.apiUrl}/sfadmin/microsoftgraph/tenants/${tenantId}/subscribedSkus`;
    return this.httpClient.get(endpoint, { headers: this.getHeaders() });
  }

  public getAdminConsentUrl(wnTenantId: string): Observable<any> {
    const queryOptions: any = {
      'redirectUri': `${this.baseUrl}/microsoftcsp/tenants/adminconsent/auth`
    };
    const endpoint = `${this.apiUrl}/tenants/adminconsent`;
    return this.httpClient.get(endpoint, { headers: this.getHeaders(), params: queryOptions });
  }

  public updateMicrosoftTenantId(wnTenantId: string, microsoftTenantId: string): Observable<any> {
    const body: any = {
      'microsoftTenantId': microsoftTenantId
    };
    const endpoint = `${this.apiUrl}/tenants/${wnTenantId}/adminconsent`;
    return this.httpClient.put(endpoint, body, { headers: this.getHeaders() });
  }

  /**
   * @see	https://portal.microsoft.com/admin/api/tenant/customviewfilterdefaults
   * @since 2021-01-04
   */
  public getUsageLocations(): Observable<any> {
    return this.httpClient.get('assets/json/microsoft-usage-locations.json');
  }

  /**
   * @see https://docs.microsoft.com/en-us/azure/active-directory/enterprise-users/licensing-service-plan-reference
   * @since 2020-12-02
   */
  public getLicensingServicePlanReference(): Observable<any> {
    return this.httpClient.get('assets/json/microsoft-licensing-service-plan-reference.json');
  }

  public createDomain(wnTenantId: string, domainName: string) {
    const body: any = {
      'id': domainName
    };
    const endpoint = `${this.apiUrl}/tenants/${wnTenantId}/domains`;
    return this.httpClient.post(endpoint, body, { headers: this.getHeaders() });
  }
}
