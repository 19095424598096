import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChildren, QueryList, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SortDirection, SortableTableHeader, SortEvent } from 'src/app/directive/sortable.directive';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { MicrosoftGraphService } from 'src/app/service/microsoftcsp/microsoft-graph.service';

@Component({
  selector: 'app-microsoftcsp-user',
  templateUrl: './microsoftcsp-user.component.html',
  styleUrls: ['./microsoftcsp-user.component.css']
})
export class MicrosoftCSPUserComponent implements OnInit {

  errorCheck: boolean = false;
  errors: any[] = [];

  tableLoading: boolean = false;

  modalRef: BsModalRef;

  userSearchParams: any;
  users: any[] = [];
  tenantId: number;

  filterForm: UntypedFormGroup;

  pageSizeOptions: number[] = [10, 20, 30, 50];
  pageSize: number;
  page: number;
  totalItems: number;
  sortColumn: string = '';
  sortDirection: SortDirection = '';
  pagedItemsInfo = { startIndex: 0, endIndex: 0, total: 0 };
  nextPage: any = {};

  @ViewChildren(SortableTableHeader) sortableHeaders: QueryList<SortableTableHeader>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private microsoftGraphApiService: MicrosoftGraphApiService,
    private microsoftGraphService: MicrosoftGraphService
  ) { }

  ngOnInit() {
    this.totalItems = 0;
    this.page = 1;
    this.pageSize = this.pageSizeOptions[0];

    this.userSearchParams = {
      // currentPage: this.page,
      $select: 'id,displayName,userPrincipalName',
      $orderby: 'displayName asc',
      $count: true,
      $top: this.pageSize,
    };

    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      if (!this.microsoftGraphService.adminConsent === true) {
        this.goToAdminConsentPage();
      } else {
        this.getMicrosoftUsers(this.tenantId);
      }
    });
  }

  getMicrosoftUsers(tenantId: number, isMore: boolean = false) {
    this.tableLoading = true;
    if (!isMore) {
      this.users.length = 0;
    }

    let queryOptions: any = {};

    queryOptions = { ...queryOptions, ...this.userSearchParams };

    this.microsoftGraphApiService
      .getUsers(this.tenantId.toString(), queryOptions)
      .subscribe((res: any) => {
        if (isMore) {
          this.users.push(...res.value);
        } else {
          this.users = res.value;
        }

        if (res['@odata.count']) {
          this.totalItems = res['@odata.count'];
        }

        if (res['@odata.nextLink']) {
          const responseParam = new URL(res['@odata.nextLink']).searchParams;
          this.nextPage = { $skiptoken: encodeURIComponent(responseParam.get('$skiptoken')) };
        } else {
          this.nextPage = undefined;
        }

        delete this.userSearchParams['$skiptoken'];

        this.updatePagedItemsInfo();
        this.tableLoading = false;
      }, err => {
        console.error('getMicrosoftUsers error', err);
        // this.handleError(err);

        // reset items
        this.totalItems = 0;
        this.users.length = 0;
        this.updatePagedItemsInfo();
        this.tableLoading = false;
      });
  }

  updatePagedItemsInfo() {
    if (this.totalItems < 1) {
      this.pagedItemsInfo = { startIndex: 0, endIndex: 0, total: 0 };
      return;
    }

    let offset: number = (this.page - 1) * this.pageSize;
    let end = offset + this.pageSize;

    this.pagedItemsInfo.total = this.totalItems;
    this.pagedItemsInfo.startIndex = offset != 0 ? offset + 1 : 1;
    this.pagedItemsInfo.endIndex = end < this.totalItems ? end : this.totalItems;
  }

  // onPageChanged(page: number) {
  //   this.page = page;

  //   let newPageInfo = {
  //     currentPage: this.page
  //   }
  //   // update userSearchParams with new page info values
  //   this.userSearchParams = { ...this.userSearchParams, ...newPageInfo };
  //   // get users by updated userSearchParams
  //   this.getMicrosoftUsers(this.tenantId);
  // }

  onMorePressed() {
    if (this.tableLoading) {
      return;
    }
    if (this.nextPage === undefined) {
      return;
    }
    this.userSearchParams = { ...this.userSearchParams, ...this.nextPage };
    this.getMicrosoftUsers(this.tenantId, true);
  }

  onPageSizeChanged(size: any) {
    // if datatable still loading then do nothing
    if (this.tableLoading) {
      return;
    }

    this.pageSize = Number(size);
    this.page = 1; // always reset page to 1

    const newPageInfo = {
      $top: this.pageSize,
      // currentPage: this.page
    }
    // update userSearchParams with new page info values
    this.userSearchParams = { ...this.userSearchParams, ...newPageInfo };
    // get users by updated userSearchParams
    this.getMicrosoftUsers(this.tenantId);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.sortableHeaders.forEach(header => {
      if (header.column !== column) {
        header.direction = '';
      }
    });

    // always reset current sorting to empty 1st
    this.sortColumn = '';
    this.sortDirection = '';

    // update the current sorting if direction event is either 'asc' or 'desc'
    if (direction === 'asc' || direction === 'desc') {
      this.sortColumn = column;
      this.sortDirection = direction;
    }

    const newSorting = {
      $orderby: this.sortColumn + ' ' + this.sortDirection
    }
    // update userSearchParams with new sorting values
    this.userSearchParams = { ...this.userSearchParams, ...newSorting };
    // get users by updated userSearchParams
    this.getMicrosoftUsers(this.tenantId);
  }

  openSearchFilterModal(template: TemplateRef<any>) {

    const displayName = this.userSearchParams.displayName;
    const userPrincipalName = this.userSearchParams.userPrincipalName;

    this.filterForm = this.formBuilder.group({
      displayName: [displayName ? displayName : ''],
      userPrincipalName: [userPrincipalName ? userPrincipalName : '']
    });

    this.openModal(template);
  }

  filterSearch() {
    let filterParams: any = this.filterForm.value;
    let actualFilterParams: any = {
      $filter: ''
    };

    if (this.filterForm.invalid) {
      return;
    }

    // unset all null or undefined values
    for (let propName in filterParams) {
      if (filterParams[propName] === null || filterParams[propName] === undefined || filterParams[propName] === '') {
        delete filterParams[propName];
      } else {
        filterParams[propName] = 'startswith(' + propName + ', \'' + filterParams[propName] + '\')';
        // filterParams[propName] = propName + ' eq \'' + filterParams[propName] + '\'';

        if (actualFilterParams.$filter !== '') {
          actualFilterParams.$filter = actualFilterParams.$filter + ' or ';
        }
        actualFilterParams.$filter = actualFilterParams.$filter + filterParams[propName];
      }
    }

    this.userSearchParams = { ...this.userSearchParams, ...actualFilterParams };
    this.getMicrosoftUsers(this.tenantId);
    this.modalRef.hide();
  }

  clearSearch() {
    this.filterForm.reset();
    let clearedFilterParams: any = this.filterForm.value;
    let actualClearedFilterParams: any = {
      $filter: ''
    };
    this.userSearchParams = { ...this.userSearchParams, ...actualClearedFilterParams };

    this.getMicrosoftUsers(this.tenantId);
    this.modalRef.hide();
  }

  openModal(template: TemplateRef<any>) {
    if (this.modalRef) {
      this.modalRef.hide();
    }

    let modalConfig: any = {
      backdrop: true,
      class: 'modal-dialog-centered'
    };

    this.modalRef = this.modalService.show(template, modalConfig);
  }

  goToUserDetails(user: any) {
    this.router.navigate(['/microsoftcsp/tenants/', this.tenantId, 'users', user.id]);
  }

  goToAdminConsentPage() {
    this.router.navigate(['/microsoftcsp/tenants/', this.tenantId, 'adminconsent']);
  }

  // handleError(httpErrorResponse: HttpErrorResponse) {
  //   this.errors.length = 0;
  //   let errorResponse = httpErrorResponse.error;

  //   if (errorResponse && errorResponse.error) {
  //     let swpApiError: any = errorResponse.error;
  //     let subCode: string = swpApiError.subCode;
  //     let message: string = swpApiError.message;

  //     if (subCode == 'SWP4000') {
  //       let validationErrors: any[] = errorResponse.validationErrors;
  //       this.errors.push(validationErrors.map(err => err.message));
  //       this.errorCheck = true;
  //       return;
  //     }
  //     if (subCode == 'SWP4001') {
  //       this.errors.push(message);
  //       this.errorCheck = true;
  //       return;
  //     }

  //     // by default set swpApiError's message
  //     this.errors.push(message);
  //     this.errorCheck = true;
  //     return;
  //   }

  //   // default flag true to indicate error
  //   this.errorCheck = true;
  // }

  errorClosePressed() {
    this.errorCheck = false;
    this.errors.length = 0;
  }

}
