<main id="storefront" class="main create-storefront">


  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'o365.orderMO365' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.steptwo' | translate }}</li>

    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
           aria-valuemin="0" aria-valuemax="100" style="width: 75%">
      </div>
    </div>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row">

        <div class="mx-auto" style="width: 600px;" *ngIf="errorCheck">
          <div class="alert alert-danger alert-dismissible fade show">
            <button type="button" (click)="closeError()" class="close" id="close">&times;</button>
            <strong>{{ 'word.error' | translate }}</strong> {{ 'error.selectproduct' | translate }}
          </div>
        </div>

        <div class="col-lg-12">

          <div class="card card-package mb-4">
            <div class="card-header">
              &nbsp;
              <div class="card-actions">
                <!-- <a (click)="closePressed()" class="btn-close"><i class="icon-close"></i></a> -->
              </div>
            </div>
            <div class="card-body">

              <div class="col-sm-12 col-12">
                <div [hidden]="dataIsLoaded" class="row" style="min-height: 450px">
                  <div class="lds-roller" style="margin: auto"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div [hidden]="!dataIsLoaded" class="row" *ngFor="let product of product_arr">

                  <div class="col-xl-6 product-name">
                    <div class="checkbox-container">
                      <div class="card-xl-table h-150">
                        <div class="card-xl-table-row">
                          <div class="card-xl-table-cell v-xl-align-middle">
                            <input value="{{ product.product_id }}" type="radio" name="packages"
                                   id="office_{{product.product_id}}" [(ngModel)]="selectedProduct">
                            <label for="office_{{product.product_id}}" class="bg-checkbox-dark"></label>
                            <h5>{{ product.product_name }}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="card-xl-table h-150">
                      <div class="card-xl-table-row">
                        <div class="card-xl-table-cell v-xl-align-middle">
                          <p>{{ 'word.annual' | translate }}: USD {{ product.annually }}</p>
                          <p>{{ 'word.monthly' | translate }}: USD {{ product.monthly }}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="float-right">

      <label class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()">
        {{ 'button.next' | translate }}
      </label>
    </div>
  </div>


</main>
