import { LanguageTranslateService } from './../../service/language-translate.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  OrderService
} from '../../service/order.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';

declare var appJS: any;
@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.css']
})
export class OrderManagementComponent implements OnInit {

  orders: any
  dataTable: any
  itemsCount: any;
  pageNumber: number;
  orderByResellerDone: boolean;
  storefronts: any; 
  
  lang = "eng";

  constructor(
    private router: Router,
    private orderService: OrderService,
    private chRef: ChangeDetectorRef,
    private languageTranslateService: LanguageTranslateService,
  ) {}

  ngOnInit() {
    this.lang = this.languageTranslateService.userLanguage$.value;

    this.itemsCount = 10;
    this.pageNumber = 1;

    this.getOrderByReseller();
    // appJS.init()  
  }

  show(id) {
    this.router.navigateByUrl('/order/' + id);
  }

  getOrderByReseller() {
    this.orderByResellerDone = false;
    this.orderService.getAllOrderByReseller().subscribe(
      result => {
        console.log(result)
        this.orders = result['orders'];
        this.storefronts = result['storefronts'];
        console.log(this.orders)
        this.chRef.detectChanges();
        // const table: any = $('#table');
        this.orderByResellerDone = true;
        var that = this;
        $(document).ready(function () {
          this.orderByResellerDone = true;
          // Setup - add a text input to each footer cell
          $('#orders tfoot th').each(function (index) {
            if (index == 3) {
              $(this).html('<select class="form-control">' +
                '<option value="">No Filter</option>' +
                '<option value="Microsoft Office 365">Microsoft Office 365</option>' +
                '</select>');
            } else if (index == 4) {
              $(this).html('<select class="form-control">' +
                '<option value="">No Filter</option>' +
                '<option value="new">New</option>' +
                '<option value="in progress">In Progress</option>' +
                '<option value="rejected">Rejected</option>' +
                '</select>');
            } else if (index == 5) {
              $(this).html('<select class="form-control">' +
                '<option value="">No Filter</option>' +
                '<option value="success">Success</option>' +
                '<option value="fail">Fail</option>' +
                '<option value="No payment made">No payment made</option>' +
                '</select>');
            } else if (index == 7) {
              var html = `<select class="form-control"><option value="" selected disabled>No Filter</option>`;

              for (var i = 0; i < that.storefronts.length; ++i) {
                html += `<option value=${that.storefronts[i]}>${that.storefronts[i]}</option>`;
              }

              html += `</select>`;

              $(this).html(html);

            } else {
              var title = $(this).text();
              $(this).html('<input type="text" class="form-control" placeholder="Search ' + title + '" />');
            }

          });

          // DataTable
          var table: any = $('#orders')
          this.dataTable = table.DataTable({
            "language": {
              "url" : "assets/json/datatable_"+ this.lang +".json"
            },
            "order": [
              [6, "desc"]
            ]
          });

          // Apply the search
          this.dataTable.columns().every(function (index) {
            var that = this;
            console.log(index)

            if (index == 3 || index == 4 || index == 5 || index == 7) {
              $('select', this.footer()).on('change', function () {
                if (that.search() !== $(this).val()) {
                  that
                    .search($(this).val())
                    .draw();
                }
              });
            } else {
              $('input', this.footer()).on('keyup change', function () {
                if (that.search() !== $(this).val()) {
                  that
                    .search($(this).val())
                    .draw();
                }
              });
            }
          });
        });
        // //
        // let jQueryInstance = this
        // $('#table').on('click', 'tbody td', function() {

        //   //get textContent of the TD
        //   console.log('TD cell textContent : ', this.parentNode.rowIndex)
        //   jQueryInstance.router.navigateByUrl('/order/' +jQueryInstance.orders[this.parentNode.rowIndex-1]["order_id"]);
        // //  window.location.href = '/order/' +jQueryInstance.orders[this.parentNode.rowIndex]["order_id"];

        //   //get the value of the TD using the API
        //   //
        // })
        // this.dataTable = table.DataTable({
        //   // dom: '<"wrapper"lfrtip>',
        //   paging: true,
        //   ajax: (data, callback, settings) => {

        //     $.ajax({
        //       url: this.orderService.apiUrl + 'order/table-retrieve-data',
        //       // dataType: 'text',
        //       type: 'post',
        //       contentType: 'application/x-www-form-urlencoded',
        //       headers: {
        //         Authorization: "Bearer " + localStorage.getItem('access_token')
        //       },
        //       data: {
        //         page: data.start,
        //         size: data.length,
        //       },
        //       success: function (res, textStatus, jQxhr) {
        //
        //
        //

        //         callback({
        //           // draw: 1,
        //           data: res.orders,
        //           recordsTotal: res.pagination.totalItems,
        //           recordsFiltered: res.pagination.totalItems,
        //           page: res.pagination.pageIndex,
        //           pageLength: res.pagination.totalPages,
        //         });
        //       },
        //       error: function (jqXhr, textStatus, errorThrown) {
        //
        //
        //
        //
        //       }
        //     });
        //   },
        //   serverSide: true,
        //   columns: [
        //     { "data": "client" },
        //     { "data": "product" },
        //     { "data": "category" },
        //     { "data": "order_status" },
        //     { "data": "payment_status" },
        //     { "data": "date" },
        //     { "data": "storefront" }
        //   ]

        // });
      }, error => {
        console.log(error)
      })
  }

}
