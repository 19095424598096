import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { StorefrontCreateComponent } from './component/storefront-create/storefront-create.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { OrderManagementComponent } from './component/order-management/order-management.component';
import { ClientManagementComponent } from './component/client-management/client-management.component';
import { NotificationCreateComponent } from './component/notification-create/notification-create.component';
import { NotificationManageComponent } from './component/notification-manage/notification-manage.component';
import { OrderShowComponent } from './component/order-show/order-show.component';
import { ClientShowComponent } from './component/client-show/client-show.component';
import { NotificationEditComponent } from './component/notification-edit/notification-edit.component';
import { StorefrontCreateOneComponent } from './component/storefront-create-one/storefront-create-one.component';
import { StorefrontCreateTwoComponent } from './component/storefront-create-two/storefront-create-two.component';
import { StorefrontCreateThreeComponent } from './component/storefront-create-three/storefront-create-three.component';
import { StorefrontCreateFourComponent } from './component/storefront-create-four/storefront-create-four.component';
import { StorefrontCreateFiveComponent } from './component/storefront-create-five/storefront-create-five.component';
import { StorefrontCreateSixComponent } from './component/storefront-create-six/storefront-create-six.component';
import { StorefrontCreateSevenComponent } from './component/storefront-create-seven/storefront-create-seven.component';
import { AuthGuard } from './guard/auth.guard';
import { StorefrontManageComponent } from './component/storefront-manage/storefront-manage.component';
import { LoginLayoutComponent } from './shared/login-layout/login-layout.component';
import { HomeLayoutComponent } from './shared/home-layout/home-layout.component';
import { NotificationListComponent } from './component/notification-list/notification-list.component';
import { O365MasterTemplateComponent } from './component/o365-master-template/o365-master-template.component'
import { O365DashboardComponent } from './component/o365-dashboard/o365-dashboard.component'
import { O365PlaceOrderComponent } from './component/o365-place-order/o365-place-order.component'
import { O365OrderManagementComponent } from './component/o365-order-management/o365-order-management.component'
import { O365StepOneComponent } from './component/o365-step-one/o365-step-one.component'
import { O365StepTwoComponent } from './component/o365-step-two/o365-step-two.component'
import { O365StepThreeComponent } from './component/o365-step-three/o365-step-three.component'
import { O365StepFourComponent } from './component/o365-step-four/o365-step-four.component'
import { O365StepFiveComponent } from './component/o365-step-five/o365-step-five.component'
import { O365OrderDetailComponent } from './component/o365-order-detail/o365-order-detail.component'
import { TranslateResolver } from './resolver/translate.resolver';
const routes: Routes = [
  {
    path: '', redirectTo: 'o365-dashboard', pathMatch: 'full'
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full'},
      { path: 'login', component: LoginComponent }

    ]
  },
  {
    path: '',
    component: O365MasterTemplateComponent,
    resolve: {translate: TranslateResolver},
    children: [
      {path: 'o365-dashboard', component: O365DashboardComponent, canActivate: [AuthGuard]},
      {path: 'o365-order-management', component: O365OrderManagementComponent, canActivate: [AuthGuard]},
      {path: 'o365-place-order', component: O365PlaceOrderComponent, canActivate: [AuthGuard]},
      {path: 'o365-step-one', component: O365StepOneComponent, canActivate: [AuthGuard]},
      {path: 'o365-step-two', component: O365StepTwoComponent, canActivate: [AuthGuard]},
      {path: 'o365-step-three', component: O365StepThreeComponent, canActivate: [AuthGuard]},
      {path: 'o365-step-four', component: O365StepFourComponent, canActivate: [AuthGuard]},
      {path: 'o365-step-five', component: O365StepFiveComponent, canActivate: [AuthGuard]},
      {path: 'o365-order-detail/:id', component: O365OrderDetailComponent, canActivate: [AuthGuard]},
    ],
    data: {panel: "o365 Panel"}
  },
  {
    // lazy-loading MicrosoftCSPModule, sub-path defined in /module/microsoftcsp/microsoftcsp-routing.modules.ts
    path: 'microsoftcsp',
    resolve: {translate: TranslateResolver},
    loadChildren: () => import('./module/microsoftcsp/microsoftcsp.module').then(m => m.MicrosoftCSPModule),
    data: {panel: "MicrosoftCSP Panel"}
  },
  {
    // lazy-loading EmailModule, sub-path defined in /module/email/email-routing.modules.ts
    path: 'email',
    resolve: {translate: TranslateResolver},
    loadChildren: () => import('./module/email/email.module').then(m => m.EmailModule), // this lazy-loading declaration is for Angular 2-7, angular 8 and above using different way
    data: {panel: "Email Panel"}
  },
  {
    // lazy-loading SectigoWebModule, sub-path defined in /module/sectigoweb/sectigoweb-routing.module.ts
    path: 'sectigoweb',
    resolve: {translate: TranslateResolver},
    loadChildren: () => import('./module/sectigoweb/sectigoweb.module').then(m => m.SectigoWebModule),
    data: {panel: "Sectigoweb Panel"}
  },
  {
    // lazy-loading WhmcsModule, sub-path defined in /module/whmcs/whmcs-routing.module.ts
    path: 'whmcs',
    resolve: {translate: TranslateResolver},
    loadChildren: () => import('./module/whmcs/whmcs.module').then(m => m.WhmcsModule),
    data: {panel: "WHMCS Panel"}
  },
  {
    path: '',
    component: HomeLayoutComponent,
    resolve: {translate: TranslateResolver},
    children: [
      {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      {path: 'notification-list', component: NotificationListComponent, canActivate: [AuthGuard]},
      {path: 'create-storefront', component: StorefrontCreateComponent, canActivate: [AuthGuard]},
      {path: 'create-storefront-1', component: StorefrontCreateOneComponent, canActivate: [AuthGuard]},
      {path: 'create-storefront-2', component: StorefrontCreateTwoComponent, canActivate: [AuthGuard]},
      {path: 'create-storefront-3', component: StorefrontCreateThreeComponent, canActivate: [AuthGuard]},
      {path: 'create-storefront-4', component: StorefrontCreateFourComponent, canActivate: [AuthGuard]},
      {path: 'create-storefront-5', component: StorefrontCreateFiveComponent, canActivate: [AuthGuard]},
      {path: 'create-storefront-6', component: StorefrontCreateSixComponent, canActivate: [AuthGuard]},
      {path: 'create-storefront-7', component: StorefrontCreateSevenComponent, canActivate: [AuthGuard]},

      {path: 'manage-storefront', component: StorefrontManageComponent, canActivate: [AuthGuard]},
      {path: 'manage-storefront-1/:id', component: StorefrontCreateOneComponent, canActivate: [AuthGuard]},
      {path: 'manage-storefront-2/:id', component: StorefrontCreateTwoComponent, canActivate: [AuthGuard]},
      {path: 'manage-storefront-3/:id', component: StorefrontCreateThreeComponent, canActivate: [AuthGuard]},
      {path: 'manage-storefront-4/:id', component: StorefrontCreateFourComponent, canActivate: [AuthGuard]},
      {path: 'manage-storefront-5/:id', component: StorefrontCreateFiveComponent, canActivate: [AuthGuard]},
      {path: 'manage-storefront-6/:id', component: StorefrontCreateSixComponent, canActivate: [AuthGuard]},
      {path: 'manage-storefront-7/:id', component: StorefrontCreateSevenComponent, canActivate: [AuthGuard]},

      {path: 'orders', component: OrderManagementComponent, canActivate: [AuthGuard]},
      {path: 'order/:id', component: OrderShowComponent, canActivate: [AuthGuard]},

      {path: 'clients', component: ClientManagementComponent, canActivate: [AuthGuard]},
      {path: 'client-show/:id', component: ClientShowComponent, canActivate: [AuthGuard]},

      {path: 'create-notification', component: NotificationCreateComponent, canActivate: [AuthGuard]},
      {path: 'manage-notification', component: NotificationManageComponent, canActivate: [AuthGuard]},
      {path: 'edit-notification/:id', component: NotificationEditComponent, canActivate: [AuthGuard]},

      {
        // lazy-loading StorefrontProductModule
        path: 'product',
        loadChildren: () => import('./module/product/product.module').then(m => m.StorefrontProductModule)
      },
    ],
    data: {panel: "Default Panel"}
  },
  {
    // lazy-loading EmailNotificationModule, sub-path defined in /module/email-notification/email-notification-routing.module.ts
    path: 'email-notification',
    resolve: {translate: TranslateResolver},
    loadChildren: () => import('./module/email-notification/email-notification.module').then(m => m.EmailNotificationModule),
    data: {panel: "Email Notification Panel"}
  },
  {
    path: 'dns',
    resolve: {translate: TranslateResolver},
    loadChildren: () => import('./module/dns/dns.module').then(m => m.DnsModule),
    data: {panel: "DNS Panel"}
  },
  {
    path: 'namewrap',
    resolve:{translate:TranslateResolver},
    loadChildren: () => import('./module/namewrap/namewrap.module').then(m => m.NamewrapModule),
    data: {panel: "Namewrap Panel"}
  },
  {
    path: 'ssl',
    resolve: {translate: TranslateResolver},
    loadChildren: () => import('./module/ssl/ssl.module').then(m => m.SslModule),
    data: {panel: "SSL Panel"}
  },
  {
    path: 'mimining',
    resolve: {translate: TranslateResolver},
    loadChildren: () => import('./module/mimining/mimining.module').then(m => m.MiminingModule),
    data: {panel: "Domain Backorder"}
  },
  {
    path: 'china-acceleration',
    resolve: {translate: TranslateResolver},
    loadChildren: () => import('./module/china-acceleration/china-acceleration.module').then(m => m.ChinaAccelerationModule),
    data: {panel: "ChinaAcceleration Panel"}
  },

  {
    path: '**', redirectTo: 'o365-dashboard' // 404 not found
  }

]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
