<!-- Domain verification section -->
<div class="animated fadeIn" *ngIf="showDomainVerificationSection">
  <div class="row">
    <div class="col-12">
      <h3>{{ 'o365.howtoverifydomain' | translate }}</h3>
      <p>{{ 'o365.beforesetupdomain' | translate }} {{domain.id}}</p>
      <form [formGroup]="domainVerificationForm" style="overflow-y: visible">
        <div class="row">
          <div class="col-12">
            <div class="group form-group">

              <div class="col-12 mb-2">
                <input id="recordTypeTxt" name="recordType" type="radio" [value]="'Txt'" formControlName="recordType">
                <label for="recordTypeTxt">
                  <span class="ml-2">{{ 'o365.radio.addtxtrecord' | translate }}</span>
                </label>
                <div>
                  <span style="margin-left: 20px">
                    {{ 'o365.domainverify.title1' | translate }}
                  </span>
                </div>
              </div>

              <div class="col-12">
                <input id="recordTypeMx" name="recordType" type="radio" [value]="'Mx'" formControlName="recordType">
                <label for="recordTypeMx">
                  <span class="ml-2">{{ 'o365.radio.addmxrecord' | translate }}</span>
                </label>
                <div>
                  <span style="margin-left: 20px">
                    {{ 'o365.domainverify.title2' | translate }}
                  </span>
                </div>
              </div>

              <!-- <div class="col-12">
                <input id="recordTypeFile" name="recordType" type="radio" [value]="'File'" formControlName="recordType">
                <label for="recordTypeFile">
                  <span class="ml-2">Add a text file to the domain's website</span>
                </label>
                <div>
                  <span style="margin-left: 20px">
                    Recommended if you've already set up a website using this domain, for example, ‎www.{{domain.id}}
                  </span>
                </div>
              </div> -->

            </div>
          </div>
        </div>

        <button type="button" class="btn btn-secondary m-3" (click)="onBackStep()">
          {{ 'button.back' | translate }}
        </button>

        <button type="button" class="btn btn-primary m-3" (click)="onContinueStep()" [disabled]="domain === undefined || domain == null || dnsRecordLoading">
          <span>{{ 'button.continue' | translate }}</span>
          <i class="ml-1 fa fa-spinner fa-spin" *ngIf="dnsRecordLoading"></i>
        </button>

      </form>
    </div>
  </div>
</div>

<!-- Verify domain section -->
<div class="animated fadeIn" *ngIf="showVerifyDomainSection">
  <div class="row">
    <div class="col-12">
      <h3>{{ 'o365.verifyowndomain' | translate }}</h3>
      <p>{{ 'o365.verifyowndomain.content1' | translate }} {{domain.id}}‎, {{ 'o365.verifyowndomain.content2' | translate }} ‎TXT‎ {{ 'o365.verifyowndomain.content3' | translate }}</p>
      <p>{{ 'o365.verifyowndomain.content4' | translate }}</p>
      <div>
        <a href="https://go.microsoft.com/fwlink/?LinkId=824396" _target="blank" (click)="openStepByStepInstructions($event)">{{ 'button.stepbystepinstructions' | translate }}</a>
      </div>

      <!-- Txt record type -->
      <div class="row" *ngIf="recordTypeFormControl.value === 'Txt' && dnsRecords && dnsRecords.length > 0">
        <div class="col-12">{{ 'o365.txtname' | translate }}</div>
        <div class="col-12">
          <div>
            <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(domain.isRoot === true ? '@' : dnsRecord.label)">
              <i class="icon-docs"></i>
            </button>
            <span class="ml-1">{{domain.isRoot === true ? '@' : dnsRecord.label}} ({{ 'o365.skipifnotsupported' | translate }})</span>
          </div>
        </div>

        <div class="col-12">{{ 'o365.txtvalue' | translate }}</div>
        <div class="col-12">
          <div>
            <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.text)">
              <i class="icon-docs"></i>
            </button>
            <span class="ml-1">{{dnsRecord.text}}</span>
          </div>
        </div>

        <div class="col-12">{{ 'word.ttl' | translate }}</div>
        <div class="col-12">
          <div>
            <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.ttl)">
              <i class="icon-docs"></i>
            </button>
            <span class="ml-1">{{dnsRecord.ttl}} ({{ 'o365.yourproviderdefault' | translate }})</span>
          </div>
        </div>

      </div>

      <!-- MX record type -->
      <div class="row" *ngIf="recordTypeFormControl.value === 'Mx' && dnsRecords && dnsRecords.length > 0">
        <div class="col-12">{{ 'word.hostname' | translate }}</div>
        <div class="col-12">
          <div>
            <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(domain.isRoot === true ? '@' : dnsRecord.label)">
              <i class="icon-docs"></i>
            </button>
            <span class="ml-1">{{domain.isRoot === true ? '@' : dnsRecord.label}} ({{ 'o365.skipifnotsupported' | translate }})</span>
          </div>
        </div>

        <div class="col-12">{{ 'o365.pointstoaddressorvalue' | translate }}</div>
        <div class="col-12">
          <div>
            <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.mailExchange)">
              <i class="icon-docs"></i>
            </button>
            <span class="ml-1">{{dnsRecord.mailExchange}}</span>
          </div>
        </div>

        <div class="col-12">{{ 'word.priority' | translate }}</div>
        <div class="col-12">
          <div>
            <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.preference)">
              <i class="icon-docs"></i>
            </button>
            <span class="ml-1">{{dnsRecord.preference}} ({{ 'o365.largevaluenotused' | translate }})</span>
          </div>
        </div>

        <div class="col-12">{{ 'word.ttl' | translate }}</div>
        <div class="col-12">
          <div>
            <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.ttl)">
              <i class="icon-docs"></i>
            </button>
            <span class="ml-1">{{dnsRecord.ttl}} ({{ 'o365.yourproviderdefault' | translate }})</span>
          </div>
        </div>

      </div>

      <button type="button" class="btn btn-secondary m-3" (click)="onVerifyDomainBackStep()">
        {{ 'button.back' | translate }}
      </button>

      <button type="button" class="btn btn-primary m-3" (click)="onCompleteStep()" [disabled]="verifyDomainLoading || !dnsRecords || dnsRecords.length <= 0">
        <span>{{ 'button.verify' | translate }}</span>
        <i class="ml-1 fa fa-spinner fa-spin" *ngIf="verifyDomainLoading"></i>
      </button>

    </div>
  </div>
</div>
