import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResellerService } from 'src/app/service/reseller/reseller.service';
import { PushNotificationService } from 'src/app/shared/push-notification.service';
import { TokenUtil } from 'src/app/util/token-util';

declare var customJS: any;
declare var $: any;
declare var jQuery: any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
	payBtnPressed = false;
	unreadNotifications = 0;
	latestNotifications;
	selectedNotif;

	panel;

	reseller: any;

	isNamewrapReseller: boolean = false;

	@ViewChild('dropdown', { static: true }) dropdownDiv; 

	constructor(
		private pushNotificationService: PushNotificationService,
		private route: ActivatedRoute,
		private resellerService: ResellerService	
	) { 
		this.panel = route.snapshot.data.panel;
	}

	async ngOnInit() {
		this.getLatestNotifications();
		this.pushNotificationService.getLatestNotifications().subscribe();
		this.initRescode();
	}
	
	getLatestNotifications(): void {
		this.pushNotificationService.latestNotifications$.subscribe((data) => {
			this.latestNotifications = data["data"]["notifications"];
			this.unreadNotifications = data["data"]["unread_count"];
		});
	}

	clickNotification(notification) {
		this.selectedNotif=notification;
		this.selectedNotif.content = eval("`"+this.selectedNotif.content+"`");

		this.pushNotificationService.markNotificationAsRead(notification['id']).subscribe(data => {
		  if(notification['type'] == "2") {
				window.open(notification['targeted_page'], '_blank');
		  } else {
				$('#notificationModal').modal('show');
		  }

		  this.pushNotificationService.getLatestNotifications().subscribe();
		}, err => {
		});
	}
	
	logoutPressed(){
		this.payBtnPressed = true;
		 localStorage.removeItem('access_token');
		 window.location.href = "/"

	}

	clickGrid(e) {
		if (e.target.classList.contains("nav-link")) {
			e.target.classList.remove("wave-animation");
			e.target.classList.toggle("click-grid");
		} else {
			e.target.classList.remove("wave-animation");
			e.target.parentElement.classList.toggle("click-grid");
		}
		
	}

	toggleSidebar() {
		var $body = document.getElementsByTagName("BODY")[0];    	
		$body.classList.toggle('sidebar-mobile-show');
	}

	initRescode(){
		let rescode: String = (TokenUtil.getRescode(localStorage.getItem("access_token")));

		if(rescode.toLowerCase().startsWith("namewrap")){
			this.isNamewrapReseller = true;
		}
	}
}
