import { Component, OnInit } from '@angular/core';
import { StoreFrontService } from '../../service/store-front.service';
import { StoreService } from '../../service/store.service'
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, FormControl, Validators } from '@angular/forms';
import { smoothCollapse } from 'src/app/shared/animations';

@Component({
  selector: 'app-storefront-create-seven',
  templateUrl: './storefront-create-seven.component.html',
  styleUrls: ['./storefront-create-seven.component.css'],
  animations: [
    smoothCollapse
  ]
})
export class StorefrontCreateSevenComponent implements OnInit {

  paymentMethods: any[] = [];
  selectedPaymentMethodIds: any[] = [];
  selectedPayPal: boolean = false;
  selectedBankTransfer: boolean = false;
  currentPaymentMethodConfigs: any[] = [];
  paypalOption: any;
  acceptRecurring: boolean = false;
  bankTransferOption: any;

  paymentMethodForm: UntypedFormGroup;
  isBankTransferCollapsed: boolean = false;
  isPayPalCollapsed: boolean = false;

  paymentMethodConfigLoading: boolean = false;
  paypalLoading: boolean = false;
  bankTransferLoading: boolean = false;

  clientId = '';
  secretKey = '';
  locationCurrency = false
  companyMerchant = false

  completeCreationLoad = false;
  storefrontId;
  edited = false;
  paymentResponse;
  storeId;
  errorMessage = '';

  constructor(
    public storeFrontService: StoreFrontService,
    public storeService: StoreService,
    public route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.createPaymentMethodForm();
    this.selectedPaymentMethodIds = this.storeService.getPaymentMethods();

    this.paymentMethodConfigLoading = true;
    this.storeFrontService.getAllPaymentMethods().subscribe((res: any) => {
      this.paymentMethods = res.data;
      this.paymentMethods = this.paymentMethods.filter(pm => {
        if (this.selectedPaymentMethodIds.includes(pm.id.toString())) {
          return pm;
        }
      });

      this.paymentMethodConfigLoading = false;
    });

    if (this.route.snapshot.paramMap.get('id')) {
      this.storeId = this.route.snapshot.paramMap.get('id')
      this.storeService.getStorefront(this.route.snapshot.paramMap.get('id')).subscribe(res => {

        // this.paymentResponse = res['data']['payments'][0]['options'];
        this.currentPaymentMethodConfigs = res['data']['payments'];
        if (this.currentPaymentMethodConfigs.some(pm => pm.id == 1)) {
          this.paypalLoading = true;
        }
        if (this.currentPaymentMethodConfigs.some(pm => pm.id == 2)) {
          this.bankTransferLoading = true;
        }

        this.currentPaymentMethodConfigs.forEach(pm => {
          if (pm.id === 1) {
            this.paypalOption = pm['options'];
            this.acceptRecurring = JSON.parse(this.paypalOption['paypal_recurring']);
          } else if (pm.id === 2) {
            this.bankTransferOption = pm['options'];
            const regions = this.bankTransferOption['regions'];

            if (regions && regions.length > 0) {
              for(let i = 0; i < regions.length; i++) {
                let region = regions[i];
                const bank_details = region['bank_details'];

                const data: any = {
                  region_name: region['region_name'],
                  remarks: region['remarks']['en'],
                  bank_details: this.formBuilder.array([])
                };

                this.addBankTransferRegionRow(data);

                if(bank_details && bank_details.length > 0) {
                  bank_details.forEach(detail => {
                    let data: any = {
                      bank_name: '',
                      bank_account_no: '',
                      bank_account_name: '',
                      bank_account_contact: '',
                      remarks: ''
                    };

                    detail.forEach(labelArr => {
                      if (labelArr['label'] == 'PaymentMethod.BankTransfer.BankName') {
                        data.bank_name = labelArr['value'];
                      } else if (labelArr['label'] == 'PaymentMethod.BankTransfer.AccNo') {
                        data.bank_account_no = labelArr['value'];
                      } else if (labelArr['label'] == 'PaymentMethod.BankTransfer.AccName') {
                        data.bank_account_name = labelArr['value'];
                      } else if (labelArr['label'] == 'PaymentMethod.BankTransfer.AccContact') {
                        data.bank_account_contact = labelArr['value'];
                      } else if (labelArr['label'] == 'PaymentMethod.BankTransfer.Remark') {
                        data.remarks = labelArr['value'];
                      }
                    });

                    this.addBankTransferDetailsRow(i, data);
                  });
                }
              }
            }
          }

          this.paypalLoading = false;
          this.bankTransferLoading = false;
        });

        // this.clientId=this.paymentResponse['paypal_merchant_client_id'];
        // this.secretKey = this.paymentResponse['paypal_merchant_secret_key'];
        // this.locationCurrency = JSON.parse(this.paymentResponse['paypal_location_based_currency']);
        // this.companyMerchant = JSON.parse(this.paymentResponse['paypal_merchant']);
        // this.acceptRecurring = JSON.parse(this.paymentResponse['paypal_recurring']);
      });
      this.storefrontId = this.route.snapshot.paramMap.get('id');
      this.edited = true;
    }
  }

  nextPressed() {

    if (this.paymentMethodForm.invalid) {
      return;
    }

    this.errorMessage = ""
    this.completeCreationLoad = true;
    this.selectedPaymentMethodIds.forEach(paymentId => {
      if (paymentId == '1') {
        let paypalOption: any = {
          paypal_merchant: this.companyMerchant,
          paypal_merchant_client_id: this.clientId,
          paypal_merchant_secret_key: this.secretKey,
          paypal_location_based_currency: this.locationCurrency,
          paypal_recurring: this.acceptRecurring
        };

        this.storeService.setupPaymentOption(1, paypalOption);

      } else if (paymentId == '2') {
        const selectedBankTransferOption: any[] = this.paymentMethodForm.value;

        selectedBankTransferOption['regions'].forEach(region => {
          let bank_details: any[] = [];
          let bankDetailArr: any[] = [];

          region['bank_details'].forEach(detail => {
            bankDetailArr = [];
            bankDetailArr.push({ label: 'PaymentMethod.BankTransfer.BankName', value: detail['bank_name'] });
            bankDetailArr.push({ label: 'PaymentMethod.BankTransfer.AccNo', value: detail['bank_account_no'] });
            bankDetailArr.push({ label: 'PaymentMethod.BankTransfer.AccName', value: detail['bank_account_name'] });
            bankDetailArr.push({ label: 'PaymentMethod.BankTransfer.AccContact', value: detail['bank_account_contact'] });
            bankDetailArr.push({ label: 'PaymentMethod.BankTransfer.Remark', value: detail['remarks'] });

            bank_details.push(bankDetailArr);
          });

          region['bank_details'] = bank_details;
          let overall_remarks = region['remarks'];
          region['remarks'] = { en: overall_remarks, my: overall_remarks };
          region['region_code'] = region['region_name'];
        });

        this.storeService.setupPaymentOption(2, selectedBankTransferOption);
      }
    });

    if (this.edited) {
      this.storeFrontService.updateStorefront(this.storeId).subscribe(resp => {
        this.completeCreationLoad = false;
        this.router.navigateByUrl('/manage-storefront')

      }, err => {
        console.log(err)
      });
    } else {
      this.storeFrontService.createStorefront().subscribe(resp => {
        this.completeCreationLoad = false;
        this.router.navigateByUrl('/create-storefront')

      }, err => {
        console.log(err)
        this.errorMessage = "Server error upon creating storefront. Please try again."
        this.completeCreationLoad = false
      });
    }
  }

  closePressed() {
    this.errorMessage = ""
  }

  createPaymentMethodForm() {
    this.paymentMethodForm = this.formBuilder.group({
      regions: this.formBuilder.array([])
    });
  }

  regions(): UntypedFormArray {
    return this.paymentMethodForm.get('regions') as UntypedFormArray;
  }

  bankDetails(regionIndex: number): UntypedFormArray {
    return this.regions().at(regionIndex).get('bank_details') as UntypedFormArray;
  }

  newBankTransferRegionRow(data?: any): UntypedFormGroup {
    if (data == undefined || data == null || !data) {
      data = {
        region_name: '',
        remarks: ''
      };
    }
    return this.formBuilder.group({
      region_name: [data.region_name, Validators.required],
      remarks: [data.remarks],
      bank_details: this.formBuilder.array([])
    });
  }

  addBankTransferRegionRow(data?: any): void {
    this.regions().push(this.newBankTransferRegionRow(data));
  }

  deleteBankTransferRegionRow(regionIndex: number): void {
    this.regions().removeAt(regionIndex);
  }

  newBankTransferDetailsRow(data?: any): UntypedFormGroup {
    if (data == undefined || data == null || !data) {
      data = {
        bank_name: '',
        bank_account_no: '',
        bank_account_name: '',
        bank_account_contact: '',
        remarks: ''
      };
    }
    return this.formBuilder.group({
      bank_name: [data.bank_name],
      bank_account_no: [data.bank_account_no],
      bank_account_name: [data.bank_account_name],
      bank_account_contact: [data.bank_account_contact],
      remarks: [data.remarks]
    });
  }

  addBankTransferDetailsRow(regionIndex: number, data?: any): void {
    this.bankDetails(regionIndex).push(this.newBankTransferDetailsRow(data));
  }

  deleteBankTransferDetailsRow(regionIndex: number, detailIndex: number): void {
    this.bankDetails(regionIndex).removeAt(detailIndex);
  }

}
