import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { O365HttpService } from 'src/app/service/o365-http.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { Router, ActivatedRoute } from '@angular/router';
import { O365OrderService } from 'src/app/service/o365-order.service';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';

declare var appJS: any;

@Component({
  selector: 'app-o365-order-management',
  templateUrl: './o365-order-management.component.html',
  styleUrls: ['./o365-order-management.component.css']
})
export class O365OrderManagementComponent implements OnInit {

  orders;
  dataTable;
  orderLoading = true;
  paginationData;
  lang = '';
  dtLangURL = {
    "language" : {
      "url" : "assets/json/datatable_eng.json"
    }
  };

  constructor(
    public httpService: O365HttpService,
    private orderService: O365OrderService,
    private chRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: LanguageTranslateService
  ) { }

  ngOnInit() {
    this.getAllOrders(); 
  }

  getAllOrders() {
    this.lang = this.translateService.userLanguage$.value;
    this.orderLoading = true;
    this.route.queryParams.subscribe((params) => {
      let filter = params['filter'];
      console.log('filter is', filter);

      this.httpService.getOrdersV2(filter).subscribe((response) => {
        this.orders = response['orders'];
        this.paginationData = response['pagination'];
        this.chRef.detectChanges();
        this.orderLoading = false;
        
        const table: any = $('table');
        if(this.lang) {
          this.dtLangURL = {
            "language": {
              "url" : "assets/json/datatable_"+ this.lang +".json"
            }
          };
        }
        this.dataTable = table.DataTable(this.dtLangURL);
      });
    });
  }
  
  showOrder(id) {
    // console.log('show order', id);
    // this.orderService.setOrderData(data);
    this.router.navigate(['/o365-order-detail', id]);
  }
}
