<main id="microsoftcsp-subscription-detail" class="main">

    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
      <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants">{{ 'word.tenants' | translate }}</li>
      <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants/{{tenantId}}">{{ tenantId }}</li>
      <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants/{{tenantId}}/subscriptions">{{ 'word.subscription' | translate }}</li>
      <li class="breadcrumb-item active">{{ subscriptionId }}</li>
    </ol>

    <div class="container-fluid">
      <div class="animated fadeIn">

        <div class="row" *ngIf="errorCheck">
          <div class="col-12">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
              <ul>
                <li *ngFor="let error of errors">
                  {{ error }}
                </li>
              </ul>
              <button type="button" class="close" (click)="errorClosePressed()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="successCheck">
          <div class="col-12">
            <div class="alert alert-success alert-dismissible fade show" role="alert">
              {{successMessage}}
              <button type="button" class="close" (click)="successClosePressed()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>

        <div class="row" style="min-height: 350px;">

          <div class="col-12">
            <div class="table-responsive mb-4">
              <table class="table table-bordered mb-0">
                <thead class="thead-light">
                  <tr>
                    <th colspan="2">{{ 'word.subscriptiondetails' | translate }}</th>
                  </tr>
                </thead>
                <tbody class="bg-white" *ngIf="subscriptionInfo">
                  <tr>
                    <td>{{ 'word.subscriptionname' | translate }}</td>
                    <td>{{ subscriptionInfo.productName }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'word.licenses' | translate }}</td>
                    <td>{{ subscriptionInfo.licenseCount }} User(s)</td>
                  </tr>
                  <tr>
                    <td>{{ 'word.status' | translate }}</td>
                    <td>{{ subscriptionInfo.statusName }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'word.startdate' | translate }}</td>
                    <td>{{ subscriptionInfo.dtstart | date:'medium' }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'word.enddate' | translate }}</td>
                    <td>{{ subscriptionInfo.dtend | date:'medium' }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'word.billingcycle' | translate }}</td>
                    <td>{{ subscriptionInfo.billingCycleDescription }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'word.autorenew' | translate }}</td>
                    <td>{{ subscriptionInfo.isAutoRenew? 'Yes':'No' }}</td>
                  </tr>
                  <tr *ngIf="subscriptionInfo.isAutoRenew">
                    <td>{{ 'word.renewaldate' | translate }}</td>
                    <td>{{ subscriptionInfo.dtnextrenew | date:'medium'  }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-12">
            <div class="table-responsive mb-4">
              <table class="table table-bordered mb-0">
                <thead class="thead-light">
                  <tr>
                    <th colspan="3">{{ 'word.action' | translate }}</th>
                  </tr>
                </thead>
                <tbody class="bg-white microsoftcsp-actions" *ngIf="subscriptionInfo">
                  <tr *ngIf="enableResumeSubscription">
                    <td colspan="2">{{ 'o365.resumesubscription' | translate }}</td>
                    <td>
                      <button (click)="resumeSubscription()" class="btn btn-block btn-primary"
                      [disabled]="resumeSubscriptionBtnPressed || allBtnDisable"> {{ 'button.resume' | translate }} </button>
                    </td>
                  </tr>
                  <tr *ngIf="enableSuspendSubscription">
                    <td colspan="2">{{ 'o365.suspendsubscription' | translate }}</td>
                    <td>
                      <button (click)="suspendSubscription()" class="btn btn-block btn-primary"
                      [disabled]="suspendSubscriptionBtnPressed || allBtnDisable">
                      {{ 'button.suspend' | translate }} <i *ngIf="suspendSubscriptionBtnPressed" class="fa fa-spinner fa-spin"></i>
                     </button>
                    </td>
                  </tr>
                  <tr *ngIf="enableUpdateLicenseQuantity">
                    <td colspan="2">{{ 'o365.updatelicense' | translate }}</td>
                    <td>
                      <button (click)="openUpdateLicenseQuantityModal(updateLicenseQuantityModal)" class="btn btn-block btn-primary"
                      [disabled]="updateLicenseQuantityBtnPressed || allBtnDisable">
                      {{ 'button.go' | translate }} <i *ngIf="updateLicenseQuantityBtnPressed" class="fa fa-spinner fa-spin"></i>
                    </button>
                    </td>
                  </tr>
                  <tr *ngIf="enableRenewSubscription">
                    <td colspan="2">{{ 'o365.renewsubscription' | translate }}</td>
                    <td>
                      <button (click)="renewSubscription()" class="btn btn-block btn-primary"
                      [disabled]="renewSubscriptionBtnPressed || allBtnDisable">
                      {{ 'button.renew' | translate }} <i *ngIf="renewSubscriptionBtnPressed" class="fa fa-spinner fa-spin"></i>
                    </button>
                    </td>
                  </tr>
                  <tr *ngIf="enableActionEnableAutoRenew">
                    <td colspan="2">{{ 'o365.enableautorenew' | translate }}</td>
                    <td>
                      <button (click)="enableAutoRenew()" class="btn btn-block btn-primary"
                      [disabled]="enableAutoRenewBtnPressed || allBtnDisable">
                      {{ 'button.enable' | translate }} <i *ngIf="enableAutoRenewBtnPressed" class="fa fa-spinner fa-spin"></i>
                     </button>
                    </td>
                  </tr>
                  <tr *ngIf="enableActionDisableAutoRenew">
                    <td colspan="2">{{ 'o365.disableautorenew' | translate }}</td>
                    <td>
                      <button (click)="disableAutoRenew()" class="btn btn-block btn-primary"
                      [disabled]="disableAutoRenewBtnPressed || allBtnDisable">
                      {{ 'button.disable' | translate }} <i *ngIf="disableAutoRenewBtnPressed" class="fa fa-spinner fa-spin"></i>
                     </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-xl-12 text-right">
            <a routerLink="/microsoftcsp/tenants/{{tenantId}}/subscriptions" class="btn btn-dark btn-lg px-5 mb-4">{{ 'button.back' | translate }}</a>
        </div>
        </div>

      </div>
    </div>

    <ng-template #updateLicenseQuantityModal>
      <div class="modal-header">
        <div class="modal-title">{{ 'o365.updatelicense' | translate }}</div>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="updateLicenseQuantityForm" id="updateLicenseQuantityForm" class="form" style="width: 100%;">
          <div class="form-row align-items-center mb-4">
            <label class="col-lg-3 col-md-3 col-12 mb-0">
              {{ 'word.addquantity' | translate }}
            </label>
            <div class="col-lg-9 col-md-9 col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <button class="btn btn-sm btn-dark border border-dark" (click)="increaseOrDecreaseLicenseCount('decrease')" type="button">
                    <i class="fa fa-minus"></i>
                  </button>
                </div>
                <input formControlName="licenseQuantity" id="licenseQuantityId" type="number"
                class="form-control form-control-sm text-center no-spinner no-focus-shadow" (change)="updateAddLicenseTotalPrice()"
                [ngClass]=" {'is-invalid': licenseQuantityFormControl.touched && licenseQuantityFormControl.invalid }" />
                <div class="input-group-append">
                  <button class="btn btn-sm btn-dark border border-dark" (click)="increaseOrDecreaseLicenseCount('increase')" type="button">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="offset-lg-3 offset-md-3 col-lg-9 col-md-9 col-12" *ngIf="licenseQuantityFormControl.touched">
              <p *ngIf="licenseQuantityFormControl.hasError('min')" class="text-danger" >
                {{ 'error.minimumquantity' | translate }}
              </p>
              <p *ngIf="licenseQuantityFormControl.hasError('max')" class="text-danger" >
                {{ 'error.maximumquantity' | translate }} {{maxAddQuantityAllowed}}.
              </p>
              <p *ngIf="licenseQuantityFormControl.hasError('required')" class="text-danger">
                {{ 'error.licensequanrequired' | translate }}
              </p>
             </div>
          </div>
          <div class="form-row align-items-center mb-4">
            <label class="col-lg-3 col-md-3 col-12 mb-0">
              {{ 'word.totalprice' | translate }}
            </label>
            <div class="col-lg-9 col-md-9 col-12 text-lg-right text-md-right text-left" style="font-weight: 700;">
              {{ addLicenseTotalPriceFormControl.value }}
              <span *ngIf="displaySubscriptionMonthly"> {{ 'word.usdpermonth' | translate }}</span>
              <span *ngIf="displaySubscriptionAnnually"> {{ 'o365.usdperannual' | translate }}</span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="updateLicenseQuantity()"
        [disabled]="updateLicenseQuantityForm.invalid || updateLicenseQuantityBtnPressed || allBtnDisable">
        {{ 'button.add' | translate }}
        </button>
        <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">
          {{ 'button.close' | translate }}
        </button>
      </div>
    </ng-template>

  </main>
