import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class O365DnsService {

  apiUrl = environment.apiUrl;
  dnsApiUrl:string = environment.dnsService.apiUrl;

  httpHeaders = {
    headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
  }

  getHeaders(){
    // create new instance for each api call
    const httpHeaders = {
      headers : new HttpHeaders()
      .set("Authorization", "Bearer " + localStorage.getItem('access_token'))
      .set('Content-Type', 'application/json')
    }
    return httpHeaders;
  }

  constructor(
    private http: HttpClient
  ) {}

  getDNSGuide() {
    return this.http.get(`${this.apiUrl}microsoft-csp/dns/get-dns-guide`, this.httpHeaders)
  }

  checkDomainName(domain) {
    let data = {
      domain: domain
    }
    let headers = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
    };

    console.log(data)
    return this.http.post(`${this.apiUrl}microsoft-csp/domain/check-domain-name`, data, headers)
  }

  checkIfWebnicDomain(domain) {
    let headers = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
    };
    let data = {
      domain: domain,
      lang: "ENG"
    }
    console.log(data)
    return this.http.post(`${this.apiUrl}microsoft-csp/domain/is-webnic-domain`, data, headers)
  }

  checkIfDefaultDNS(domain) {
    let headers = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
    };
    let data = {
      domain: domain,
      lang: "ENG"
    }
    console.log(data)
    return this.http.post(`${this.apiUrl}microsoft-csp/domain/check-default-ns`, data, headers)
  }

  autoconfigDns(domain, orderId) {
    let headers = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
    };
    let data = {
      domain: domain,
      lang: "ENG",
      order_id: orderId
    }
    console.log(data)
    return this.http.post(`${this.apiUrl}microsoft-csp/dns/dns-auto-config`, data, headers)
  }

  createDomain(data) {
    let headers = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
    };
    return this.http.post(`${this.apiUrl}microsoft-csp/domain/register`, data, headers)
  }

  getDomainPrice() {
    return this.http.get(`${this.apiUrl}microsoft-csp/domain/get-product-domain-info`, this.httpHeaders)
  }

  // dns-rest section -----------------------------------
  createDnsRecord(request: any, domain: String) {
    let endpoint: string = `/domains/${domain}/records`;
    return this.http.post(this.dnsApiUrl+endpoint, request, this.getHeaders());
  }

  deleteDnsRecord(request: any, domain: String) {
    let endpoint: string = `/domains/${domain}/records/delete`;
    return this.http.post(this.dnsApiUrl+endpoint, request, this.getHeaders());  
  }
}
