export class UserBasics {
  firstName?: string;
  lastName?: string;
  displayName: string;
  username: string;
  domain: string;
  generatePassword: boolean;
  password?: string;
  forceChangePassword: boolean;

  constructor() { }
}
