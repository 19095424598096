import { finalize } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/service/order.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ScrollUtil } from 'src/app/util/scroll-util';

declare var appJS: any;
@Component({
  selector: 'app-order-show',
  templateUrl: './order-show.component.html',
  styleUrls: ['./order-show.component.css']
})

export class OrderShowComponent implements OnInit {

  orderId:number;
  order:any
  storefront:any
  orderStatus = 'Incomplete';

  paymentDoneForm: UntypedFormGroup;
  markAsDoneBtnPressed: boolean = false;
  disableRefNoInput: boolean = false;

  successCheck:boolean = false;
  successMessage:string = '';

  errorCheck:boolean = false;
  errors:any[] = [];

  constructor(
    private orderService:OrderService,
    private route:ActivatedRoute,
    private formBuilder:UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.orderId = +this.route.snapshot.paramMap.get('id');
    this.getOrderById(this.orderId); 
  }

  getOrderById(id){
    this.orderService.getOrderById(id).subscribe(
      result => {
        this.order = result['order']
        this.storefront = result['storefront']
        
        if(this.order['status'] == 'Success' || this.order['payment_details'][0]['status'] == 'Success') {
          this.orderStatus = 'Completed';
          this.disableRefNoInput = true;
        }

        if(this.order.payment_details[0].status == 'Pending'){
          this.disableRefNoInput = false;
        }

        let refNo:string = '';
        if(this.order.payment_details[0].refno != '' && this.order.payment_details[0].refno != null){
          refNo = this.order.payment_details[0].refno;
        }

        console.log(result);

        this.paymentDoneForm = this.formBuilder.group({
          refno: [ {value: refNo,disabled: this.disableRefNoInput || this.markAsDoneBtnPressed},
             [Validators.required]],
          id: [this.order['order_id'], [Validators.min(1)]],
          sf_id: [this.order['sf_id'], [Validators.min(1)]],
          sf_user_id: [this.order['sf_user_id'], [Validators.min(1)]]
        });
      }, error => {

      }
    )
  }

  /*paymentDone(id){
    this.orderService.postPaymentStatus(id).subscribe(
      result => {
        console.log(result)
      }, error => {

      }
    )
  }*/

  /*paymentDone(id, sf_id, sf_user_id){
    this.orderService.postPaymentStatus(id, sf_id, sf_user_id).subscribe(
      result => {
        console.log(result)
      }, error => {

      }
    )
  }*/

  paymentDone(){
    this.markAsDoneBtnPressed = true;
    this.disableRefNoInput = true;
    console.log(this.paymentDoneForm.value);

    let refno = this.paymentDoneForm.value.refno;
    let id = this.paymentDoneForm.value.id;
    let sf_id = this.paymentDoneForm.value.sf_id;
    let sf_user_id = this.paymentDoneForm.value.sf_user_id;

    this.orderService.postPaymentStatus(refno, id, sf_id, sf_user_id).pipe(finalize(()=>{
      this.markAsDoneBtnPressed = false;
      this.disableRefNoInput = false;
    })).subscribe(
      (res:any) => {
          this.getOrderById(this.orderId);
          this.successMessage = res.message;
          this.successCheck = true;
          ScrollUtil.scrollToTop();
          
      }, error => {
        console.error(error);
        this.errors.push(error.error.message);
        this.errorCheck = true;
        ScrollUtil.scrollToTop();
      }
    )
  }

  proceedOrder(id, sf_id, sf_user_id){
    this.orderService.postProceedOrder(id, sf_id, sf_user_id).subscribe(
      result => {
        console.log(result)
      }, error => {
        console.log(error)
      }
    )
  }

  refundOrder(){
    this.orderService.postRefundOrder(0).subscribe(
      result => {

      }, error => {

      }
    )
  }

  closeErrorPressed(){
    this.errorCheck = false;
    this.errors.length = 0;
  }
  closeSuccessPressed(){
    this.successCheck = false;
    this.successMessage = '';
  }

  scrollToTop() {
		(function smoothscroll() {
			var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			if (currentScroll > 0) {
				window.requestAnimationFrame(smoothscroll);
				window.scrollTo(0, currentScroll - (currentScroll / 8));
			}
		})();
	}

}
