
    <main id="manage-order" class="main">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item active">{{ 'word.orders' | translate }}</li>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  
                  <div class="row">
                    <div class="table-responsive col">
                      <table id="orders" class="table table-hover table-bordered">
                        <thead>
                          <tr>
                            <th>{{ 'word.orderid' | translate }}</th>
                            <th>{{ 'word.client' | translate }}</th>
                            <th>{{ 'word.products' | translate }}</th>
                            <th>{{ 'word.productcategory' | translate }}</th>
                            <th>{{ 'word.orderstatus' | translate }}</th>
                            <th>{{ 'word.paymentstatus' | translate }}</th>
                            <th>{{ 'word.orderdate' | translate }}</th>
                            <th>{{ 'word.storefront' | translate }}</th>
                          </tr>
                        </thead>
                        <tbody id="table-data">
                          <td colspan="7" style="text-align: center" [hidden]="orderByResellerDone">{{ 'word.loading' | translate }}</td>
                          <tr class="clickable-row" *ngFor="let order of orders" (click)="show(order.order_id)">
                            <td>{{order.order_id}}</td>
                            <td>{{order.client}}</td>
                            <td>{{order.product}}</td>
                            <td>{{order.category}}</td>
                            <td>
                              <span *ngIf="order.order_status == 'New'" class="badge badge-success">{{order.order_status}}</span>
                              <span *ngIf="order.order_status == 'Processing'" class="badge badge-warning">{{order.order_status}}</span>
                              <span *ngIf="order.order_status == 'Done'" class="badge badge-secondary">{{order.order_status}}</span>
                              <span *ngIf="order.order_status == 'Rejected'" class="badge badge-danger">{{order.order_status}}</span>
                              <span *ngIf="order.order_status == 'In Progress'" class="badge badge-warning">{{order.order_status}}</span>
                              <span *ngIf="order.order_status == 'Success'" class="badge badge-secondary">{{order.order_status}}</span>
                            </td>
                            <td>
                              <span *ngIf="order.payment_status == 'Success'" class="badge badge-secondary">{{order.payment_status}}</span>
                              <span *ngIf="order.payment_status == 'Refunded'" class="badge badge-secondary">{{order.payment_status}}</span>
                              <span *ngIf="order.payment_status == 'Fail'" class="badge badge-danger">{{order.payment_status}}</span>
                              <span *ngIf="order.payment_status == 'Pending'" class="badge badge-success">{{order.payment_status}}</span>
                              <span *ngIf="order.payment_status == 'Payment not made'" class="badge badge-warning">{{order.payment_status}}</span>
                              <span *ngIf="order.payment_status == 'Missing Payment Detail'" class="badge badge-danger">{{order.payment_status}}</span>
                            </td>
                            <td>{{order.date }}</td>
                            <td>{{order.storefront}}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>{{ 'word.orderid' | translate }}</th>
                            <th>{{ 'word.client' | translate }}</th>
                            <th>{{ 'word.products' | translate }}</th>
                            <th>{{ 'word.productcategory' | translate }}</th>
                            <th>{{ 'word.orderstatus' | translate }}</th>
                            <th>{{ 'word.paymentstatus' | translate }}</th>
                            <th>{{ 'word.orderdate' | translate }}</th>
                            <th>{{ 'word.storefront' | translate }}</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </main>
