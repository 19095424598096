import {
  Component,
  OnInit
} from '@angular/core';
import {
  O365HttpService
} from '../../service/o365-http.service'
import {
  Router
} from '@angular/router'
import {
  O365OrderService
} from 'src/app/service/o365-order.service';
@Component({
  selector: 'app-o365-step-two',
  templateUrl: './o365-step-two.component.html',
  styleUrls: ['./o365-step-two.component.css']
})
export class O365StepTwoComponent implements OnInit {

  product_arr: any[];
  selectedProduct;
  errorCheck = false;
  dataIsLoaded = false;

  constructor(
    public o365HttpService: O365HttpService,
    public orderService: O365OrderService,
    public router: Router
  ) {}

  ngOnInit() {
    // CR229 2020-03-06 get product price by country condition, added by ethan
    let country_id = this.orderService.data.contact_address_country_id;
    let options:any = {
      category_name: 'Microsoft Office 365',
      country_id: country_id
    }

    this.o365HttpService.getAllProductsV2(options).subscribe(resp => {
      this.product_arr = resp["data"];

      let tenantData = this.orderService.getTenantData();
      let microsoftMarketCategories: any[] = [];
      this.o365HttpService.getMarketCategories().subscribe((res: any) => {
        microsoftMarketCategories = res.data;
        let selectedMicrosoftMarketCategory = microsoftMarketCategories.find(c => c.id == tenantData.microsoft_market_category_id);

        // CR253
        if(selectedMicrosoftMarketCategory && selectedMicrosoftMarketCategory.name != 'Academic') {
          // temporary filter out all products which is academic and not-for-profit
          let academicAndNonProfitProductId = [377,378,379,380,381,382,383,391,392,393,394];
          // notes: this will filter to products which is not in 'academicAndNotProfitProductId' array
          this.product_arr = this.product_arr.filter(p => !academicAndNonProfitProductId.includes(p.product_id) );
        }

        this.dataIsLoaded = true;
      });
    }, error => {
      console.error(error);
    });

    // CR253
    this.orderService.selectedProduct = null;
  }

  closeError() {
    this.errorCheck = false;
  }

  backPressed() {
    this.router.navigate(['/o365-step-one']);
  }

  nextPressed() {
    if(this.selectedProduct == null) {
      this.errorCheck = true;
    } else {
      console.log('selected product is', this.selectedProduct);
      this.orderService.setProductId(this.selectedProduct);

      this.product_arr.forEach((e) => {
        if(e.product_id == this.selectedProduct) {
          this.orderService.setPriceMonthly(e.monthly);
          this.orderService.setPriceAnnually(e.annually);
        }
      });

      // CR253 set selected product object
      let currentSelectedProduct = this.product_arr.find(p=>p.product_id == this.selectedProduct);
      if(currentSelectedProduct){
        this.orderService.selectedProduct = currentSelectedProduct;
      }

      this.router.navigate(['/o365-step-three'])
    }
  }

}
