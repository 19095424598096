<main class="main">

  <ol class="breadcrumb"></ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">

        <ng-template #loadingSpinner>
          <div class="col-12 text-center">
            <div class="card card-body" style="min-height: 400px;">
              <div class="lds-roller" style="margin: auto">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">

                <div class="col-12 mb-4">
                  <h3>{{ 'o365.adminconsent' | translate }}</h3>
                </div>

                <div class="col-12">
                  <h4>{{ 'o365.ac.title' | translate }}</h4>
                  <p>
                    <i>{{ 'o365.ac.subtitle' | translate }}</i>
                  </p>
                  <ol>
                    <li>{{ 'o365.ac.stepone' | translate }}</li>
                    <li>{{ 'o365.ac.steptwo' | translate }}</li>
                    <li>{{ 'o365.ac.stepthree' | translate }}</li>
                  </ol>
                </div>

                <div class="col-12">
                  <button type="button" class="btn btn-primary" (click)="onGrantAdminConsentPressed()">{{ 'button.grantadminconsent' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</main>
