import { Component, OnInit } from '@angular/core';
declare var appJS: any;

@Component({
  selector: 'app-email-master-template',
  templateUrl: './email-master-template.component.html',
  styleUrls: ['./email-master-template.component.css']
})
export class EmailMasterTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    appJS.init();
  }

}
