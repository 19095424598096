import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StepModel } from '../../../../../model/step.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';
import { TranslateUtil } from 'src/app/util/translate-util';

@Component({
  selector: 'app-microsoftcsp-user-new-basics',
  templateUrl: './microsoftcsp-user-new-basics.component.html',
  styleUrls: ['./microsoftcsp-user-new-basics.component.css']
})
export class MicrosoftCSPUserNewBasicsComponent implements OnInit {

  @Input() step: StepModel;
  @Output() newUserBasicsFormChanged: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  private validateUsername = new Subject<string>();

  newUserBasicsForm: UntypedFormGroup = null;

  tenantId: number;
  domains: any[] = [];

  usernameRegex = '^[0-9A-Za-z_\\-\'.]{1,}$';
  // tslint:disable-next-line: max-line-length
  passwordRegex = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,256}$';

  usernameLoading = false;
  domainsLoading = false;
  hidePassword = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService,
    private translateService: LanguageTranslateService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      this.createNewUserBasicsForm();
      this.setValidateUsernameDebouncer();
      this.getDomains();
    });
  }

  onCompleteStep() {
    if (this.newUserBasicsForm.valid) {
      this.newUserBasicsFormChanged.emit(this.newUserBasicsForm);
      this.step.isComplete = true;
    }
  }

  // formValueToUserBasics() {
  //   this.userBasics = { ...this.newUserBasicsForm.value };
  // }

  // userBasicsToFormValue() {
  //   this.firstNameFormControl.patchValue(this.userBasics.firstName);
  //   this.lastNameFormControl.patchValue(this.userBasics.lastName);
  //   this.displayNameFormControl.patchValue(this.userBasics.displayName);
  //   this.usernameFormControl.patchValue(this.userBasics.username);
  //   this.domainFormControl.patchValue(this.userBasics.domain);
  //   this.generatePasswordFormControl.patchValue(this.userBasics.generatePassword);
  //   this.passwordFormControl.patchValue(this.userBasics.password);
  //   this.forceChangePasswordFormControl.patchValue(this.userBasics.forceChangePassword);
  // }

  createNewUserBasicsForm() {
    this.newUserBasicsForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      displayName: ['', [Validators.required]],
      username: ['', [Validators.required, Validators.maxLength(64), Validators.pattern(this.usernameRegex)]],
      domain: ['', [Validators.required]],
      generatePassword: [true, [Validators.required]],
      password: [''],
      forceChangePassword: [true]
    });
  }

  setValidateUsernameDebouncer() {
    this.validateUsername.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => this.microsoftGraphApiService.getUsers(this.tenantId.toString(), this.getQueryOptions()))
    ).subscribe((res: any) => {
      const available = res.value.length === 0 ? true : false;
      this.usernameLoading = false;

      if (available) {
        this.usernameFormControl.clearValidators();
        this.usernameFormControl.setValidators([Validators.required, Validators.maxLength(64), Validators.pattern(this.usernameRegex)]);
        this.usernameFormControl.updateValueAndValidity();
      } else {
        this.usernameFormControl.setErrors({ availability: true });
      }
    }, err => {
      console.error('onUsernameKeyUp error', err);
      this.usernameLoading = false;
      this.usernameFormControl.clearValidators();
      this.usernameFormControl.setValidators([Validators.required, Validators.maxLength(64), Validators.pattern(this.usernameRegex)]);
      this.usernameFormControl.updateValueAndValidity();
    });
  }

  getDomains() {
    this.domainsLoading = true;
    this.microsoftGraphApiService.getDomains(this.tenantId.toString())
      .subscribe((res: any) => {
        this.domains = res.value;
        this.domains = this.domains.filter(domain => domain.isVerified === true);
        this.domainsLoading = false;

        if (this.domainFormControl.value === '') {
          const defaultDomain = this.domains.find(domain => domain.isDefault);
          this.domainFormControl.setValue(defaultDomain.id, { onlySelf: true });
          this.domainFormControl.updateValueAndValidity();
        }
      }, err => {
        console.error('getMicrosoftDomains error', err);
        this.domainsLoading = false;
      });
  }

  onGeneratePasswordChanged() {
    if (this.generatePasswordFormControl.value === false) {
      this.passwordFormControl.setValidators([Validators.required, Validators.pattern(this.passwordRegex)]);
    } else {
      this.passwordFormControl.clearValidators();
      this.passwordFormControl.patchValue('');
      this.passwordFormControl.markAsUntouched();
    }
    this.passwordFormControl.updateValueAndValidity();
  }

  onFocusDisplayName() {
    if (this.displayNameFormControl.value === '') {
      const displayName: string = this.firstNameFormControl.value + ' ' + this.lastNameFormControl.value;
      this.displayNameFormControl.patchValue(displayName.trim());
    }
  }

  onDomainChange(event: any) {
    this.domainFormControl.patchValue(event.target.value);
    this.onUsernameKeyUp();
  }

  onUsernameKeyUp() {
    this.usernameLoading = true;
    this.validateUsername.next(this.usernameFormControl.value);
  }

  getQueryOptions(): any {
    const queryOptions: any = {
      '$filter': 'userPrincipalName eq ' + '\'' + this.usernameFormControl.value + '@' + this.domainFormControl.value + '\''
    };

    return queryOptions;
  }

  get firstNameFormControl() { return this.newUserBasicsForm.get('firstName'); }
  get lastNameFormControl() { return this.newUserBasicsForm.get('lastName'); }
  get displayNameFormControl() { return this.newUserBasicsForm.get('displayName'); }
  get usernameFormControl() { return this.newUserBasicsForm.get('username'); }
  get domainFormControl() { return this.newUserBasicsForm.get('domain'); }
  get generatePasswordFormControl() { return this.newUserBasicsForm.get('generatePassword'); }
  get passwordFormControl() { return this.newUserBasicsForm.get('password'); }
  get forceChangePasswordFormControl() { return this.newUserBasicsForm.get('forceChangePassword'); }

}
