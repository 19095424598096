
    <main id="storefront" class="main create-storefront">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item active">{{ 'word.clientmanagement' | translate }}</li>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive py-2">
                    <table id="example" class="table table-hover table-bordered datatable-dropdown-filter">
                      <thead>
                        <tr>
                            <th>{{ 'word.email' | translate }}</th>
                            <th>{{ 'word.name' | translate }}</th>
                            <th>{{ 'word.status' | translate }}</th>
                            <th>{{ 'word.access' | translate }}</th>
                            <th>{{ 'word.storefront' | translate }}</th>
                        </tr>

                      </thead>
                      <tbody>
                        <tr class="clickable-row" *ngFor="let client of clients" (click)="show(client)">
                          <td>{{client.user.email}}</td>
                          <td>{{client.first_name}} {{client.last_name}}</td>
                          <td>
                            <span *ngIf="client.user.status_email == 0" class="badge badge-warning">{{ 'word.pendingactivation' | translate }}</span>
                            <span *ngIf="client.user.status_account == 1" class="badge badge-success">{{ 'word.active' | translate }}</span>
                            <span *ngIf="client.user.status_account == 0" class="badge badge-danger">{{ 'word.suspended' | translate }}</span>
                          </td>
                          <td>{{client.access_level?.name}}</td>
                          <td>{{client.storefront.storefront_name}}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>{{ 'word.email' | translate }}</th>
                          <th>{{ 'word.name' | translate }}</th>
                          <th>{{ 'word.status' | translate }}</th>
                          <th>{{ 'word.access' | translate }}</th>
                          <th>{{ 'word.storefront' | translate }}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </main>