import { AlertService } from 'src/app/service/alert.service';
import { Component, OnInit, SimpleChanges, SimpleChange, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { O365HttpService } from 'src/app/service/o365-http.service';
import { O365OrderService } from 'src/app/service/o365-order.service';
import { O365DnsService } from 'src/app/service/o365-dns.service';
import { CountriesService } from 'src/app/service/countries.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';

declare var $: any;
declare var jQuery: any;
declare var mainJS: any;
declare var customCompleteOrderSteps: any;
declare var customSelectJS: any;

@Component({
  selector: 'app-o365-step-five',
  templateUrl: './o365-step-five.component.html',
  styleUrls: ['./o365-step-five.component.css']
})

export class O365StepFiveComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild('domainNameInput', { static: false }) domainNameInput: ElementRef;

  orderId = 0;
  productId = 3;

  showMainPart = true;
  showDnsGuide = false;
  showAutoConfigDns = false;

  txtRecord;
  cnameRecord;
  srvRecord;
  mxRecord;

  domainName = "";
  existdomainName = "";
  errorMessage = "";
  errorDomainMessage = "";
  errorRegisterMessage = null;

  data = {
    order_id: 0
  };

  existing = false;
  transfer = false;
  searching = false;
  newDomain = true;
  checkingAvailability = false;
  checkingExistingAvailability = false;
  domainAvailable = false;

  regdata = {
    order_id: 0,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    fax_number: "",
    address_1: "",
    address_2: "",
    company_name: "",
    country_code: "",
    zip_code: "",
    state: "",
    city: "",
    domain: "",
    lang: "ENG",
    other_city: "",
    contact_type: 0,
    use_other_city: false
  };

  useOtherCity = false;

  countries = [];
  countriesLoading = true;
  countriesInit = false;
  selectedCountry: any = null;

  states = [];
  statesLoading = true;
  statesInit = false;
  selectedState: any = null;

  cities = [];
  prices;
  citiesLoading = true;
  citiesInit = false;
  selectedCity: any = null;
  domainPrice = 0;

  selectedCountry2: any = null;
  selectedState2: any = null;
  selectedCity2: any = null;

  availablePurchase = false;
  domainTransfer = false;
  nonWebnic = "";
  payBtnPressed = false;

  validDomain = false;

  errorCheck = false;

  managedByThirdPartyMessage = 'The domain name XXX is manage by 3rd party, please choose from one of the following action:';

  fromEventCheckDomainInput;
  domainPriceChecking = false;

  lang = "eng";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: O365HttpService,
    private orderService: O365OrderService,
    private dnsHttpService: O365DnsService,
    private countriesService: CountriesService,
    private translateService: LanguageTranslateService,
    private alert: AlertService,
  ) { }

  ngOnInit() {
    this.lang = this.translateService.userLanguage$.value;
    this.orderId = this.orderService.getOrderId();

    if(this.orderId == null) {
      this.router.navigate(['/o365-order-management']);
    }

    this.regdata.order_id = this.orderId; 
  }

  ngAfterViewChecked() {
    if (!this.countriesLoading && !this.countriesInit) {
      this.countriesInit = true;

      // Country select
      let countrySelect: Element = document.getElementById("country");
      // customSelectJS.init(countrySelect, country => {
      //   this.selectCountry(country);
      // });
    } else if (!this.statesLoading && !this.statesInit) {
      this.statesInit = true;

      // State select
      let stateSelect: Element = document.getElementById("state");
      // customSelectJS.init(stateSelect, state => {
      //   this.selectState(state);
      // });
    } else if (!this.citiesLoading && !this.citiesInit) {
      this.citiesInit = true;

      // State select
      let citySelect: Element = document.getElementById("city");
      // customSelectJS.init(citySelect, city => {
      //   this.selectCity(city);
      // });
    }
  }

  ngAfterViewInit() {
    this.detectDomainInputChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.currentUser;
    this.regdata.first_name = currentItem.currentValue.customer.first_name;
    this.regdata.last_name = currentItem.currentValue.customer.last_name;
    this.regdata.email = currentItem.currentValue.email;
    var phoneNumber = currentItem.currentValue.customer.mobile_number;
    this.regdata.phone_number = phoneNumber.replace(".", "");
    this.regdata.company_name = currentItem.currentValue.customer.company_name
    this.regdata.address_1 = currentItem.currentValue.customer.address1
    this.regdata.address_2 = currentItem.currentValue.customer.address2
    this.regdata.other_city = currentItem.currentValue.other_city

    this.regdata.zip_code = currentItem.currentValue.customer.zipcode

    if (currentItem.currentValue.customer.country) {
      this.regdata.country_code = currentItem.currentValue.customer.country
      this.selectCountry(this.regdata.country_code)
    }

    if (currentItem.currentValue.customer.state) {
      this.regdata.state = currentItem.currentValue.customer.state
      this.selectState(this.regdata.state)
    }

    if (currentItem.currentValue.customer.city) {
      this.regdata.city = currentItem.currentValue.customer.city
      this.selectCity(this.regdata.city)
    }
  }

  translate(key:string):string{
    return this.translateService.translate(key);
  }

  detectDomainInputChanges() {
    fromEvent(this.domainNameInput.nativeElement, 'keyup').pipe(debounceTime(1200)).subscribe((value) => {
      console.log('done tulis domain', this.domainName);
      this.domainPrice = 0;
      this.checkingAvailability = true;
      this.errorMessage = ""

      if (this.domainName == "") {
        this.errorMessage = this.translate('error.fillindomain');
        this.checkingAvailability = false;

        return;
      }

      let pattern = /^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/i;
      let validDomain = pattern.test(this.domainName);

      if(!validDomain) {
        this.errorMessage = this.translate('error.enteravaliddomain');
        this.checkingAvailability = false;

        return;
      }

      this.dnsHttpService.checkDomainName(this.domainName).subscribe((response) => {
        let avail = response['data']['avail'];

        if(avail == '1') {
          this.domainPrice = response['data']['term_price']['term1'];
          this.domainAvailable = true;
        } else {
          this.domainAvailable = false;
          this.errorMessage = this.translate('error.domainnotavailable');
        }

        this.checkingAvailability = false;
      });
    });
  }

  existingDomainPressed() {
    this.existing = true;
    this.newDomain = false;
    this.errorMessage = "";
  }

  registerNewPressed() {
    this.transfer = false;
    this.existing = false;
    this.newDomain = true;
    this.domainName = this.existdomainName;
    this.domainAvailable = false;
    this.domainPrice = 0;

    setTimeout(() => {
      this.detectDomainInputChanges();
    }, 1000);
  }

  checkDomainPressed() {
    this.errorDomainMessage = ""
    this.transfer = false
    this.domainTransfer = false

    if (this.existdomainName == "") {
      this.errorDomainMessage = this.translate('error.fillindomain');
      return
    }

    let validExistDomain = /^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/i.test(this.existdomainName);

    if (!validExistDomain) {
      this.errorDomainMessage = this.translate('error.enteravaliddomain');
      return;
    }

    this.checkingExistingAvailability = true;
    // this.transfer = true
    this.existing = false;
    this.searching = true;

    this.dnsHttpService.checkIfWebnicDomain(this.existdomainName).subscribe(response => {
      console.log("check webnic domain:", response)
      if (response["data"]["message"] == "true") {
        this.dnsHttpService.checkIfDefaultDNS(this.existdomainName).subscribe(resp => {

          if (response["data"]["message"] == "true") {
            this.dnsHttpService.checkIfDefaultDNS(this.existdomainName).subscribe(response => {
              //Use default DNS
              this.checkingExistingAvailability = false;
              this.searching = false;
              if (response["data"]["message"] == "false") {
                this.configurePressed();
              } else {
                console.log(this.orderId)
                this.dnsHttpService.autoconfigDns(this.existdomainName, this.orderId).subscribe(res => {
                  if (res["response"]["message"] == "true") {
                    this.showMainPart = false;
                    this.showAutoConfigDns = true;
                  } else {
                    if (res["response"]["errors"] == "Domain is pending for Auto DNS configuration") {
                      this.errorDomainMessage = "Domain is pending Auto DNS configuration. Please enter another domain or skip this step."
                    }
                  }
                });
              }
            });
          } else {

          }
        });
      } else {
        this.dnsHttpService.checkDomainName(this.existdomainName).subscribe(resp => {
          if (resp["data"]["avail"] == 0) {
            console.log('domain name not available')
            this.transfer = true
            this.domainTransfer = true
            this.checkingExistingAvailability = false
            this.nonWebnic = this.existdomainName
            this.managedByThirdPartyMessage = `The domain name ${this.existdomainName} is manage by 3rd party, please choose from one of the following action:`;
          } else {
            this.searching = false;
            this.transfer = true;
            this.availablePurchase = true;
            this.checkingExistingAvailability = false;
            this.managedByThirdPartyMessage = `The domain name ${this.existdomainName} is manage by 3rd party, please choose from one of the following action:`;
          }
        });
      }

    });

    this.checkingExistingAvailability = false;
  }

  buyDomainPressed() {
    this.errorRegisterMessage = null;
    this.countriesService.getCountries().subscribe(response => {
      this.countries = response["data"];
      this.countriesLoading = false;
      let countrySelect: Element = document.getElementById("country");
      
      $("#register-domain-modal").modal("show");
    }, err => {
      console.log("countries errror is " + err)
    });
  }

  checkAvailabilityPressed() {
    this.errorMessage = ""

    if (this.domainName == "") {
      this.errorMessage = this.translate('error.fillindomain');
      return;
    }

    let validDomain = /^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/i.test(this.domainName);

    if(!validDomain) {
      this.errorDomainMessage = this.translate('error.enteravaliddomain');
      return;
    }

    this.checkingAvailability = true;
    var splitted = this.domainName.split(".", 2);

    if (this.newDomain) {
      this.dnsHttpService.checkDomainName(this.domainName).subscribe(response => {
        this.checkingAvailability = false;
        if (response["data"]["avail"] == 1) {
          this.domainPrice = response['data']['term_price']['term1'];
          $("#register-domain-modal").modal("show");
          // Country select

          this.countriesService.getCountries().subscribe(response => {
            this.countries = response["data"];
            this.countriesLoading = false;
            let countrySelect: Element = document.getElementById("country");
            // customSelectJS.init(countrySelect, country => {
            //   this.selectCountry(country);
            // });
          }, err => {
            console.log("countries errror is " + err)
          });
        } else {
          this.checkingAvailability = false;
          this.errorMessage = this.translate('error.domainnotavailable');
        }
      }, err => {
        this.checkingAvailability = false;
        console.log(err)
      });
    } else {
      this.searching = true;
      this.dnsHttpService.checkIfWebnicDomain(this.existdomainName).subscribe(response => {
        this.dnsHttpService.checkIfDefaultDNS(this.existdomainName).subscribe(resp => {
          this.checkingAvailability = false;
          this.searching = false;
          if (response["data"]["message"] == true) {
            //If webnic domnain
            this.dnsHttpService.autoconfigDns(this.existdomainName, this.orderId).subscribe(resp => {
              console.log(resp)
            });
          } else {
            //Not webnic domain
            this.dnsHttpService.checkDomainName(this.existdomainName).subscribe(resp => {
              if (response["data"]["avail"] == 1) {
                console.log("available")

              } else {
                console.log("not available")
                this.transfer = true;
                this.managedByThirdPartyMessage = `The domain name ${this.existdomainName} is manage by 3rd party, please choose from one of the following action:`;
                this.domainVerified(true)
              }
            });
            //If available
            //not available
          }
        });
      });
    }
  }

  // Functions

  domainVerified(bool) {
    // this.domainProceed.emit(true)
    console.log("emit")
  }

  

  skipPressed() {
    const successL = {
      eng: "Successfully created order.",
      cn: "订单提交成功。",
      zh: "訂單提交成功。"
    }

    console.log("skip pressed ")
    this.alert.showSuccess(successL[this.lang])
    this.router.navigate(['/o365-order-detail', this.orderId]);
  }

  keyboardUp() {
    
    
    
  }

  registedDNSPressed() {
    this.payBtnPressed = true;
    this.errorRegisterMessage = "";
    
    this.regdata.order_id = this.orderService.getOrderId();
    this.regdata.domain = this.domainName;

    var phoneNumber = this.regdata.phone_number["internationalNumber"];
    this.regdata.phone_number = phoneNumber.replace(" ", ".");

    var faxNumber = this.regdata.fax_number["internationalNumber"];
    this.regdata.fax_number = faxNumber.replace(" ", ".");

    if(this.useOtherCity) {
      this.regdata.use_other_city = true;
    }

    console.log('topkek', this.regdata);

    this.dnsHttpService.createDomain(this.regdata).subscribe(resp => {

      if (resp["data"]["message"] == "true") {
        let data = {
          domain_id: resp["data"]["id"]
        };

        this.showMainPart = false;
        this.showAutoConfigDns = true;
  
        this.autoConfigDns();
      } else {
        if (resp["data"]["message"] == "false") {
          this.errorMessage = resp['data']['errors'][0]
        }
      }
      
    }, error => {
      this.payBtnPressed = false;
      this.errorRegisterMessage = error['error']['errors']

      console.error('createDomain', error);
    });
  }

  autoConfigDns() {
    this.dnsHttpService.autoconfigDns(this.domainName, this.orderId).subscribe(res => {
      if (res["response"]["message"] == "true") {
        this.showMainPart = false;
        this.showAutoConfigDns = true;
      } else {
        this.payBtnPressed = false;
        if (res["response"]["errors"] == "Domain is pending for Auto DNS configuration") {
          this.errorDomainMessage = "Domain is pending Auto DNS configuration. Please enter another domain or skip this step."
          this.errorRegisterMessage = [ "Domain is pending Auto DNS configuration. Please enter another domain or skip this step." ];
        }
      }
    });
  }

  configurePressed() {
    this.dnsHttpService.getDNSGuide().subscribe(response => {
      this.txtRecord = response["response"]["txt"][0]["records"];
      this.cnameRecord = response["response"]["cname"];
      this.srvRecord = response["response"]["srv"];
      this.mxRecord = response["response"]["mx"];
    });

    this.showMainPart = false;
    this.showDnsGuide = true;
  }

  // Country, states

  selectCountry(selectedCountry) {
    /* Reset */
    this.selectedCountry = null;
    this.resetStates();
    this.resetCities();

    /* Load */
    this.selectedCountry = this.countries.find(element => element.id == selectedCountry);
    this.regdata.country_code = this.selectedCountry.code;

    this.countriesService.getStates(selectedCountry).subscribe(response => {
      //
      this.states = response["data"];
      this.statesLoading = false;
    });
  }

  selectState(selectedState) {
    /* Reset */
    // this.selectedState = null;
    this.resetCities();

    /* Load */
    this.selectedState2 = this.states.find(element => element.id == selectedState);
    this.regdata.state = this.selectedState2.name;

    this.countriesService.getCities(selectedState).subscribe(response => {
      this.cities = response["data"];
      this.citiesLoading = false;
    });
  }

  selectCity(selectedCity) {
    this.selectedCity2 = this.cities.find(element => element.id == selectedCity);

    if(selectedCity != 50000) {
      this.useOtherCity = false;
      this.regdata.city = this.selectedCity2.name;
    } else {
      this.useOtherCity = true;
    }
    
    console.log('selectedcity', this.regdata.city)
  }

  resetStates() {
    this.states = [];
    this.statesLoading = true;
    this.statesInit = false;
    this.selectedState = null;
    const fgState = document.getElementById("fg-state");

    if (fgState) {
      Array.from(fgState.querySelectorAll(".select")).forEach(element => element.remove());
    }
  }

  resetCities() {
    this.cities = [];
    this.citiesLoading = true;
    this.citiesInit = false;
    this.selectedCity = null;

    const fgCity = document.getElementById("fg-city");

    if (fgCity) {
      Array.from(fgCity.querySelectorAll(".select")).forEach(element => element.remove());
    }
  }

  onCountryChanged(event) {
    // this.selectCountry(event.target.value);
    this.resetStates();
    this.resetCities();
    this.selectedCountry2 = this.countries.find(element => element.id == event.target.value);
    this.regdata.country_code = this.selectedCountry2.code;
    this.countriesService.getStates(event.target.value).subscribe(response => {
      //
      this.states = response["data"];
      this.statesLoading = false;
    });
    // this.selectCountry(this.selectedCountry)
  }

  onStateChanged(event) {
    this.selectState(event.target.value)
  }

  onCityChanged(event) {
    this.selectCity(event.target.value)
  }

}
