import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { CountriesService } from 'src/app/service/countries.service';
import { O365HttpService } from 'src/app/service/o365-http.service';

@Component({
  selector: 'app-microsoftcsp-product-promotion-step-two',
  templateUrl: './microsoftcsp-product-promotion-step-two.component.html',
  styleUrls: ['./microsoftcsp-product-promotion-step-two.component.css']
})
export class MicrosoftCSPProductPromotionStepTwoComponent implements OnInit {

  productId: number = null;

  loading: boolean = false;
  // countries: any[] = [];
  private cachedCountries: any[] = null;

  o365Product: any;
  productPromotions: any[]; 

  constructor(
    private o365HttpService: O365HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private countriesService: CountriesService
  ) {
  }

  ngOnInit() { 
    this.route.params.subscribe(params => {
      this.productId = +params['productId'] || null;

      this.loading = true;

      this.initData().then(() => {

        
        this.loading = false;
      });
    });
  }

  async initData() {
    // get countries data
    if (this.cachedCountries != null) {
      // use cached instead
      this.countries = this.cachedCountries;
    } else {
      // else fetch it then cache it
      await this.countriesService.getCountriesCsp().toPromise().then((res: any) => {
        this.countries = res.data;
        // update to cached countries
        this.cachedCountries = this.countries;
      }).catch(err => {
        console.error('getCountriesCsp error', err);
        this.alertService.showError('Something went wrong. Please try again later.');
      })
    }

    await this.loadO365Product();

  }

  async loadO365Product() {
    const options: any = {
      category_name: 'Microsoft Office 365',
      product_id: this.productId
    };

    await this.o365HttpService.getProductV2ByProductId(options).toPromise().then((res: any) => {
      this.o365Product = res.data;
    }).catch(err => {
      console.error('getProductV2ByProductId error', err);
      this.alertService.showError('Something went wrong. Please try again later.');
    })

    await this.o365HttpService.getProductPromotionsByProductId(this.productId).toPromise().then((res: any) => {
      this.productPromotions = res.data.product_promotions;
    }).catch(err => {
      console.error('getO365ProductActivePromotions error', err);
      this.alertService.showError('Something went wrong. Please try again later.');
    })

    this.setCountryInfoToList('o365');
  }


  setCountryInfoToList(productCategoryName: string) {
    if (productCategoryName == 'o365') {
      this.productPromotions.forEach(p => {
        let countryInfo: any = this.countries.find(c => c.code == p.countryIso2Code);
        if (countryInfo) {
          p.countryId = countryInfo.id;
          p.countryName = countryInfo.name;
        } else {
          // should not happened, use country code instead as fallback
          p.countryName = p.countryIso2Code;
        }

      })
    }
  }

  // setRetailPromotionPrice(productCategoryName: string, configuredStorefrontPromotions: any[]) {
  //   switch (productCategoryName) {
  //     case 'o365': {

  //       this.productPromotions.forEach(p => {

  //         // set default using retail price
  //         p.retailPromotionPrice = {
  //           monthly_price: this.o365Product.monthly_selling_price,
  //           yearly_price: this.o365Product.yearly_selling_price
  //         };

  //         let configuredPromo = configuredStorefrontPromotions.find(c => c.country_code == p.countryIso2Code);

  //         // if found then overide default and set existing configured retail promo price
  //         if (configuredPromo) {
  //           let configuredRetailPromotionPrice: any = {
  //             monthly_price: configuredPromo.monthly_price,
  //             yearly_price: configuredPromo.yearly_price
  //           };
  //           p.retailPromotionPrice = configuredRetailPromotionPrice;
  //         }

  //       })

  //       break;
  //     }
  //     default:
  //       break;
  //   }
  // }

  back() {
    this.router.navigate(['/microsoftcsp/products/promotion']);
  }

  get countries() {
    return this.cachedCountries;
  }

  set countries(newCountriesValue: any[]) {
    this.cachedCountries = newCountriesValue;
  }
}
