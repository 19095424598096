
    <main id="storefront" class="main create-storefront">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item">{{ 'sf.storefrontsettings' | translate }}</li>
        <li class="breadcrumb-item active">{{ 'word.managestorefront' | translate }}</li>

        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
            aria-valuemin="0" aria-valuemax="100" style="width: 0%">
          </div>
        </div>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">
          <div class="row">


            <!-- <a *ngFor="let store of storefronts" routerLink="/manage-storefront-1/{{store.id}}" class="storefront-card" style="width: 100%;"> -->
             <a *ngFor="let store of storefronts" class="storefront-card" style="width: 100%;">
              <div class="col-lg-12">
                <div class="card">
                  <div class="row">
                    <div class="col-lg-6 pr-lg-0">
                      <!--  <iframe [src]="'http://'+store.storefront_code+'.ezyicp.com'| safe">
                      <p>Your browser does not support iframes.</p>
                    </iframe>-->
                      <img class="card-img-top show-cursor" src="{{ store.cover?.cover_url }}" alt="" routerLink="/manage-storefront-1/{{store.id}}">
                    </div>

                    <div class="col-lg-6 pl-lg-0">
                      <table class="table float-right">
                        <tbody>
                          <tr>
                            <td><b>{{ 'word.companyname' | translate }}</b></td>
                            <td>{{ store.storefront_name }}</td>
                          </tr>
                          <tr>
                            <td><b>{{ 'sf.storefronturl' | translate }}</b></td>
                            <td><a href="{{ store.url }}">{{ store.storefront_code }}</a></td>
                          </tr>
                          <!--<tr>
                            <td><b>Company Logo</b></td>
                            <td><a href="{{store.logo_url}}" _target="blank"><span class="badge badge-dark">View Logo</span></a></td>
                          </tr>-->
                          <tr>
                            <td><b>{{ 'sf.theme' | translate }}</b></td>
                            <td>
                              {{ store.theme?.theme_name }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>{{ 'sf.sellingproducts' | translate }}</b></td>
                            <td>
                              {{ store.selling_categories_count["Microsoft Office 365"] }} {{ 'word.microsoftoffice365' | translate }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </a>

          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </main>