import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StepModel } from '../model/step.model';

// const STEPS = [
//   { stepIndex: 1, isComplete: false },
//   { stepIndex: 2, isComplete: false },
//   { stepIndex: 3, isComplete: false },
//   { stepIndex: 4, isComplete: false }
// ];

@Injectable({
  providedIn: 'root'
})
export class StepsService {

  STEPS: any[] = [
    { stepIndex: 1, isComplete: false },
    { stepIndex: 2, isComplete: false },
    { stepIndex: 3, isComplete: false },
    { stepIndex: 4, isComplete: false }
  ];

  steps$: BehaviorSubject<StepModel[]>;
  currentStep$: BehaviorSubject<StepModel>;

  constructor() {
    this.steps$ = new BehaviorSubject<StepModel[]>(this.STEPS);
    this.currentStep$ = new BehaviorSubject<StepModel>(null);
    this.currentStep$.next(this.steps$.value[0]);
  }

  setCurrentStep(currentStep: StepModel): void {

    // mark all previous steps to completed
    this.STEPS.forEach((s: StepModel) => {
      if (s.stepIndex < currentStep.stepIndex) {
        s.isComplete = true;
      }
    });
    // mark all incoming steps to imcompleted
    this.STEPS.forEach((s: StepModel) => {
      if (s.stepIndex >= currentStep.stepIndex) {
        s.isComplete = false;
      }
    });

    this.steps$.next(this.STEPS);
    this.currentStep$.next(currentStep);
  }

  getCurrentStep(): Observable<StepModel> {
    return this.currentStep$.asObservable();
  }

  getSteps(): Observable<StepModel[]> {
    return this.steps$.asObservable();
  }

  moveToNextStep(): void {
    const index = this.currentStep$.value.stepIndex;

    if (index < this.steps$.value.length) {
      this.currentStep$.next(this.steps$.value[index]);
    }
  }

  moveToPreviousStep(): void {
    const index = this.currentStep$.value.stepIndex;

    if (index > 0 && index <= this.steps$.value.length) {
      this.currentStep$.next(this.steps$.value[index - 2]);
    }
  }

  moveToStep(stepIndex: number) {
    const index = stepIndex;

    if (index <= this.steps$.value.length) {
      let currentStep = this.steps$.value[index - 1];

      // mark all previous steps to completed
      this.STEPS.forEach((step: StepModel) => {
        if (step.stepIndex < currentStep.stepIndex) {
          step.isComplete = true;
        }
      });
      // mark all incoming steps to imcompleted
      this.STEPS.forEach((step: StepModel) => {
        if (step.stepIndex >= currentStep.stepIndex) {
          step.isComplete = false;
        }
      });

      this.steps$.next(this.STEPS);
      this.currentStep$.next(this.STEPS[currentStep.stepIndex-1]);
    }
  }

  isLastStep(): boolean {
    return this.currentStep$.value.stepIndex === this.steps$.value.length;
  }
}
