import { Pipe, PipeTransform } from '@angular/core';
import { LanguageTranslateService } from '../service/language-translate.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
    
    constructor(
        private translateService: LanguageTranslateService
    ) { } 

    // button.add: {cn: "添加", eng: "Add", ind: "", tha: "", vn: "", zho: "添加"}
    transform(wordKey: string, lang?: string) : string {
        let wordlist = this.translateService.languageProperties$.value;

        if(!lang) {
        lang = this.translateService.userLanguage$.value;
        }
        
        if(wordlist[wordKey]) {
            if(wordlist[wordKey][lang])
                return wordlist[wordKey][lang];
            else if(wordlist[wordKey]['eng'])
                return wordlist[wordKey]['eng'];
            else
                return wordKey;
        }

        return wordKey;
    }
}