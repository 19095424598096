import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  apiUrl = environment.apiUrl;

  httpHeaders = {
    headers : new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
  }

  constructor(
    private http: HttpClient
  ) { }

  getOrderByReseller(){
    console.log(this.httpHeaders)
    return this.http.get(this.apiUrl+'order/get-all-orders-by-reseller', this.httpHeaders)
  }

  getAllOrderByReseller(){
    console.log(this.httpHeaders)
    return this.http.get(this.apiUrl+'order/get-all-table-data', this.httpHeaders)
  }

  getOrderById(id){
    return this.http.get(this.apiUrl+'order/get-order-by-order-id?order_id='+id, this.httpHeaders)
  }

  /*postPaymentStatus(id){
    let data = {
      order_id : id
    }
    return this.http.post(this.apiUrl+'order/get-order-by-order-id', data, this.httpHeaders)
  }*/

  postPaymentStatus(refno, id, sf_id, sf_user_id){
    let data = {
      refno: refno,
      order_id : id,
      sf_id : sf_id,
      sf_user_id : sf_user_id
    }
    return this.http.post(this.apiUrl+'order/mark-payment-as-done', data, this.httpHeaders)
  }

  postProceedOrder(id, sf_id, sf_user_id){
    let data = {
      order_id : id,
      sf_id : sf_id,
      sf_user_id : sf_user_id
    }
    return this.http.post(this.apiUrl+'order/proceed-order', data, this.httpHeaders)
  }

  postRefundOrder(id){
    let data = {
      order_id : id
    }
    return this.http.post(this.apiUrl+'order/get-order-by-order-id', data, this.httpHeaders)
  }


}
