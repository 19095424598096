import { LanguageTranslateService } from './../../service/language-translate.service';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute, Router
} from '@angular/router';
import {
  O365HttpService
} from 'src/app/service/o365-http.service';
import { O365DnsService } from 'src/app/service/o365-dns.service';
import { O365OrderService } from 'src/app/service/o365-order.service';
import { ScrollUtil } from 'src/app/util/scroll-util';

declare var $: any;

@Component({
  selector: 'app-o365-order-detail',
  templateUrl: './o365-order-detail.component.html',
  styleUrls: ['./o365-order-detail.component.css']
})

export class O365OrderDetailComponent implements OnInit {

  orderId;
  order;
  subscription;
  domain;
  tenant;
  quantity = 0;
  history;
  data;

  errorCheck = false;
  errorMessage = '';
  successCheck = false;
  successMessage = '';

  autoConfigBtnPressed = false;
  renewBtnPressed = false;
  suspendBtnPressed = false;
  resumeBtnPressed = false;
  updateQuantityBtnPressed = false;
  enableAutoRenewBtnPressed = false;
  disableAutoRenewBtnPressed = false;
  historicalOrderBtnPressed = false;
  setupDomainBtnPressed = false;

  lang = "eng";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: O365HttpService,
    private dnsService: O365DnsService,
    private orderService: O365OrderService,
    private languageTranslateService: LanguageTranslateService,
  ) {}

  ngOnInit() {
    this.lang = this.languageTranslateService.userLanguage$.value;

    this.route.params.subscribe(
      params => {
          this.orderId = +params['id'];
          this.getOrderDetails();
      }
    );
    // this.data = this.orderService.getOrderData();
    // this.order = this.data;
    // this.subscription = this.data['subscriptions'][0];
    // this.tenant = 
    // this.tenant = response['data']['tenant'];
    // this.domain = response['data']['domain'];
  }

  setAllButtonsDisabled(state: boolean) {
    this.autoConfigBtnPressed = state;
    this.renewBtnPressed = state;
    this.suspendBtnPressed = state;
    this.resumeBtnPressed = state;
    this.updateQuantityBtnPressed = state;
    this.enableAutoRenewBtnPressed = state;
    this.disableAutoRenewBtnPressed = state;
    this.historicalOrderBtnPressed = state;
    this.setupDomainBtnPressed = state;
  }

  getOrderDetails() {
    this.setAllButtonsDisabled(true);

    this.httpService.getOrderV2(this.orderId).subscribe(response => {
      this.order = response['data']['order'];
      this.subscription = response['data']['subscription'][0];
      this.tenant = response['data']['tenant'];
      this.domain = response['data']['domain'];

      this.quantity = this.subscription.license_count;

      this.setAllButtonsDisabled(false);
    }, error => {
      this.errorCheck = true;
      this.errorMessage = error.error.message;
      ScrollUtil.scrollToTop();

      this.setAllButtonsDisabled(false);
    });
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  closeErrorPressed() {
    this.errorCheck = false;
  }

  closeSuccessPressed() {
    this.successCheck = false;
  }

  autoConfigPressed() {
    this.autoConfigBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;
    
    this.dnsService.autoconfigDns(this.domain.domain, this.order.order_id).subscribe(response => {
      console.log('autoConfigPressed', response)

      if(response['response']['message'] == 'false') {
        this.errorCheck = true;
        this.errorMessage = response['response']['errors'][0];
        ScrollUtil.scrollToTop();
      } else if(response['response']['message'] == 'true') {
        this.successCheck = true;
        let successLang = {
          eng: 'Domain is now queued for Auto DNS configuration.',
          cn: '域名已在等待自动域名设定。',
          zh: '域名已在等待自動域名設定。' 
        }
        this.successMessage = successLang[this.lang];
        this.getOrderDetails();
      }

      this.autoConfigBtnPressed = false;
    }, error => {
      console.log('error', error);
      this.errorCheck = true;
      this.errorMessage = error.error.message;
      ScrollUtil.scrollToTop();
      this.autoConfigBtnPressed = false;
    });
  }

  renewPressed() {
    this.renewBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    this.httpService.renewSubscription(this.subscription.subscription_id).subscribe(response => {
      console.log('renewPressed', response)

      this.successCheck = true;
      let successLang = {
        eng: 'Subscription renewed.',
        cn: '订阅已更新',
        zh: '訂閱已更新'
      }
      this.successMessage = successLang[this.lang];
      this.getOrderDetails();
    }, error => {
      console.log('error', error);
      this.errorCheck = true;
      this.errorMessage = error.error.message;
      ScrollUtil.scrollToTop();
      this.renewBtnPressed = false;
    });
  }

  suspendPressed() {
    this.suspendBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    this.httpService.suspendSubscription(this.subscription.subscription_id).subscribe(response => {
      console.log('suspendPressed', response)

      this.successCheck = true;
      let successLang = {
        eng: "Subscription suspended.",
        cn: "订阅已被暂停。",
        zh: "訂閱已被暫停。"
      }
      this.successMessage = successLang[this.lang];
      this.getOrderDetails();
    }, error => {
      console.log('error', error);
      this.errorCheck = true;
      this.errorMessage = error.error.message;
      ScrollUtil.scrollToTop();
      this.suspendBtnPressed = false;
    });

  }

  resumePressed() {
    this.resumeBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    this.httpService.resumeSubscription(this.subscription.subscription_id).subscribe(response => {
      console.log('resumePressed', response)

      this.successCheck = true;
      let successLang = {
        eng: "Subscription resumed.",
        cn: "订阅已繼續。",
        zh: "訂閱已繼續。"
      }
      this.successMessage = successLang[this.lang];
      this.getOrderDetails();
    }, error => {
      console.log('error', error);
      this.errorCheck = true;
      this.errorMessage = error.error.message;
      ScrollUtil.scrollToTop();
      this.resumeBtnPressed = false;
    });

  }

  updateQuantityPressed() {
    this.updateQuantityBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    let diff = this.quantity - this.subscription.license_count;

    this.httpService.updateQuantityV2(this.order.order_id, diff).subscribe(response => {
      console.log('updateQuantityPressed', response)

      this.successCheck = true;
      let successLang = {
        eng: "Quantity updated.",
        cn: "数量已更新。",
        zh: "數量已更新。"
      }
      this.successMessage = successLang[this.lang];
      this.getOrderDetails();
    }, error => {
      console.log('error', error);
      this.errorCheck = true;
      this.errorMessage = error.error[0];
      ScrollUtil.scrollToTop();
      this.updateQuantityBtnPressed = false;
    });

  }

  enableAutoRenewPressed() {
    this.enableAutoRenewBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    this.httpService.enableAutoRenewSubscription(this.subscription.subscription_id).subscribe(response => {
      console.log('enableAutoRenewPressed', response)

      this.successCheck = true;

      let successLang = {
        eng: "Auto renew enabled.",
        cn: "自动更新已启动。",
        zh: "自動更新已啟動。"
      }
      this.successMessage = successLang[this.lang];
      this.getOrderDetails();
    }, error => {
      console.log('error', error);
      this.errorCheck = true;
      this.errorMessage = error.error.message;
      ScrollUtil.scrollToTop();
      this.enableAutoRenewBtnPressed = false;
    });
  }

  disableAutoRenewPressed() {
    this.disableAutoRenewBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    this.httpService.disableAutoRenewSubscription(this.subscription.subscription_id).subscribe(response => {
      console.log('disableAutoRenewPressed', response)

      this.successCheck = true;
      let successLang = {
        eng: "Auto renew disabled.",
        cn: "自动更新已关闭。",
        zh: "自動更新已關閉。"
      }
      this.successMessage = successLang[this.lang];
      this.getOrderDetails();
    }, error => {
      console.log('error', error);
      this.errorCheck = true;
      this.errorMessage = error.error.message;
      ScrollUtil.scrollToTop();
      this.disableAutoRenewBtnPressed = false;
    });
  }

  historicalOrderPressed() {
    this.historicalOrderBtnPressed = true;
    this.errorCheck = false;
    this.successCheck = false;

    this.httpService.getHistoricalOrder(this.order.order_id).subscribe(response => {
      console.log('historicalOrderPressed', response)
      this.history = response['data'];
      this.historicalOrderBtnPressed = false;

      $("#historicalOrderModal").modal("show");
    }, error => {
      console.log('error', error);
      this.errorCheck = true;
      this.errorMessage = error.error.message;
      ScrollUtil.scrollToTop();
      this.historicalOrderBtnPressed = false;
    });
  }

  historicalOrderView(id) {
    this.router.navigate(['/o365-order-detail', id]).finally(() => {
      $("#historicalOrderModal").modal("hide");
    });
  }

  setupDomainPressed() {
    this.setupDomainBtnPressed = true;
    this.orderService.setOrderId(this.order.order_id);
    this.router.navigate(['/o365-step-five']);
  }

}
