export class ScrollUtil {

    static scrollToTop() {
        (function topFunction() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          })();
    }

    // static scrollToTop() {
    //     (function smoothscroll() {
    //         var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    //         if (currentScroll > 0) {
    //             window.requestAnimationFrame(smoothscroll);
    //             window.scrollTo(0, currentScroll - (currentScroll / 8));
    //         }
    //     })();
    // }

    static scrollToTopNoDelay(){
        (function topFunction() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          })(); 
    }

    static scrollToElement(element:any): void {
        element.scrollIntoView({
            behavior: "smooth",
            block: 'start'
        });
    }
}
