<div class="sidebar">
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-item" *ngFor="let menu of menuItems" >
        <a class="nav-link" routerLink="{{menu?.routerLink}}"><i class="{{menu?.iconClassName}}"></i> {{menu?.name}}</a>
      </li>
    </ul>
  </nav>
  <button class="sidebar-minimizer brand-minimizer" type="button"></button>
</div>
