<div class="row">
  <div class="col-12">
    <h3>{{ 'o365.reviewandfinish' | translate }}</h3>
    <div class="row mt-4">
      <div class="col-12">
        <label class="font-weight-bold">{{ 'o365.assignedsetting' | translate }}</label>
        <div>{{ 'o365.reviewsetting' | translate }}</div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <label class="font-weight-bold">{{ 'o365.displayandusername' | translate }}</label>
        <div>{{newUserBasicsForm.get('displayName').value}}</div>
        <div>{{newUserBasicsForm.get('username').value}}@{{newUserBasicsForm.get('domain').value}}</div>
        <button type="button" class="btn-link" (click)="onEditUserBasicsClicked()">{{ 'button.edit' | translate }}</button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <label class="font-weight-bold">{{ 'word.password' | translate }}</label>
        <div>{{ 'word.type' | translate }}: {{newUserBasicsForm.get('generatePassword').value === true ? 'Auto-generated' : 'Custom Password'}}</div>
        <button type="button" class="btn-link" (click)="onEditUserBasicsClicked()">{{ 'button.edit' | translate }}</button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <label class="font-weight-bold">{{ 'o365.productlicense' | translate }}</label>
        <div *ngIf="newUserLicensesForm.get('createUserWithLicense').value === false">{{ 'o365.createuserwopermission' | translate }}</div>
        <div *ngIf="newUserLicensesForm.get('createUserWithLicense').value === true">
          <div>{{ 'word.location' | translate }}: {{newUserLicensesForm.get('location').value}}</div>
          <div>{{ 'word.licenses' | translate }}: {{productLicenses}}</div>
        </div>
        <button type="button" class="btn-link" (click)="onEditUserLicensesClicked()">{{ 'button.edit' | translate }}</button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <label class="font-weight-bold">{{ 'o365.rolesdefault' | translate }}</label>
        <div>{{ 'o365.user.noadminaccess' | translate }}</div>
        <button type="button" class="btn-link" (click)="onEditUserOptionalClicked()">{{ 'button.edit' | translate }}</button>
      </div>
    </div>

    <!-- <div class="row mt-4">
      <div class="col-12">
        <label class="font-weight-bold">Profile Info</label>
        <div>None Provided</div>
        <button type="button" class="btn-link" (click)="onEditUserOptionalClicked()">Edit</button>
      </div>
    </div> -->

    <button type="button" class="btn btn-secondary m-3" (click)="onBackStep()" [disabled]="createUserLoading">
      {{ 'button.back' | translate }}
    </button>

    <button type="button" class="btn btn-primary m-3" (click)="onCompleteStep()" [disabled]="newUserBasicsForm.invalid || newUserLicensesForm.invalid || createUserLoading">
      <span>{{ 'button.finishadding' | translate }}</span>
      <i class="fa fa-spinner fa-spin" *ngIf="createUserLoading"></i>
    </button>
  </div>
</div>
