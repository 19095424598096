import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class StorefrontEmailApiService {

  emailServiceApiUrl: any = environment.emailService.apiUrl;
  provider: string = 'Alibaba';

  constructor(private httpClient:HttpClient) {
  }

  getHeaders(){
    const httpHeaders = {
      headers : new HttpHeaders()
      .set("Authorization", "Bearer " + localStorage.getItem('access_token'))
      .set('Content-Type', 'application/json')
    }
    return httpHeaders;
  }
  
  getAllResellerProductPlans(){
    let endpoint: string = '/product/all-plans/' + this.provider;
    return this.httpClient.get(this.emailServiceApiUrl + endpoint, this.getHeaders() );
  }

  getActiveSfProductPlans(storefrontId:any){
    let endpoint: string = `/product/all-plans/${this.provider}/${storefrontId}`;
    return this.httpClient.get(this.emailServiceApiUrl + endpoint, this.getHeaders() );
  }

  updateActiveSfProductPlanPrice(storefrontId:any, data:any){
    let endpoint: string = `/product/client/${storefrontId}/price/save`;
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, data, this.getHeaders() );
  }
}