import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  apiUrl = environment.mainApiUrl;

  httpHeaders = {
		headers : new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
	}

  constructor(
    private http: HttpClient
  ) { }

  getCountries() {
    return this.http.get(`${this.apiUrl}countries`);
  }

  getCountriesCsp() {
    return this.http.get(`${this.apiUrl}sfadmin/microsoft-csp/countries`, this.httpHeaders);
  }

  getStates(countryId) {
    console.log("id is "+countryId)
    return this.http.get(
      `${this.apiUrl}country/${countryId}/states`
    );
  }

  getCities(cityId) {
    return this.http.get(
      `${this.apiUrl}state/${cityId}/cities`
    );
  }
}
