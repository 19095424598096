<main id="storefront" class="main create-storefront">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'email.orderprofessionalemail' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.steptwo' | translate }}</li>
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
      </div>
    </div>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">

        <div class="col-lg-12">

          <div class="alert alert-success alert-dismissible fade show" role="alert" *ngIf="successCheck">
             {{successMessage}}
            <button type="button" class="close" (click)="closePressed('success')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorCheck">
            <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            {{ 'error.contactsupport' | translate }}
            <button type="button" class="close" (click)="closePressed('error')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <form class="form" style="overflow-y: visible">

            <div class="col-lg-12 mt-3">
              <h2>{{ 'word.orderconfirm' | translate }}</h2>

              <div class="card mt-4 pl-2 pr-2">
                <div class="card-body">

                  <table class="table">
                    <tbody>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'email.accountstorage' | translate }}
                        </td>
                        <td>
                          {{ data?.mailstorage }} GB
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'email.sharestorage' | translate }}
                        </td>
                        <td>
                          {{ data?.sharedstorage }} GB
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'email.personalstorage' | translate }}
                        </td>
                        <td>
                          {{ data?.personalstorage }} GB
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'email.emaildomain' | translate }}
                        </td>
                        <td>
                          {{ data?.emailDomain }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'word.licenses' | translate }}
                        </td>
                        <td>
                          {{ data?.licenses }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'word.duration' | translate }}
                        </td>
                        <td>
                          {{ data?.terms }} {{ 'word.month' | translate }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'word.totalprice' | translate }}
                        </td>
                        <td >USD {{ data?.totalprices.toFixed(2) }}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="float-right">
                <button class="btn-primary" class="btn btn-warning btn-lg px-5 mb-4" (click)="placeOrderPressed()"
                [disabled]="placeOrderBtnPressed || errorCheck || errors.length > 0 || !data ">
                  <span class="text-white">{{ 'button.submitorder' | translate }}</span> 
                  <i class="fa fa-cog fa-spin ml-2" style="font-size:24px;color: lightseagreen" *ngIf="placeOrderBtnPressed"></i> 
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</main>
