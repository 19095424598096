import { LanguageTranslateService } from './../../../../service/language-translate.service';
import { Component, OnInit, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { O365HttpService } from 'src/app/service/o365-http.service';
import { SortEvent, SortDirection, SortableTableHeader } from 'src/app/directive/sortable.directive';
import { DatatableUtil } from 'src/app/util/datatable-util';
import { SearchableTableHeader } from 'src/app/directive/searchable.directive';
import { ScrollUtil } from 'src/app/util/scroll-util';

@Component({
  selector: 'microsoftcsp-subscription-management',
  templateUrl: './microsoftcsp-subscription-management.component.html',
  styleUrls: ['./microsoftcsp-subscription-management.component.css']
})
export class MicrosoftCSPSubscriptionManagementComponent implements OnInit {

  tenantId:number;
  tenantSubscriptions: any[] = [];
  filteredTenantSubscriptions: any[] = [];
  
  tableLoading: boolean = false;

  pageSizeOptions:number[] = [10,20,30,50];
  pageSize:number;
  page:number;
  totalItems:number;

  pagedItemsInfo = { startIndex: 0, endIndex: 0, total: 0, searchFiltered: false };

  fullTextSearchDebounceTimer:any;
  fullTextSearchInput:string = null; 

  sortColumn: string = '';
  sortDirection: SortDirection = '';

  @ViewChildren(SortableTableHeader) sortableHeaders: QueryList<SortableTableHeader>;
  @ViewChildren(SearchableTableHeader) searchableHeaders: QueryList<SearchableTableHeader>;

  errorCheck: boolean = false;
  errors: any[] = [];

  lang = "eng";
  entriesLang = {
    eng: "entries per page",
    cn: "每页项目",
    zh: "每頁項目"
  }
 
  constructor(
    private o365ApiService: O365HttpService,
    private chRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: LanguageTranslateService,
  ) { }

  ngOnInit() {
    this.lang = this.translateService.userLanguage$.value;
    this.totalItems = 0;
    this.page = 1;
    this.pageSize = this.pageSizeOptions[0];

    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      this.getTenantSubscriptions();
    }); 
  }

  getTenantSubscriptions(){
    this.tableLoading = true;
    this.o365ApiService.getTenantSubscriptions(this.tenantId).subscribe((res:any)=>{
      this.tenantSubscriptions = res.data;

      this.tenantSubscriptions.sort((a, b) => (a.subscriptionId > b.subscriptionId ? -1 : 1));

      this.filtering();
      this.tableLoading = false;
    },err=>{
      this.handleError(err);
    })
  }

  filtering(){
    this.page = Number(this.page);
    this.pageSize = Number(this.pageSize);

    // if current page greater than calculated total pages then reset page to 1
    if(this.page > Math.ceil(this.tenantSubscriptions.length/this.pageSize)){
      this.page = 1;
    }

    // always copy a new dataset from master data sources
    this.filteredTenantSubscriptions.length = 0;
    this.filteredTenantSubscriptions = [...this.tenantSubscriptions];
    this.totalItems = this.tenantSubscriptions.length;

    // sorting
    this.filteredTenantSubscriptions = DatatableUtil.sort(this.filteredTenantSubscriptions,this.sortColumn,this.sortDirection);

    // full text filtering 
    let searchText = this.fullTextSearchInput;
    this.pagedItemsInfo.searchFiltered = false;
    if(searchText && searchText != null && searchText != ''){
      // get searchableHeaders columns
      let columns = DatatableUtil.getFullTextSearchableColumns(this.searchableHeaders);
      // 
      this.filteredTenantSubscriptions = [...this.filteredTenantSubscriptions.filter(item => {
        return DatatableUtil.filterMatching(item,searchText,columns);
      })];
      // update total items count using filtered dataset
      this.totalItems = this.filteredTenantSubscriptions.length;
      this.pagedItemsInfo.searchFiltered = true;
    }
   
    // pagination by slicing array items
    let offset: number = (this.page - 1) * this.pageSize;
    let end = offset + this.pageSize;
    this.filteredTenantSubscriptions = this.filteredTenantSubscriptions.slice(offset,end);

    // update pagedItemInfo
    if(this.totalItems < 1){
      this.pagedItemsInfo = { startIndex: 0, endIndex: 0, total: 0, searchFiltered: false };
      return;
    }

    this.pagedItemsInfo.total = this.totalItems;
    this.pagedItemsInfo.startIndex = offset != 0 ? offset + 1 : 1;
    this.pagedItemsInfo.endIndex = end < this.totalItems ? end : this.totalItems;
  }

  onFullTextSearchKeyUp(value: string) {
    clearTimeout(this.fullTextSearchDebounceTimer);

    this.fullTextSearchDebounceTimer = setTimeout(() => {
      this.fullTextSearchInput = value;
      this.filtering();
    }, 1000);
  }

  onPageChanged(page:number){
    this.filtering();
  }

  onPageSizeChanged(size:number){
    this.pageSize = size;
    this.page = 1; // always reset page to 1
    this.filtering();
  }

  onSort({column, direction}:SortEvent){
    // resetting other headers
    this.sortableHeaders.forEach(header => {
      if (header.column !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;
    this.filtering();
  }

  goToSubscriptionDetails(subscription:any){
    this.router.navigate(['/microsoftcsp/tenants', this.tenantId, 'subscriptions', subscription.subscriptionId]);
  }

  handleError(err: any) {
    if(err.error && err.error.error){
      try {
        let errs:any[] = err.error.error;
        errs.forEach((e:any) => {
          this.errors.push(e.detail);
        });
      } catch (tryErr) {
        // not array then should be single string
        this.errors.push(String(err.error.error));
      }
    } else if (err.error && err.error.message) {
      this.errors.push(err.error.message);
    } else {
      this.errors.push(err.message);
    }
    this.errorCheck = true;
    ScrollUtil.scrollToTop();
  }

  errorClosePressed() {
    this.errorCheck = false;
    this.errors.length = 0;
  }

}
