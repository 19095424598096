import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';
import { O365HttpService } from 'src/app/service/o365-http.service';

@Component({
  selector: 'app-microsoftcsp-product-pricing',
  templateUrl: './microsoftcsp-product-pricing.component.html',
  styleUrls: ['./microsoftcsp-product-pricing.component.css']
})
export class MicrosoftCSPProductPricingComponent implements OnInit {

  loading: boolean = false;

  o365Products: any[] = []; 

  constructor(
    private router: Router,
    private o365HttpService: O365HttpService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.loadMicrosoftCSPProducts();
  }

  loadMicrosoftCSPProducts() {
    this.loading = true;

    this.loadO365Products().then(() => {
      this.loading = false;
    });
  }

  async loadO365Products() {

    const options: any = {
      category_name: 'Microsoft Office 365'
    };

    await this.o365HttpService.getAllProductsV2(options).toPromise().then((res: any) => {
      this.o365Products = this.mapResellerPriceToList('o365', res.data);
    }).catch(err => {
      console.error(err);
    });
  }

  mapResellerPriceToList(productCategoryName: string, products: any[]) {
    if (!products) {
      return [];
    }
    return products.map((product: any) => {
      let mappedProduct: any = { ...product };

      switch (productCategoryName) {
        case 'o365': {
          let resellerPrice = {
            monthly_price: product.monthly,
            yearly_price: product.annually,
          }
          mappedProduct.resellerPrice = resellerPrice;
          break;
        }
        default: break;
      }

      return mappedProduct;
    });
  }
}
