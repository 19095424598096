import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-o365-place-order',
  templateUrl: './o365-place-order.component.html',
  styleUrls: ['./o365-place-order.component.css']
})
export class O365PlaceOrderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
