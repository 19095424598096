import { trigger, state, style, transition, animate } from "@angular/animations";

export const smoothCollapse = trigger('smoothCollapse', [
    state('hide', style({
        display: 'none',
        opacity: '0',
        height: '0',
        overflow: 'hidden'
    })),
    state('show', style({
        display: 'block',
        opacity: '1',
        height: '*',
        overflow: 'hidden'
    })),
    transition('hide=>show', animate('0.2s')),
    transition('show=>hide', animate('0.2s'))
]);