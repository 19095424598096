import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { O365HttpService } from 'src/app/service/o365-http.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/service/alert.service';
import { ScrollUtil } from 'src/app/util/scroll-util';
import { ResellerService } from '../../../../service/reseller/reseller.service';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';


@Component({
  selector: 'microsoftcsp-subscription-create',
  templateUrl: './microsoftcsp-subscription-create.component.html',
  styleUrls: ['./microsoftcsp-subscription-create.component.css']
})
export class MicrosoftCSPSubscriptionCreateComponent implements OnInit {

  tenantId: number;
  loading: boolean = false;
  products: any[] = [];
  filteredProducts: any[] = [];
  selectedProduct: any; 

  addSubscriptionForm: UntypedFormGroup;

  totalPrice: number = 0;
  currentBalance: number;
  leftoverBalance: number;
  showInvalidTotalPrice: boolean = false;

  submitPressed:boolean = false;

  errorCheck: boolean = false;
  errors: any[] = [];

  successCheck: boolean = false;
  
  lang: string = 'eng';
  orderSuccessLang = {
    eng: "Sucessfully created order.",
    cn: "订单已成功创建。",
    zh: "訂單已成功創建。",
  }
  successMessage: string;

  orderConfirmLang = {
    eng: "Are you confirm to place order?",
    cn: "确认下单？",
    zh: "確認下單？",
  }

  orderSummaryLoading: boolean = false;

  productRules: any[] = [];
  currentProductRule: any;

  constructor(
    private o365ApiService: O365HttpService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private resellerService: ResellerService,
    private translateService: LanguageTranslateService
  ) { }

  ngOnInit() {
    this.lang = this.translateService.userLanguage$.value;

    console.log();
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      this.loadSubscriptionProducts();
      this.getResellerBalance();
    });

    this.createAddSubscriptionForm();
  }

  loadSubscriptionProducts() {
    this.loading = true;
    this.o365ApiService.getTenantSubscriptionProducts(this.tenantId).pipe(
      finalize(() => this.loading = false)
    ).subscribe((res: any) => {
      this.products = res.data;
      this.filteredProducts = [...this.products];

      this.o365ApiService.getTenantDetails(this.tenantId).subscribe((res: any) => {
        let tenant = res.data.data;
        if (tenant && tenant.microsoft_market_category != 'Academic') {
          // temporary filter out all products which is academic and not-for-profit
          let academicAndNonProfitProductId = [377,378,379,380,381,382,383,391,392,393,394];
          // notes: this will filter to products which is not in 'academicAndNotProfitProductId' array
          this.filteredProducts = this.filteredProducts.filter(p => !academicAndNonProfitProductId.includes(p.product_id) );
        }
      });
    })
  }

  async getResellerBalance() {
    await this.resellerService.getResellerByRescode()
      .toPromise()
      .then((res: any) => {
        this.currentBalance = +res.balance.toFixed(2);
      })
      .catch(err => {
        this.currentBalance = undefined;
        console.error('getResellerByRescode', err);
      })
  }

  createAddSubscriptionForm() {
    this.addSubscriptionForm = this.formBuilder.group({
      subscriptionType: ['Monthly', Validators.required],
      quantity: [1, [Validators.required, Validators.min(1)]],
      productId: [undefined, [Validators.required, Validators.min(1)]]

    });
  }

  get subscriptionTypeFormControl() { return this.addSubscriptionForm.get('subscriptionType'); }
  get quantityFormControl() { return this.addSubscriptionForm.get('quantity'); }
  get productIdFormControl() { return this.addSubscriptionForm.get('productId'); }

  selectProduct(product: any) {
    this.orderSummaryLoading = true;
    let selectedProduct = this.products.find(p => p.product_id == product.product_id);
    this.selectedProduct = selectedProduct;

    this.productIdFormControl.patchValue(this.selectedProduct.product_id);
    this.productIdFormControl.markAsTouched();

    // get product rules and update form validation
    this.o365ApiService.getProductRule(this.selectedProduct.product_id)
    .pipe(finalize(()=>{
      this.orderSummaryLoading = false;
      this.updateTotalPrice(); // either success or error, must call update
    }))
    .subscribe((res:any)=>{
      this.productRules = res.data;
      this.updateProductValidationRules();
    }, err => {
      console.error('getProductRule error', err);
    });
  }

  updateProductValidationRules(){
    if (this.productRules.length < 1) {
      return;
    }
    // reset
    this.currentProductRule = null;

    let currentSubscriptionType:string = this.subscriptionTypeFormControl.value;
    // find rule based on billingCycle
    this.currentProductRule = this.productRules.find(r => {
      let ruleBillingCycle: string = r.billingCycle;
      if (ruleBillingCycle && ruleBillingCycle.toLowerCase() == currentSubscriptionType.toLowerCase()) {
        return true;
      }
      return false;
    });

    // not found then do nothing
    if (!this.currentProductRule) {
      console.error('Product rule not found !!');
      return;
    }
    // either min or max quantity rule not found then do nothing
    if(!this.currentProductRule.minQuantity || !this.currentProductRule.maxQuantity){
      console.error('Min quantity or max quantity product rule not found !!');
      return;
    }

    let minQuantity = Number(this.currentProductRule.minQuantity);
    let maxQuantity = Number(this.currentProductRule.maxQuantity);

    this.quantityFormControl.setValidators([
      Validators.required,
      Validators.min(minQuantity),
      Validators.max(maxQuantity)
    ]);

    this.quantityFormControl.markAsTouched();
    this.quantityFormControl.updateValueAndValidity();
  }

  increaseOrDecreaseLicenseCount(type: string) {
    let currentLicenseCount: number = this.quantityFormControl.value;
    if (type == 'increase') {
      this.quantityFormControl.patchValue(currentLicenseCount + 1);
      this.updateTotalPrice();
    } else if (type == 'decrease' && currentLicenseCount - 1 > 0) {
      this.quantityFormControl.patchValue(currentLicenseCount - 1);
      this.updateTotalPrice();
    }
    this.quantityFormControl.markAsTouched();
  }

  updateTotalPrice() {

    console.log(this.subscriptionTypeFormControl.value);
    if (this.quantityFormControl.invalid || !this.selectedProduct) {
      this.showInvalidTotalPrice = true;
      return;
    }
    let productPrice = this.selectedProduct.product_price;

    if (!productPrice) {
      this.alertService.showError('Product price info not found. Please try again later.');
      this.showInvalidTotalPrice = true;
      return;
    }

    if (this.subscriptionTypeFormControl.value == 'Monthly') {
      if (!productPrice.monthly) {
        this.alertService.showError('Product price info not found. Please try again later.');
        this.showInvalidTotalPrice = true;
        return;
      }
      let monthlyPrice = Number(productPrice.monthly);
      let licenseCount = Number(this.quantityFormControl.value);

      this.totalPrice = monthlyPrice * licenseCount;
      this.showInvalidTotalPrice = false;

    } else if (this.subscriptionTypeFormControl.value == 'Annual') {
      // for annually cycle
      if (!productPrice.annually) {
        this.alertService.showError('Product price info not found. Please try again later.');
        this.showInvalidTotalPrice = true;
        return;
      }
      let annuallyPrice = Number(productPrice.annually);
      let licenseCount = Number(this.quantityFormControl.value);

      this.totalPrice = annuallyPrice * licenseCount;
      this.showInvalidTotalPrice = false;

    } else {
      // unknown
      this.alertService.showError('Product price info not found. Please try again later.');
      this.showInvalidTotalPrice = true;
    }

  }

  onSubscriptionTypeChanged(value: any) {
    console.log(this.selectedProduct);
    console.log(this.quantityFormControl.invalid);

    if (!this.selectedProduct || this.quantityFormControl.invalid) {
      console.log("yee");

      return;
    }

    this.updateProductValidationRules();
    this.updateTotalPrice();

  }

  submit(){

    this.leftoverBalance = 0;
    this.leftoverBalance = this.currentBalance - this.totalPrice;

    if (this.leftoverBalance < 0) {
      this.submitPressed = false;

      let message: string = 'Insufficient balance. Lacking of USD ' + Math.abs(this.leftoverBalance).toFixed(2);

      let errorResponse: any = {
        error: {}
      };
      let error: any = {
        error: []
      };
      error.error.push({ detail: message });
      errorResponse.error = error;

      this.alertService.showError(message);
      this.handleError(errorResponse);

      return;
    } else {
      let callback = () =>{
        this.submitPressed = true;
        let subscriptionType:string = this.subscriptionTypeFormControl.value;

        let duration:number = 1; // default monthly
        if(subscriptionType == 'Annual'){
          duration = 12;
        }

        let data :any = {
          tenant_id: this.tenantId,
          product_id: this.addSubscriptionForm.value.productId,
          quantity: this.addSubscriptionForm.value.quantity,
          duration: duration
        }

        this.o365ApiService.createOrder(data)
        .pipe(finalize(()=>{this.submitPressed = false}))
        .subscribe((res:any)=>{
          console.log(res);
          let order = res.data.order;
          let subscriptionId:number = order.existing_subscription_id;

        
          this.alertService.showSuccess(this.orderSuccessLang[this.lang]);
          this.successCheck = true;

          if(subscriptionId){
            this.router.navigate(['/microsoftcsp/tenants', this.tenantId, 'subscriptions', subscriptionId]);
          }else{
            this.router.navigate(['/microsoftcsp/tenants', this.tenantId, 'subscriptions']);
          }
        },err=>{
          console.error('create order error', err);
          let message: string = 'Failed to place order.';
          if (err.error && err.error.message) {
            message = err.error.message;
          }
          this.alertService.showError(message);
          this.handleError(err);
        })
      }

      this.alertService.showComfirmation(
        {message: this.orderConfirmLang[this.lang]},
         callback);
    }
  }

  handleError(err: any) {
    if(err.error && err.error.error){
      try {
        let errs:any[] = err.error.error;
        errs.forEach((e:any) => {
          this.errors.push(e.detail);
        });
      } catch (tryErr) {
        // not array then should be single string
        this.errors.push(String(err.error.error));
      }
    } else if (err.error && err.error.message) {
      this.errors.push(err.error.message);
    } else {
      this.errors.push(err.message);
    }
    this.errorCheck = true;
    ScrollUtil.scrollToTop();
  }

  errorClosePressed() {
    this.errorCheck = false;
    this.errors.length = 0;
  }
  successClosePressed() {
    this.successCheck = false;
    this.successMessage = '';
  }
}
