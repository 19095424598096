<main id="manage-order" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'word.ordermanagement' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.orderdetails' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row" *ngIf="errorCheck">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorCheck">
            <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            <button type="button" class="close" (click)="closeErrorPressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="successCheck">
        <div class="col-12">
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>{{ 'word.success' | translate }}</strong>&nbsp;{{ successMessage }}
            <button type="button" class="close" (click)="closeSuccessPressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-7">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="2">{{ 'word.orderinfo' | translate }}</th>
                </tr>
              </thead>
              <tbody class="bg-white" *ngIf="order">
                <tr>
                  <td>{{ 'word.products' | translate }}</td>
                  <td>{{ order?.productName }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.package' | translate }}</td>
                  <td>{{ order?.packageName }}</td>
                </tr>
                <tr *ngIf="order.storefrontname && order.storefrontname !='' ">
                  <td>{{ 'word.storefront' | translate }}</td>
                  <td>{{ order?.storefrontname }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.orderid' | translate }}</td>
                  <td>{{ order?.orderid }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.orderdate' | translate }}</td>
                  <td>{{ order?.dtorder | date:'medium' }}</td>
                </tr>
                <tr *ngIf="order?.dtcomplete">
                  <td>{{ 'word.completeddate' | translate }}</td>
                  <td>{{ order?.dtcomplete | date:'medium' }}</td>
                </tr>
                <tr *ngIf="order?.dtexpiry">
                  <td>{{ 'word.expirationdate' | translate }}</td>
                  <td>{{ order?.dtexpiry | date:'medium' }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.orderstatus' | translate }}</td>
                  <td>{{order?.statusValue}}</td>
                </tr>
                <tr>
                  <td>{{ 'word.paymentstatus' | translate }}</td>
                  <td>{{order?.paymentStatusValue}}</td>
                </tr>
                <tr *ngIf="order.payfailurereason && order.payfailurereason !=''">
                  <td>{{ 'email.failurereason' | translate }}</td>
                  <td>{{order?.payfailurereason}}</td>
                </tr>
                <tr *ngIf="order.referenceno && order.referenceno !='' ">
                  <td>{{ 'email.refno' | translate }}</td>
                  <td>{{order?.referenceno}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-5">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="2">{{ 'word.productdetails' | translate }}</th>
                </tr>
              </thead>
              <tbody class="bg-white" *ngIf="order">
                <tr>
                  <td>{{ 'email.emaildomain' | translate }}</td>
                  <td>{{ order?.domemail }}</td>
                </tr>
                <tr>
                  <td>{{ 'email.accountstorage' | translate }}</td>
                  <td>{{ order?.mailstorage }}</td>
                </tr>
                <tr>
                  <td>{{ 'email.sharestorage' | translate }}</td>
                  <td>{{ order?.sharedstorage }}</td>
                </tr>
                <tr>
                  <td>{{ 'email.personalstorage' | translate }}</td>
                  <td>{{ order?.personalstorage }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.licensecount' | translate }}</td>
                  <td>{{ order?.licenses }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.subscribedmonth' | translate }}</td>
                  <td>{{ order?.terms }}</td>
                </tr>
                <tr>
                  <td>{{ 'word.totalprice' | translate }} (USD)</td>
                  <td>{{ order?.totalPrice }}</td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-7">
          <div class="table-responsive mb-4">
            <table class="table table-bordered mb-0">
              <thead class="thead-light">
                <tr>
                  <th colspan="3">{{ 'word.action' | translate }}</th>
                </tr>
              </thead>
              <tbody class="bg-white" *ngIf="order">
                <ng-container *ngIf="order.isClientOrder ;else resellerOrderActions">
                  <tr>
                    <td colspan="2">{{ 'email.markpaymentasdone' | translate }}</td>
                    <td style="width: 30%;">
                      <button class="btn btn-block btn-primary"(click)="openModifyPaymentStatusModal(modifyPaymentStatusModal)"
                       [disabled]="modifyPaymentStatusBtnPressed || !enableModifyPaymentStatus"> {{ 'button.go' | translate }}
                        <span *ngIf="modifyPaymentStatusBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="enableModifyAddLicensesOAPaymentStatus">
                    <td colspan="2">{{ 'email.markaddlicensepaymentasdone' | translate }}
                      <i class="fa fa-exclamation-circle text-warning" placement="right" triggers="click:blur hover focus"
                      tooltip="{{ 'email.addlicensetooltip' | translate }}">
                      </i>
                    </td>
                    <td >
                      <button class="btn btn-block btn-primary" (click)="openModifyAddLicensesOAPaymentStatusModal(modifyAddLicensesOAPaymentStatusModal)"
                       [disabled]="modifyAddLicensesOAPaymentStatusBtnPressed"> {{ 'button.go' | translate }}
                        <span *ngIf="modifyAddLicensesOAPaymentStatusBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                      </button>
                    </td>
                  </tr>
                </ng-container>
                <ng-template #resellerOrderActions>
                  <tr>
                    <td colspan="2">{{ 'email.addlicense' | translate }}</td>
                    <td style="width: 30%;">
                      <button class="btn btn-block btn-primary" (click)="openModifyLicensesModal(modifyLicensesModal)"
                       [disabled]="modifyLicensesBtnPressed || !enableModifyLicenses"> {{ 'button.go' | translate }}
                        <span *ngIf="modifyLicensesBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">{{ 'word.reneworder' | translate }}</td>
                    <td>
                      <button class="btn btn-block btn-primary" (click)="openRenewOrderModal(renewOrderModal)"
                      [disabled]="renewOrderBtnPressed || !enableRenewOrder"> {{ 'button.go' | translate }}
                        <span *ngIf="renewOrderBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">{{ 'email.changereleasedomain' | translate }}</td>
                    <td>
                      <button class="btn btn-block btn-primary" (click)="openChangeOrReleaseDomainModal(changeOrReleaseDomainModal)"
                      [disabled]="changeOrReleaseDomainBtnPressed || !enableChangeOrReleaseDomain">
                      {{ 'button.go' | translate }}
                        <span *ngIf="changeOrReleaseDomainBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="float-right">
            <a routerLink="/email/email-order-management" class="btn btn-dark btn-lg px-5 mb-4">{{ 'button.back' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->

  <!-- Modal -->
  <ng-template #modifyPaymentStatusModal>
    <div class="modal-header">
      <h4 class="modal-title">{{ 'email.updatepaymentstatus' | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="modifyPaymentStatusForm" id="modifyPaymentStatusForm" class="form" style="width: 100%;">
        <div class="group form-group mb-4">
          <div class="mb-1">
            {{ 'word.referenceno' | translate }}
            <i class="fa fa-exclamation-circle text-warning" placement="right" triggers="click:blur hover focus"
            tooltip="{{ 'email.refnotooltip' | translate }}">
            </i>
          </div>
          <input formControlName="referenceno" class="form-control my-2" />
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="modifyPaymentStatusForm.invalid || modifyPaymentStatusBtnPressed"
       (click)="modifyPaymentStatusPressed()">
       {{ 'button.update' | translate }} <span *ngIf="modifyPaymentStatusBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">{{ 'button.close' | translate }}</button>
    </div>
  </ng-template>

  <ng-template #modifyAddLicensesOAPaymentStatusModal>
    <div class="modal-header">
      <h4 class="modal-title">{{ 'email.updateaddlicenses' | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="modifyAddLicensesOAPaymentStatusForm" id="modifyAddLicensesOAPaymentStatusForm" class="form" style="width: 100%;">
        <div class="group form-group mb-4">
          <div class="mb-1">
            {{ 'email.alorderrequests' | translate }}
            <i class="fa fa-exclamation-circle text-warning" placement="right" triggers="click:blur hover focus"
            tooltip="{{ 'email.modifyaddlicensetooltip1' | translate }}">
            </i>
          </div>
          <select formControlName="oaid" class="form-control my-2">
            <option value="null" disabled="true">{{ 'email.selectorderrequest' | translate }}</option>
            <option *ngFor="let oa of pendingPaymentAddLicensesOAList" [value]="oa.oaid">
              {{ 'word.totalprice' | translate }} = USD {{oa.totalprices | number}}, {{ 'email.foradditional' | translate }} {{oa.licenses}} {{ 'word.licenses' | translate }}(s)
            </option>
          </select>
        </div>
        <div class="group form-group mb-4">
          <div class="mb-1">
            {{ 'word.referenceno' | translate }}
            <i class="fa fa-exclamation-circle text-warning" placement="right" triggers="click:blur hover focus"
            tooltip="{{ 'email.modifyaddlicensetooltip2' | translate }}">
            </i>
          </div>
          <input formControlName="referenceno" class="form-control my-2" />
          <small *ngIf="oaReferencenoFormControl.touched">
            <div *ngIf="oaReferencenoFormControl.hasError('required')" class="text-danger">{{ 'error.referencenorequired' | translate }}</div>
          </small>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="modifyAddLicensesOAPaymentStatusForm.invalid || modifyAddLicensesOAPaymentStatusBtnPressed"
       (click)="modifyAddLicensesOAPaymentStatusPressed()">
       {{ 'button.update' | translate }} <span *ngIf="modifyAddLicensesOAPaymentStatusBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">{{ 'button.close' | translate }}</button>
    </div>
  </ng-template>

  <ng-template #modifyLicensesModal>
    <div class="modal-header">
      <h4 class="modal-title">{{ 'email.addlicense' | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="modifyLicensesForm" id="modifyLicensesForm" class="form" style="width: 100%;">
        <div class="group form-group mb-4">
          <div class="mb-1">
            {{ 'word.licenses' | translate }}
          </div>
          <input formControlName="licenses" id="licensesId" type="number" class="form-control"
          [ngClass]=" {'is-invalid': licensesFormControl.invalid }" />
            <p *ngIf="licensesFormControl.hasError('min')" class="text-danger" >
              {{ 'error.minlicensetoadd' | translate }}
            </p>
            <p *ngIf="licensesFormControl.hasError('max')" class="text-danger" >
              {{ 'error.maxlicensetoadd' | translate }} {{emailProduct.maxlicenses - order.licenses}}.
            </p>
            <p *ngIf="licensesFormControl.hasError('required')" class="text-danger">
              {{ 'error.licensequanrequired' | translate }}
            </p>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="modifyLicensesForm.invalid || modifyLicensesBtnPressed"
       (click)="modifyLicensesPressed()">
       {{ 'button.add' | translate }} <span *ngIf="modifyLicensesBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">{{ 'button.close' | translate }}</button>
    </div>
  </ng-template>

  <ng-template #renewOrderModal>
    <div class="modal-header">
      <h4 class="modal-title">{{ 'word.reneworder' | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="renewOrderForm" id="renewOrderForm" class="form" style="width: 100%;">
        <div class="group form-group mb-4">
          <div class="mb-1">
            {{ 'word.terms.months' | translate }}
          </div>
          <select name="termId" formControlName="terms" id="termsId" class="form-control">
            <option [value]="0" disabled>{{ 'word.selectterm' | translate }}</option>
            <option *ngFor="let productPrice of emailProductPriceList" [value]="productPrice?.terms">
              {{ productPrice?.terms }}
            </option>
          </select>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="renewOrderForm.invalid || renewOrderBtnPressed"
       (click)="renewOrderPressed()">
       {{ 'button.renew' | translate }} <span *ngIf="renewOrderBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">{{ 'button.close' | translate }}</button>
    </div>
  </ng-template>

  <ng-template #changeOrReleaseDomainModal>
    <div class="modal-header">
      <h4 class="modal-title">{{ 'email.changereleasedomain' | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="changeOrReleaseDomainForm" id="changeOrReleaseDomainForm" class="form" style="width: 100%;">
        <div class="group form-group mb-4">
          <div class="mb-1">
            {{ 'word.options' | translate }}
          </div>
          <select name="domainOptions" formControlName="domainOptions" id="domainOptionsId" class="form-control">
            <option [value]="1">{{ 'email.option.changedomain' | translate }}</option>
            <option [value]="2">{{ 'email.option.releasedomain' | translate }}</option>
          </select>
        </div>
        <div class="group form-group mb-4" *ngIf="emailDomainFormControl.enabled">
          <div class="mb-1">
            {{ 'word.domains' | translate }}
          </div>
          <div class="position-relative">
            <input type="text" name="emailDomain" formControlName="emailDomain" id="emailDomainId" class="form-control"
            [ngClass]=" {'is-invalid': emailDomainFormControl.errors && emailDomainFormControl.touched,
            'is-valid': emailDomainFormControl.valid && emailDomainFormControl.touched } "
            (blur)="onEmailDomainBlur($event.target.value)"/>
            <div *ngIf="emailDomainFormControl.touched" class="feedback-icon"
              [ngClass]="emailDomainFormControl.valid ? 'valid-feedback' : 'invalid-feedback' ">
              <i [ngClass]="emailDomainFormControl.valid ? 'icon-check' : 'icon-close' "></i>
            </div>
          </div>
          <div *ngIf="emailDomainFormControl.touched">
            <p *ngIf="emailDomainFormControl.valid" class="text-success">{{emailDomainFormControl.value}} {{ 'email.domainisavailable' | translate }}</p>
            <p *ngIf="emailDomainFormControl.hasError('pattern')" class="text-danger">{{ 'error.invaliddomain' | translate }}</p>
            <p *ngIf="emailDomainFormControl.hasError('notAvailable')" class="text-danger">{{emailDomainFormControl.value}} {{ 'email.domainhasowned' | translate }}</p>
            <p *ngIf="emailDomainFormControl.hasError('unableToCheck')" class="text-danger">{{ 'error.domainunabletocheck' | translate }}</p>
            <p *ngIf="emailDomainFormControl.hasError('required')" class="text-danger">{{ 'error.domainrequired' | translate }}</p>
          </div>
          
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="changeOrReleaseDomainForm.invalid || changeOrReleaseDomainForm.pending || changeOrReleaseDomainBtnPressed"
       (click)="changeOrReleaseDomainPressed()">
       {{ 'button.update' | translate }} <span *ngIf="changeOrReleaseDomainBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark" (click)="modalRef.hide()">{{ 'button.close' | translate }}</button>
    </div>
  </ng-template>

  <ng-template #cancelOrderModal>
    <div class="modal-header">
      <div class="modal-title">{{ 'email.cancelorder' | translate }}</div>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center">
      <div class="py-4">{{ 'email.dialog.confirmcancelorder' | translate }}</div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" [disabled]="cancelOrderBtnPressed"
       (click)="cancelOrderConfirmationPressed()">
       {{ 'button.confirm' | translate }} <span *ngIf="cancelOrderBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
      </button>
      <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">{{ 'button.close' | translate }}</button>
    </div>
  </ng-template>

</main>
