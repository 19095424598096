export class EmailPlaceOrder {

    //rescode:string = '';
	productid:number = 0 ;
	emailDomain:string = '';
	licenses:number = 0;
	terms:number = 0;
	mailstorage:number = 0;
	sharedstorage:number = 0;
	personalstorage:number = 0;
	totalprices:number = 0.0;

	isOfflinePayment:boolean = false;
    
    constructor() {}
}