<main id="microsoftcsp-subscription-management" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants">{{ 'word.tenants' | translate }}</li>
    <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants/{{tenantId}}">{{ tenantId }}</li>
    <li class="breadcrumb-item active">{{ 'word.subscription' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row" *ngIf="errorCheck">
          <div class="col-12">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
              <ul>
                <li *ngFor="let error of errors">
                  {{ error }}
                </li>
              </ul>
              <button type="button" class="close" (click)="errorClosePressed()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="card">
                    <a routerLink="/microsoftcsp/tenants/{{tenantId}}/subscriptions/new" style="text-decoration: none;">
                      <div class="card-body text-center">
                        <i class="fa fa-plus"></i> {{ 'button.addnewsubscription' | translate }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-12">

                  <div class="d-flex flex-lg-row flex-md-row flex-column justify-content-lg-between
                  justify-content-md-between justify-content-center my-3" *ngIf="!tableLoading">
                    <select class="custom-select" style="width: auto" (change)="onPageSizeChanged($event.target.value)">
                      <option *ngFor="let size of pageSizeOptions" [value]="size">{{size}} {{this.entriesLang[this.lang]}}}</option>
                    </select>
                    <div class="row h-100 flex-lg-row flex-md-row flex-column mt-lg-0 mt-md-0 mt-2">
                      <div class="col-lg-4 col-md-4 col-12 my-auto text-lg-right text-md-right text-left">
                        <span>{{ 'button.search' | translate }} : </span>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12 pl-lg-0 pl-md-0">
                        <input class="form-control w-100" type="text" name="fullTextSearch"
                         (keyup)="onFullTextSearchKeyUp($event.target.value)"/>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th column="productName" searchable sortable (sort)="onSort($event)">{{ 'word.subscriptionname' | translate }}</th>
                          <th column="licenseCount" searchable sortable (sort)="onSort($event)">{{ 'word.licenses' | translate }}</th>
                          <th column="dtend" searchable sortable (sort)="onSort($event)" >{{ 'word.expirationdate' | translate }}</th>
                          <th column="statusName" searchable sortable (sort)="onSort($event)">{{ 'word.status' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <td colspan="5" style="text-align: center" [hidden]="!tableLoading">
                          {{ 'word.loading' | translate }}...
                        </td>
                        <td colspan="5" style="text-align: center"
                          *ngIf="!tableLoading && totalItems < 1">
                          {{ 'word.nodataavailable' | translate }}
                        </td>
                        <tr *ngFor="let subscription of filteredTenantSubscriptions" class="pointer-row"
                        (click)="goToSubscriptionDetails(subscription)">
                          <td>{{subscription.productName}}</td>
                          <td>{{subscription.licenseCount}} User(s) </td>
                          <td>{{subscription.dtend| date:'medium' }}</td>
                          <td>{{subscription.statusName}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex flex-lg-row flex-md-row flex-column justify-content-lg-between
                   justify-content-md-between justify-content-center align-items-center
                   mt-lg-2 mt-md-2 my-3" *ngIf="!tableLoading">
                    <div class="row h-100 mb-lg-0 mb-md-0 mb-2">
                      <div class="col-12">
                        <span>{{ 'word.showing' | translate }} {{ pagedItemsInfo.startIndex }}
                          {{ 'word.to' | translate }} {{ pagedItemsInfo.endIndex }}
                          {{ 'word.of' | translate }} {{ pagedItemsInfo.total }} {{ 'word.entry' | translate }}
                        </span>
                        <span *ngIf="pagedItemsInfo.searchFiltered">
                          ({{ 'word.filteredfrom' | translate }} {{tenantSubscriptions.length}} {{ 'word.totalentry' | translate }})
                        </span>
                      </div>
                    </div>

                    <ngb-pagination [collectionSize]="totalItems" [pageSize]="pageSize" [(page)]="page"
                      (pageChange)="onPageChanged($event)" [maxSize]="4" [rotate]="true">
                    </ngb-pagination>
                  </div>
                </div>
              </div>

              <div class="row justify-content-lg-end justify-content-center mt-4 mb-2">
                <div class="col-lg-2 col-md-4 col-6 text-center">
                  <button type="button" routerLink="/microsoftcsp/tenants/{{tenantId}}"
                    class="btn btn-dark btn-block">{{ 'button.back' | translate }}</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</main>
