
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { SharedModule } from '../shared.module';
import { EmailRoutingModule } from './email-routing.module';
import { EmailMasterTemplateComponent } from 'src/app/component/email-master-template/email-master-template.component';
import { EmailDashboardComponent } from 'src/app/component/email-dashboard/email-dashboard.component';
import { EmailSidebarComponent } from 'src/app/component/email-sidebar/email-sidebar.component';
import { EmailOrderManagementComponent } from 'src/app/component/email-order-management/email-order-management.component';
import { EmailOrderDetailComponent } from 'src/app/component/email-order-detail/email-order-detail.component';
import { EmailStepOneComponent } from 'src/app/component/email-step-one/email-step-one.component';
import { EmailApiService } from 'src/app/service/email-api.service';
import { EmailServiceModule } from './email-service.module';
import { EmailStepTwoComponent } from 'src/app/component/email-step-two/email-step-two.component';


@NgModule({
  declarations: [
    // declare all email related components here
    EmailMasterTemplateComponent,
    EmailSidebarComponent,
    EmailDashboardComponent,
    EmailOrderManagementComponent,
    EmailOrderDetailComponent,
    EmailStepOneComponent,
    EmailStepTwoComponent,
  ],
  imports: [
    // take note to import the Angular Core Modules for this child module to use angular core features 
    // eg. CommonModule, FormsModule,...
    CommonModule, // this import all angular common directives eg. *ngIf, *ngFor, ngClass,...
    EmailRoutingModule, // this routing module define the path of this child module's routing
    EmailServiceModule, // to import all services injected to this module
    FormsModule,
    ReactiveFormsModule, 
    SharedModule, // import all sharing module or components that exposed for reuse

    // import ngx-bootstrap modal, ( without .forRoot() )
    ModalModule,
    // import ngx-bootstrap tooltip
    TooltipModule.forRoot(),

    // import ngx-loading, consider moving to AppModules or SharedModule if other modules required to use
     NgxLoadingModule.forRoot({
       animationType: ngxLoadingAnimationTypes.threeBounce,
       backdropBackgroundColour: 'rgba(50,50,50,0.2)',
       primaryColour: '#D6EAF8',
       secondaryColour: '#EBF5FB',
       tertiaryColour: '#D6EAF8'
     }),

  ],
  providers: [
    EmailApiService,
  ]
})
export class EmailModule {
  // this module will be lazy-loaded as specified in app-routing.module.ts
}
