// tslint:disable: max-line-length
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guard/auth.guard';
import { O365MasterTemplateComponent } from 'src/app/component/o365-master-template/o365-master-template.component';
import { MicrosoftCSPTenantManagementComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/tenant-management/microsoftcsp-tenant-management.component';
import { MicrosoftCSPTenantCreateComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/tenant-create/microsoftcsp-tenant-create.component';
import { MicrosoftCSPTenantDetailComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/tenant-detail/microsoftcsp-tenant-detail.component';
import { MicrosoftCSPSubscriptionManagementComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-subscription/subscription-management/microsoftcsp-subscription-management.component';
import { MicrosoftCSPSubscriptionCreateComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-subscription/subscription-create/microsoftcsp-subscription-create.component';
import { MicrosoftCSPSubscriptionDetailComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-subscription/subscription-detail/microsoftcsp-subscription-detail.component';
import { MicrosoftCSPProductPricingComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-products/product-pricing/microsoftcsp-product-pricing/microsoftcsp-product-pricing.component';
import { MicrosoftCSPProductPromotionStepOneComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-products/product-promotion/microsoftcsp-product-promotion-step-one/microsoftcsp-product-promotion-step-one.component';
import { MicrosoftCSPProductPromotionStepTwoComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-products/product-promotion/microsoftcsp-product-promotion-step-two/microsoftcsp-product-promotion-step-two.component';
import { PartnerConnectGuidelinesComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/partner-connect-guidelines/partner-connect-guidelines.component';
import { MicrosoftCSPUserComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-user/microsoftcsp-user.component';
import { MicrosoftCSPUserNewComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-user/microsoftcsp-user-new/microsoftcsp-user-new.component';
import { MicrosoftCSPUserDetailsComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-user/microsoftcsp-user-details/microsoftcsp-user-details.component';
import { MicrosoftCSPDomainComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-domain/microsoftcsp-domain.component';
import { AdminConsentComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/admin-consent/admin-consent.component';
import { AdminConsentAuthComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/admin-consent-auth/admin-consent-auth.component';
import { MicrosoftCSPDomainNewComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-domain/microsoftcsp-domain-new/microsoftcsp-domain-new.component';

// all path defined here are prefix with /microsoftcsp, see app-routing.module.ts
const routes: Routes = [
  {
    // redirect back to o365 dashboard if no child route specified
    path: '', redirectTo: '/o365-dashboard', pathMatch: 'full'
  },
  {
    path: '',
    component: O365MasterTemplateComponent, // <--- always loaded with prefix /microsoftcsp/**
    children: [
      { path: 'tenants', component: MicrosoftCSPTenantManagementComponent, canActivate: [AuthGuard] },
      { path: 'tenants/new', component: MicrosoftCSPTenantCreateComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId', component: MicrosoftCSPTenantDetailComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId/subscriptions', component: MicrosoftCSPSubscriptionManagementComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId/subscriptions/new', component: MicrosoftCSPSubscriptionCreateComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId/subscriptions/:subscriptionId', component: MicrosoftCSPSubscriptionDetailComponent, canActivate: [AuthGuard] },
      { path: 'tenants/partner-connect/guidelines', component: PartnerConnectGuidelinesComponent, canActivate: [AuthGuard] },
      { path: 'tenants/adminconsent/auth', component: AdminConsentAuthComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId/adminconsent', component: AdminConsentComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId/users', component: MicrosoftCSPUserComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId/users/new', component: MicrosoftCSPUserNewComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId/users/:msUserId', component: MicrosoftCSPUserDetailsComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId/domains', component: MicrosoftCSPDomainComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId/domains/new', component: MicrosoftCSPDomainNewComponent, canActivate: [AuthGuard] },
      { path: 'tenants/:tenantId/domains/:domainName', component: MicrosoftCSPDomainNewComponent, canActivate: [AuthGuard] },
      { path: 'products/pricing', component: MicrosoftCSPProductPricingComponent, canActivate: [AuthGuard] },
      { path: 'products/promotion', component: MicrosoftCSPProductPromotionStepOneComponent, canActivate: [AuthGuard] },
      { path: 'products/promotion/:productId', component: MicrosoftCSPProductPromotionStepTwoComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path: '**', redirectTo: '/o365-dashboard' // 404 not found
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MicrosoftCSPRoutingModule { }
