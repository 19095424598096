
    <main class="main">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item active">{{ 'word.dashboard' | translate }}</li>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-sm-6 col-lg-3" (click)="orderPressed()" style="cursor: pointer">
                  <div class="card text-white bg-info">
                    <a  class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-basket-loaded"></i>
                        </div>
                        <div class="h4 mb-0">{{ info?.total }}</div>
                        <small class="text-muted text-uppercase font-weight-bold">{{ 'word.orders' | translate }}</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'word.totalorders' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->

                <div class="col-sm-6 col-lg-3">
                  <div class="card text-white bg-primary">
                    <a  class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-basket-loaded"></i>
                        </div>
                        <div class="h4 mb-0">{{ info?.expiring }}</div>
                        <small class="text-muted text-uppercase font-weight-bold">{{ 'word.orders' | translate }}</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'o365.expiringorders' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->

                <div class="col-sm-6 col-lg-3">
                  <div class="card text-white bg-danger">
                    <a class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-credit-card"></i>
                        </div>
                        <div class="h4 mb-0">{{ info?.expired }}</div>
                        <small class="text-muted text-uppercase font-weight-bold">{{ 'word.orders' | translate }}</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'o365.expiredorders' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->

                <div class="col-sm-6 col-lg-3" style="cursor: pointer" (click)="goToTenantManagement()">
                  <div class="card text-white bg-warning">
                    <a class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-wrench"></i>
                        </div>
                        <div class="h4 mb-0">{{ info?.tenants }}</div>
                        <small class="text-muted text-uppercase font-weight-bold">{{ 'word.tenants' | translate }}</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'o365.totaltenants' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->
              </div>
              <!--/.row-->

              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-5">
                      <h4 class="card-title mb-0">{{ 'word.salesperformance' | translate }}</h4>
                    </div>
                    <!--/.col-->

                    <div class="col-sm-7 d-none d-md-block">
                      <div class="float-right">
                        <p class="d-inline">{{ 'word.display' | translate }}&nbsp;</p>
                        <select #salesChartDays (change)="changeSalesChartDays(salesChartDays.value)" id="sales-chart-days" class="form-control d-inline" style="width:80px">
                          <option [value]="10">10</option>
                          <option [value]="50">50</option>
                          <option [value]="100">100</option>
                        </select>
                        <p class="d-inline">&nbsp;{{ 'word.daysrecord' | translate }}</p>
                      </div>
                    </div>
                    <!--/.col-->
                  </div>
                  <!--/.row-->
                  
                  <div class="chart-wrapper" style="height:300px;margin-top:40px;">
                    <canvas id="sales-chart" class="chart" height="300"></canvas>

                  </div>
                </div>
              </div>
              <!--/.card-->
              <!--/.row-->
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </main>