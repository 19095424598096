
    <main id="storefront" class="main create-storefront">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item">{{ 'word.clientmanagement' | translate }}</li>
        <li class="breadcrumb-item active">{{ 'word.editclient' | translate }}</li>
      </ol>
      <div class="alert alert-success" role="alert" style="margin-top: -25px" *ngIf='message!=""'>
        <button type="button" class="close" data-bs-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          {{message}}
        </div>


        <div class="container-fluid">
          <div class="animated fadeIn">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive mb-4">
                  <table class="table table-bordered mb-0">
                    <thead class="thead-light">
                      <tr>
                        <th colspan="2">{{ 'word.clientinfo' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr>
                        <td>{{ 'word.email' | translate }}</td>
                        <td>{{customer.user.email}}</td>
                      </tr>
                      <tr>
                        <td>{{ 'word.name' | translate }}</td>
                        <td>{{customer.first_name}} {{customer.last_name}}</td>
                      </tr>
                      <tr>
                        <td>{{ 'word.signupdate' | translate }}</td>
                        <td>{{customer.updated_at | date:'dd MMMM yyyy'}}</td>
                      </tr>
                      <tr>
                        <td>{{ 'word.accountstatus' | translate }}</td>
                        <td>
                          <div class="form-check">
                            <label class="form-check-label" for="sa_active">
                              <input type="radio" class="form-check-input" name="status" [value]="1" [(ngModel)]="customer.user.status_account">{{ 'word.active' | translate }}
                            </label>
                          </div>
                          <div class="form-check">
                            <label class="form-check-label" for="sa_suspended">
                              <input type="radio" class="form-check-input" name="status" [value]="0" [(ngModel)]="customer.user.status_account">
                              {{ 'word.suspended' | translate }}
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ 'word.emailverificationstatus' | translate }}</td>
                        <td>
                          
                          <div class="form-check">
                            <label class="form-check-label" for="ev_status_v">
                              <input type="radio" class="form-check-input" name="emailver" [value]="1" [(ngModel)]="customer.user.status_email">{{ 'word.verified' | translate }}
                            </label>
                          </div>
                          <div class="form-check">
                            <label class="form-check-label" for="ev_status_nf">
                              <input type="radio" class="form-check-input" name="emailver" [value]="0" [(ngModel)]="customer.user.status_email">
                              {{ 'word.pendingverification' | translate }}
                            </label>
                          </div>
                          <div class="form-check">
                            <label for="" class="form-check-label" *ngIf="customer.user.status_email == 0">
                              <em>
                                <button class="btn btn-sm btn-link p-0" (click)="sendVerifyEmail(customer)" [disabled]="sendVerifyEmailPressed">{{ 'word.resendverificationemail' | translate }}</button>
                              </em>
                              <span *ngIf="sendVerifyEmailPressedSpin">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                              <span *ngIf="sendVerifyEmailSuccess" style="color: green;">&nbsp;<i class="fa fa-check"></i> {{ 'word.sent' | translate }}</span>
                              <span *ngIf="sendVerifyEmailFailed" style="color: red;">&nbsp;<i class="fa fa-times"></i> {{ 'word.failed' | translate }}</span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ 'word.access' | translate }}</td>
                        <td>
                          <div class="form-check">
                            <label class="form-check-label" for="access_pp">
                              <input type="radio" class="form-check-input" name="access_level_id" [value]="1" [(ngModel)]="customer.user.access_level_id">
                              {{ 'word.purchaseprohibited' | translate }}
                            </label>
                          </div>
                          <div class="form-check">
                            <label class="form-check-label" for="access_mp">
                              <input type="radio" class="form-check-input" name="access_level_id" [value]="2" [(ngModel)]="customer.user.access_level_id">
                              {{ 'word.manageprohibited' | translate }}
                              </label>
                            </div>
                          <div class="form-check">
                            <label class="form-check-label" for="access_fa">
                              <input type="radio" class="form-check-input" name="access_level_id" [value]="3" [(ngModel)]="customer.user.access_level_id">
                              {{ 'word.fullaccess' | translate }}
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ 'word.storefront' | translate }}</td>
                        <td>{{customer.storefront.storefront_name}}</td>
                      </tr>
                      <tr>
                        <td>{{ 'word.action' | translate }}</td>
                        <td>
                          <button class="btn btn-primary" type="submit" [disabled]="payBtnPressed" (click)="submitPressed()">{{ 'button.submit' | translate }}
                          <!-- display loading page -->
                          <i *ngIf="payBtnPressed" class="fa fa-spinner fa-spin"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-12">
                <div class="float-right">
                  <a href="#" class="btn btn-dark btn-lg px-5 mb-4" (click)="backPressed()">{{ 'button.back' | translate }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->
      </main>
