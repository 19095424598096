import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { O365HttpService } from 'src/app/service/o365-http.service';
import Chart from '../../../assets/vendors/js/Chart.min.js';

declare var $: any;
declare var jQuery: any;
declare var appJS: any;

@Component({
  selector: 'app-o365-dashboard',
  templateUrl: './o365-dashboard.component.html',
  styleUrls: ['./o365-dashboard.component.css']
})
export class O365DashboardComponent implements OnInit {

  mainChart;
  info?;
  barChartData = {
    'labels': null,
    'datasets': null
  };

  constructor(
    private router: Router,
    private httpService: O365HttpService
  ) { }

  ngOnInit() {
    this.httpService.getDashboardData().subscribe(data => {
      this.info = data['data'];
    });

    this.getSalesChartData(10);
  }

  orderPressed(filter = null) {
    this.router.navigate(['/o365-order-management'], { queryParams: { "filter": filter } });
  }

  goToTenantManagement() {
    this.router.navigate(['/microsoftcsp/tenants']);
  }

  changeSalesChartDays(days) {
    this.getSalesChartData(days);
  }

  getSalesChartData(days) {
    this.httpService.getSalesChartData(days).subscribe(data => {
      this.renderSalesChart(data);
    }, err => {
      console.error(err);
    });
  }

  renderSalesChart(result) {
    if (this.mainChart) {
      this.mainChart.destroy();
    }

    this.barChartData = {
      'labels': result.labels,
      'datasets': result.datasets
    };

    let ctx = $('#sales-chart');

    this.mainChart = new Chart(ctx, {
      type: 'bar',
      data: this.barChartData,

      options: {
        title: {
          display: false,
          text: 'Sales Performance Chart'
        },
        tooltips: {
          intersect: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                // when the floored value is the same as the value we have a whole number
                if (Math.floor(label) === label) {
                  return label;
                }
              },
            }
          }]
        }
      }
    })
  }

}
