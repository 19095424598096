import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { Observable, timer } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl = environment.apiUrl;
  data:any = {};
  jwtHelperService: JwtHelperService = new JwtHelperService();

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  ssoLogin(data) {
    return this.http.post(this.apiUrl + 'sso-login', data).pipe(
      tap(response => {
        this.data = data;

        localStorage.setItem('access_token', response['access_token']);
        this.showExpiring(response['access_token'], data.returnUrl);

        let returnUrl = this.data.returnUrl;
        if(returnUrl && returnUrl != null){
          this.router.navigateByUrl(returnUrl);
        }else{
          this.router.navigate(["/o365-dashboard"]);
        }
      })
    );
  }

  postLogin(data) {
    return this.http.post(this.apiUrl + 'reslogin', data).pipe(
      tap(response => {
        this.data = data;

        localStorage.setItem('access_token', response['access_token']);
        this.showExpiring(response['access_token']);

         //window.location.href = "/o365-dashboard";
        let returnUrl = this.data.returnUrl;

        if(returnUrl && returnUrl != null){
          this.router.navigateByUrl(returnUrl);
        }else{
          this.router.navigate(["/o365-dashboard"]);
        }
      })
    );
  }

  refreshSession(data:any) {
    return this.http.post(this.apiUrl + 'refresh-token', data).pipe(
      tap((response: any) => {
        this.data = data;

        localStorage.setItem('access_token', response['access_token']);
        this.showExpiring(response['access_token']);

        let returnUrl = this.data.returnUrl;
        console.log(returnUrl);

        if (returnUrl && returnUrl != null) {
          this.router.navigateByUrl(returnUrl);
        } else {
          this.router.navigate(["/o365-dashboard"]);
        }
      })
    );
  }

  showExpired(returnUrl?:string) {
    Swal.fire({
      icon: 'error',
      title: 'Session Expired!',
      text: 'You have been automatically logged out.',
      allowOutsideClick: false
    }).then((result) => {
      //window.location.href = "/login";

      if(returnUrl){
        this.router.navigate(['/login'], { queryParams: { returnUrl: decodeURI(returnUrl) } });
      }else{
        this.router.navigate(['/login']);
      }
    });
  }

  showExpiring(access_token:any, returnUrl?:string) {

    if (!access_token || access_token === "") {
      console.error('No session token provided.');
      this.clearAllLocalStorage();
      this.showExpired(returnUrl);
      return;
    }

    // if (this.jwtHelperService.isTokenExpired(access_token)) {
    //   console.error('Session token expired.');
    //   this.clearAllLocalStorage();
    //   this.router.navigate(["/login"]);
    //   return;
    // }

    const date: Date = this.jwtHelperService.getTokenExpirationDate(access_token);
    let now = new Date().valueOf();
    let expiresAt = date;
    let timerEnds = expiresAt.valueOf() - ( 1000 * 60 * 1 );
    let distance = timerEnds - now;

    if (distance <= 0) {
      console.error('Session token expired.');
      this.clearAllLocalStorage();
      this.showExpired(returnUrl);
      return;
    }

    let timerEndsInMinutes = Math.floor(Math.abs(distance) / 60000);
    console.log('timerEndsInMinutes: ' + timerEndsInMinutes);

    timer(timerEndsInMinutes * 60000).subscribe(value => {
      let timerInterval;

      Swal.fire({
        title: 'Session Expiring!',
        html: 'You will be automatically logged out in <strong></strong> seconds.<br/><br/>' +
          '<button id="refresh" class="btn btn-primary btn-lg">' +
          'Keep me logged in!' +
          '</button><br/>',
        timer: 60000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton:false,

        willOpen: () => {
          const content = Swal.getHtmlContainer();
          const $ = content.querySelector.bind(content);
          const refresh = $("#refresh");

          Swal.showLoading();

          refresh.addEventListener('click', () => {
            clearInterval(timerInterval);
            Swal.stopTimer();
            Swal.getHtmlContainer().textContent = "Refreshing session... Please wait.";

            refresh.disabled = true;

            // set current url as returnUrl to redirect back after login
            this.data.returnUrl = this.router.url;

            // set access token that expiring
            this.data.accessToken = localStorage.getItem('access_token');

            this.refreshSession(this.data).subscribe(result => {
              Swal.close();
            }, error => {
              Swal.getHtmlContainer().textContent = "Failed to refresh session. Logging you out...";

              setTimeout(() => {
                this.clearAllLocalStorage();
                this.router.navigate(["/login"]);
                Swal.close();
              }, 3000);
            });

          });

          timerInterval = setInterval(() => {
            if(Swal.getHtmlContainer().querySelector('strong') && Swal.getHtmlContainer().querySelector('strong').textContent != null){
              Swal.getHtmlContainer().querySelector('strong').textContent = Math.ceil(Swal.getTimerLeft() / 1000).toString()
            }
          }, 100)
        },

        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.timer
        ) {
          localStorage.removeItem('access_token');
          this.showExpired();
        }

        clearInterval(timerInterval);
      });
    });
  }

  showExpiringSso(access_token) {

    if (!access_token || access_token === "") {
      console.log('No expiry session timer.');
      this.clearAllLocalStorage();
      this.router.navigate(["/login"]);
      return;
    }

    if (this.jwtHelperService.isTokenExpired(access_token)) {
      console.log('Session expired.');
      this.clearAllLocalStorage();
      this.router.navigate(["/login"]);
      return;
    }

    const date: Date = this.jwtHelperService.getTokenExpirationDate(access_token);
    let now = new Date().valueOf();
    let expiresAt = date;
    let timerEnds = expiresAt.valueOf() - ( 1000 * 60 * 1 );
    let distance = timerEnds - now;

    if(distance <= 0) {
      console.log('Session expired.');
      this.clearAllLocalStorage();
      this.router.navigate(["/login"]);
      return;
    }

    let timerEndsInMinutes = Math.floor(Math.abs(distance) / 60000);
    console.log('timerEndsInMinutes: '+timerEndsInMinutes);

    timer(timerEndsInMinutes * 60000).subscribe(value => {
      let timerInterval;

      Swal.fire({
        title: 'Session Expiring!',
        html: 'You will be automatically logged out in <strong></strong> seconds.',
        timer: 60000,
        allowOutsideClick: false,
        allowEscapeKey: false,
        
        willOpen: () => {
          Swal.showLoading();

          timerInterval = setInterval(() => {
            if(Swal.getHtmlContainer().querySelector('strong').textContent != null){
              Swal.getHtmlContainer().querySelector('strong').textContent = Math.ceil(Swal.getTimerLeft() / 1000).toString()
            }
          }, 100)
        },

        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.timer
        ) {
          localStorage.removeItem('access_token');
          this.showExpired();
        }
      });
    });
  }

  checkAuth(access_token) {
    return this.http.post(this.apiUrl + 'verify-token-db', {
      "accessToken": access_token
    })
  }

  checkHcaptchaToken(token:string):Observable<any> {
    return this.http.post(this.apiUrl + 'verify-hcaptcha-token', {
      "token": token
    });
  }

  clearAllLocalStorage(){
    localStorage.clear();
  }
}
