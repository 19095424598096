import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	apiUrl = environment.apiUrl;

	httpHeaders = {
		headers : new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
	}

	constructor(private http: HttpClient) { }

	getAllNotification(){
		console.log(this.httpHeaders)
		return this.http.get(this.apiUrl+'notification/get-all-sf-notifications-by-reseller', this.httpHeaders)
	}

	getNotificationById(id){
		return this.http.get(this.apiUrl+'notification/get-sf-notification-by-id/'+id, this.httpHeaders)
	}

	createNotification(data){
    console.log(data)
		return this.http.post(this.apiUrl+'notification/create-sf-notification',data, this.httpHeaders)
	}

	editNotification(data){
		return this.http.post(this.apiUrl+'notification/update-sf-notification',data, this.httpHeaders)	
	}
}
