import { Component, OnInit, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { O365HttpService } from 'src/app/service/o365-http.service';
import { SortDirection, SortableTableHeader, SortEvent } from 'src/app/directive/sortable.directive';
import { SearchableTableHeader } from 'src/app/directive/searchable.directive';
import { DatatableUtil } from 'src/app/util/datatable-util';

@Component({
  selector: 'microsoftcsp-tenant-management',
  templateUrl: './microsoftcsp-tenant-management.component.html',
  styleUrls: ['./microsoftcsp-tenant-management.component.css']
})
export class MicrosoftCSPTenantManagementComponent implements OnInit {

  tableLoading: boolean = true;

  tenants: any[] = [];
  filteredTenants: any[] = [];

  pageSizeOptions:number[] = [10,20,30,50];
  pageSize:number;
  page:number;
  totalItems:number;

  pagedItemsInfo = { startIndex: 0, endIndex: 0, total: 0, searchFiltered: false };

  fullTextSearchDebounceTimer:any;
  fullTextSearchInput:string = null; 

  sortColumn: string = '';
  sortDirection: SortDirection = '';

  @ViewChildren(SortableTableHeader) sortableHeaders: QueryList<SortableTableHeader>;
  @ViewChildren(SearchableTableHeader) searchableHeaders: QueryList<SearchableTableHeader>;

  errorCheck:boolean = false;
  errors: any[] = [];

  constructor(
    private o365ApiService: O365HttpService,
    private chRef: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.totalItems = 0;
    this.page = 1;
    this.pageSize = this.pageSizeOptions[0];

    this.getTenants();
  }

  getTenants(){
    this.o365ApiService.getTenants().subscribe((res:any)=>{
      this.tenants = res.data;
      // by default sort by tenantId desc
      this.tenants.sort((a, b) => (a.tenantId > b.tenantId ? -1 : 1));
      
      this.filtering();
      this.tableLoading = false;
    },err=>{
      console.error(err);
      this.handleError(err);
    })
  }

  filtering(){
    this.page = Number(this.page);
    this.pageSize = Number(this.pageSize);

    // if current page greater than calculated total pages then reset page to 1
    if(this.page > Math.ceil(this.tenants.length/this.pageSize)){
      this.page = 1;
    }

    // always copy a new dataset from master data sources
    this.filteredTenants.length = 0;
    this.filteredTenants = [...this.tenants];
    this.totalItems = this.tenants.length;

    // sorting
    this.filteredTenants = DatatableUtil.sort(this.filteredTenants,this.sortColumn,this.sortDirection);

    // full text filtering 
    let searchText = this.fullTextSearchInput;
    
    this.pagedItemsInfo.searchFiltered = false;
    if(searchText && searchText != null && searchText != ''){
      // get searchableHeaders columns
      let columns = DatatableUtil.getFullTextSearchableColumns(this.searchableHeaders);
      // 
      this.filteredTenants = [...this.filteredTenants.filter(item => {
        return DatatableUtil.filterMatching(item,searchText,columns);
      })];
      // update total items count using filtered dataset
      this.totalItems = this.filteredTenants.length;
      this.pagedItemsInfo.searchFiltered = true;
    }
   
    // pagination by slicing array items
    let offset: number = (this.page - 1) * this.pageSize;
    let end = offset + this.pageSize;
    this.filteredTenants = this.filteredTenants.slice(offset,end);

    // update pagedItemInfo
    if(this.totalItems < 1){
      this.pagedItemsInfo = { startIndex: 0, endIndex: 0, total: 0, searchFiltered: false };
      return;
    }

    this.pagedItemsInfo.total = this.totalItems;
    this.pagedItemsInfo.startIndex = offset != 0 ? offset + 1 : 1;
    this.pagedItemsInfo.endIndex = end < this.totalItems ? end : this.totalItems;
  }

  onFullTextSearchKeyUp(value: string) {
    clearTimeout(this.fullTextSearchDebounceTimer);

    this.fullTextSearchDebounceTimer = setTimeout(() => {
      this.fullTextSearchInput = value;
      this.filtering();
    }, 1000);
  }

  onPageChanged(page:number){
    this.filtering();
  }

  onPageSizeChanged(size:number){
    this.pageSize = size;
    this.page = 1; // always reset page to 1
    this.filtering();
  }

  onSort({column, direction}: SortEvent){
    // resetting other headers
    this.sortableHeaders.forEach(header => {
      if (header.column !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;
    this.filtering();
  }

  goToTenantDetails(tenant:any){
    this.router.navigate(['/microsoftcsp/tenants', tenant.tenantId]);
  }

  handleError(err:any){
    if(err.error && err.error.message){
      this.errors.push(err.error.message);
    }
    this.errorCheck = true;
  }

  closePressed(){
    this.errors.length = 0;
    this.errorCheck = false;
  }

}
