import { Component, OnInit } from '@angular/core';
import Chart from '../../../assets/vendors/js/Chart.min.js';
import { DashboardService } from '../../service/dashboard.service';
import { Router } from '@angular/router';

declare var $: any;
declare var jQuery: any;
declare var appJS: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  mainChart;
  info?;
  barChartData = {
    'labels': null,
    'datasets': null
  };
  

  constructor(
    private router:Router,
    public dashboardService: DashboardService
  ) { }

  ngOnInit() {
    // appJS.init();

    this.dashboardService.getDashboardInfo().subscribe(data => {
      this.info = data['data'];
    }, err => {
    });

    this.getSalesChartData(10);

    
  }

  changeSalesChartDays(days) {
    this.getSalesChartData(days);
  }

  getSalesChartData(days) {
    this.dashboardService.getSalesChartData(days).subscribe(data => {
      this.renderSalesChart(data);
    }, err => {
    });
  }

  renderSalesChart(result) {
    if(this.mainChart) {
      this.mainChart.destroy();
    }

    this.barChartData = {
      'labels': result.labels,
      'datasets': result.datasets
    };

    let ctx = $('#sales-chart');

    this.mainChart = new Chart(ctx, {
      type: 'bar',
      data: this.barChartData,

      options: {
        title: {
          display: false,
          text: 'Sales Performance Chart'
        },
        tooltips: {
          intersect: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              userCallback: function(label, index, labels) {
                // when the floored value is the same as the value we have a whole number
                if (Math.floor(label) === label) {
                    return label;
                }
              },
            }
          }]
        }
      }
    })
  }

  orderPressed() {
    this.router.navigateByUrl('/orders');
  }

  clientPressed() {
    this.router.navigateByUrl('/clients');
  }
}




