import { Component, OnInit, Renderer2 } from '@angular/core';
import { StoreFrontService } from '../../service/store-front.service';
import { StoreService } from '../../service/store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';

declare var $: any;
@Component({
  selector: 'app-storefront-create-six',
  templateUrl: './storefront-create-six.component.html',
  styleUrls: ['./storefront-create-six.component.css']
})
export class StorefrontCreateSixComponent implements OnInit {

  paymentMethodForm: UntypedFormGroup;
  pickedPaymentMethods: any[] = [];

  payments;
  edited = false;
  storefrontId;
  userSelectedPayments = [];

  constructor(
    public storeFrontService: StoreFrontService,
    public router: Router,
    public storeService: StoreService,
    public route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.storeFrontService.getAllPaymentMethods().subscribe(data => {
      this.payments = data['data'];
      this.createPaymentMethodForm();

      if (this.route.snapshot.paramMap.get('id')) {
        this.storefrontId = this.route.snapshot.paramMap.get('id');
        this.edited = true;

        this.storeService.getStorefront(this.route.snapshot.paramMap.get('id')).subscribe(Response => {
          console.log('Storefront To Update Details : ', Response['data'])
          this.userSelectedPayments = Response['data']['payments']
          this.userSelectedPayments.forEach(payment => {
            const payment_id = payment['id'];
            this.paymentMethodFormGroup.controls[payment_id].patchValue(true);

            const inputTag = document.getElementById('payment' + payment_id);
            const labelTag = this.getParentNodeByName(inputTag, 'label');
            this.renderer.addClass(labelTag, 'image-checkbox-checked');

            this.paymentMethodFormGroup.controls[payment_id].markAsTouched();
          });
        });
      }
    });

    $('#close').click(function () {
      $('.alert').hide();
    }); 
  }

  nextPressed() {
    this.pickedPaymentMethods = this.paymentMethodFormGroupSelectedIds;

    // TODO fix paymentForm to use .valid or .invalid
    if (this.pickedPaymentMethods.length > 0) {

      this.storeService.setupPaymentMethods(this.pickedPaymentMethods);

      if (this.edited) {
        this.router.navigate(['/manage-storefront-7/' + this.storefrontId]);
      } else {
        this.router.navigate(['/create-storefront-7']);
      }
    } else {
      $(".alert").show();
    }
  }

  get f() { return this.paymentMethodForm && this.paymentMethodForm.controls; }
  get paymentMethodFormGroup(): UntypedFormGroup { return this.f && <UntypedFormGroup>this.f.paymentMethodFormGroup; }

  get paymentMethodFormGroupSelectedIds(): string[] {
    let ids: string[] = [];
    for (let key in this.paymentMethodFormGroup.controls) {
      if (this.paymentMethodFormGroup.controls[key].value) {
        ids.push(key);
      }
    }
    return ids;
  }

  createPaymentMethodForm() {
    this.paymentMethodForm = this.formBuilder.group({

    });
    this.paymentMethodForm.addControl('paymentMethodFormGroup', this.buildPaymentMethodFormGroup(this.payments));
  }

  buildPaymentMethodFormGroup(payments: any[], selectedPaymentId: string[] = []): UntypedFormGroup {
    let group = this.formBuilder.group({}, {
      validators: (formGroup: UntypedFormGroup) => {
        let minRequired = 1;
        let checked = 0;

        Object.keys(formGroup.controls).forEach(key => {
          const control = formGroup.controls[key];

          if (control.value === true) {
            checked++;
          }
        });

        if (checked < minRequired) {
          return {
            requireCheckboxToBeChecked: true,
          };
        }

        return null;
      }
    });
    payments.forEach(payment => {
      const isSelected = selectedPaymentId.some(id => id === payment.id);
      group.addControl(payment.id, this.formBuilder.control(isSelected));
    });
    return group;
  }

  onClickCheckbox(event: any) {
    const target = event.currentTarget;
    const labelTag = this.getParentNodeByName(target, 'label');

    if (event.target.checked) {
      this.renderer.addClass(labelTag, 'image-checkbox-checked');
    } else {
      this.renderer.removeClass(labelTag, 'image-checkbox-checked');
    }
  }

  getParentNodeByName(target, nodeName) {
    let actualNode = target.parentElement;
    while (actualNode.nodeName !== nodeName.toUpperCase()) {
      actualNode = actualNode.parentElement;
    }
    return actualNode;
  }
}
