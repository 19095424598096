<main id="microsoftcsp-product-pricing" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'word.products' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.pricing' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row">

        <ng-template #loadingSpinner>
          <div class="col-12 text-center">
            <div class="card card-body" style="min-height: 400px;">
              <div class="lds-roller" style="margin: auto">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="col-12" *ngIf="!loading; else loadingSpinner">
          <div class="card">
            <div class="card-body">

              <div class="row">

                <form #microsoftCSPForm="ngForm" style="width: 100%;">

                  <div class="col-12 mb-4">
                    <span style="font-weight: bolder; font-size: large;">Microsoft CSP</span>
                  </div>

                  <div class="col-12" *ngFor="let product of o365Products">
                    <div class="card card-body mb-2" ngModelGroup="productPrice_{{product.product_id}}" #productPrices="ngModelGroup">
                      <div class="row h-100 align-items-center">

                        <div class="col-lg-6 col-md-12 col-12 mb-lg-0 mb-2">
                          <div>
                            {{product.product_name}}
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-12">
                          <div class="row">
                            <div class="col-12">
                              <div class="horizontal-scrollable">
                                <div class="row-item">
                                  <div class="col-item col-item-title"></div>
                                  <div class="col-item">{{ 'word.monthly' | translate }}</div>
                                  <div class="col-item">{{ 'word.annual' | translate }}</div>
                                </div>
                                <div class="row-item">
                                  <div class="col-item col-item-title">{{ 'word.cost' | translate }}
                                    <i class="fa fa-info-circle text-primary cursor-pointer" placement="auto" triggers="click:blur hover focus"
                                    ngbTooltip="{{ 'o365.productpricedesc' | translate }}"></i>
                                  </div>
                                  <div class="col-item">
                                    <input [value]="product.resellerPrice.monthly_price" class="form-control" type="text"
                                      [disabled]="true" readonly>
                                  </div>
                                  <div class="col-item">
                                    <input [value]="product.resellerPrice.yearly_price" class="form-control" type="text"
                                      [disabled]="true" readonly>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </form>

              </div>

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</main>
