import {Component,OnInit,Inject} from '@angular/core';
import {StoreFrontService} from '../../service/store-front.service';
import {StoreService} from '../../service/store.service';
import {Router,ActivatedRoute} from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { smoothCollapse } from 'src/app/shared/animations';
import { StorefrontEmailApiService } from 'src/app/service/storefront-email-api.service';
import { ScrollUtil } from 'src/app/util/scroll-util';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';
import { TranslateUtil } from 'src/app/util/translate-util';

declare var $: any;

const categories = [
  {id:'1', name:'o365'},
  {id:'2', name:'alibabaMail'}
]

@Component({
  selector: 'app-storefront-create-five',
  templateUrl: './storefront-create-five.component.html',
  styleUrls: ['./storefront-create-five.component.css'],
  animations: [
    smoothCollapse
  ]
})
export class StorefrontCreateFiveComponent implements OnInit {

  constructor(
    public storefrontService: StoreFrontService,
    public storeService: StoreService,
    public router: Router,
    public route: ActivatedRoute,
    private storefrontEmailApiService: StorefrontEmailApiService
  ) {}

  storefrontId:number = null;
  isManageStorefront: boolean = false;
  selectedCategoryIds: any[] = [];

  enableO365Setup:boolean = false;
  o365Loading:boolean = false;
  isO365Collapsed: boolean = false;
  o365Products: any[] = [];

  enableAlibabaMailSetup:boolean = false;
  alibabaMailLoading:boolean = false;
  isAlibabaMailCollapsed: boolean = false;
  alibabaMailProducts: any[] = [];

  showError: boolean = false;
  errorMessages: any [] = [];

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.storefrontId = +params['id'] || null;

      if(this.storefrontId){
        this.isManageStorefront = true;
      }

      this.loadSelectedProductCategories();
    });
  }

  getCategoryIdByName(name:string){
    // used in this component only, 'o365' or 'alibabaMail' only
    let category = categories.find(c => c.name == name);
    if(!category){
      return '';
    }
    return category.id;
  }

  loadSelectedProductCategories() {
    this.selectedCategoryIds = this.storeService.getSelectedCategoryIds();

    // enable o365 products setup 
    if (this.selectedCategoryIds.includes(this.getCategoryIdByName('o365'))) {
      this.enableO365Setup = true;

      this.loadO365Products().then(() => {
        this.o365Loading = false;
      });
    }

    // enable alibabaMail product setup
    if (this.selectedCategoryIds.includes(this.getCategoryIdByName('alibabaMail'))) {
      this.enableAlibabaMailSetup = true;

      this.loadAlibabaMailProducts().then(() => {
        this.alibabaMailLoading = false;
      });
    }

  }

  async loadO365Products() {
    this.o365Loading = true;

    await this.storefrontService.getO365ProductListForResellerPricing().toPromise().then((res: any) => {
      // will add extra 'selected' boolean property to each items
      this.o365Products = this.mapToSelectableItems(res.data);
    }).catch(err=>{
      console.error(err);
    });

    if (this.isManageStorefront) {
      await this.storefrontService.getO365ProductListForStorePricing(this.storefrontId).toPromise()
        .then((res: any) => {
          let configuredProductItems = res.data;
          this.updateConfiguredProductInfoToList('o365', configuredProductItems);
        }).catch(err => {
          console.error(err);
        });
    }

    // ****** IMPROTANT ********
    // custom settings
    // for product id of 1,2,3 must alway selected
    // if not the some pages of storefront can't work properly
    // eg. guided tour page, main o365 page.. (were hardcoded to depend these products)
    let productIdsMustSelected:any[] = [1,2,3];

    this.o365Products.forEach(p=>{
      if(productIdsMustSelected.includes(p.product_id)){
        p.selected = true;
        p.selectDisabled = true;
      }
    });

  }

  async loadAlibabaMailProducts() {
    this.alibabaMailLoading = true;

    await this.storefrontEmailApiService.getAllResellerProductPlans().toPromise().then((res: any) => {
      // will add extra 'selected' boolean property to each items
      this.alibabaMailProducts = this.mapToSelectableItems(res.productPlans);
      this.alibabaMailProducts = this.mapRetailPriceToList('alibabaMail', this.alibabaMailProducts);
    }).catch(err=>{
      console.error(err);
    });

    if (this.isManageStorefront) {
      await this.storefrontEmailApiService.getActiveSfProductPlans(this.storefrontId).toPromise()
        .then((res: any) => {
          let configuredProductItems = res.productPlans;
          this.updateConfiguredProductInfoToList('alibabaMail', configuredProductItems);
        }).catch(err => {
          console.error(err);
        });
    }
  }

  updateConfiguredProductInfoToList(productCategoryName:string, configuredSfProductItems: any[]){
    // do nothing if configuredSfProductItems is undefined or null
    if(configuredSfProductItems == undefined || configuredSfProductItems == null ){
        return;
    }

    switch(productCategoryName){
      case 'o365': {
        let modifiedO365Products: any[] = [...this.o365Products].map(p => {

          // always set reseller cost as base price
          let retailPrice: any = {
            monthly_price: p.product_price.monthly,
            yearly_price: p.product_price.annually
          };
          // assign 'retailPrice' property to product
          p.retailPrice = retailPrice;

          // then find the actual configured retail price
          let item = configuredSfProductItems.find(item => item.product_id == p.product_id);
          
          // if found then update the configured retail price
          if (item) {
            // override it
            p.retailPrice.monthly_price = item.monthly_price;
            p.retailPrice.yearly_price = item.yearly_price;
  
            // if product configured means the product is enabled
            p.selected = true;

            // currently backend cant support disable product so if product enabled already 
            // then need to disallow 
            p.selectDisabled = true;
          }
          return p;
        });

        // update the o365Products 
        this.o365Products.length = 0;
        this.o365Products = modifiedO365Products;

        break;
      }
      case 'alibabaMail': {
        let modifiedAlibabaMailProducts: any[] = [...this.alibabaMailProducts].map(p => {

          // always set reseller cost as base price
          p.retailPrice = p.priceInfo; // array of priceTerms object

          let item = configuredSfProductItems.find(item => item.productid == p.productid);
          
          // if found then update the configured retail price
          if (item) {
            // override it
            p.retailPrice = item.priceInfo;
            // if product configured means the product is enabled
            p.selected = true;

             // currently backend cant support disable product so if product enabled already 
            // then need to disallow 
            p.selectDisabled = true;
          }
          return p;
        });
        // update it
        this.alibabaMailProducts.length = 0;
        this.alibabaMailProducts = modifiedAlibabaMailProducts;
        break;
      }
      default: break;
    }
  }

  mapToSelectableItems(items:any[]): any[] {
    if(!items){
      return [];
    }

    return items.map((item:any)=>{
      let selectableItem: any = {...item};
      selectableItem.selected = false;
      selectableItem.selectDisabled = false;
      return selectableItem;
    });
  }

  mapRetailPriceToList(productCategoryName: string, products: any[]) {
    if (!products) {
        return [];
    }
    return products.map((product: any) => {
        let mappedProduct: any = { ...product };

        switch (productCategoryName) {
            case 'o365': {
                let retailPrice = {
                    monthly_price: product.monthly_price,
                    yearly_price: product.yearly_price,
                }
                mappedProduct.retailPrice = retailPrice;
                break;
            }
            case 'alibabaMail': {
                mappedProduct.retailPrice = product.priceInfo;
                break;
            }
            default: break;
        }

        return mappedProduct;
    });
}

  selectAll(event:any,productCategory:string){
    let checked = event.target.checked;

    if(productCategory == 'o365'){
      this.o365Products.forEach(p=>{
        if(p.selectDisabled == false){
          if(checked){
            p.selected = true;
          }else{
            p.selected = false;
          }
        }
      });
    }else if(productCategory == 'alibabaMail'){
      this.alibabaMailProducts.forEach(p=>{
        if(p.selectDisabled == false){
          if(checked){
            p.selected = true;
          }else{
            p.selected = false;
          }
        }
      });
    }

  }

  nextPressed() {
    this.errorMessages.length = 0;

    let selectedO365products:any[] = [];
    let selectedAlibabaMailproducts:any[] = []; 

    if(this.enableO365Setup){
      selectedO365products = [...this.o365Products].filter(p=>p.selected == true);
      if(selectedO365products.length < 1){
        this.errorMessages.push('Please select at least 1 Microsoft 365 product.')
      }
    }

    if(this.enableAlibabaMailSetup){
      selectedAlibabaMailproducts = [...this.alibabaMailProducts].filter(p=>p.selected == true);
      if(selectedAlibabaMailproducts.length < 1){
        this.errorMessages.push('Please select at least 1 AlibabaMail product.')
      }
    }

    if(this.errorMessages.length > 0){
      this.showError = true;
      ScrollUtil.scrollToTop();
      return;
    }

    this.storeService.saveSelectedProducts(selectedO365products, selectedAlibabaMailproducts);

    console.log(this.storeService.getAllSelectedProducts());
    

    if (this.isManageStorefront) {
      this.router.navigate(['/manage-storefront-6/' + this.storefrontId]);
    } else {
      this.router.navigate(['/create-storefront-6']);
    }
  }

  errorClosePressed(){
    this.errorMessages.length = 0;
    this.showError = false;
  }

  back(){
    if (this.isManageStorefront) {
      this.router.navigate(['/manage-storefront-4/' + this.storefrontId]);
    } else {
      this.router.navigate(['/create-storefront-4']);
    }
  }

}
