<div class="row">
  <div class="col-12">
    <h6>{{ 'o365.optionalsetting' | translate }}</h6>
    <p>{{ 'o365.choosewhatrole' | translate }}</p>
    <form [formGroup]="newUserOptionalForm" style="overflow-y: visible">
      <div>
        <div type="button" class="btn d-flex align-items-center" (click)="isRoleCollapsed = !isRoleCollapsed">
          <span>{{ 'o365.role.noadminaccess' | translate }}</span>
          <i class="px-2" style="font-size: 20px;" [ngClass]="isRoleCollapsed ? 'fa fa-caret-down': 'fa fa-caret-up'"></i>
        </div>

        <div class="collapse" [@smoothCollapse]="isRoleCollapsed?'hide':'show'">
          <div class="card" style="border: none !important;">
            <div class="card-body">
              <input type="radio" name="adminRole" id="adminRole" [value]="true" [checked]="true">
              <label for="adminRole">
                <span class="ml-2">{{ 'o365.user.noadminaccess' | translate }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- <div>
        <div class="btn d-flex align-items-center" (click)="isProfileCollapsed = !isProfileCollapsed">
          <span>Profile Info</span>
          <i class="px-2" style="font-size: 20px;" [ngClass]="isProfileCollapsed ? 'fa fa-caret-down': 'fa fa-caret-up'"></i>
        </div>

        <div class="collapse" [@smoothCollapse]="isProfileCollapsed?'hide':'show'">
          <div class="card">
            <div class="card-body">

            </div>
          </div>
        </div>
      </div> -->
    </form>

    <button type="button" class="btn btn-secondary m-3" (click)="onBackStep()">
      {{ 'button.back' | translate }}
    </button>

    <button type="button" class="btn btn-primary m-3" (click)="onCompleteStep()" [disabled]="newUserOptionalForm.invalid">
      {{ 'button.next' | translate }}
    </button>
  </div>
</div>
