import { Component, OnInit } from '@angular/core';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';
import { TranslateUtil } from 'src/app/util/translate-util';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

@Component({
  selector: 'app-partner-connect-guidelines',
  templateUrl: './partner-connect-guidelines.component.html',
  styleUrls: ['./partner-connect-guidelines.component.css']
})
export class PartnerConnectGuidelinesComponent implements OnInit {

  webnicUrl: string = 'https://www.webnic.cc';
  microsoftAsiaPacificMarketRegionUrl: string = 'https://docs.microsoft.com/en-us/partner-center/regional-authorization-overview#asia-and-pacific-region-and-market';

  constructor() { }

  ngOnInit() {
  }

  openWebNICUrl(event: any) {
    event.preventDefault();
    window.open(this.webnicUrl, '_blank');
  }

  openMicrosoftAsiaPacificMarketRegionUrl(event: any) {
    event.preventDefault();
    window.open(this.microsoftAsiaPacificMarketRegionUrl, '_blank');
  }

}
