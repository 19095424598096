import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { O365OrderService } from 'src/app/service/o365-order.service';
import { O365HttpService } from 'src/app/service/o365-http.service';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';
@Component({
  selector: 'app-o365-step-three',
  templateUrl: './o365-step-three.component.html',
  styleUrls: ['./o365-step-three.component.css']
})
export class O365StepThreeComponent implements OnInit {

  validDuration = [1, 12];
  errorMessages = [];
  duration: number = 0;
  quantity: number;
  lang: string = 'eng';
  optionsDuration = [
    {
      id: 1,
      value: 1,
      name: {
        eng: '1 month',
        cn: '一个月',
        zh: '一個月'}
    },
    {
      id: 2,
      value: 12,
      name: {
        eng: '12 months',
        cn: '十二个月',
        zh: '十二個月'}
    }
  ];

  auto_renew = 1;

  productRules: any[] = [];
  currentProductRule: any; 

  constructor(
    public router: Router,
    public orderService: O365OrderService,
    private o365ApiService: O365HttpService,
    private translateService: LanguageTranslateService
  ) { }

  ngOnInit() {
    this.lang = this.translateService.userLanguage$.value;
    // CR253
    let selectedProduct = this.orderService.selectedProduct;

    if (selectedProduct) {
      // get product rules based on product id (can have multiple rules)
      this.o365ApiService.getProductRule(selectedProduct.product_id).subscribe((res: any) => {
        this.productRules = res.data;
        console.log(this.productRules);
      }, err => {
        console.error('getProductRule error', err);
        this.errorMessages = [];
        this.errorMessages.push(err.error.message);
      })
    }
  }

  translate(key:string):string{
    return this.translateService.translate(key);
  }

  onDurationChanged(duration: any) {
    if (this.productRules.length < 1) {
      return;
    }

    // reset
    this.currentProductRule = null;

    let billingCycle: string = '';
    if (Number(duration) == 1) {
      billingCycle = 'Monthly';
    } else if (Number(duration) == 12) {
      billingCycle = 'Annual';
    } else {
      return;
    }

    // find rule based on billingCycle
    let rule = this.productRules.find(r => {
      let ruleBillingCycle: string = r.billingCycle;
      if (ruleBillingCycle && ruleBillingCycle.toLowerCase() == billingCycle.toLowerCase()) {
        return true;
      }
      return false;
    });

    // not found then do nothing
    if (!rule) {
      return;
    }

    // set current product rule
    this.currentProductRule = rule;
  }

  nextPressed() {
    this.errorMessages = [];

    if (this.quantity < 1 || this.quantity == null) {
      this.errorMessages.push(this.translate('error.licenserequired'));
    }

    if (!this.validDuration.includes(Number(this.duration))) {
      this.errorMessages.push(this.translate('error.durationinvalid'));
    }

    if (this.checkIsQuantityExceedMaxAmountAllowed()) {
      return;
    }

    if (this.errorMessages.length > 0) {
      return;
    }

    this.orderService.setLicence(Number(this.quantity));
    this.orderService.setMonth(Number(this.duration));
    this.orderService.setAutoRenew(this.auto_renew);
    this.router.navigate(['/o365-step-four']);
  }

  checkIsQuantityExceedMaxAmountAllowed() {
    if (this.currentProductRule && this.currentProductRule.maxQuantity) {
      let maxQuantity = Number(this.currentProductRule.maxQuantity);
      if (this.quantity > maxQuantity) {
        this.errorMessages.push(`At most ${maxQuantity} licenses are allowed to place an order.`);
        return true;
      }
    }
    return false;
  }

  toggleAutoRenewStatus() {
    this.auto_renew = this.auto_renew == 0 ? 1 : 0;
  }

  closePressed() {
    this.errorMessages = [];
  }
}
