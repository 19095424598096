
    <main class="main">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item active">{{ 'word.dashboard' | translate }}</li>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">
          <div class="row">
            <div class="col-xl-9">
              <div class="row">
                <div class="col-sm-6 col-lg-3" (click)="orderPressed()" style="cursor: pointer">
                  <div class="card text-white bg-info">
                    <a  class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-basket-loaded"></i>
                        </div>
                        <div class="h4 mb-0">{{info?.totalPendingOrders}}</div>
                        <small class="text-muted text-uppercase font-weight-bold">{{ 'word.orders' | translate }}</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'sf.pendingorders' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->

                <div class="col-sm-6 col-lg-3" (click)="clientPressed()" style="cursor: pointer">
                  <div class="card text-white bg-primary">
                    <a  class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-user"></i>
                        </div>
                        <div class="h4 mb-0">{{info?.totalClients}}</div>
                        <small class="text-muted text-uppercase font-weight-bold">{{ 'word.users' | translate }}</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'word.clientmanagement' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->

                <div class="col-sm-6 col-lg-3">
                  <div class="card text-white bg-danger">
                    <a href="https://r.webnic.cc" style="cursor: pointer" class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-credit-card"></i>
                        </div>
                        <div class="h4 mb-0">&nbsp;</div>
                        <small class="text-muted text-uppercase font-weight-bold">&nbsp;</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'sf.storefronttransactions' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->

                <div class="col-sm-6 col-lg-3">
                  <div class="card text-white bg-warning">
                    <a routerLink="/manage-storefront" style="cursor: pointer" class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-wrench"></i>
                        </div>
                        <div class="h4 mb-0">&nbsp;</div>
                        <small class="text-muted text-uppercase font-weight-bold">&nbsp;</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'sf.storefrontconfiguration' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->
              </div>
              <!--/.row-->

              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-5">
                      <h4 class="card-title mb-0">{{ 'word.salesperformance' | translate }}</h4>
                    </div>
                    <!--/.col-->

                    <div class="col-sm-7 d-none d-md-block">
                      <div class="float-right">
                        <p class="d-inline">{{ 'word.display' | translate }}&nbsp;</p>
                        <select #salesChartDays (change)="changeSalesChartDays(salesChartDays.value)" id="sales-chart-days" class="form-control d-inline" style="width:80px">
                          <option [value]="10">10</option>
                          <option [value]="50">50</option>
                          <option [value]="100">100</option>
                        </select>
                        <p class="d-inline">&nbsp;{{ 'word.daysrecord' | translate }}</p>
                      </div>
                    </div>
                    <!--/.col-->
                  </div>
                  <!--/.row-->
                  
                  <div class="chart-wrapper" style="height:300px;margin-top:40px;">
                    <canvas id="sales-chart" class="chart" height="300"></canvas>

                  </div>
                </div>
              </div>
              <!--/.card-->
            </div>

            <div class="col-xl-3">
              <div class="row">
                <div class="col-12">
                  <table class="table table-bordered mb-4">
                    <thead class="thead-light">
                      <tr>
                        <th colspan="2">{{ 'sf.averageperformance' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr>
                        <td>{{ 'word.microsoftoffice365' | translate }}</td>
                        <td>USD {{info?.avgSales["Microsoft Office 365"] | number:'1.2-2' | number:'1.2-2'}}  / {{ 'word.day' | translate }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-12">
                  <table class="table table-bordered mb-4">
                    <thead class="thead-light">
                      <tr>
                        <th colspan="2">{{ 'sf.totalrevenue' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr>
                        <td>{{ 'word.microsoftoffice365' | translate }}</td>
                        <td>USD {{info?.revenues["Microsoft Office 365"] | number:'1.2-2'}} / {{ 'word.day' | translate }}</td>
                      </tr>
                      <tr>
                        <td><strong>{{ 'word.total' | translate }}</strong></td>
                        <td><strong>USD {{info?.totalRevenue | number:'1.2-2'}}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-12">
                  <table class="table table-bordered mb-4">
                    <thead class="thead-light">
                      <tr>
                        <th colspan="2">{{ 'sf.totalprofit' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr>
                        <td>{{ 'word.microsoftoffice365' | translate }}</td>
                        <td>USD {{info?.profits["Microsoft Office 365"] | number:'1.2-2'}} / {{ 'word.day' | translate }}</td>
                      </tr>
                      <tr>
                        <td><strong>{{ 'word.total' | translate }}</strong></td>
                        <td><strong>USD {{info?.totalProfit | number:'1.2-2'}}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-12">
                  <table class="table table-bordered mb-4">
                    <thead class="thead-light">
                      <tr>
                        <th colspan="2">{{ 'sf.clientstatistics' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr>
                        <td>{{ 'sf.existinguserlogin' | translate }}</td>
                        <td>{{info?.existingLogins}}</td>
                      </tr>
                      <tr>
                        <td>{{ 'sf.visitortohomepage' | translate }}</td>
                        <td>{{info?.visitorsHomepage}}</td>
                      </tr>
                      <tr>
                        <td>{{ 'sf.visitortoproduct' | translate }}</td>
                        <td>{{info?.visitorsProductPage}}</td>
                      </tr>
                      <tr>
                        <td>{{ 'sf.totalproductadded' | translate }}</td>
                        <td>{{info?.totalAddedToCart}}</td>
                      </tr>
                      <tr>
                        <td>{{ 'sf.totalpurchased' | translate }}</td>
                        <td>{{info?.totalPurchased}}</td>
                      </tr>
                      <tr>
                        <td>{{ 'sf.totalabandoned' | translate }}</td>
                        <td>{{info?.totalAbandoned}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--/.row-->
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </main>