<main class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants">{{ 'word.tenants' | translate }}</li>
    <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants/{{tenantId}}">{{ tenantId }}</li>
    <li class="breadcrumb-item active">{{ 'word.domains' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row" *ngIf="errorCheck">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            <button type="button" class="close" (click)="errorClosePressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="card">
                    <a routerLink="/microsoftcsp/tenants/{{tenantId}}/domains/new" style="text-decoration: none;">
                      <div class="card-body text-center">
                        <i class="fa fa-plus"></i> {{ 'button.adddomain' | translate }}
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-12">

                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th column="domainName" sortable (sort)="onSort($event)">{{ 'word.domainname' | translate }}</th>
                          <th column="domainStatus" sortable (sort)="onSort($event)">{{ 'word.status' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <td colspan="5" style="text-align: center" [hidden]="!tableLoading">
                          {{ 'word.loading' | translate }}...
                        </td>
                        <td colspan="5" style="text-align: center"
                          *ngIf="!tableLoading && domains.length < 1">
                          {{ 'word.nodataavailable' | translate }}
                        </td>
                        <tr *ngFor="let domain of domains" class="pointer-row"
                        (click)="goToDomainDetails(domain)">
                          <td>{{domain.id}}{{domain.isDefault ? ' (Default)' : ''}}</td>
                          <td>{{domain.isVerified ? 'Verified' : 'Not Verified'}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="d-flex flex-lg-row flex-md-row flex-column justify-content-end align-items-center mt-lg-2 mt-md-2 my-3" *ngIf="!tableLoading && domains.length > 0">

                    <button type="button" class="btn btn-light" (click)="onMorePressed()">
                      <span>{{ 'button.more' | translate }}</span>
                      <i class="fa fa-spinner fa-spin" *ngIf="tableLoading"></i>
                    </button>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 text-right">
          <a routerLink="/microsoftcsp/tenants/{{tenantId}}" class="btn btn-dark btn-lg px-5 mb-4">{{ 'button.back' | translate }}</a>
        </div>
      </div>

    </div>
  </div>

</main>
