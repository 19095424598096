import { Injectable } from '@angular/core';
import { EmailServiceModule } from '../module/email/email-service.module';
import { EmailPlaceOrder } from '../model/email/email-place-order.model';

@Injectable({
  providedIn: EmailServiceModule // inject to lazy loaded EmailModule through EmailServiceModule
}) 
export class EmailDataService {

  private emailPlaceOrderData:EmailPlaceOrder = null;

  constructor() {}

  setEmailPlaceOrderData(data:EmailPlaceOrder){
    this.emailPlaceOrderData = data;
  }

  getEmailPlaceOrderData(){
    return this.emailPlaceOrderData;
  }


}
