import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, Validators } from '@angular/forms';

@Directive({
    selector: 'input[max]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxNumberValidatorDirective, multi: true }]
})
export class MaxNumberValidatorDirective implements Validator {

    @Input('max') max: number;

    validate(control: AbstractControl): { [key: string]: any } {
        return Validators.max(this.max)(control);
    }
}
