import { Component} from '@angular/core';
import { PushNotificationService } from './shared/push-notification.service';
import { AuthService } from './service/auth.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'webnic-sf-admin';

  constructor(
    private authService: AuthService,
    private pushNotificationService: PushNotificationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    let validateTokenSession:boolean = true;

    let currentUrl:string = window.location.href;

    if(currentUrl.includes('/login')){
      // bypass for ssologin to supply new token
      validateTokenSession = false;
    }

    if (validateTokenSession && localStorage.getItem('access_token')) {

      this.activatedRoute.queryParams.subscribe(params => {
        let returnUrl = params['returnUrl'] || undefined ;
        this.authService.showExpiring(localStorage.getItem('access_token'), returnUrl);
      });
     
      // temp remove push notification (since not using currently)
      //this.pushNotificationService.requestPermission();
      //this.pushNotificationService.receiveMessage();
      //this.message = this.pushNotificationService.currentMessage;
    }
  }
}
