import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { HeaderComponent } from './component/header/header.component';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { StorefrontCreateComponent } from './component/storefront-create/storefront-create.component';
import { StorefrontManageComponent } from './component/storefront-manage/storefront-manage.component';
import { OrderManagementComponent } from './component/order-management/order-management.component';
import { ClientManagementComponent } from './component/client-management/client-management.component';
import { NotificationCreateComponent } from './component/notification-create/notification-create.component';
import { NotificationManageComponent } from './component/notification-manage/notification-manage.component';
import { MasterTemplateComponent } from './component/master-template/master-template.component';
import { ClientShowComponent } from './component/client-show/client-show.component';
import { OrderShowComponent } from './component/order-show/order-show.component';
import { AsideMenuComponent } from './component/aside-menu/aside-menu.component';
import { NotificationEditComponent } from './component/notification-edit/notification-edit.component';
import { StorefrontCreateOneComponent } from './component/storefront-create-one/storefront-create-one.component';
import { StorefrontCreateTwoComponent } from './component/storefront-create-two/storefront-create-two.component';
import { StorefrontCreateThreeComponent } from './component/storefront-create-three/storefront-create-three.component';
import { StorefrontCreateFourComponent } from './component/storefront-create-four/storefront-create-four.component';
import { StorefrontCreateFiveComponent } from './component/storefront-create-five/storefront-create-five.component';
import { StorefrontCreateSixComponent } from './component/storefront-create-six/storefront-create-six.component';
import { StorefrontCreateSevenComponent } from './component/storefront-create-seven/storefront-create-seven.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { PreviewPageComponent } from './preview-page/preview-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SafePipe } from './safe.pipe';
import { AsyncPipe, CommonModule } from '@angular/common';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat/';
import { environment } from '../environments/environment';
import { PushNotificationService } from './shared/push-notification.service';

import { ToastrModule } from 'ngx-toastr';
import { LoginLayoutComponent } from './shared/login-layout/login-layout.component';
import { HomeLayoutComponent } from './shared/home-layout/home-layout.component';
import { NotificationListComponent } from './component/notification-list/notification-list.component';
import { O365DashboardComponent } from './component/o365-dashboard/o365-dashboard.component';
import { O365PlaceOrderComponent } from './component/o365-place-order/o365-place-order.component';
import { O365OrderManagementComponent } from './component/o365-order-management/o365-order-management.component';
import { O365StepOneComponent } from './component/o365-step-one/o365-step-one.component';
import { O365StepTwoComponent } from './component/o365-step-two/o365-step-two.component';
import { O365StepThreeComponent } from './component/o365-step-three/o365-step-three.component';
import { O365StepFourComponent } from './component/o365-step-four/o365-step-four.component';
import { O365StepFiveComponent } from './component/o365-step-five/o365-step-five.component';
import { O365OrderDetailComponent } from './component/o365-order-detail/o365-order-detail.component';
import { EmailModule } from './module/email/email.module';
import { SharedModule } from './module/shared.module';
import { MicrosoftCSPModule } from './module/microsoftcsp/microsoftcsp.module';
import { NgxIntlTelInputModule } from './library/ngx-intl-tel-input/ngx-intl-tel-input.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NsDetailsComponent } from './component/dns/ns-details/ns-details.component';
import { ResellerService } from './service/reseller/reseller.service';
import { LanguageTranslateService } from './service/language-translate.service';
import { HCaptchaDirective } from './directive/h-captcha.directive';
import { WhmcsDashboardComponent } from './component/whmcs/whmcs-dashboard/whmcs-dashboard.component';
import { WhmcsSidebarComponent } from './component/whmcs/whmcs-sidebar/whmcs-sidebar.component';
import { WhmcsLayoutComponent } from './component/whmcs/whmcs-layout/whmcs-layout.component';
import { WhmcsPlaceComponent } from './component/whmcs/whmcs-place/whmcs-place.component';
import { WhmcsLicenseManagementComponent } from './component/whmcs/whmcs-license-management/whmcs-license-management.component';
import { WhmcsLicenseDetailsComponent } from './component/whmcs/whmcs-license-details/whmcs-license-details.component';
import { WhmcsLicenseUpgradeComponent } from './component/whmcs/whmcs-license-upgrade/whmcs-license-upgrade.component';
import { WhmcsRenewComponent } from './component/whmcs/whmcs-renew/whmcs-renew.component';
import { DatePipe } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    //HeaderComponent,  <---------  moved to SharedModule
    LoginComponent,
    DashboardComponent,
    StorefrontCreateComponent,
    StorefrontManageComponent,
    OrderManagementComponent,
    ClientManagementComponent,
    NotificationCreateComponent,
    NotificationManageComponent,
    MasterTemplateComponent,
    ClientShowComponent,
    OrderShowComponent,
    AsideMenuComponent,
    NotificationEditComponent,
    StorefrontCreateOneComponent,
    StorefrontCreateTwoComponent,
    StorefrontCreateThreeComponent,
    StorefrontCreateFourComponent,
    StorefrontCreateFiveComponent,
    StorefrontCreateSixComponent,
    StorefrontCreateSevenComponent,
    PreviewPageComponent,
    SafePipe,
    LoginLayoutComponent,
    HomeLayoutComponent,
    NotificationListComponent,
    //O365MasterTemplateComponent,  <---------  moved to SharedModule
    //O365SidebarComponent, <---------  moved to SharedModule
    O365DashboardComponent,
    O365PlaceOrderComponent,
    O365OrderManagementComponent,
    O365StepOneComponent,
    O365StepTwoComponent,
    O365StepThreeComponent,
    O365StepFourComponent,
    O365StepFiveComponent,
    O365OrderDetailComponent,
    HCaptchaDirective,
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CKEditorModule,
    NgbModule,
    BrowserAnimationsModule,
    CommonModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ToastrModule.forRoot({
      maxOpened: 5,
      autoDismiss: true
    }),
      // import ngx-bootstrap dropdown module, required by NgxIntlTelInputModule
    BsDropdownModule.forRoot(),
    // import ngx-bootstrap tooltip module, required by NgxIntlTelInputModule
    TooltipModule.forRoot(),
    // this is local imported using 2.4.1 version due to the need for code customization
    // remainder: do modify/update the library code locally
    // https://github.com/webcat12345/ngx-intl-tel-input
    NgxIntlTelInputModule,

    // import shared module can be reuse among other modules
    SharedModule,

    // import other child modules
    EmailModule,
    MicrosoftCSPModule,
    TabsModule.forRoot()

  ],
  providers: [
    PushNotificationService,
    AsyncPipe,
    HeaderComponent,
    ResellerService,
    LanguageTranslateService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }