
        <main id="manage-order" class="main">

            <!-- Breadcrumb -->
            <ol class="breadcrumb">
                <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
                <li class="breadcrumb-item">{{ 'word.orders' | translate }}</li>
                <li class="breadcrumb-item active">{{ 'word.orderdetails' | translate }}</li>
            </ol>

            <div class="container-fluid">
                <div class="animated fadeIn">

                    <div class="row" *ngIf="errorCheck">
                        <div class="col-12">
                            <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorCheck">
                                <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
                                <ul>
                                    <li *ngFor="let error of errors">
                                        {{ error }}
                                    </li>
                                </ul>
                                <button type="button" class="close" (click)="closeErrorPressed()" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="successCheck">
                        <div class="col-12">
                            <div class="alert alert-success alert-dismissible fade show" role="alert">
                                <strong>{{ 'word.success' | translate }}</strong>&nbsp;{{ successMessage }}
                                <button type="button" class="close" (click)="closeSuccessPressed()" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xl-7">
                            <div class="table-responsive mb-4">
                                <table class="table table-bordered mb-0">
                                    <thead class="thead-light">
                                        <tr>
                                            <th colspan="2">{{ 'word.productinfo' | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white" *ngIf="order">
                                        <tr>
                                            <td>{{ 'word.orderid' | translate }}</td>
                                            <td>{{order?.order_id}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'word.products' | translate }}</td>
                                            <td>{{order?.order_items[0].product}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'word.productcategory' | translate }}</td>
                                            <td>{{order?.category}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'word.creationdate' | translate }}</td>
                                            <td>{{order?.order_date}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'word.lastmodifieddate' | translate }}</td>
                                            <td>{{order?.order_date}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'word.status' | translate }}</td>
                                            <td>{{orderStatus}}</td>
                                        </tr>
                                        <tr *ngIf="storefront">
                                            <td>{{ 'word.orderfrom' | translate }}</td>
                                            <td>{{storefront?.storefront_name}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-xl-5">
                            <div class="table-responsive mb-4">
                                <table class="table table-bordered mb-0">
                                    <thead class="thead-light">
                                        <tr>
                                            <th colspan="2">{{ 'word.productdetails' | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white">

                                        <tr>
                                            <td>{{ 'word.licensecount' | translate }}</td>
                                            <td>{{order?.order_items[0].license_count}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'word.subscribedmonth' | translate }}</td>
                                            <td>{{order?.order_items[0].subscribed_month}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'word.companyname' | translate }}</td>
                                            <td>{{order?.order_items[0].item_detail.company_name}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-xl-7">
                            <div class="table-responsive mb-4">
                                <table class="table table-bordered mb-0">
                                    <thead class="thead-light">
                                        <tr>
                                            <th colspan="3">{{ 'word.orderprocess' | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white" *ngIf="order">
                                        <tr>
                                            <td>{{ 'word.paymentmethod' | translate }}</td>
                                            <td colspan="2" *ngIf="order.payment_details.length > 0">
                                                {{order?.payment_details[order?.payment_details.length - 1].paymethod_type}}</td>
                                            <td colspan="2" *ngIf="order.payment_details.length == 0">N/A</td>
                                        </tr>
                                        <tr>
                                          <td>{{ 'word.paymentstatus' | translate }}</td>
                                            <td *ngIf="order.payment_details.length > 0">
                                                {{order?.payment_details[order.payment_details.length - 1].status}}
                                            </td>
                                            <td *ngIf="order.payment_details.length == 0">
                                                {{ 'error.nopaymentmade' | translate }}
                                            </td>
                                            <td>
                                              <form [formGroup]="paymentDoneForm">
                                                <input type="text" formControlName="refno" class="form-control"><br>
                                                <div class="justify-content-center d-flex">
                                                    <button *ngIf="order.payment_details.length > 0" type="submit" [disabled]="order.payment_details[0].status == 'Success' ||
                                                         markAsDoneBtnPressed || order.sf_id == null || order.sf_user_id == null"
                                                         class="btn btn-block btn-primary" (click)="paymentDone()">
                                                         {{ 'button.markasdone' | translate }} <i class="fa fa-cog fa-spin" style="font-size:24px;color: whitesmoke" *ngIf="markAsDoneBtnPressed"></i>
                                                    </button>
                                                    <button *ngIf="order.payment_details.length == 0" type="submit" [disabled]="order.payment_details.length == 0 || markAsDoneBtnPressed "
                                                    class="btn btn-block btn-primary" >
                                                    {{ 'button.markasdone' | translate }} <i class="fa fa-cog fa-spin" style="font-size:24px;color: whitesmoke" *ngIf="markAsDoneBtnPressed"></i>
                                                </button>
                                                </div>
                                              </form>
                                          </td>
                                        </tr>
                                        <!--<pre>{{ paymentDoneForm.value| json  }}</pre>-->
                                        <tr>
                                            <td>{{ 'word.orderstatus' | translate }}</td>
                                            <td colspan="2">{{order?.status}}</td>
                                            <!-- <td>
                                              <div class="justify-content-center d-flex">
                                                  <button [disabled]="order.status != 'New'" class="btn btn-block btn-primary" data-bs-toggle="modal" data-bs-target="#order-dialog">Proceed
                                                      Order</button>
                                              </div>
                                          </td>-->
                                        </tr>
                                        <tr>
                                            <td>{{ 'word.refundstatus' | translate }}</td>
                                            <td colspan="2" *ngIf="order.order_items[0].refundable != 0">
                                                <p class="mt-2 mb-0 reason-caption">{{ 'word.reason' | translate }}:</p>
                                                <p class="mb-0 reason-caption">#</p>
                                            </td>
                                            <td *ngIf="order.order_items[0].refundable == 0">No</td>
                                            <!--<td>
                                              <div class="justify-content-center d-flex">
                                                  <button class="btn btn-block btn-primary" [disabled]="order.order_items[0].refundable == 0" data-bs-toggle="modal" data-bs-target="#refund-dialog">Proceed
                                                      Refund</button>
                                              </div>
                                          </td>-->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-xl-12">
                            <div class="float-right">
                                <a routerLink="/orders" class="btn btn-dark btn-lg px-5 mb-4">{{ 'button.back' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.container-fluid -->
            <!-- Modal -->
            <div class="modal fade pr-0" id="order-dialog" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ 'word.confirmation' | translate }}</h5>
                            <button type="button" class="close" data-bs-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{{ 'dialog.proceedorder' | translate }}</p>
                        </div>
                        <div class="modal-footer">
                            <input type="hidden" name="sf_id" value="">
                            <input type="hidden" name="sf_user_id" value="">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'button.cancel' | translate }}</button>
                            <button type="submit" (click)="proceedOrder(order.order_id, order.sf_id, order.sf_user_id)"
                                    class="btn btn-dark">{{ 'button.proceed' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade pr-0" id="refund-dialog" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ 'word.confirmation' | translate }}</h5>
                            <button type="button" class="close" data-bs-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>{{ 'dialog.refundorder' | translate }}</p>

                            <form [formGroup]="">
                                <div class="form-group mt-2">
                                    <label>{{ 'word.reason' | translate }}</label>
                                    <textarea id="textarea-input" name="textarea-input" rows="5"
                                              class="form-control form-control-sm"></textarea>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'button.cancel' | translate }}</button>
                            <a href="#" class="btn btn-dark">{{ 'button.proceed' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
