import {
  Injectable
} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class O365OrderService {
  // Used when making order
  tenant_id;
  product_id;
  number_licence;
  month;
  order_id;
  subscription_id;
  auto_renew;
  price_monthly = 0;
  price_annually = 0;

  // Used in order management
  order_data = null;

  data = {
    username: '',
    password: '',
    company_name: '',
    contact_email_address: '',
    alt_email_address: '',
    contact_first_name: '',
    contact_last_name: '',
    contact_phone_no: '',
    default_domain_prefix: '',
    use_transit_tenant: false,
    contact_address_country_id: 0,
    contact_address_state_id: 0,
    contact_address_city_id: 0,
    contact_address_postcode: '',
    contact_address_city_other: '',
    contact_address_street1: '',
    contact_address_street2: '',
    contact_address_street3: '',
    billing_address_country_id: 0,
    billing_address_state_id: 0,
    billing_address_city_id: 0,
    billing_address_city_other: '',
    billing_address_postcode: '',
    billing_address_street1: '',
    billing_address_street2: '',
    billing_address_street3: '',
    is_marketing_promo: false,
    microsoft_market_category_id: 1,
    include_partner: false
  };

  //CR253 used in o365-step-two
  product:any = null;

  constructor() {}

  setTenantData(data = {
    username: '',
    password: '',
    company_name: '',
    contact_email_address: '',
    alt_email_address: '',
    contact_first_name: '',
    contact_last_name: '',
    contact_phone_no: '',
    default_domain_prefix: '',
    use_transit_tenant: false,
    contact_address_country_id: 0,
    contact_address_state_id: 0,
    contact_address_city_id: 0,
    contact_address_city_other: '',
    contact_address_postcode: '',
    contact_address_street1: '',
    contact_address_street2: '',
    contact_address_street3: '',
    billing_address_country_id: 0,
    billing_address_state_id: 0,
    billing_address_city_id: 0,
    billing_address_city_other: '',
    billing_address_postcode: '',
    billing_address_street1: '',
    billing_address_street2: '',
    billing_address_street3: '',
    is_marketing_promo: false,
    microsoft_market_category_id: 1,
    include_partner: false
  }) {
    this.data = data;
  }

  getTenantData() {
    return this.data;
  }

  setTenantId(id) {
    this.tenant_id = id
  }

  getTenantId() {
    return this.tenant_id
  }

  setProductId(id) {
    this.product_id = id
  }

  getProductId() {
    return this.product_id
  }

  setLicence(number) {
    this.number_licence = number
  }

  getLicences() {
    return this.number_licence
  }

  setMonth(month) {
    this.month = month
  }

  getMonth() {
    return this.month
  }

  setOrderId(id) {
    this.order_id = id;
  }

  getOrderId() {
    return this.order_id;
  }

  setAutoRenew(value) {
    this.auto_renew = value;
  }

  getAutoRenew() {
    return this.auto_renew;
  }

  setPriceMonthly(value) {
    this.price_monthly = value;
  }

  getPriceMonthly() {
    return this.price_monthly;
  }

  setPriceAnnually(value) {
    this.price_annually = value;
  }

  getPriceAnnually() {
    return this.price_annually;
  }

  // Order management

  setOrderData(data) {
    this.order_data = data;
  }

  getOrderData() {
    return this.order_data;
  }


  //CR253
  get selectedProduct(){
    return this.product;
  }
  set selectedProduct(product:any){
    this.product = product;
  }
}
