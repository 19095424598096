import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../service/notification.service'
import { StoreFrontService } from '../../service/store-front.service'
import { Router } from '@angular/router'
import * as moment from 'moment';
import { ScrollUtil } from 'src/app/util/scroll-util';

declare var $: any;
declare var jQuery: any;
declare var appJS: any;
@Component({
	selector: 'app-notification-create',
	templateUrl: './notification-create.component.html',
	styleUrls: ['./notification-create.component.css']
})
export class NotificationCreateComponent implements OnInit {

	model;
	payBtnPressed=false;
	disable: boolean = false;
	form: any;
	minDate = undefined;
	alertMsg;
	
	data = {
		title: '',
		snippet: '',
		type: 1,
		content: '',
		targeted_page: '',
		publish_time: '',
		selected_groups: [],

	}

	countries = []
	selectedGroup
	editHtml = false
	selectedCountries = []
	selectedItem
	createNotifLoad = false

	publish_time = {
		date: {
			year: 0,
			month: 0,
			day: 0,
		},
		hour: 12,
		minute: 0,
		period: 'AM',
	}
	message = [];
	
	constructor(
		public notificationService:NotificationService,
		public storeFrontService:StoreFrontService,
		public router:Router
	) { 
		const current = new Date();
		this.minDate = {
		  year: current.getFullYear(),
		  month: current.getMonth() + 1,
		  day: current.getDate()
		};
	}


	ngOnInit() {
		this.storeFrontService.getCountries().subscribe(resp => {
			this.countries = resp['data'];
		});
	}

	sendPressed(){
		this.closeAlertMsg();
		this.payBtnPressed = true;

		for (let i = 0; i < this.selectedCountries.length;i++){
			console.log(this.selectedCountries[i])
			this.data.selected_groups.push(this.selectedCountries[i]["id"])
		}
		let publish_time = moment(this.getDateTimestamp());
		this.data['publish_time'] = publish_time.format("YYYY-MM-DD hh:mm A");
		this.notificationService.createNotification(this.data).subscribe(resp => {
			console.log(resp)
			this.createNotifLoad = true
			this.disable = true;

			if (resp["message"] == "Successfully created notification.") {
				this.payBtnPressed = false;
				this.createNotifLoad = false
				this.alertMsg = 1;
				ScrollUtil.scrollToTop();

				setTimeout(() => {
					this.router.navigate(['/manage-notification'])
				}, 1350);
			}
		}, err => {
			this.payBtnPressed = false;
			this.alertMsg = 2;
			if(err["error"]["error"]) {
				// Validation message from backend
				for(let error in err["error"]["error"]) {
				  console.log('lal', err["error"]["error"][error][0]);
				  this.message.push(`${err["error"]["error"][error][0]}`);
				}
			}
			// this.message = eval("`"+this.message+"`");
			ScrollUtil.scrollToTop();
			console.log('cant create notification.', err);
		});
	}

	closeAlertMsg() {
		this.alertMsg = 0;
		this.message = [];
	}

	scrollToTop() {
		(function smoothscroll() {
			var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			if (currentScroll > 0) {
				window.requestAnimationFrame(smoothscroll);
				window.scrollTo(0, currentScroll - (currentScroll / 8));
			}
		})();
	}

	getDateTimestamp(): string {
		let year = this.publish_time.date.year;
		let month = `${this.publish_time.date.month}`;
		let day = `${this.publish_time.date.day}`;

		let hour = `${this.publish_time.hour}`;
		let minute = `${this.publish_time.minute}`;
		let period = this.publish_time.period;

		if (this.publish_time.date.month < 10) {
			month = `0${this.publish_time.date.month}`;
		}

		if (this.publish_time.date.day < 10) {
			day = `0${this.publish_time.date.day}`;
		}

		// if(this.publish_time.hour < 10) {
		// 	hour = `0${this.publish_time.hour}`;
		// }

		if (this.publish_time.minute < 10) {
			minute = `0${this.publish_time.minute}`;
		}

		return `${year}-${month}-${day} ${hour}:${minute} ${period}`;
	}

	onSelectItem(event) {
		let item = this.countries.filter(val => {
			return val.id == this.selectedItem
		})

		delete item[0]['phonecode'];
		item[0].id = item[0].id.toString();

		console.log("ini dia data,", this.selectedCountries);
		console.log("item[0]", item[0]);

		if (this.containsCountry(item[0], this.selectedCountries)) {
			console.log("cannot add this country anymore");
		} else {
			this.selectedCountries.push(item[0]);
			console.log(this.selectedCountries)
		}
	}

	containsCountry(obj, list) {
		for (var i = 0; i < list.length; i++) {
			if (list[i].id === obj.id) {
				return true;
			}
		}
		return false;
	}

	removeCountry(country) {
		console.log("button clicked")
		this.selectedCountries.splice(this.selectedCountries.indexOf(country), 1)
	}
}
