<div class="sidebar">
  <nav class="sidebar-nav">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/o365-dashboard"><i class="icon-speedometer"></i> {{ 'word.dashboard' | translate }}</a>
      </li>
      <li class="nav-item nav-dropdown" id="product-nav-dropdown">
        <a class="nav-link nav-dropdown-toggle" id="product-nav" href="#" (click)="toggleDropdown()"><i class="fa fa-shopping-bag"></i> {{ 'word.products' | translate }}</a>
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a class="nav-link" routerLink="/microsoftcsp/products/pricing">
              <i class="fa fa-usd"></i><span class="sidebar-menu-item">{{ 'word.pricing' | translate }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/microsoftcsp/products/promotion">
              <i class="fa fa-bullhorn"></i><span class="sidebar-menu-item">{{ 'word.promotion' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/o365-step-one"><i class="icon-basket-loaded"></i>  {{ 'word.placeorder' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/o365-order-management"><i class="icon-basket-loaded"></i>  {{ 'word.orders' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/microsoftcsp/tenants"><i class="icon-user"></i>  {{ 'word.tenants' | translate }}</a>
      </li>
    </ul>
  </nav>
  <button class="sidebar-minimizer brand-minimizer" type="button"></button>
</div>
