<main id="storefront" class="main create-storefront" style="position: relative;">
<ngx-loading [show]="loading"></ngx-loading>
  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'email.orderprofessionalemail' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.stepone' | translate }}</li>

    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="50"
        aria-valuemin="0" aria-valuemax="100" style="width: 50%">
      </div>
    </div>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">

        <div class="col-lg-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorCheck">
            <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            {{ 'word.error.fixerrors' | translate }}
            <button type="button" class="close" (click)="closePressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>

        <form [formGroup]="emailProductForm" class="form" style="overflow-y: visible; width: 100%;">
          <div class="col-lg-12 mt-3">
            <h2>{{ 'email.orderinfomation' | translate }}</h2>
            <div class="card mt-4 pl-2 pr-2">

              <div class="card-body">

                <div class="row">
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group mb-4">
                      <h4 style="padding-left: 15px; padding-right: 4px;">
                        {{ 'email.packageplan' | translate }}
                      </h4>
                      <select name="productId" formControlName="productid" id="productId" class="form-control">
                        <option [value]="0" disabled>{{ 'email.selectpackage' | translate }}</option>
                        <option *ngFor="let product of emailProductList" [value]="product?.productid">
                          {{ product?.packageName }}</option>
                      </select>
                    </div>
                  </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4 style="padding-left: 15px; padding-right: 4px;">
                          {{ 'email.storagesize' | translate }}
                        </h4>
                        <input id="storageId" type="number" formControlName="storagesize" class="form-control"
                          [ngClass]=" {'is-invalid': storageSizeFormControl.invalid }" 
                          (change)="onStorageSizeValueChanged()"/>
                        <p *ngIf="storageSizeFormControl.hasError('min') || storageSizeFormControl.hasError('max')"
                          class="text-danger">
                          {{ 'error.storagesizerange' | translate }} {{selectedProduct?.minstoragesize}} GB to
                          {{selectedProduct?.maxstoragesize}} GB only.
                        </p>
                        <p *ngIf="storageSizeFormControl.hasError('required')" class="text-danger">
                          {{ 'error.storagesizerequired' | translate }}
                        </p>
                      </div>
                    </div>
                 </div>

                <div class="row">

                  <div class="col-lg-6 col-md-6 col-12">

                    <div class="group form-group mb-4">
                      <h4 style="padding-left: 15px; padding-right: 4px;">
                        {{ 'email.sharestorage' | translate }}
                      </h4>
                      <input id="sharedId" type="number" formControlName="sharedsize" class="form-control"
                        [ngClass]=" {'is-invalid': sharedSizeFormControl.invalid }" 
                        (change)="onSharedSizeValueChanged()" />
                      <p *ngIf="sharedSizeFormControl.hasError('min') || sharedSizeFormControl.hasError('max')"
                        class="text-danger">
                        {{ 'error.sharedstoragesizerange' | translate }} {{selectedProduct?.minsharedsize}} GB to
                        {{selectedProduct?.maxsharedsize}} GB only.
                      </p>
                      <p *ngIf="sharedSizeFormControl.hasError('required')" class="text-danger">
                        {{ 'error.sharedstoragerequired' | translate }}
                      </p>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">

                    <div class="group form-group mb-4">
                      <h4 style="padding-left: 15px; padding-right: 4px;">
                        {{ 'email.personalstorage' | translate }}
                      </h4>
                      <input id="personalId" type="number" formControlName="personalsize" class="form-control"
                        [ngClass]=" {'is-invalid': personalSizeFormControl.invalid } "
                        (change)="onPersonalSizeValueChanged()" />
                      <p *ngIf="personalSizeFormControl.hasError('min') || personalSizeFormControl.hasError('max')"
                        class="text-danger" >
                        {{ 'error.personalstoragesizerange' | translate }} {{selectedProduct?.minpersonalsize}} GB to
                        {{selectedProduct?.maxpersonalsize}} GB only.
                      </p>
                      <p *ngIf="personalSizeFormControl.hasError('required')" class="text-danger">
                        {{ 'error.personalstoragesizerequired' | translate }}
                      </p>
                    </div>

                  </div>

                </div>

                <div class="row">

                  <div class="col-lg-6 col-md-6 col-12">

                    <div class="group form-group mb-4">
                      <h4 style="padding-left: 15px; padding-right: 4px;">
                        {{ 'word.licenses' | translate }}
                      </h4>
                      <input id="licensesId" type="number" formControlName="licenses" class="form-control" 
                      [ngClass]=" {'is-invalid': licensesFormControl.invalid } "
                      (change)="onLicenseValueChanged()" />
                      <p *ngIf="licensesFormControl.hasError('min')" class="text-danger" >
                        {{ 'error.licensemin' | translate }} {{selectedProduct?.minlicenses}}.
                      </p>
                      <p *ngIf="licensesFormControl.hasError('max')" class="text-danger">
                        {{ 'error.licensemax' | translate }} {{selectedProduct?.maxlicenses}}.
                      </p>
                      <p *ngIf="licensesFormControl.hasError('required')" class="text-danger">
                        {{ 'error.licensequanrequired' | translate }}
                      </p>
                    </div>

                  </div>

                  <div class="col-lg-6 col-md-6 col-12">

                    <div class="group form-group mb-4">
                      <h4 style="padding-left: 15px; padding-right: 4px;">
                        {{ 'word.terms.months' | translate }}
                      </h4>
                      <select name="termId" formControlName="terms" id="termId" class="form-control"
                      (change)="onTermValueChanged($event.target.value)" >
                        <option [value]="undefined" disabled>{{ 'word.selectterm' | translate }}</option>
                        <option *ngFor="let productPrice of emailProductPriceList" [value]="productPrice?.terms">
                          {{ productPrice?.terms }}</option>
                      </select>
                    </div>

                  </div>
                  
                </div>

                <div class="row">

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group mb-4">
                      <h4 style="padding-left: 15px; padding-right: 4px;">
                        {{ 'word.domains' | translate }}
                      </h4>
                      <div class="position-relative">
                        <input id="domainId" type="text" formControlName="domain" class="form-control" 
                          [ngClass]=" {'is-invalid': domainFormControl.errors && domainFormControl.touched,
                          'is-valid': domainFormControl.valid && domainFormControl.touched } "
                          (blur)="onDomainBlur($event.target.value)" />
                        <div *ngIf="domainFormControl.touched" class="feedback-icon"
                          [ngClass]="domainFormControl.valid ? 'valid-feedback' : 'invalid-feedback' ">
                          <i [ngClass]="domainFormControl.valid ? 'icon-check' : 'icon-close' "></i>
                        </div>
                      </div>
                      <div *ngIf="domainFormControl.touched">
                        <p *ngIf="domainFormControl.valid" class="text-success">{{domainFormControl.value}} {{ 'email.domainisavailable' | translate }}</p>
                        <p *ngIf="domainFormControl.hasError('pattern')" class="text-danger">{{ 'error.invaliddomain' | translate }}</p>
                        <p *ngIf="domainFormControl.hasError('notAvailable')" class="text-danger">{{domainFormControl.value}} {{ 'email.domainhasowned' | translate }}</p>
                        <p *ngIf="domainFormControl.hasError('unableToCheck')" class="text-danger">{{ 'error.domainunabletocheck' | translate }}</p>
                        <p *ngIf="domainFormControl.hasError('required')" class="text-danger">{{ 'error.domainrequired' | translate }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group mb-4">
                      <h4 style="padding-left: 15px; padding-right: 4px;">
                        {{ 'word.price' | translate }}
                      </h4>
                      <input id="priceId" type="text" formControlName="price" class="form-control" />
                      <span class="highlight"></span>
                      <span class="bar"></span>
                    </div>
                  </div>

                </div>

              </div>
              <!-- card-body end -->
              
            </div>
          </div>

          <div class="col-lg-12">
            <div class="float-right">
              <button class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()"
              [disabled]="nextBtnPressed || emailProductForm.invalid || emailProductForm.pending">
              {{ 'button.next' | translate }} <i class="fa fa-cog fa-spin" style="font-size:24px;color: lightseagreen" *ngIf="nextBtnPressed"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>