<main id="storefront" class="main create-storefront">
  
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'o365.orderMO365' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.stepthree' | translate }}</li>
    
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
      aria-valuemin="0" aria-valuemax="100" style="width: 100%">
    </div>
  </div>
</ol>

<div class="container-fluid">
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-12">
        <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessages?.length > 0">
          <strong>{{ 'word.error.oops' | translate }} {{ 'word.error.somethingwrong' | translate }}</strong>
          <ul>
            <li *ngFor="let message of errorMessages">{{ message }}</li>
          </ul>
          <button type="button" class="close" (click)="closePressed()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        <h2>{{ 'o365.stepthree.title' | translate }}</h2>
        <div class="card mb-4 pt-5" style="min-height: 250px;">
          <div class="card-body">
            <div class="row">
              
              <div class="col-sm-12 col-12">
                <div class="row mb-2">
                  <div class="col">
                    <input required type="number" min="1" class="form-control form-control-lg" placeholder="{{ 'o365.stepthree.numlicense' | translate }}" name="quantity" [(ngModel)]="quantity">
                  </div>
                  <div class="col">
                    <select required style="color: #7b8388; vertical-align: top" (change)="onDurationChanged($event.target.value)"
                     class="form-control form-control-lg country-select" [(ngModel)]="duration">
                      <option [value]="0" disabled selected>{{ 'word.duration' | translate }} *</option>
                      <option *ngFor="let option of optionsDuration" [value]="option.value">
                        {{ option.name[this.lang] }}
                      </option>
                    </select>
                  </div>
                </div>
                
                <div class="checkbox-container checkbox-payment">
                  <input id="auto_renew" type="checkbox" name="auto_renew" [(ngModel)]="auto_renew" (click)="toggleAutoRenewStatus()">
                  <label for="auto_renew" class="bg-checkbox-dark"></label>
                  <h6>
                    {{ 'word.autorenew' | translate }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        
      </div>
      
      <div class="col-lg-12">
        <div class="float-right">
          <label class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()">
            {{ 'button.next' | translate }}
            <!-- <i class="fa fa-cog fa-spin" style="font-size:24px;color: lightgreen" *ngIf='completeCreationLoad'></i> -->
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
</main>