import { EmailApiService } from 'src/app/service/email-api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailOrderStatistics } from 'src/app/model/email/email-order-statistics.model';

declare var $: any;

@Component({
  selector: 'app-email-dashboard',
  templateUrl: './email-dashboard.component.html',
  styleUrls: ['./email-dashboard.component.css']
})
export class EmailDashboardComponent implements OnInit {

  info: EmailOrderStatistics = new EmailOrderStatistics();

  constructor(
    private emailApiService: EmailApiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.emailApiService.getOrderStatistics().subscribe((res: any) => {
      this.info = res;
    });
  }

  onStatisticBoxPressed(type: string) {
    let params: any;

    switch (type) {
      case 'totalOrder':
        params = { filter: 'TotalOrder' };
        break;
      case 'totalLicense':
        params = { filter: 'TotalLicense' };
        break;
      case 'totalExpiring':
        params = { filter: 'TotalExpiring' };
        break;
      case 'totalExpired':
        params = { filter: 'TotalExpired' };
        break;
      default:
        break;
    }

    this.router.navigate(['/email/email-order-management'], { queryParams: params });
  }

}
