import { Component, OnInit } from '@angular/core';
import { StoreService } from '../../service/store.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;
declare var jQuery: any;
declare var appJS: any;

@Component({
	selector: 'app-storefront-create-three',
	templateUrl: './storefront-create-three.component.html',
	styleUrls: ['./storefront-create-three.component.css']
})
export class StorefrontCreateThreeComponent implements OnInit {
	storefrontId: string;
	loadEditor: boolean = false;

	constructor(
		public storeService: StoreService,
		public router: Router,
		public domSanitizer: DomSanitizer,
		public route: ActivatedRoute
	) { }
	
	selectedPreview;
	selectedThemePreview;
	title;
	text;
	logo;
	filestring;
	cover;
	pageTitle = 'Custom Page';
	edited = false;
	customStatus = 1;
	storeName = "";
	storeAddress = "";
	storePhone = "";
	editAbout = false;
	editContact = false;
	editCustom = false;

	languages;
	aboutLang = "en";
	contactusLang = "en";
	editorLang = "en";

	contactusValue = {
		'en': `We want to hear from you ! Your comments are always welcome at all times! <p>${this.storeService.getStoreName()}</p> <p>${this.storeService.getAddress()}</p> <p>${this.storeService.getPhoneNumber()}</p>`
	};

	aboutusValue = {
		'en': '<p>We are the reseller of Web Commerce Communications (Singapore) Pte. Ltd. (WEBCC), an Internet service provider focus in domain name wholesale service and digital brand management.</p><p> WebNIC – our global identity, is an internationally recognized brand synonymous with quality wholesale Internet service to resellers. The WebNIC Premier Partner Program, designed with growing Internet businesses in mind, has helped our customers accelerate their business expansion globally over the years. Established in 2000, WEBCC has offices in Singapore, Taiwan, Kuala Lumpur, Jakarta and Seoul. WEBCC is an accredited Registrar for ICANN, China, Taiwan, Hong Kong, Malaysia, Singapore, the Philippines, Vietnam, EU, US and growing list of other country-code domain names.</p>'
	};

	editorValue = {
		'en': '<h1>This is a sample custom page. Modify it to your own needs!</h1>'
	};

	ngOnInit() {
		this.storeService.getLanguages().subscribe(data => {
			this.languages = data['data'];

			this.languages.forEach(lang => {
				this.editorValue[lang.code] = '<h1>This is a sample custom page. Modify it to your own needs!</h1>';
				this.aboutusValue[lang.code] = '<p>We are the reseller of Web Commerce Communications (Singapore) Pte. Ltd. (WEBCC), an Internet service provider focus in domain name wholesale service and digital brand management.</p><p> WebNIC – our global identity, is an internationally recognized brand synonymous with quality wholesale Internet service to resellers. The WebNIC Premier Partner Program, designed with growing Internet businesses in mind, has helped our customers accelerate their business expansion globally over the years. Established in 2000, WEBCC has offices in Singapore, Taiwan, Kuala Lumpur, Jakarta and Seoul. WEBCC is an accredited Registrar for ICANN, China, Taiwan, Hong Kong, Malaysia, Singapore, the Philippines, Vietnam, EU, US and growing list of other country-code domain names.</p>';
				this.contactusValue[lang.code] = `We want to hear from you ! Your comments are always welcome at all times! <p>${this.storeService.getStoreName()}</p> <p>${this.storeService.getAddress()}</p> <p>${this.storeService.getPhoneNumber()}</p>`;
			});
		});

		if (this.route.snapshot.paramMap.get('id')) { 

			this.storefrontId = this.route.snapshot.paramMap.get('id');
			this.storeService.getStorefront(this.storefrontId).subscribe(respStore=>{

				this.pageTitle = respStore["data"]["custom_page"]["page_title"]
				this.customStatus = respStore["data"]["custom_page_status"]

				this.editorValue = respStore["data"]["custom_page"]["page_content"]
				this.aboutusValue = respStore["data"]["page"]["about_content"]
				this.contactusValue = respStore["data"]["page"]["contact_content"]

				// this.storeService.setStoreName(respStore["data"]["storefront_name"]);
				// this.storeService.setAddress(respStore["data"]["address"]);
				// this.storeService.setPhoneNumber(respStore["data"]["phone_number"]);
	
			},
			err => {
			},
			() => {
				this.loadEditor = true;
			});

			

			this.selectedPreview = this.storeService.getSelectedPreview();
			this.title = this.storeService.coverTitle;
			this.text = this.storeService.coverText;
			this.logo = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + this.storeService.logo);
			this.selectedThemePreview = this.storeService.previewThemeCss
			this.cover = this.storeService.customCover
			this.filestring = `url('data:image/jpeg;base64, ${this.storeService.customCover}')`;
			this.edited = true;
		} else {
			this.selectedPreview = this.storeService.getSelectedPreview();
			this.title = this.storeService.coverTitle
			this.text = this.storeService.coverText
			this.logo = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + this.storeService.logo);
			this.selectedThemePreview = this.storeService.previewThemeCss
			this.cover = this.storeService.customCover
			this.filestring = `url('data:image/jpeg;base64, ${this.storeService.customCover}')`;
			this.edited = false;
			this.loadEditor = true;
		}
		// this.selectedPreview = this.storeService.getSelectedPreview();
		// this.title = this.storeService.coverTitle
		// this.text = this.storeService.coverText
		// this.logo = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + this.storeService.logo);
		// this.selectedThemePreview = this.storeService.previewThemeCss
		// this.cover = this.storeService.customCover
		// this.filestring = `url('data:image/jpeg;base64, ${this.storeService.customCover}')`;
		console.log(this.filestring)
		console.log(this.selectedPreview)
		// appJS.init()

	}
	nextPressed() {
		console.log(this.aboutusValue)
		console.log(this.contactusValue)
		if (this.pageTitle !== '' && Object.keys(this.editorValue).length != 0) {
			
			this.storeService.setupCustomPageAndTitle(
				this.pageTitle, 
				this.editorValue, 
				this.aboutusValue, 
				this.contactusValue,
				this.customStatus
			);

			if(this.edited) {

				this.router.navigate(['/manage-storefront-4/'+this.storefrontId])
			} else {
				
				this.router.navigate(['/create-storefront-4']);
			}
			
		} else {
		}


	}

	toggleCustomPageButton() {
		if(this.customStatus == 1)
			this.customStatus = 0;
		else if(this.customStatus == 0) 
			this.customStatus = 1;
	}

}
