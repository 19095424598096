<span [ngSwitch]="type">

  <span *ngSwitchCase="'home'">
    <nav class="{{selectedThemePreview}} navbar navbar-expand-lg">
      <div class="container">
        <a class="navbar-brand" style="cursor: pointer;">
          <img class="logo" [src]="logo" alt="" height="20px">
        </a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto" style="font-size: 10px;">
            <li class="active">
              <a class="nav-link" style="cursor: pointer;">Home <span class="sr-only">(current)</span></a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">About Company</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Contact Us</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Custom Page</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Login</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Sign Up</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="jumbotron jumbotron-fluid bg--overlay" [ngStyle]="{'background-image': 'url(' + selectedPreview + ')'}"
         style="height: 550px; background-size: cover; " *ngIf="(!cover)">
      <div class="container text-light col-md-4" style="margin-left: 65px">
        <h3 class="display-4" style="font-weight: 700; text-transform: uppercase; font-size: 40px">{{title}}</h3>
        <p class="lead">{{text}}</p>
      </div>
    </div>

    <div class="jumbotron jumbotron-fluid bg--overlay text-light" [ngStyle]="{'background-image': filestring}" style="height: 550px; background-size: cover; " *ngIf="(filestring != '' && cover)">
      <div class="container">
        <h3 class="display-4">{{title}}</h3>
        <p class="lead">{{text}}</p>
      </div>
    </div> 
  </span>

  <span *ngSwitchCase="'product'">
    <nav class="{{selectedThemePreview}} navbar navbar-expand-lg">
      <div class="container">
        <a class="navbar-brand" style="cursor: pointer;">
          <img class="logo" [src]="logo" alt="" height="20px">
        </a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto" style="font-size: 10px;">
            <li class="active">
              <a class="nav-link" style="cursor: pointer;">Home <span class="sr-only">(current)</span></a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">About Company</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Contact Us</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Custom Page</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Login</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Sign Up</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="jumbotron jumbotron-fluid bg--img-blue"
         style="height:400px; background-size: cover; background-color: #3E4E65" *ngIf="!this.filestring">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="video-container">
              <img class="image-container" src="../../assets/video/ss.png" alt="Screeshot">
            </div>
          </div>

          <div class="col-lg-6">
            <div class="get-started-content">
              <ul class="nav nav-tabs nav-fill nav-justified" id="myTab" role="tablist">
                <li class="nav-item active">
                  <a class="nav-link active" id="startup-tab" data-bs-toggle="tab" href="#startup" role="tab">
                    Startup / Small Business
                    <small>Light usage with Cloud Office and Coorporate
                      email.</small>
                  </a>
                </li>
              </ul>

              <div class="tab-content" id="getStartedTabContent">
                <div class="tab-pane fade show active" id="startup" role="tabpanel">
                  <div class="checkbox-container">
                    <div *ngFor="let option of optionStartup">
                      <input type="checkbox" name="optionStartup" value="{{option.value}}" [(ngModel)]="option.checked"
                             id="startup-{{option.value}}" [attr.disabled]="option.preselect ? '' : null" />
                      <label for="startup-{{option.value}}" class="bg-checkbox-dark"></label>
                      <h6>
                        {{ option.title }}
                      </h6>
                    </div>
                  </div>

                  <h4 class="text-center"><small>From</small> USD 45</h4>

                  <div class="get-started-bar mt-3">
                    <a class="btn btn-custom-white-outline hvr-fade m-auto">Get Started</a>
                  </div>
                </div>

                <div class="tab-pane fade" id="enterprise" role="tabpanel">
                  <div class="checkbox-container">
                    <div *ngFor="let option of optionEntreprise">
                      <input type="checkbox" name="packages" id="entreprise-{{option.value}}" name="optionEnterprise"
                             value="{{option.value}}" [attr.disabled]="option.preselect ? '' : null" />
                      <label for="entreprise-{{option.value}}" class="bg-checkbox-dark"></label>
                      <h6>
                        {{ option.title }}
                      </h6>
                    </div>
                  </div>

                  <h4 class="text-center"><small>From</small> USD 45</h4>

                  <div class="get-started-bar mt-3">
                    <a class="btn btn-custom-white-outline hvr-fade m-auto">Get Started</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>

  <span *ngSwitchDefault>
    <nav class="{{selectedThemePreview}} navbar navbar-expand-lg">
      <div class="container">
        <a class="navbar-brand" style="cursor: pointer;">
          <img class="logo" [src]="logo" alt="" height="20px">
        </a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto" style="font-size: 10px;">
            <li class="active">
              <a class="nav-link" style="cursor: pointer;">Home <span class="sr-only">(current)</span></a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">About Company</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Contact Us</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Custom Page</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Login</a>
            </li>
            <li>
              <a class="nav-link" style="cursor: pointer;">Sign Up</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="jumbotron jumbotron-fluid bg--overlay" [ngStyle]="{'background-image': 'url(' + selectedPreview + ')'}"
         style="height:400px; background-size: cover; " *ngIf="!this.filestring">
      <div class="container text-light">
        <h3 class="display-4">{{title}}</h3>
        <p class="lead">{{text}}</p>
      </div>
    </div>
  </span>

</span>
