<main id="storefront" class="main create-storefront">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'o365.orderMO365' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.stepfour' | translate }}</li>
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 15%">
      </div>
    </div>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">

        <div class="col-lg-12">

          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorCheck">
            <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            {{ 'error.contactsupport' | translate }}
            <button type="button" class="close" (click)="closePressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <form class="form" style="overflow-y: visible">

            <!-- <form class="form" [formGroup]="storeFrontDetails"> -->
            <div class="col-lg-12 mt-3">
              <h2>{{ 'word.orderconfirm' | translate }}</h2>

              <div class="card mt-4 pl-2 pr-2">
                <div class="card-body">

                  <table class="table">
                    <tbody>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'word.username' | translate }}
                        </td>
                        <td>
                          {{ data.username }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'word.password' | translate }}
                        </td>
                        <td>
                          {{ data.password }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'word.companyname' | translate }}
                        </td>
                        <td>
                          {{ data.company_name }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'o365.contactemail' | translate }}
                        </td>
                        <td>
                          {{ data.contact_email_address }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'o365.alternateemail' | translate }}
                        </td>
                        <td>
                          {{ data.alt_email_address }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'o365.contactfirstname' | translate }}
                        </td>
                        <td>
                          {{ data.contact_first_name }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'o365.contactlastname' | translate }}
                        </td>
                        <td>
                          {{ data.contact_last_name }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'o365.contactphone' | translate }}
                        </td>
                        <td>
                          {{ data.contact_phone_no }}
                        </td>
                      </tr>
                      <tr *ngIf="!data.use_transit_tenant">
                        <td style="font-weight: bold">
                          {{ 'o365.defaultdomainprefix' | translate }}
                        </td>
                        <td>
                          {{ data.default_domain_prefix }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'o365.stepfour.includemyself' | translate }}
                        </td>
                        <td>
                          {{ data.include_partner === true ? ('word.yes' | translate) : ('word.no' | translate) }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'word.autorenew' | translate }}
                        </td>
                        <td *ngIf="auto_renew == 1">{{ 'word.enabled' | translate }}</td>
                        <td *ngIf="auto_renew == 0">{{ 'word.disabled' | translate }}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'o365.quantity' | translate }}
                        </td>
                        <td>
                          {{ orderData.quantity }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'word.duration' | translate }}
                        </td>
                        <td>
                          {{ orderData.duration }} {{ 'word.month' | translate }}
                        </td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'word.totalprice' | translate }}
                        </td>
                        <td *ngIf="orderData?.duration == 1">USD {{ price_monthly * orderData.quantity | number:'1.2-2' }} {{ 'word.monthly' | translate }}</td>
                        <td *ngIf="orderData?.duration == 12">USD {{ price_annually * orderData.quantity | number:'1.2-2' }} {{ 'word.yearly' | translate }}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'o365.currentbalance' | translate }}
                        </td>
                        <td>USD {{ currentBalance | number:'1.2-2' }}</td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold">
                          {{ 'o365.leftoverbalance' | translate }}
                        </td>
                        <td *ngIf="orderData?.duration == 1">USD {{ (currentBalance - (price_monthly * orderData.quantity)) | number:'1.2-2' }}</td>
                        <td *ngIf="orderData?.duration == 12">USD {{ (currentBalance - (price_annually * orderData.quantity)) | number:'1.2-2' }}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="float-right">
                <button class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()" [disabled]="nextBtnPressed">
                  {{ 'button.next' | translate }} <i class="fa fa-cog fa-spin" style="font-size:24px;color: lightseagreen" *ngIf="nextBtnPressed"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>
