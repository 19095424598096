import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StepModel } from '../../../../../model/step.model';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { UserLicenses } from '../../../../../model/microsoftcsp/new-user/user-licenses.model';
import { atLeastOneCheckboxCheckedValidator } from './atLeastOneCheckboxCheckedValidator';

@Component({
  selector: 'app-microsoftcsp-user-new-licenses',
  templateUrl: './microsoftcsp-user-new-licenses.component.html',
  styleUrls: ['./microsoftcsp-user-new-licenses.component.css']
})
export class MicrosoftCSPUserNewLicensesComponent implements OnInit {

  @Input() step: StepModel;
  @Output() newUserLicensesFormChanged: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() stepChanged: EventEmitter<any> = new EventEmitter<any>();

  newUserLicensesForm: UntypedFormGroup = null;

  tenantId: number;
  usageLocations: Array<{ DisplayName: string, CountryCode: string, IsDefault: boolean }> = undefined;
  subscribedSkus: any[] = undefined;
  // selectedSubscribedSkus: Set<string> = new Set<string>();
  selectedSubscribedSkusIds: string[] = [];
  licensingServicePlanReferences: any[];

  usageLocationLoading = false;
  subscribedSkuLoading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      this.createNewUserLicensesForm();
      this.getUsageLocations();
      this.getSubscribedSkus();

    });
  }

  //   getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
  //     let hasError = false;
  //     const result = Object.keys(form.controls).reduce((acc, key) => {
  //         const control = form.get(key);
  //         const errors = (control instanceof FormGroup || control instanceof FormArray)
  //             ? this.getAllErrors(control)
  //             : control.errors;
  //         if (errors) {
  //             acc[key] = errors;
  //             hasError = true;
  //         }
  //         return acc;
  //     }, {} as { [key: string]: any; });
  //     return hasError ? result : null;
  // }

  onCompleteStep() {
    if (this.newUserLicensesForm.valid) {
      if (this.createUserWithLicenseFormControl.value === true) {
        if (!(this.selectedSubscribedSkusIds.length > 0)) {
          return;
        } else {
          this.newUserLicensesForm.addControl('addLicenses', this.buildAddLicensesFormArray());
        }
      } else {
        this.newUserLicensesForm.removeControl('addLicenses');
      }
      this.newUserLicensesFormChanged.emit(this.newUserLicensesForm);
      this.step.isComplete = true;
    }
  }

  onBackStep() {
    this.step.isComplete = false;
    this.stepChanged.emit();
  }

  createNewUserLicensesForm() {
    this.newUserLicensesForm = this.formBuilder.group({
      usageLocation: ['', [Validators.required]],
      location: [''],
      createUserWithLicense: [true, [Validators.required]]
    });
  }

  // buildSubscribedSkusFormGroup(subscribedSkus: any[], selectedSubscribedSkus: string[] = []): FormGroup {
  //   let group = this.formBuilder.group({}, {
  //     validators: atLeastOneCheckboxCheckedValidator(),
  //   });
  //   subscribedSkus.forEach(subscribedSku => {
  //     let isSelected = selectedSubscribedSkus.some(id => id === subscribedSku.id);
  //     group.addControl(subscribedSku.id, this.formBuilder.control(isSelected));
  //   });
  //   return group;
  // }

  buildSubscribedSkusFormArray(subscribedSkus: any[], selectedSubscribedSkus: any[] = []): UntypedFormArray {
    const controlArr = subscribedSkus.map(subsribedSku => {
      const isSelected = selectedSubscribedSkus.some(selectedSubscribeSku => selectedSubscribeSku.skuId === subsribedSku.skuId);
      return this.formBuilder.control(isSelected);
    })
    return this.formBuilder.array(controlArr, atLeastOneCheckboxCheckedValidator())
  }

  buildAddLicensesFormArray(): UntypedFormArray {
    this.newUserLicensesForm.removeControl('addLicenses');
    const controlArr = this.subscribedSkus
      .filter((cat, catIdx) => this.subscribedSkusFormArray.controls.some((control, controlIdx) => catIdx === controlIdx && control.value))
      .map(cat => {
        return this.formBuilder.control({ disabledPlans: [], skuId: cat.skuId, friendlyName: cat.friendlyName });
      });
    return this.formBuilder.array(controlArr);
  }

  onUsageLocationChange(event: any) {
    this.usageLocationFormControl.patchValue(event.target.value);
    this.locationFormControl.patchValue(this.usageLocations.find(usageLocation => usageLocation.CountryCode === event.target.value).DisplayName);
  }

  onCreateUserWithLicenseChange() {
    if (this.createUserWithLicenseFormControl.value === true) {
      this.newUserLicensesForm.addControl('subscribedSkusFormArray', this.buildSubscribedSkusFormArray(this.subscribedSkus, this.subscribedSkus));
      this.onSubscribedSkuChange();
    } else {
      this.newUserLicensesForm.removeControl('subscribedSkusFormArray');
      this.selectedSubscribedSkusIds = [];
    }
  }

  onSubscribedSkuChange() {
    this.selectedSubscribedSkusIds = this.subscribedSkusFormArraySelectedIds;
  }

  get f() { return this.newUserLicensesForm && this.newUserLicensesForm.controls; }
  get usageLocationFormControl() { return this.newUserLicensesForm.get('usageLocation'); }
  get locationFormControl() { return this.newUserLicensesForm.get('location'); }
  get createUserWithLicenseFormControl() { return this.newUserLicensesForm.get('createUserWithLicense'); }
  // get subscribedSkusFormGroup(): FormGroup { return this.f && <FormGroup>this.f.subscribedSkusFormGroup; }
  get subscribedSkusFormArray(): UntypedFormArray { return this.f && <UntypedFormArray>this.f.subscribedSkusFormArray; }

  // get subscribedSkusFormGroupSelectedIds(): string[] {
  //   let ids: string[] = [];
  //   for (var key in this.subscribedSkusFormGroup.controls) {
  //     if (this.subscribedSkusFormGroup.controls[key].value) {
  //       ids.push(key);
  //     }
  //   }
  //   return ids;
  // }

  get subscribedSkusFormArraySelectedIds(): string[] {
    return this.subscribedSkus
      .filter((cat, catIdx) => this.subscribedSkusFormArray.controls.some((control, controlIdx) => catIdx === controlIdx && control.value))
      .map(cat => cat.skuId);
  }

  getUsageLocations() {
    this.usageLocationLoading = true;

    this.microsoftGraphApiService.getUsageLocations()
      .subscribe((res: any) => {
        this.usageLocations = res;
        this.usageLocationLoading = false;

        const defaultUsageLocation = this.usageLocations.find(usageLocation => usageLocation.IsDefault);
        this.usageLocationFormControl.setValue(defaultUsageLocation.CountryCode, { onlySelf: true });
        this.usageLocationFormControl.updateValueAndValidity();

        this.locationFormControl.setValue(defaultUsageLocation.DisplayName, { onlySelf: true });
        this.locationFormControl.updateValueAndValidity();
      });
  }

  getSubscribedSkus() {
    this.subscribedSkuLoading = true;

    this.microsoftGraphApiService.getLicensingServicePlanReference()
      .subscribe((references: any) => {

        this.licensingServicePlanReferences = references;

        this.microsoftGraphApiService.getSubscribedSkus(this.tenantId.toString())
          .subscribe((res: any) => {
            this.subscribedSkus = res.value;
            this.subscribedSkus = this.subscribedSkus.map(subscribedSku => {

              const reference = this.licensingServicePlanReferences.find(ref => {
                return ref['String ID'] === subscribedSku.skuPartNumber;
              });

              if (reference === undefined || reference === null) {
                subscribedSku.friendlyName = subscribedSku.skuPartNumber;
              } else {
                subscribedSku.friendlyName = reference['Product name'];
              }
              return subscribedSku;
            });

            this.subscribedSkuLoading = false;

            // this.newUserLicensesForm.addControl('subscribedSkusFormGroup', this.buildSubscribedSkusFormGroup(this.subscribedSkus));
            this.newUserLicensesForm.addControl('subscribedSkusFormArray', this.buildSubscribedSkusFormArray(this.subscribedSkus, this.subscribedSkus));
            this.onSubscribedSkuChange();
          }, err => {
            console.error('getSubscribedSkus error', err);
          });
      });

  }

}
