<main id="microsoftcsp-subscription-create" class="main">

    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
      <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants">{{ 'word.tenants' | translate }}</li>
      <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants/{{tenantId}}">{{ 'o365.tenantdetails' | translate }}</li>
      <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants/{{tenantId}}/subscriptions">{{ 'word.subscription' | translate }}</li>
      <li class="breadcrumb-item active">{{ 'o365.createnewsubscription' | translate }}</li>
    </ol>

    <div class="container-fluid">
      <div class="animated fadeIn">

        <div class="row" *ngIf="errorCheck">
          <div class="col-12">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
              <ul>
                <li *ngFor="let error of errors">
                  {{ error }}
                </li>
              </ul>
              <button type="button" class="close" (click)="errorClosePressed()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="successCheck">
          <div class="col-12">
            <div class="alert alert-success alert-dismissible fade show" role="alert">
              {{successMessage}}
              <button type="button" class="close" (click)="successClosePressed()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-12 mb-3">
            <h3>{{ 'word.addsubscription' | translate }}</h3>
          </div>

          <div class="col-12">
            <div class="card" style="min-height: 350px;">
              <div class="card-body">

                <form class="form" [formGroup]="addSubscriptionForm" style="overflow-y: visible">

                  <div class="row mt-2">

                    <div class="col-lg-7 col-md-12 col-12">

                      <div class="row">
                        <div class="col-12 mb-3 d-flex justify-content-between align-items-center">
                          <div>
                            <span>{{ 'word.addsubscription' | translate }}</span>
                          </div>
                          <div class="btn-group" role="group" (change)="onSubscriptionTypeChanged($event.target.value)" name="subscriptionType" >
                            <input type="radio" id="monthlyRadio" class="btn-check"  value="Monthly" autocomplete="off" formControlName="subscriptionType">
                            <label class="btn btn-primary" for="monthlyRadio">{{ 'word.monthly' | translate }}</label>
                          
                            <input type="radio" id="annuallyRadio" class="btn-check" value="Annual" autocomplete="off" formControlName="subscriptionType">
                            <label class="btn btn-primary" for="annuallyRadio">{{ 'word.annual' | translate }}</label>
                          </div>
                        </div>

                        <div class="col-12">

                          <div class="card border-0" *ngIf="!loading">
                            <div class="card-header border-0 py-3">
                              <span style="font-weight: 700; font-size: 1rem;">{{ 'word.microsoftoffice365' | translate }}</span>
                            </div>
                            <div class="card-body px-0 py-1">

                              <div *ngFor="let product of filteredProducts" class="card p-0 mb-1" [ngClass]="{'product-selected': product.product_id == selectedProduct?.product_id }"
                              (click)="selectProduct(product)" style="cursor: pointer;">
                                <div class="card-body">
                                  <div class="row h-100 align-items-center">
                                    <div class="col-7">
                                      {{ product.product_name }}
                                    </div>
                                    <div class="col-5 pl-0 text-right">
                                      <span *ngIf="subscriptionTypeFormControl.value == 'Monthly';else showAnnuallyPrice">
                                        {{ product.product_price?.monthly ? product.product_price?.monthly + ' USD ': 'n/a' }} {{ 'word.perusermonth' | translate}}
                                      </span>
                                      <ng-template #showAnnuallyPrice>
                                        <span>
                                          {{ product.product_price?.annually ? product.product_price?.annually + ' USD ' : 'n/a' }} {{ 'word.peruseryear' | translate}}
                                        </span>
                                      </ng-template>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                    <div class="col-lg-5 col-md-12 col-12">
                      <div class="card card-body">
                        <div class="row">
                          <div class="col-12">
                            <h5>{{ 'word.ordersummary' | translate }}</h5>
                          </div>
                        </div>
                        <ng-template #noProductSelected>
                          <div class="row mt-4">
                            <div class="col-12">
                              {{ 'error.noproductselected' | translate }}
                            </div>
                          </div>
                        </ng-template>

                        <div class="row mt-3" *ngIf="selectedProduct && !orderSummaryLoading">
                          <div class="col-12 d-flex justify-content-between align-items-center" style="font-weight: bolder; font-size: larger;">
                            <span>{{ 'word.currentbalance' | translate }}</span>
                            <span *ngIf="showInvalidTotalPrice;else showValidTotalPrice">n/a</span>
                            <ng-template #showValidTotalPrice >
                              <span>{{currentBalance | number:'1.2-2'}} USD</span>
                            </ng-template>
                          </div>
                          <div class="col-12">
                            <hr/>
                          </div>
                        </div>

                        <div class="row mt-4 mb-2" style="min-height:50px;" *ngIf="selectedProduct; else noProductSelected">

                          <ng-template #orderSummarySectionLoading>
                            <div class="col-12 text-center">
                              <div class="lds-roller" style="margin: auto"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                          </ng-template>

                          <ng-container *ngIf="!orderSummaryLoading; else orderSummarySectionLoading">
                            <div class="col-lg-7 col-md-8 col-8">
                              {{selectedProduct?.product_name}}
                            </div>
                            <div class="col-lg-5 col-md-4 col-4 pl-0">
                              <div class="input-group" style="min-width: 60px;">
                                <div class="input-group-prepend">
                                  <button class="btn btn-sm btn-dark border border-dark" style="padding: 0.1rem 0.25rem;"
                                  (click)="increaseOrDecreaseLicenseCount('decrease')" type="button">
                                    <i class="fa fa-minus"></i>
                                  </button>
                                </div>
                                <input formControlName="quantity" id="quantityId" type="number"
                                  class="form-control form-control-sm text-center no-spinner no-focus-shadow"
                                  (change)="updateTotalPrice()" style="padding: 0.1rem 0.25rem;"
                                  [ngClass]=" {'is-invalid': quantityFormControl.touched && quantityFormControl.invalid }" />
                                <div class="input-group-append">
                                  <button class="btn btn-sm btn-dark border border-dark" style="padding: 0.1rem 0.25rem;"
                                  (click)="increaseOrDecreaseLicenseCount('increase')" type="button">
                                    <i class="fa fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </ng-container>

                        </div>

                        <div class="row" *ngIf="selectedProduct && !orderSummaryLoading">
                          <div class="col-12">
                            <hr/>
                          </div>
                          <div class="col-12 d-flex justify-content-between align-items-center" style="font-weight: bolder; font-size: larger;">
                            <span>{{ 'word.totalprice' | translate }}</span>
                            <span *ngIf="showInvalidTotalPrice;else showValidTotalPrice">n/a</span>
                            <ng-template #showValidTotalPrice >
                              <span>{{totalPrice | number:'1.2-2'}} USD</span>
                            </ng-template>
                          </div>
                        </div>

                        <div class="row" *ngIf="selectedProduct && !orderSummaryLoading">
                          <div class="col-12">
                            <hr/>
                          </div>
                          <div class="col-12 d-flex justify-content-between align-items-center" style="font-weight: bolder; font-size: larger;">
                            <span>{{ 'o365.leftoverbalance' | translate }}</span>
                            <span *ngIf="showInvalidTotalPrice;else showValidTotalPrice">n/a</span>
                            <ng-template #showValidTotalPrice >
                              <span>{{currentBalance - totalPrice | number:'1.2-2'}} USD</span>
                            </ng-template>
                          </div>
                        </div>

                      </div>
                      <div class="mt-4">
                        <button class="btn btn-dark btn-block" type="button" (click)="submit()"
                        [disabled]="addSubscriptionForm.invalid || showInvalidTotalPrice || submitPressed || orderSummaryLoading" >
                        {{ 'button.submit' | translate }} <i *ngIf="submitPressed" class="fa fa-spinner fa-spin"></i>
                      </button>
                      </div>
                    </div>

                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </main>
