
    <main class="main">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item active">{{ 'word.dashboard' | translate }}</li>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">

                <div class="col-sm-6 col-lg-3" (click)="onStatisticBoxPressed('totalOrder')" style="cursor: pointer">
                  <div class="card text-white bg-info">
                    <a  class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-basket-loaded"></i>
                        </div>
                        <div class="h4 mb-0">{{ info?.TotalOrder }}</div>
                        <small class="text-muted text-uppercase font-weight-bold">{{ 'word.domains' | translate }}</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'email.totaldomains' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->

                <div class="col-sm-6 col-lg-3" (click)="onStatisticBoxPressed('totalLicense')"  style="cursor: pointer">
                  <div class="card text-white bg-primary">
                    <a  class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-basket-loaded"></i>
                        </div>
                        <div class="h4 mb-0">{{ info?.TotalLicense }}</div>
                        <small class="text-muted text-uppercase font-weight-bold">{{ 'word.licenses' | translate }}</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'email.totallicenses' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->

                <div class="col-sm-6 col-lg-3" (click)="onStatisticBoxPressed('totalExpiring')" style="cursor: pointer">
                  <div class="card text-white bg-danger">
                    <a class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-credit-card"></i>
                        </div>
                        <div class="h4 mb-0">{{ info?.TotalExpiring }}</div>
                        <small class="text-muted text-uppercase font-weight-bold">{{ 'word.licenses' | translate }}</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'email.expiringlicenses' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->

                <div class="col-sm-6 col-lg-3" (click)="onStatisticBoxPressed('totalExpired')" style="cursor: pointer">
                  <div class="card text-white bg-warning">
                    <a class="card-link">
                      <div class="card-body">
                        <div class="h1 text-muted text-right mb-4">
                          <i class="icon-wrench"></i>
                        </div>
                        <div class="h4 mb-0">{{ info?.TotalExpired }}</div>
                        <small class="text-muted text-uppercase font-weight-bold">{{ 'word.licenses' | translate }}</small>
                        <div class="progress progress-white progress-xs mt-3">
                          <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p class="mt-3">{{ 'email.expiredlicenses' | translate }}</p>
                      </div>
                    </a>
                  </div>
                </div>
                <!--/.col-->

              </div>
              <!--/.row-->

            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </main>