import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class StoreService {
	storefrontName;
	storefrontCode;
	department;
	address;
	email;
	phoneNumber;
	stateId;
	countryId;
	selectedCover;
	coverTitle;
	coverText;
	themeId;
	customPageStatus;
	selectedPreview;
	pageContent;
	pageTitle;
	monthlyPrice;
  yearlyPrice;
  selectedCategoryIds: any[] = [];
  selectedProducts;
  selectedPaymentMethodIds: any[] = [];
  paymentOptions: any[] = [];
	logo;
	customCover;
	previewThemeCss
	aboutusValue;
	contactusValue;
	customDomain;


	public updatedLogo = false;

	apiUrl = environment.apiUrl;
  	mainApiUrl = environment.mainApiUrl;

	coverObject: any;

	selectedO365Products: any[] = [];
	selectedAlibabaMailProducts: any[] = [];


	constructor(private http: HttpClient) { }

	initializeStore(
		storefrontName,
		storefrontCode,
		department,
		address,
		email,
		phoneNumber,
		stateId,
		countryId,
		coverTitle,
		coverText,
		logo,
		customDomain?,
		themeId?,
		coverObject?,
		selectedPreview?,
		customCover?,
		previewThemeCss?,
		pageTitle?,
		pageContent?
	) {
		this.storefrontName = storefrontName;
		this.storefrontCode = storefrontCode;
		this.department = department;
		this.address = address;
		this.email = email;
		this.phoneNumber = phoneNumber;
		this.stateId = stateId;
		this.countryId = countryId;
		this.coverTitle = coverTitle;
		this.coverText = coverText;
		this.logo = logo;
		this.themeId = themeId;
		this.coverObject = coverObject;
		this.selectedPreview = selectedPreview;
		this.customCover = customCover;
		this.previewThemeCss = previewThemeCss
		this.pageTitle = pageTitle;
		this.pageContent = pageContent;
		this.customDomain = customDomain;
	}

	setupThemeAndCover(themeId, coverId, selectedPreview, customCover, previewThemeCss) {
		this.themeId = themeId;
		this.selectedCover = coverId;
		this.selectedPreview = selectedPreview;
		this.customCover = customCover;
		this.previewThemeCss = previewThemeCss
	}

	setupCustomPageAndTitle(pageTitle, pageContent, aboutusValue, contactusValue, customPageStatus) {
		this.pageTitle = pageTitle;
		this.pageContent = pageContent;
		this.aboutusValue = aboutusValue;
		this.contactusValue = contactusValue;
		this.customPageStatus = customPageStatus;
  }

  setupCategories(selectedCategoryIds: any[] = []) {
    this.selectedCategoryIds = selectedCategoryIds;
  }

	setupPrice(monthlyPrice, yearlyPrice, selectedProducts) {
		this.monthlyPrice = monthlyPrice;
		this.yearlyPrice = yearlyPrice;
		this.selectedProducts = selectedProducts;
  }

  setupPaymentMethods(selectedPaymentMethodIds: any[] = []) {
    this.selectedPaymentMethodIds = selectedPaymentMethodIds;
  }

  getPaymentMethods(): any[] {
    return this.selectedPaymentMethodIds;
  }

  setupPaymentOption(paymentId: number, paymentOption: any): void {
    this.paymentOptions[paymentId] = paymentOption;
  }

	getSelectedPreview() {
		return this.selectedPreview;
	}

	getStorefront(id){
		const httpHeaders = {
			headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
		};
		return this.http.get(
			`${this.apiUrl}storefront/get-storefront-by-id/`+id,
			httpHeaders
			);

	}

	checkStorefrontExist(storefrontCode) {
		const httpHeaders = {
			headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
		};

		const data = {
			storefront_code: storefrontCode
		};

		return this.http.post(
			`${this.apiUrl}storefront/check-storefront-exists`,
			data,
			httpHeaders
			);
	}

	checkCustomDomainExists(domain) {
		const httpHeaders = {
			headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
		};

		const data = {
			'custom_domain': domain
		};

		return this.http.post(
			`${this.apiUrl}storefront/check-custom-domain-exists`,
			data,
			httpHeaders
		);
	}

	setNewLog(boolean) {
		this.updatedLogo = boolean
	}
	getNewLog() {
		return this.updatedLogo;
	}

	setStoreName(storefrontName) {
		this.storefrontName = storefrontName;
	}

	getStoreName() {
		return this.storefrontName;
	}

	setAddress(address) {
		this.address = address;
	}

	getAddress() {
		return this.address;
	}

	setPhoneNumber(phoneNumber) {
		this.phoneNumber = phoneNumber;
	}

	getPhoneNumber() {
		return this.phoneNumber;
	}

	getLanguages() {
		const httpHeaders = {
			headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('access_token'))
		};

		return this.http.get(
			`${this.mainApiUrl}languages`,
			httpHeaders
		);
	}


	/////////////////////////////////////
	getSelectedCategoryIds(){
		return this.selectedCategoryIds;
	}

	saveSelectedProducts(o365Products:any[], alibabaMailProducts:any[]){
		this.selectedO365Products = o365Products;
		this.selectedAlibabaMailProducts = alibabaMailProducts;
	}

	getAllSelectedProducts(){
		return {
			selectedO365Products: this.selectedO365Products,
			selectedAlibabaMailProducts: this.selectedAlibabaMailProducts
		}
	}
}
