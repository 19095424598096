<main class="main">

  <ol class="breadcrumb"></ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">

        <ng-template #loadingSpinner>
          <div class="col-12 text-center">
            <div class="card card-body" style="min-height: 400px;">
              <div class="lds-roller" style="margin: auto">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">

                <div class="col-12 mb-4">
                  <h3>{{ 'o365.adminconsent' | translate }}</h3>
                </div>

                <!-- Success -->
                <div class="col-12" *ngIf="grantProcessComplete && !pageLoading">
                  <h4>{{ 'success.permissiongranted' | translate }}</h4>
                  <p>{{ 'success.proceedaccess' | translate }}</p>
                </div>

                <div class="col-12 mt-2 mb-2" *ngIf="grantProcessComplete && !pageLoading">
                  <button type="button" class="btn btn-primary" (click)="goToTenantDetails()">{{ 'button.gotenantdetails' | translate }}</button>
                </div>

                <!-- Failed -->
                <div class="col-12" *ngIf="!grantProcessComplete && !pageLoading">
                  <h4>{{ 'error.grantfailed' | translate }}</h4>
                  <p>
                    <i>{{ 'error.donebytenantadmin' | translate }}</i>
                  </p>
                </div>

                <div class="col-12 mt-2 mb-2" *ngIf="!grantProcessComplete && !pageLoading">
                  <button type="button" class="btn btn-primary" (click)="goToTenantManagement()">{{ 'button.gotenantmanagement' | translate }}</button>
                </div>


              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</main>
