<main id="storefront-product-promotion-step-three" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'word.products' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.promotion' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">

        <ng-template #loadingSpinner>
          <div class="col-12 text-center">
            <div class="card card-body" style="min-height: 320px;">
              <div class="lds-roller" style="margin: auto">
                  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="col-12" *ngIf="!loading; else loadingSpinner">
          <div class="card" style="min-height: 320px;">
            <div class="card-body">

              <form #microsoftCSPForm="ngForm" style="width: 100%;">

                <div class="row mb-4">
                  <div class="col-12">
                     <span style="font-weight: bold;font-size: 1.2rem;">{{o365Product.product_name}}</span>
                  </div>
                </div>

                <div class="row h-100 align-items-center">
                  <div class="col-12" *ngFor="let promotion of productPromotions">
                    <div class="card card-body mb-1" ngModelGroup="promotionPrices_{{promotion.productId}}_{{promotion.countryIso2Code}}"
                    #promotionPrices="ngModelGroup">

                      <div class="row">

                        <div class="col-lg-5 col-md-5 col-12">
                          <div class="d-flex flex-column h-100 justify-content-center text-left">
                            <span class="mb-2" style="font-weight: bold;">{{promotion.countryName}}</span>
                            <span>{{promotion.startdate | date: 'yyyy/MM/dd HH:mm:ss' }} - {{promotion.expirationdate | date: 'yyyy/MM/dd HH:mm:ss'}}</span>
                          </div>
                        </div>

                        <div class="col-lg-7 col-md-7 col-12">
                          <div class="row">
                            <div class="col-12">
                              <div class="horizontal-scrollable">
                                <div class="row-item">
                                  <div class="col-item col-item-title"></div>
                                  <div class="col-item">{{ 'word.monthly' | translate }}</div>
                                  <div class="col-item">{{ 'word.annual' | translate }}</div>
                                </div>
                                <div class="row-item">
                                  <div class="col-item col-item-title">{{ 'o365.promocost' | translate }}</div>
                                  <div class="col-item">
                                    <input [value]="promotion.monthly" class="form-control" type="text"
                                      [disabled]="true" readonly>
                                  </div>
                                  <div class="col-item">
                                    <input [value]="promotion.annually" class="form-control" type="text"
                                      [disabled]="true" readonly>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-12 text-right">
          <button class="btn btn-dark btn-lg px-5 mb-4" (click)="back()">
            {{ 'button.back' | translate }}
          </button>
        </div>
      </div>

    </div>
  </div>
</main>
