<main id="storefront" class="main create-storefront">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'sf.storefrontsettings' | translate }}</li>
    <li class="breadcrumb-item active" *ngIf="!isManageStorefront">{{ 'sf.createstorefront' | translate }}</li>
    <li class="breadcrumb-item active" *ngIf="isManageStorefront">{{ 'sf.editstorefront' | translate }}</li>


    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%">
      </div>
    </div>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn" style="min-height: 300px;">

      <div class="row">

          <div class="col-12" *ngIf="showError">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>{{ 'error.inputerror' | translate }}:</strong>
              <ul>
                <li *ngFor="let error of errorMessages">
                  {{ error }}
                </li>
              </ul>
              <button type="button" class="close" (click)="errorClosePressed()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

        <ng-container *ngIf="enableO365Setup || enableAlibabaMailSetup; else noCategoriesSetup">
          <div class="col-12 mx-0" *ngIf="isManageStorefront; else createStorefrontInfo">
            <div class="card card-body config-price-info">
              <span class="mb-2"><span class="ml-1 mr-1">&#9642;</span>{{ 'sf.configprice.info1' | translate }} <strong>'{{ 'word.products' | translate }}' > '{{ 'word.pricing' | translate }}'</strong>. </span>
              <span><span class="ml-1 mr-1">&#9642;</span>{{ 'sf.configprice.info2' | translate }} <strong>'{{ 'word.products' | translate }}' > '{{ 'word.promotion' | translate }}'</strong> {{ 'sf.configprice.info3' | translate }} </span>
            </div>
          </div>

          <ng-template #createStorefrontInfo>
            <div class="col-12 mx-0">
              <div class="card card-body config-price-info">
                <span class="mb-2"><span class="ml-1 mr-1">&#9642;</span>{{ 'sf.configprice.info1' | translate }} <strong>'{{ 'word.products' | translate }}' > '{{ 'word.pricing' | translate }}'</strong> {{ 'sf.configprice.info4' | translate }} </span>
                <span><span class="ml-1 mr-1">&#9642;</span>{{ 'sf.configprice.info2' | translate }} <strong>'{{ 'word.products' | translate }}' > '{{ 'word.promotion' | translate }}'</strong> {{ 'sf.configprice.info5' | translate }} </span>
              </div>
            </div>
          </ng-template>
        </ng-container>

        <ng-template #noCategoriesSetup>
          <div class="col-12 mx-0">
            <div class="card card-body setup-products-danger">
              <span class="mb-2">{{ 'word.error.oops' | translate }}  {{ 'word.error.somethingwrong' | translate }} </span>
              <span style="font-weight: bolder;">{{ 'error.productnotfound' | translate }} </span>
            </div>
          </div>
        </ng-template>



        <div class="col-12" *ngIf="enableO365Setup">

          <div class="card collapsable">
            <div class="card-header" (click)="isO365Collapsed = !isO365Collapsed">
              <span class="card-header-text">Microsoft CSP</span>
              <div class="card-action-collapse">
                <a>
                  <i style="font-size: 20px;" [ngClass]="isO365Collapsed? 'fa fa-caret-down': 'fa fa-caret-up' "></i>
                </a>
              </div>
            </div>
            <div id="o365-panel" class="card-body" [@smoothCollapse]="isO365Collapsed?'hide':'show'">
              <ng-template #o365LoadingSpinner>
                <div class="row" style="min-height: 150px">
                  <div class="lds-roller" style="margin: auto"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                  </div>
                </div>
              </ng-template>
              <div class="row" *ngIf="!o365Loading; else o365LoadingSpinner">

                <div *ngIf="o365Products && o365Products.length < 1" class="col-12">
                  <span>{{ 'word.error.oops' | translate }} {{ 'word.noavailableproduct' | translate }}</span>
                </div>

                <div *ngIf="o365Products && o365Products.length > 1" class="col-12 mb-4">
                    <div class="custom-control-lg custom-control custom-checkbox">
                      <input class="custom-control-input" id="chkbox-o365-select-all" type="checkbox"
                       (change)="selectAll($event, 'o365')"/>
                      <label class="custom-control-label" for="chkbox-o365-select-all">
                        {{ 'checkbox.selectall' | translate }}
                      </label>
                    </div>
                </div>

                <div class="col-12" *ngFor="let product of o365Products">
                  <div class="card card-body mb-2">
                    <div class="row h-100 align-items-center">

                      <div class="col-lg-6 col-md-12 col-12">

                        <div class="custom-control-lg custom-control custom-checkbox mb-lg-0 mb-3">
                          <input class="custom-control-input" id="chkbox-o365-{{product.product_id}}"
                          type="checkbox" [(ngModel)]="product.selected" [disabled]="product.selectDisabled" />
                          <label class="custom-control-label" for="chkbox-o365-{{product.product_id}}">
                              {{product.product_name}}
                          </label>
                        </div>

                      </div>
                      <div class="col-lg-6 col-md-12 col-12">

                        <div class="horizontal-scrollable">

                          <div class="row-item">
                            <div class="col-item col-item-title"></div>
                            <div class="col-item">{{ 'word.monthly' | translate }}</div>
                            <div class="col-item">{{ 'word.annual' | translate }}</div>
                          </div>

                          <div class="row-item">
                            <div class="col-item col-item-title">{{ 'word.cost' | translate }} </div>
                            <div class="col-item">
                              <input [value]="product.product_price.monthly" class="form-control" type="text" [disabled]="true" readonly >
                            </div>
                            <div class="col-item">
                              <input [value]="product.product_price.annually" class="form-control" type="text" [disabled]="true" readonly >
                            </div>
                          </div>

                          <div class="row-item">
                            <div class="col-item col-item-title">{{ 'word.retail' | translate }} </div>

                            <ng-container *ngIf="isManageStorefront;else showO365ResellerCost">
                              <div class="col-item">
                                <input [value]="product.retailPrice?.monthly_price" class="form-control" type="text" [disabled]="true" readonly >
                              </div>
                              <div class="col-item">
                                <input [value]="product.retailPrice?.yearly_price" class="form-control" type="text" [disabled]="true" readonly >
                              </div>
                            </ng-container>
                            <ng-template #showO365ResellerCost>
                              <div class="col-item">
                                <input [value]="product.product_price.monthly" class="form-control" type="text" [disabled]="true" readonly >
                              </div>
                              <div class="col-item">
                                <input [value]="product.product_price.annually" class="form-control" type="text" [disabled]="true" readonly >
                              </div>
                            </ng-template>


                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

        <div class="col-12" *ngIf="enableAlibabaMailSetup">

          <div class="card collapsable">
            <div class="card-header" (click)="isAlibabaMailCollapsed = !isAlibabaMailCollapsed">
              <span class="card-header-text">AlibabaMail</span>
              <div class="card-action-collapse">
                <a>
                  <i style="font-size: 20px;" [ngClass]="isAlibabaMailCollapsed? 'fa fa-caret-down': 'fa fa-caret-up' "></i>
                </a>
              </div>
            </div>
            <div id="alibaba-mail-panel" class="card-body" [@smoothCollapse]="isAlibabaMailCollapsed?'hide':'show'">
              <ng-template #alibabaMailLoadingSpinner>
                <div class="row" style="min-height: 150px">
                  <div class="lds-roller" style="margin: auto"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                  </div>
                </div>
              </ng-template>
              <div class="row" *ngIf="!alibabaMailLoading; else alibabaMailLoadingSpinner">

                <div *ngIf="alibabaMailProducts && alibabaMailProducts.length < 1" class="col-12">
                  <span>{{ 'word.error.oops' | translate }}  {{ 'word.noavailableproduct' | translate }}</span>
                </div>

                <div *ngIf="alibabaMailProducts && alibabaMailProducts.length > 1" class="col-12 mb-4">
                    <div class="custom-control-lg custom-control custom-checkbox">
                      <input class="custom-control-input" id="chkbox-alibaba-mail-select-all" type="checkbox"
                       (change)="selectAll($event, 'alibabaMail')"/>
                      <label class="custom-control-label" for="chkbox-alibaba-mail-select-all">
                        {{ 'checkbox.selectall' | translate }}
                      </label>
                    </div>
                </div>

                <div class="col-12" *ngFor="let product of alibabaMailProducts">
                  <div class="card card-body mb-2">
                    <div class="row h-100 align-items-center">

                      <div class="col-lg-4 col-md-12 col-12">

                        <div class="custom-control-lg custom-control custom-checkbox mb-lg-0 mb-3">
                          <input class="custom-control-input" id="chkbox-alibaba-mail-{{product.productid}}"
                          type="checkbox" [(ngModel)]="product.selected" [disabled]="product.selectDisabled"/>
                          <label class="custom-control-label" for="chkbox-alibaba-mail-{{product.productid}}">
                              {{product.productName}} - {{product.packageName}}
                          </label>
                        </div>

                      </div>
                      <div class="col-lg-8 col-md-12 col-12">

                            <div class="horizontal-scrollable pb-3">

                              <div class="row-item">
                                <div class="col-item col-item-title"></div>
                                <div class="col-item" *ngFor="let price of product.priceInfo">
                                  {{price.terms}} term(s)
                                </div>
                              </div>

                              <div class="row-item">
                                <div class="col-item col-item-title">{{ 'word.cost' | translate }} </div>
                                <div class="col-item" *ngFor="let price of product.priceInfo">
                                  <input type="text" [disabled]="true" class="form-control" readonly
                                  [value]="price.ispromotion? price.promoprices : price.newprices">
                                </div>
                              </div>

                              <div class="row-item">
                                <div class="col-item col-item-title">{{ 'word.retail' | translate }} </div>

                                <ng-container *ngIf="isManageStorefront;else showAlibabaMailResellerCost">
                                  <div class="col-item" *ngFor="let price of product.retailPrice">
                                    <input type="text" [disabled]="true" class="form-control" readonly
                                    [value]="price.newprices">
                                  </div>
                                </ng-container>
                                <ng-template #showAlibabaMailResellerCost>
                                  <div class="col-item" *ngFor="let price of product.priceInfo">
                                    <input type="text" [disabled]="true" class="form-control" readonly
                                    [value]="price.newprices">
                                  </div>
                                </ng-template>

                              </div>

                            </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>

    <div class="col-lg-12 px-0">
      <div class="float-right">
          <button class="btn btn-dark btn-lg px-5 mb-4 mr-2" (click)="back()">
            {{ 'button.back' | translate }}
        </button>
        <button class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()">
          {{ 'button.next' | translate }}
        </button>
      </div>
    </div>

  </div>

</main>
