<main id="storefront" class="main create-storefront">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'o365.orderMO365' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.stepfive' | translate }}</li>
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 15%"></div>
    </div>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">

        <div class="col-lg-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorCheck">
            <strong>{{ 'word.error.oops' | translate }} {{ 'word.error.somethingwrong' | translate }}</strong>&nbsp;{{ error }}
          </div>
          <form class="form" style="overflow-y: visible">

            <div class="col-lg-12 mt-3">
              <h2> {{ 'word.dnsmanagement' | translate }}</h2>

              <div class="card mt-4 pl-2 pr-2" *ngIf="showMainPart">
                <div class="card-body">
                  <div id="check-available" *ngIf="newDomain == true">
                    <div class="row">
                      <div class="col-xl-9 col-lg-8 pr-lg-0">

                        <div class="input-group">
                          <input [(ngModel)]="domainName" type="text" class="form-control" placeholder="PrivateLimitedCompany.com" pattern="^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$" name="domainName" #domainNameInput />
                          <div class="input-group-append">
                            <span class="input-group-text d-block" *ngIf="!checkingAvailability">
                              USD {{ domainPrice }}
                            </span>
                            <span class="input-group-text d-block" *ngIf="checkingAvailability">
                              <i class="fa fa-spinner fa-spin"></i>
                            </span>
                          </div>
                        </div>
                        <a (click)="existingDomainPressed()" id="exist-domain" class="exist-link btn btn-link" *ngIf="!existing">
                          {{ 'o365.stepfive.link.title1' | translate }}
                        </a>
                      </div>

                      <div class="col-xl-3 col-lg-4" *ngIf="existing == false && transfer == false">
                        <div class="btn-container">
                          <button id="btn-check-avb" class="btn btn-primary" type="button" (click)="buyDomainPressed()" [disabled]="!domainAvailable">
                            {{ 'button.buydomain' | translate }}
                            <!-- <i class="fa fa-spinner fa-spin" *ngIf="checkingAvailability"></i> -->
                          </button>
                          <!-- <button class="btn btn-custom btn-get-domain">year <small class="d-block">USD {{domainPrice}} / year</small></button> -->
                        </div>
                      </div>
                    </div>
                    <p class="text-danger" *ngIf="errorMessage">{{ errorMessage }}</p>
                  </div>

                  <div id="check-domain" *ngIf="newDomain == false">
                    <div class="row">
                      <div class="col-xl-9 col-lg-8 pr-lg-0">
                        <div class="input-group">
                          <input type="text" [(ngModel)]="existdomainName" name="existdomainName" class="form-control" pattern="^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$" placeholder="PrivateLimitedCompany.com" />
                        </div>
                        <a (click)="registerNewPressed()" id="exist-domain" class="exist-link btn btn-link">
                          {{ 'o365.stepfive.link.title2' | translate }}
                        </a>
                      </div>

                      <div class="col-xl-3 col-lg-4">
                        <div class="btn-container">
                          <button (click)="checkDomainPressed()" id="btn-check-domain" class="btn btn-custom btn-check hvr-fade" type="button" [disabled]="checkingExistingAvailability">
                            {{ 'button.checkdomain' | translate }}
                            <i class="fa fa-spinner fa-spin" *ngIf="checkingExistingAvailability"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <p class="text-danger" *ngIf="errorDomainMessage">{{errorDomainMessage}}</p>
                  </div>

                  <div id="preload-check" *ngIf="searching">
                    <div class="row">
                      <div class="col-12">
                        <!-- <h6 class="mb-3">{{ existdomainName }}</h6> -->
                        <div class="loading-pulse"></div>
                        <p class="mt-3" id="checking-existing-domain" *ngIf="checkingExistingAvailability">
                          {{ 'o365.stepfive.loadingmssg' | translate }}</p>
                      </div>
                    </div>
                  </div>

                  <div id="post-preload-check" *ngIf="transfer">
                    <div class="row">
                      <div class="col-12">
                        <h6>
                          {{ managedByThirdPartyMessage }}
                        </h6>

                        <div class="btn-container">
                          <button id="btn-register-domain" class="btn btn-custom hvr-fade" type="button" (click)="registerNewPressed()" *ngIf="availablePurchase">
                              {{ 'button.registerdomain' | translate }}
                            <!-- <small class="d-block">From USD {{ domainPrice }}</small> -->
                          </button>
                        </div>

                        <div class="btn-container">
                          <button class="btn btn-custom hvr-fade" type="button" (click)="configurePressed()" style="margin-top: 1.5em;" *ngIf="domainTransfer">
                            {{ 'button.configuredomain' | translate }}
                            <small class="d-block">{{ 'button.configuredomain.title' | translate }}</small>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mt-4 pl-2 pr-2" *ngIf="showAutoConfigDns">
                <div class="card-body">
                  <h3>{{ 'o365.finish' | translate }}</h3>
                  <p>{{ 'o365.finish.content' | translate }}</p>
                </div>
              </div>

              <!-- <div class="card mt-4 pl-2 pr-2" *ngIf="showDnsGuide">
    <div class="card-body">
    </div>
  </div> -->

              <!-- <div class="card mt-4 pl-2 pr-2" *ngIf="showDnsGuide">
    <div class="card-body">
    </div>
  </div> -->

              <div class="card mt-4 pl-2 pr-2" *ngIf="showDnsGuide">
                <div class="card-body">
                  <h6>{{ 'o365.dnsguide.content1' | translate }}</h6>

                  <h6>{{ 'o365.dnsguide.content2' | translate }}</h6>
                  <div class="row">
                    <div class="col-lg-10">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">{{ 'word.name' | translate }}
                            </th>
                            <th scope="col">TXT Value
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let record of txtRecord">
                            <td>@</td>
                            <td>{{record.content}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <h6>{{ 'o365.dnsguide.content3' | translate }}</h6>
                  <div class="row">
                    <div class="col-lg-10">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">{{ 'word.host' | translate }}
                            </th>
                            <th scope="col">{{ 'word.pointsto' | translate }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let record of cnameRecord">
                            <td>{{record.name}}</td>
                            <td>{{record.records[0].content}}</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>

                  <h6>{{ 'o365.dnsguide.content4' | translate }}</h6>
                  <div class="row">
                    <div class="col-lg-10">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">{{ 'word.host' | translate }}
                            </th>
                            <th scope="col">{{ 'word.priority' | translate }}
                            </th>
                            <th scope="col">{{ 'dns.target' | translate }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let record of mxRecord">
                            <td>@</td>
                            <td>{{record.prio}}</td>
                            <td>{{record.records[0].content}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <h6>{{ 'o365.dnsguide.content5' | translate }}</h6>
                  <div class="row">
                    <div class="col-lg-10">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <!--<th scope="col">Port</th>-->
                            <!--<th scope="col">Protocol</th>-->
                            <th scope="col">{{ 'word.name' | translate }}
                            </th>
                            <th scope="col">{{ 'word.service' | translate }}
                            </th>
                            <th scope="col">{{ 'word.priority' | translate }}
                            </th>
                            <!--<th scope="col">Weight</th>-->
                            <th scope="col">{{ 'dns.target' | translate }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let record of srvRecord">
                            <!--<td>443</td>-->
                            <!--<td>{{record.name}}</td>-->
                            <td>@</td>
                            <td>{{record.name}}</td>
                            <td>{{record.prio}}</td>
                            <!--<td>1</td>-->
                            <td>{{record.records[0].content}}</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-lg-12">
              <div class="float-right">
                <label class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="skipPressed()">
                  {{ 'button.skip' | translate }}
                  <!-- <i class="fa fa-cog fa-spin" style="font-size:24px;color: lightseagreen" *ngIf="nextPageLoading"></i> -->
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Start modal -->

    <div id="register-domain-modal" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h6 class="text-center"> {{ 'o365.stepfive.modaltitle' | translate }}</h6>
            <form id="microsoftLoginForm" #microsoftLoginForm="ngForm">
              <h6>{{ 'o365.domaindetails' | translate }}</h6>
              <div class="input-group">
                <input class="form-control" type="text" id="domain_name" readonly disabled [value]="domainName">
                <div class="input-group-append">
                  <span class="input-group-text d-block">USD {{ domainPrice }}</span>
                </div>
              </div>
              <hr>
              <div class="form-group">
                <label for="username" class="label-title">
                  {{ 'o365.contactfirstname' | translate }} *</label>
                <input type="text" id="username" name="contactFirstName" class="form-control required" pattern="[a-zA-Z !@#$%^&*(),.?:{}|<>/'`\-\_\\=+\[\]~;]{1,}" required #fName="ngModel" [(ngModel)]="regdata.first_name" />
                <div [hidden]="fName.valid || fName.pristine">
                  First Name must be letters only
                </div>
              </div>
              <div class="form-group">
                <label for="microsoftPass" class="label-title">{{ 'o365.contactlastname' | translate }} *</label>
                <input type="text" id="microsoftPass" name="contactLastName" class="form-control required" pattern="[a-zA-Z !@#$%^&*(),.?:{}|<>/'`\-\_\\=+\[\]~;]{1,}" required #lName="ngModel" [(ngModel)]="regdata.last_name" />
                <div [hidden]="lName.valid || lName.pristine">
                  Last Name must be letters only
                </div>
              </div>
              <div class="form-group">
                <label for="microsoftPass" class="label-title">{{ 'o365.contactemail' | translate }} *</label>
                <input type="email" id="microsoftEmail" name="contactEmailAddress" class="form-control required" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required #email="ngModel" [(ngModel)]="regdata.email" />
                <div [hidden]="email.valid || email.pristine">
                  Must be a valid email address
                </div>
              </div>
              <div class="form-group">
                <label for="microsoftPass" class="label-title">{{ 'o365.contactphone' | translate }} *</label>
                <ngx-intl-tel-input id="phone_number" [cssClass]="'form-control'" [preferredCountries]="['my', 'sg']" [enablePlaceholder]="true" [enableAutoCountrySelect]="true" name="phone" #mobNum="ngModel" [(ngModel)]="regdata.phone_number" required></ngx-intl-tel-input>
              </div>
              <div class="form-group">
                <label for="microsoftPass" class="label-title">{{ 'o365.faxnumber' | translate }} *</label>
                <ngx-intl-tel-input id="fax_number" [cssClass]="'form-control'" [preferredCountries]="['my', 'sg']" [enablePlaceholder]="true" [enableAutoCountrySelect]="true" name="contactFaxNumber" #faxNum="ngModel" [(ngModel)]="regdata.fax_number"></ngx-intl-tel-input>
              </div>
              <div class="form-group">
                <label for="microsoftPass" class="label-title">{{ 'o365.contactaddressline1' | translate }} *</label>
                <input type="text" id="contactAddress1" name="contactAddress1" class="form-control required" [(ngModel)]="regdata.address_1" #addLine1="ngModel" pattern="[a-zA-Z,0-9 !@#$%^&*(),.?:{}|<>/'`\-\_\\=+\[\]~;]{1,}" required />
                <div [hidden]="addLine1.valid || addLine1.pristine">
                  {{ 'error.addressline1required' | translate }}
                </div>
              </div>
              <div class="form-group">
                <label for="microsoftPass" class="label-title">{{ 'o365.contactaddressline2' | translate }}</label>
                <input type="text" id="contactAddress2" name="contactAddress2" class="form-control required" [(ngModel)]="regdata.address_2" #addLine2="ngModel" pattern="[a-zA-Z,0-9 !@#$%^&*(),.?:{}|<>/'`\-\_\\=+\[\]~;]{1,}" />
                <div [hidden]="addLine2.valid || addLine2.pristine">
                  {{ 'error.addressline2required' | translate }}
                </div>
              </div>
              <div class="form-group">
                <label for="microsoftPass" class="label-title">{{ 'word.companyname' | translate }} *</label>
                <input type="tel" id="microsoftPhone" name="companyName" class="form-control required" [(ngModel)]="regdata.company_name" pattern="[a-zA-Z,0-9 !@#$%^&*(),.?:{}|<>/'`\-\_\\=+\[\]~;]{1,}" #cName="ngModel" required />
                <div [hidden]="cName.valid || cName.pristine">
                  {{ 'error.companynameletters' | translate }}
                </div>
              </div>
              <div class="form-group">
                <label for="microsoftPass" class="label-title">{{ 'word.zipcode' | translate }} *</label>
                <input type="tel" id="microsoftPhone" name="contactPhoneNumberr" class="form-control required" [(ngModel)]="regdata.zip_code" #zip="ngModel" pattern="[0-9]{1,}" required />
                <div [hidden]="zip.valid || zip.pristine">
                  {{ 'error.zipcoderequired' | translate }}
                </div>
              </div>

              <div class="form-group" id="fg-country">
                <label for="country" class="label-title">
                  {{ 'word.country' | translate }} *
                  <i class="fa fa-spinner fa-spin" *ngIf="countriesLoading"></i>
                </label>
                <select class="required form-control" id="country" name="country" *ngIf="!countriesLoading" (change)="onCountryChanged($event)" [(ngModel)]="selectedCountry">
                  <option value="0" disabled selected>
                    {{ 'word.selectcountry' | translate }}</option>
                  <option *ngFor="let country of countries" [value]="country.id" [selected]="country.id == selectedCountry">{{ country.name }}</option>
                  <!-- <option
                *ngFor="let country of countries"
                [value]="country.id"
                [selected]="country.id == selectedCountry"
                >{{ country.name }}</option
                > -->
                </select>
              </div>

              <div class="form-group" *ngIf="selectedCountry != null" id="fg-state">
                <label for="state" class="label-title">
                  {{ 'word.state' | translate }} *
                  <i class="fa fa-spinner fa-spin" *ngIf="statesLoading"></i>
                </label>
                <select class="required form-control" id="state" name="state" (change)="onStateChanged($event)" [(ngModel)]="selectedState" *ngIf="!statesLoading" #state>
                  <option value="0" disabled selected>
                    {{ 'word.selectstate' | translate }}</option>
                  <option *ngFor="let state of states" [value]="state.id">{{ state.name }}</option>
                  <!-- <option *ngFor="let state of states" [value]="state.id" [selected]="state.id == selectedState">{{
                  state.name
                }}</option> -->
                </select>
              </div>

              <div class="form-group" *ngIf="selectedState != null" id="fg-city">
                <label>
                  {{ 'word.city' | translate }} *
                  <i class="fa fa-spinner fa-spin" *ngIf="citiesLoading"></i>
                </label>
                <select id="city" name="city" *ngIf="!citiesLoading" (change)="onCityChanged($event)" [(ngModel)]="selectedCity" class="form-control required" #city required>
                  <option value="0" disabled selected>
                    {{ 'word.selectcity' | translate }}</option>
                  <option *ngFor="let city of cities" [value]="city.id">{{ city.name }}</option>
                  <option value='50000'>{{ 'word.others' | translate }}</option>
                </select>
              </div>
              <div class="form-group" *ngIf="selectedCity==50000">
                <input type="text" class="form-control" placeholder="City Name" [(ngModel)]="regdata.other_city" name="other_city" #otherrCity="ngModel" pattern="[a-zA-Z,0-9 !@#$%^&*(),.?:{}|<>/'`\-\_\\=+\[\]~;]{1,}" />
              </div>

              <button id="microsoftBtn" class="btn btn-custom float-right mt-2" type="submit" (click)="registedDNSPressed()" [disabled]="payBtnPressed || !microsoftLoginForm.valid || selectedCountry<=0 || selectedState<=0 ||selectedCity<=0">
                {{ 'button.register' | translate }}
                <i *ngIf="payBtnPressed" class="fa fa-spinner fa-spin"></i>
              </button>

              <div *ngIf="errorRegisterMessage != ''">
                <p class="text-danger" *ngFor="let error of errorRegisterMessage">{{ error }}</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- End modal -->
  </div>
</main>
