import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { StoreFrontService } from '../../service/store-front.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from '../../service/store.service';
import { DomSanitizer } from '@angular/platform-browser';
import {Observable,Observer} from 'rxjs';
import {style, state, animate, transition, trigger} from '@angular/animations';

declare var $: any;

@Component({
  selector: 'app-storefront-create-one',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(100, style({opacity:1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(100, style({opacity:0}))
      ])
    ])
  ],
  templateUrl: './storefront-create-one.component.html',
  styleUrls: ['./storefront-create-one.component.css']
})
export class StorefrontCreateOneComponent implements OnInit {
  storeId;
  storeFrontDetails: UntypedFormGroup;
  countries;
  selectedCountry;
  selectedState;
  states;
  storefrontName;
  storefrontCode;
  department;
  address;
  email;
  phoneNumber;
  customDomain;

  coverTitle: Object = {};

  coverText: Object = {};

  filestring;
  fileStringShow;
  files;
  logo;

  languages;
  headingLanguage = "en";

  errorCheck = false;
  errors = [];
  storefrontCheckReplica = false;
  domainCheckReplica = false;
  edited = false
  storefrontId;
  storeDataFromDatabase;

  checkNameLoading = false;
  checkDomainLoading = false;
  nextPageLoading = false;
  updatedLogo: boolean = false;
  logourl;
  manageMode = false;
  open;
  showTipsMessage: boolean = false;
  logoBase64;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public storeFrontService: StoreFrontService,
    public router: Router,
    public storeService: StoreService,
    private cd: ChangeDetectorRef,
    public domSanitizer: DomSanitizer,
    public route: ActivatedRoute
  ) {
    this.storeFrontDetails = this.formBuilder.group({
      storefront_code: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9_-]+$/)
        ])],
      storefront_name: ['', Validators.required],
      department: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9_.+-/\'` ]+$/)
        ])],
      address: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9_.+-/ ]+$/)
        ])],
      selectedCountry: ["0", Validators.required],
      selectedState: ["0", Validators.required],
      phone_number: new UntypedFormControl(undefined, [Validators.required]),
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/)
        ])],

      custom_domain: ['', Validators.compose([
        Validators.pattern(/^(?!\-)(?:[a-zA-Z0-9\-]{0,62}[a-zA-Z0-9]\.){1,126}(?!0-9+)[a-zA-Z0-9]{1,63}$/)
      ])]
    });
  }

  ngOnInit() {
    this.storeService.getLanguages().subscribe(data => {
      this.languages = data['data'];

      this.languages.forEach((lang, id) => {
        this.coverTitle[lang.code] = "Your Creative Tagline";
        this.coverText[lang.code] = "Insert a creative short description here.";

        let langName = lang.name;
        langName = langName.replace(' ', "_");

        this.languages[id]['safe_name'] = langName;
        console.log('safe_name', langName);

        this.storeFrontDetails.addControl(`cover_title_${langName}`, new UntypedFormControl('', Validators.required));
        this.storeFrontDetails.addControl(`cover_text_${langName}`, new UntypedFormControl('', Validators.required));
      });
    });

    // this._NgxUiLoaderModule.start();
    if (this.route.snapshot.paramMap.get('id')) {
      this.manageMode = true;
      this.storefrontId = this.route.snapshot.paramMap.get('id');
      this.edited = true;

      this.storeService.getStorefront(this.route.snapshot.paramMap.get('id')).subscribe(respStore => {
        this.storeDataFromDatabase = respStore['data'];
        console.log('Storefront To Update Details : ', respStore['data'])
        this.storeFrontService.getCountries().subscribe(resp => {
          var phoneNumber = respStore["data"]["phone_number"];
          this.countries = resp['data'];
          this.phoneNumber = phoneNumber.replace(".", " ");
          this.email = respStore["data"]["email"]
          this.selectedCountry = respStore["data"]["country_id"]
          this.selectedState = respStore["data"]["state_id"]

          this.selectCountry(this.selectedCountry);
          this.selectState(this.selectedState);
          this.logo = respStore["data"]["logo_url"],
          this.storefrontName = respStore["data"]["storefront_name"]
          this.storefrontCode = respStore["data"]["storefront_code"]
          this.department = respStore["data"]["department"]
          this.address = respStore["data"]["address"]
          this.logourl = respStore["data"]["logo_url"]
          this.getBase64ImageFromURL(this.logourl).subscribe(base64data => {
            console.log(base64data);
            this.logoBase64 = 'data:image/jpg;base64,'+base64data;
            this.filestring = base64data;
          });
          this.storeService.customPageStatus = respStore["data"]["custom_page_status"]
          this.customDomain = respStore["data"]["custom_domain"]

          if (this.storeService.customPageStatus == 1){
            this.storeService.pageTitle = respStore["data"]["custom_page"]["page_title"]
            this.storeService.pageContent = respStore["data"]["custom_page"]["page_content"]
            console.log("title "+this.storeService.pageTitle)
            console.log("status "+this.storeService.pageContent)
          }

          this.coverTitle = respStore["data"]["cover_title"]
          this.coverText = respStore["data"]["cover_text"]

        });
      });
    } else {
      this.storeFrontService.getCountries().subscribe(resp => {
        this.countries = resp['data'];
      });
    }

    // this._NgxUiLoaderModule.stop();
  }

  ngAfterViewInit() {
    this.onInputHasValue('#storefront_code');
    this.onInputHasValue('#storefront_name');
    this.onInputHasValue('#department');
    this.onInputHasValue('#address');
    this.onInputHasValue('#email');
    this.onInputHasValue('#phone_number');
    this.onInputHasValue('#cover_title');
    this.onInputHasValue('#cover_text');
    this.onInputHasValue('#custom_domain');
  }

  checkButton() {
  }

  onCountryChanged(event) {
    // this.selectCountry(this.selectedCountry);
    this.selectCountry(this.storeFrontDetails.controls['selectedCountry'].value);

  }

  selectCountry(selectedCountry) {

    /* Load */
    this.selectedCountry = selectedCountry;
    this.storeFrontDetails.patchValue({
      selectedCountry: this.selectedCountry,
      storefront_logo: this.logo,
    });
    //  this.data.country = selectedCountry;

    this.storeFrontService.getStates(selectedCountry).subscribe(response => {
      this.states = response['data'];
      // this.statesLoading = false;
    }, err => {
    });
  }

  selectState(selectedState) {

    /* Load */
    this.selectedState = selectedState;
    this.storeFrontDetails.patchValue({
      selectedState: this.selectedState
    });
    //  this.data.country = selectedCountry;
  }

  checkCode() {
    this.checkNameLoading = true;
    this.storeService.checkStorefrontExist(this.storeFrontDetails.controls['storefront_code'].value).subscribe(data => {
      this.checkNameLoading = false;
      this.storefrontCheckReplica = data['data'];
    }, error => {
      this.checkNameLoading = false;
    });
  }

  checkDomainReplica() {

    if(this.storeFrontDetails.controls['custom_domain'].value === null || this.storeFrontDetails.controls['custom_domain'].value === "") {
      this.domainCheckReplica = false;
    } else {
      this.checkDomainLoading = true;
      this.storeService.checkCustomDomainExists(this.storeFrontDetails.controls['custom_domain'].value).subscribe(data => {
        this.checkDomainLoading = false;
        this.domainCheckReplica = data['exists'];
      }, error => {
        this.checkDomainLoading = false;
      });
    }
  }

  mouseEvent(event: string){
    if(event == "enter")
      this.showTipsMessage = true;
    else if(event == "leave")
      this.showTipsMessage = false;
  }

  setSpacer(event: string, id: string) {
    document.getElementById(id).style.paddingLeft = (event+"px");
  }

  checkOnInput(inputId: string, divId: string) {
    $(inputId).focus(function(){
      $(divId).css("padding-left", "5px");
    });

    $("input").blur(function(){
      // if input has value
      if($(inputId).val().length >= 1) {
        $(divId).css("padding-left", "5px");
      }
    })
  }

  onInputHasValue(divId: string) {
    $(divId).css("padding-left", "5px");
  }

  coverTitleChange(e) {
    var langCode = (e.target.id).split("-")[3];
    this.coverTitle[langCode] = e.target.value;
  }

  coverTextChange(e) {
    var langCode = (e.target.id).split("-")[3];
    this.coverText[langCode] = e.target.value;
  }

  nextPressed() {
    this.nextPageLoading = true;
    this.errorCheck = false;
    this.errors = [];
    if (!this.updatedLogo) {
      this.storeService.setNewLog(true);
    }else {
      this.storeService.setNewLog(true);
    }

    // tslint:disable-next-line:max-line-length
    if (this.storeFrontDetails.valid && this.storeFrontDetails.controls['selectedCountry'].value > 0 && this.storeFrontDetails.controls['selectedState'].value > 0 && !this.storefrontCheckReplica && !this.domainCheckReplica && this.filestring) {

      var phoneNumber = this.phoneNumber['internationalNumber'];
      this.phoneNumber['internationalNumber'] = phoneNumber.replace(" ", "."),

      this.storeService.initializeStore(
        this.storefrontName,
        this.storefrontCode,
        this.department,
        this.address,
        this.email,
        this.phoneNumber['internationalNumber'],
        this.storeFrontDetails.controls['selectedState'].value,
        this.storeFrontDetails.controls['selectedCountry'].value,
        this.coverTitle,
        this.coverText,
        this.filestring,
        this.customDomain
      );

      this.nextPageLoading = false;
      if (this.edited) {
        this.storeService.initializeStore(
          this.storefrontName,
          this.storefrontCode,
          this.department,
          this.address,
          this.email,
          this.phoneNumber['internationalNumber'],
          this.storeFrontDetails.controls['selectedState'].value,
          this.storeFrontDetails.controls['selectedCountry'].value,
          this.coverTitle,
          this.coverText,
          this.filestring,
          this.customDomain,
          this.storeDataFromDatabase['theme_id'],
          this.storeDataFromDatabase['cover'],"url",
          this.storeDataFromDatabase['cover_url'],"theme"
          // this.storeDataFromDatabase['CUSTOM PAGE TITLE XDE KAT API'],
          // this.storeDataFromDatabase['CUSTOM PAGE CONTENT XDE KAT API'],
          );

        this.router.navigate(['/manage-storefront-2/' + this.storefrontId]);
      } else {
        this.router.navigate(['/create-storefront-2']);
      }

    } else {
      this.errorCheck = true;
      this.errors = []; //resets the error lists
      const controls = this.storeFrontDetails.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.errors.push(name.replace('_', ' '));
        }
      }
      if (this.storeFrontDetails.controls['selectedCountry'].value <= 0) {
        this.errors.push('Country');
      }
      if (this.storeFrontDetails.controls['selectedState'].value <= 0) {
        this.errors.push('State');
      }
      if (!this.filestring){
        this.errors.push('Logo')
      }
      //
      this.nextPageLoading = false;
    }
    document.documentElement.scrollTop = 0;
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      console.log(myReader)
      this.filestring = myReader.result;
    }
    myReader.readAsDataURL(file);
  }
  getFiles(event) {
    this.files = event.target.files;
    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    this.updatedLogo = true;
    reader.readAsBinaryString(this.files[0]);
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.filestring = btoa(binaryString);
    this.fileStringShow = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64, ' + this.filestring); // Converting binary string data.
  }
  closePressed() {
    this.errorCheck = false
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
}
