import { Component, OnInit } from '@angular/core';
import { O365OrderService } from 'src/app/service/o365-order.service';
import { O365HttpService } from 'src/app/service/o365-http.service';
import { ResellerService } from '../../service/reseller/reseller.service';
import { Router } from '@angular/router';
import { ScrollUtil } from 'src/app/util/scroll-util';

@Component({
  selector: 'app-o365-step-four',
  templateUrl: './o365-step-four.component.html',
  styleUrls: ['./o365-step-four.component.css']
})
export class O365StepFourComponent implements OnInit {

  data = {
    username: '',
    password: '',
    company_name: '',
    contact_email_address: '',
    alt_email_address: '',
    contact_first_name: '',
    contact_last_name: '',
    contact_phone_no: '',
    default_domain_prefix: '',
    use_transit_tenant: false,
    include_partner: false
  };

  auto_renew;
  price_monthly;
  price_annually;

  orderData = {
    tenant_id: 0,
    product_id: 0,
    quantity: 0,
    duration: 0,
    auto_renew: false
  };

  currentBalance: number;
  leftoverBalance: number;

  errors;
  errorCheck = false;

  nextBtnPressed = false;

  constructor(
    public orderService: O365OrderService,
    public httpService: O365HttpService,
    public router: Router,
    private resellerService: ResellerService
  ) { }

  ngOnInit() {
    this.data = this.orderService.getTenantData();

    this.getResellerBalance();
    console.log('tenantData', this.data);

    this.orderData = {
      tenant_id: 0,
      product_id: this.orderService.getProductId(),
      quantity: this.orderService.getLicences(),
      duration: this.orderService.getMonth(),
      auto_renew: this.orderService.getAutoRenew()
    };

    this.auto_renew = this.orderService.getAutoRenew();
    this.price_annually = this.orderService.getPriceAnnually();
    this.price_monthly = this.orderService.getPriceMonthly();
  }

  nextPressed() {
    this.nextBtnPressed = true;

    let useTransitTenant = this.data.use_transit_tenant;

    let totalPrice;
    if (this.orderData.duration == 1) {
      totalPrice = this.price_monthly * this.orderData.quantity;
    } else if (this.orderData.duration == 12) {
      totalPrice = this.price_annually * this.orderData.quantity;
    }

    if (totalPrice === undefined || totalPrice == null || totalPrice <= 0) {
      return;
    }

    this.leftoverBalance = 0;
    this.leftoverBalance = this.currentBalance - totalPrice;
    if (this.leftoverBalance < 0) {
      this.nextBtnPressed = false;

      let errorResponse: any = {
        error: {}
      };
      let error: any = {
        error: []
      };
      error.error.push({ detail: 'Insufficient balance. Lacking of USD ' + Math.abs(this.leftoverBalance).toFixed(2) });
      errorResponse.error = error;

      var er = [];
      var ers = errorResponse.error.error;

      ers.forEach((e) => {
        er.push(e.detail);
      });

      this.errors = er;

      this.nextBtnPressed = false;
      this.errorCheck = true;
      ScrollUtil.scrollToTop();

      return;
    }

    if (!useTransitTenant) {
      this.createTenant();
    } else {
      this.transitTenant();
    }
  }

  createTenant() {
    this.httpService.createTenant(this.data).subscribe(response => {
      this.orderService.setTenantId(response['data']['tenant']['webnic_tenant_id']);
      this.orderData.tenant_id = response['data']['tenant']['webnic_tenant_id'];

      setTimeout(() => {
        this.createOrder();
      }, 5000);

    }, errorResponse => {
      this.nextBtnPressed = false;

      console.error('createTenant ERROR', errorResponse);

      if (errorResponse.error.message == 'The given data was invalid.') {
        var er = [];
        var ers = errorResponse.error.errors;
        console.log('errors', ers);

        console.log('aasdasdasd', Object.keys(ers));
        Object.keys(ers).forEach((key) => {
          er.push(ers[key][0]);
        });

        this.errors = er;
        console.log('this.errors', ers);
      } else {
        var er = [];
        var ers = errorResponse.error.error;

        ers.forEach((e) => {
          er.push(e.detail);
        });

        this.errors = er;
        console.log('this.errors', ers);
      }

      this.nextBtnPressed = false;
      this.errorCheck = true;
      ScrollUtil.scrollToTop();
    });
  }

  transitTenant() {
    this.httpService.transitTenant(this.data).subscribe(response => {
      this.orderService.setTenantId(response['data']['tenant']['webnic_tenant_id']);
      this.orderData.tenant_id = response['data']['tenant']['webnic_tenant_id'];

      let url = response['data']['transit_url']['data']['partner_connect_link'];
      window.open(url, '_blank');

      setTimeout(() => {
        this.createOrder();
      }, 5000);

    }, errorResponse => {
      this.nextBtnPressed = false;

      console.error('transitTenant ERROR', errorResponse);

      if (errorResponse.error.message == 'The given data was invalid.') {
        var er = [];
        var ers = errorResponse.error.errors;
        console.log('errors', ers);

        console.log('aasdasdasd', Object.keys(ers));
        Object.keys(ers).forEach((key) => {
          er.push(ers[key][0]);
        });

        this.errors = er;
        console.log('this.errors', ers);
      } else {
        var er = [];
        var ers = errorResponse.error.error;

        ers.forEach((e) => {
          er.push(e.detail);
        });

        this.errors = er;
        console.log('this.errors', ers);
      }

      this.errorCheck = true;
      ScrollUtil.scrollToTop();
    })
  }

  createOrder() {
    this.httpService.createOrder(this.orderData).subscribe(response => {
      console.log('Create order success', response);
      this.orderService.setOrderId(response['data']['order']['webnic_id']);

      if (this.orderService.getProductId() == 1) {
        this.router.navigate(['/o365-order-detail', this.orderService.getOrderId()]);
      } else {
        this.router.navigate(['/o365-step-five']);
      }
    }, error => {
      this.nextBtnPressed = false;

      if (error.error.type == 'wn_error') {
        var er = [];
        var ers = error.error.error;

        ers.forEach((e) => {
          er.push(e.detail);
        });

        this.errors = er;
        console.log('this.errors', ers);
      } else {
        this.errors = [
          'Failed to create order.'
        ];
      }

      this.errorCheck = true;
      ScrollUtil.scrollToTop();
      console.error('createOrder ERROR', error);
    });
  }

  closePressed() {
    this.errorCheck = false;
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  async getResellerBalance() {
    await this.resellerService.getResellerByRescode()
      .toPromise()
      .then((res :any) => {
        this.currentBalance = +res.balance.toFixed(2);
      })
      .catch(err => {
        this.currentBalance = undefined;
        console.error("getResellerByRescode error", err)
      });
  }

}
