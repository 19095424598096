import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StepModel } from '../../../../../model/step.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { MicrosoftGraphService } from 'src/app/service/microsoftcsp/microsoft-graph.service';
import { Observable } from 'rxjs';
import { StepsService } from 'src/app/service/steps.service';

@Component({
  selector: 'app-microsoftcsp-domain-new-domain-name',
  templateUrl: './microsoftcsp-domain-new-domain-name.component.html',
  styleUrls: ['./microsoftcsp-domain-new-domain-name.component.css']
})
export class MicrosoftCSPDomainNewDomainNameComponent implements OnInit {

  @Input() step: StepModel;
  @Output() newDomainNameFormChanged: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  newDomainNameForm: UntypedFormGroup;

  tenantId: number;
  tenantDomains: any[];

  domainNameRegex = '^((?!-)[A-Za-z0-9-]{1,255}(?<!-)\\.)+[A-Za-z0-9-]{1,255}$';

  createDomainLoading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService,  
    private microsoftGraphService: MicrosoftGraphService,
    private stepsService: StepsService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      this.createNewDomainNameForm();
    });
  }

  async onCompleteStep() {
    if (this.newDomainNameForm.valid) {
      if (this.tenantDomains === undefined || this.tenantDomains == null) {
        await this.microsoftGraphApiService.getDomains(this.tenantId.toString())
          .toPromise()
          .then((res: any) => {
            this.tenantDomains = res.value;
            return;
          })
          .catch(err => {
            console.error('getDomains error', err);
            return;
          });
      }

      let existingTenantDomain: any = this.tenantDomains.find(_tenantDomain => _tenantDomain.id === this.domainNameFormControl.value);
      if (existingTenantDomain !== undefined || existingTenantDomain != null) {
        this.createDomainLoading = false;

        let domain: any = existingTenantDomain;

        this.newDomainNameFormChanged.emit(domain);
        this.step.isComplete = true;
        return;
      }

      this.createMicrosoftDomain()
        .subscribe((res: any) => {
          this.createDomainLoading = false;

          let domain: any = res.domain;

          this.microsoftGraphService.domain = undefined;
          this.microsoftGraphService.createServiceRecordsRequest = undefined;
          this.microsoftGraphService.hasConflictRecords = false;
          this.microsoftGraphService.conflictRecords = undefined;

          if(res.hasPremiumSubscription && res.hasPremiumSubscription == true){
            // domain has premium sub

            if(res.verifyDomainSuccess && res.verifyDomainSuccess == true){
              // verify domain success
              
              // set domain
              this.microsoftGraphService.domain = domain;

              if(res.serviceConfigRecordCreated && res.serviceConfigRecordCreated == true){
                this.stepsService.moveToStep(4);
              } else{
                // service records not created

                // set create request if exists
                if(res.serviceConfigRecordRequest){
                  this.microsoftGraphService.createServiceRecordsRequest = res.serviceConfigRecordRequest;
                }

                // set conflict records if exists
                if(res.hasConflictRecords && res.hasConflictRecords == true){
                  this.microsoftGraphService.hasConflictRecords = true;

                  let conflictRecords: any[] = res.conflictDnsRecords;
                  this.microsoftGraphService.conflictRecords = conflictRecords;
                }

                // go to step 3
                this.stepsService.moveToStep(3);
              }

            }else{
              // verify domain failed, fallback to manual handling
              this.newDomainNameFormChanged.emit(domain);
              this.step.isComplete = true;
            }

          }else{
            // default
            this.newDomainNameFormChanged.emit(domain);
            this.step.isComplete = true;
          }
        }, err => {
          console.error('createMicrosoftDomain error', err);
          this.createDomainLoading = false;
          this.domainNameFormControl.setErrors({ invalidDomain: true });
        });

    }
  }

  createNewDomainNameForm() {
    this.newDomainNameForm = this.formBuilder.group({
      domainName: ['', [Validators.required, Validators.pattern(this.domainNameRegex)]]
    });
  }

  createMicrosoftDomain(): Observable<any> {
    this.createDomainLoading = true;
    return this.microsoftGraphApiService.createDomain(this.tenantId.toString(), this.domainNameFormControl.value);
  }

  get domainNameFormControl() { return this.newDomainNameForm.get('domainName'); }

}
