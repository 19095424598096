<main class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants">{{ 'word.tenants' | translate }}</li>
    <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants/{{tenantId}}">{{ tenantId }}</li>
    <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants/{{tenantId}}/users">{{ 'word.users' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.new' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <!-- <div class="row" *ngIf="errorCheck">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Oops!</strong> Something went wrong.
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            <button type="button" class="close" (click)="errorClosePressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div> -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <div class="form-page-container">
                <app-steps></app-steps>
                <div class="step-page-container">
                  <div class="form-step">
                      <ng-container [ngSwitch]="currentStep?.stepIndex">
                          <ng-container *ngSwitchCase="'1'">
                            <app-microsoftcsp-user-new-basics [step]="currentStep" (newUserBasicsFormChanged)="onNewUserBasicsFormChanged($event)"></app-microsoftcsp-user-new-basics>
                          </ng-container>
                          <!-- <ng-container *ngSwitchCase="'1'">
                            <app-step-template [step]="currentStep"></app-step-template>
                          </ng-container> -->
                          <ng-container *ngSwitchCase="'2'">
                            <app-microsoftcsp-user-new-licenses [step]="currentStep" (newUserLicensesFormChanged)="onNewUserLicensesFormChanged($event)" (stepChanged)="onPreviousStep()"></app-microsoftcsp-user-new-licenses>
                          </ng-container>
                          <ng-container *ngSwitchCase="'3'">
                            <app-microsoftcsp-user-new-optional [step]="currentStep" (newUserOptionalFormChanged)="onNewUserOptionalFormChanged($event)" (stepChanged)="onPreviousStep()"></app-microsoftcsp-user-new-optional>
                          </ng-container>
                          <ng-container *ngSwitchCase="'4'">
                            <app-microsoftcsp-user-new-finish [step]="currentStep" (stepChanged)="onPreviousStep()" (editStepChanged)="onCustomStep($event)" (onFinishPageChanged)="onFinishPageChanged()" [newUserBasicsForm]="newUserBasicsForm" [newUserLicensesForm]="newUserLicensesForm" [newUserOptionalForm]="newUserOptionalForm"></app-microsoftcsp-user-new-finish>
                          </ng-container>
                      </ng-container>
                  </div>
                </div>
                <!-- <button (click)="onNextStep()" [disabled]="!currentStep?.isComplete" class="form-continue-btn common-btn">
                  {{ showButtonLabel() }}
                </button> -->
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 text-right">
          <a routerLink="/microsoftcsp/tenants/{{tenantId}}/users" class="btn btn-dark btn-lg px-5 mb-4">{{ 'button.back' | translate }}</a>
        </div>
      </div>

    </div>
  </div>

</main>
