
    <main id="storefront" class="main create-storefront">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item">{{ 'sf.notificationmanagement' | translate }}</li>
        <li class="breadcrumb-item active">{{ 'word.editnotification' | translate }}</li>
      </ol>
      <div id="myAlert" class="alert-container" style="margin-top: -35px; padding-top: 10px" *ngIf="alertMsg == 1">
        <div class="alert alert-custom alert-success">
          {{ 'success.notificationcreated' | translate }}
          <button type="button" class="close" (click)="closeAlertMsg()">×</button>
        </div>
        <br>
      </div>
      <div id="myAlert" class="alert-container" style="margin-top: -35px; padding-top: 10px" *ngIf="alertMsg == 2">
        <div class="alert alert-custom alert-danger">
          <button type="button" class="close" (click)="closeAlertMsg()">×</button>
          {{ 'error.creatingnotification' | translate }}
          <ul style="margin-top: 1rem">
            <li *ngFor="let msg of message">{{ msg }}</li>
          </ul>
        </div>
        <br>
      </div>

      <div class="container">
        <div class="animated fadeIn">
          <div class="row">
            <div class="col-lg-12">

              <h2>{{ 'word.editnotification' | translate }}</h2>

              <div class="card mt-4 p-3">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="title" class="col-sm-3 col-form-label col-form-label-lg">{{ 'sf.title' | translate }}</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" placeholder="Notification Title" aria-label="Title"
                             name="title" [(ngModel)]="data.title">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="snippet" class="col-sm-3 col-form-label col-form-label-lg">{{ 'sf.snippet' | translate }}</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" placeholder="Notification Peak Preview" name="snippet"
                             [(ngModel)]="data.snippet">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="type" class="col-sm-3 col-form-label col-form-label-lg">{{ 'sf.notificationtype' | translate }}</label>
                    <div class="col-sm-9">
                      <select class="custom-select" id="notificationType" [(ngModel)]="data.type">

                        <option value="1" selected="">{{ 'word.info' | translate }}</option>
                        <option value="2">{{ 'sf.deeplink' | translate }}</option>
                      </select>
                    </div>
                  </div>
                  <div id="content_container" class="form-group row" *ngIf="data.type==1">
                    <label for="content" class="col-sm-3 col-form-label col-form-label-lg">{{ 'word.content' | translate }}</label>
                    <div class="col-sm-9">
                      <div style="min-height: 24px;" class="mb-1">
                        <label class="switch switch-3d switch-primary">
                          <input type="checkbox" class="switch-input" id="switcher" [(ngModel)]="editHtml">
                          <span class="switch-label"></span>
                          <span class="switch-handle"></span>
                        </label>
                        <label class="col-form-label col-form-label-lg"
                               style="margin-top: -10px; margin-left: 5px;">{{ 'word.editinhtml' | translate }}</label>
                      </div>

                      <div class="editor-container" id="editor-container">
                        <textarea id="htmleditor" *ngIf="editHtml" rows="10" [(ngModel)]="data.content"></textarea>
                        <ck-editor name="editor1" [(ngModel)]="data.content" language="en" [fullPage]="true"
                                   *ngIf="!editHtml"></ck-editor>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="data.type==2">
                    <label for="target_group" class="col-sm-3 col-form-label col-form-label-lg">{{ 'sf.targetedpage' | translate }}</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" placeholder="URL Path" name="snippet"
                             [(ngModel)]="data.targeted_page">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="selected_group" class="col-sm-3 col-form-label col-form-label-lg">{{ 'sf.targetgroup' | translate }}</label>
                    <div class="col-sm-9">
                      <div id="selected-groups-row">
                        <select name="target_group" id="target_group" class="form-control form-control-lg"
                                [(ngModel)]="selectedItem" (change)="onSelectItem($event)">
                          <option value="base" selected="">Countries..</option>
                          <option *ngFor="let country of countries" value={{country.id}}>{{country.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="selected_group" class="col-sm-3 col-form-label col-form-label-lg">{{ 'sf.selectedgroup' | translate }}</label>
                    <div class="col-sm-9">
                      <div id="selected-groups-row">
                        <button (click)="removeCountry(country)"
                                class="btn btn-primary btn-lg mr-2 mb-2 btn-selected-group" type="button"
                                *ngFor="let country of selectedCountries">{{country.name}}&nbsp;<span
                                aria-hidden="true">&times;</span></button>
                      </div>

                    </div>
                  </div>
                  <!-- <div class="form-group row">
                    <label for="publish_time" class="col-sm-3 col-form-label col-form-label-lg">Publish Time</label>
                    <div class="col-sm-9">
                      <div class="input-group input-group-lg">
                        <div class="input-group-append">
                          <input type="date" class="form-control" placeholder="Publish Time" name="publish_time" [(ngModel)]="data.publish_time">
                          <span class="input-group-text" id="publish_date_icon"><i class="fa fa-calendar"></i></span>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="form-group row">
                    <label for="publish_time" class="col-sm-3 col-form-label col-form-label-lg">{{ 'sf.publishtime' | translate }}</label>
                    <div class="form-group col-sm-3 col-md-2">
                      <div class="input-group">
                        <div class="input-group-append">
                          <!-- <input type="date" class="form-control" placeholder="Publish Time" name="publish_time" [(ngModel)]="data.publish_time"> -->
                          <!-- <span class="input-group-text" id="publish_date_icon"><i class="fa fa-calendar" ></i></span> -->
                          <input class="form-control" placeholder="yyyy-mm-dd" name="publish_time" [(ngModel)]="publish_time.date" ngbDatepicker [minDate]="minDate" (click)="datePicker.toggle()" #datePicker="ngbDatepicker" placeholder="yyyy-mm-dd">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="datePicker.toggle()" type="button"><i class="fa fa-calendar"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label class="col-form-label">:</label>
                    <div class="form-group col-sm-3 col-md-2">
                      <input type="number" class="form-control" [(ngModel)]="publish_time.hour" placeholder="01"
                             min="01" max="12" step="01" oninput="validity.valid||(value='');">
                    </div>
                    <div class="form-group col-sm-3 col-md-2">
                      <input type="number" class="form-control" [(ngModel)]="publish_time.minute" placeholder="00"
                             min="00" max="59" step="01" oninput="validity.valid||(value='');">
                    </div>
                    <div class="form-group col-sm-3 col-md-2">
                      <select class="form-control custom-select" [(ngModel)]="publish_time.period">
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="float-right">
                <button (click)="sendPressed()" class="btn btn-dark text-light btn-lg px-5 mb-4"
                        [disabled]="editNotifLoad">{{ 'button.save' | translate }} <i class="fa fa-cog fa-spin"
                     style="font-size:24px;color: lightgreen" *ngIf='editNotifLoad'></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </main>