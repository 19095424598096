<main id="manage-order" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.orders' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="table-responsive col">
                  <table id="orders" class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>{{ 'word.orderid' | translate }}</th>
                        <th>{{ 'word.tenants' | translate }}</th>
                        <th>{{ 'word.products' | translate }}</th>
                        <th>{{ 'o365.quantity' | translate }}</th>
                        <th>{{ 'word.orderstatus' | translate }}</th>
                        <th>{{ 'word.autorenew' | translate }}</th>
                        <th>{{ 'word.creationdate' | translate }}</th>
                        <th>{{ 'word.expirationdate' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody id="table-data">
                      <td colspan="8" style="text-align: center" [hidden]="!orderLoading">{{ 'word.loading' | translate }}...</td>
                      <tr class="clickable-row" *ngFor="let order of orders" (click)="showOrder(order.order_id)">
                        <td>{{order.order_id}}</td>
                        <td>{{order.tenant}}</td>
                        <td>{{order.product}}</td>
                        <td>{{order.quantity}}</td>
                        <td>{{order.order_status}}</td>
                        <td>{{order.is_auto_renew}}</td>
                        <td>{{order.created_at}}</td>
                        <td>{{order.expired_at}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
