<main id="microsoftcsp-tenant-detail" class="main">

    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
      <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants">{{ 'word.tenants' | translate }}</li>
      <li class="breadcrumb-item active">{{ tenantId }}</li>
    </ol>

    <div class="container-fluid">
      <div class="animated fadeIn">

        <div class="row" *ngIf="errorCheck">
          <div class="col-12">
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
              <strong>{{ 'word.error.oops' | translate }} </strong> {{ 'word.error.somethingwrong' | translate }}
              <ul>
                <li *ngFor="let error of errors">
                  {{ error }}
                </li>
              </ul>
              <button type="button" class="close" (click)="errorClosePressed()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="isTransit">
          <div class="col-12">
            <div class="card card-body transit-info">
              <h5>{{ 'o365.tenantassociation' | translate }}</h5>
              <span>{{ 'o365.instructlogin' | translate }} <strong>{{ 'o365.partnerconnect' | translate }}</strong> {{ 'o365.usingadmincredential' | translate }}</span>
              <span>{{ 'o365.promptedconfirm' | translate }}</span>
              <span>{{ 'o365.afterconfirm' | translate }}</span>
            </div>
          </div>
        </div>

        <ng-template #loadingSpinner>
          <div class="col-12 text-center">
            <div class="card card-body" style="min-height: 400px;">
              <div class="lds-roller" style="margin: auto">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
              </div>
            </div>
          </div>
        </ng-template>

        <div class="row" style="min-height: 350px;" *ngIf="!tableLoading; else loadingSpinner">

          <div class="col-12">
            <div class="table-responsive mb-4">
              <table class="table table-bordered mb-0">
                <thead class="thead-light">
                  <tr>
                    <th colspan="2">{{ 'o365.tenantinfo' | translate }}</th>
                  </tr>
                </thead>
                <tbody class="bg-white" *ngIf="tenant">
                  <tr>
                    <td>{{ 'word.tenantid' | translate }}</td>
                    <td>{{ tenant.tenant_id }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'o365.tenantdomain' | translate }}</td>
                    <td>{{ tenant.tenant_domain? tenant.tenant_domain : '-' }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'o365.tenantqualification' | translate }}</td>
                    <td>{{ tenant.microsoft_market_category }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'word.status' | translate }}</td>
                    <td>{{ tenant.status }}</td>
                  </tr>
                  <tr>
                    <td>{{ 'word.totalsubscription' | translate }}</td>
                    <td>{{ tenant.subscriptions? tenant.subscriptions.length : '0' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-12">
            <div class="table-responsive mb-4">
              <table class="table table-bordered mb-0">
                <thead class="thead-light">
                  <tr>
                    <th colspan="3">{{ 'word.action' | translate }}</th>
                  </tr>
                </thead>
                <tbody class="bg-white microsoftcsp-actions" *ngIf="tenant">
                  <tr>
                    <td colspan="2">{{ 'o365.managesubscription' | translate }}</td>
                    <td>
                      <button (click)="goToSubscriptionManagement()" class="btn btn-block btn-primary"
                      [disabled]="!enableSubscriptionManagement" > {{ 'button.manage' | translate }} </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">{{ 'o365.manageuser' | translate }}</td>
                    <td>
                      <button (click)="goToUserManagement()" class="btn btn-block btn-primary"
                      [disabled]="!enableUserManagement" > {{ 'button.manage' | translate }} </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">{{ 'o365.managedomains' | translate }}</td>
                    <td>
                      <button (click)="goToDomainManagement()" class="btn btn-block btn-primary"
                      [disabled]="!enableDomainManagement" > {{ 'button.manage' | translate }} </button>
                    </td>
                  </tr>
                  <tr *ngIf="enableSendTenantCredentialsEmail">
                    <td colspan="2">{{ 'o365.sendtenantcredential' | translate }}
                      <i class="fa fa-info-circle text-warning" placement="auto" triggers="click:blur hover focus"
                      ngbTooltip={{this.receipTipLang[this.lang]}}>
                     </i>
                    </td>
                    <td>
                      <button (click)="sendTenantCredentialsEmail()" class="btn btn-block btn-primary"
                       [disabled]="sendTenantCredentialsEmailBtnPressed" > {{ 'button.send' | translate }}
                       <span *ngIf="sendTenantCredentialsEmailBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                     </button>
                    </td>
                  </tr>
                  <tr *ngIf="enableSendPartnerConnectUrlEmail">
                    <td colspan="2">{{ 'o365.sendpcurl' | translate }}
                      <i class="fa fa-info-circle text-warning" placement="auto" triggers="click:blur hover focus"
                      ngbTooltip="Send an email containing the partner connect url to registered tenant account email.">
                     </i>
                    </td>
                    <td>
                      <button (click)="sendPartnerConnectUrlEmail()" class="btn btn-block btn-primary"
                      [disabled]="sendPartnerConnectUrlEmailBtnPressed" > {{ 'button.send' | translate }}
                       <span *ngIf="sendPartnerConnectUrlEmailBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                     </button>
                    </td>
                  </tr>
                  <tr  *ngIf="enableGeneratePartnerConnectUrl">
                    <td colspan="2">{{ 'o365.generatepcurl' | translate }}
                      <i class="fa fa-info-circle text-warning" placement="auto" triggers="click:blur hover focus"
                      ngbTooltip="Generate the partner connect url.">
                     </i>
                    </td>
                    <td>
                      <button (click)="generatePartnerConnectUrlAndOpenModal(generatePartnerConnectUrlModal)" class="btn btn-block btn-primary"
                      [disabled]="generatePartnerConnectUrlBtnPressed"> {{ 'button.generate' | translate }}
                       <span *ngIf="generatePartnerConnectUrlBtnPressed">&nbsp;<i class="fa fa-spinner fa-spin"></i></span>
                     </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">{{ 'o365.getmcua' | translate }}</td>
                    <td>
                      <button class="btn btn-block btn-primary" (click)="getMCUA()"> {{ 'button.go' | translate }} </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-xl-12 text-right">
            <a routerLink="/microsoftcsp/tenants" class="btn btn-dark btn-lg px-5 mb-4">{{ 'button.back' | translate }}</a>
          </div>
        </div>


      </div>
    </div>

    <ng-template #generatePartnerConnectUrlModal>
      <div class="modal-header">
        <div class="modal-title">{{ 'o365.generatepcurl' | translate }}</div>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <code class="py-4">{{generatedPartnerConnectUrl}}</code>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark"  (click)="modalRef.hide()">{{ 'button.close' | translate }}</button>
      </div>
    </ng-template>

  </main>
