import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';
import { TranslateUtil } from 'src/app/util/translate-util';

@Component({
  selector: 'app-email-sidebar',
  templateUrl: './email-sidebar.component.html',
  styleUrls: ['./email-sidebar.component.css']
})
export class EmailSidebarComponent implements OnInit {

  menuItems: any[];

  constructor(
    private translateService: LanguageTranslateService
  ) { 

  }

  ngOnInit() {
    var $body = document.getElementsByTagName("BODY")[0];

    $body.classList.remove("sidebar-minimized");
    $body.classList.remove("brand-minimized");

    // the link must match with email-routing.module.ts defined paths
    this.menuItems = [
      { name: this.translate('word.dashboard'), routerLink:'/email/email-dashboard', iconClassName:'icon-speedometer'},
      { name: this.translate('word.placeorder'), routerLink:'/email/email-step-one', iconClassName:'icon-basket-loaded'},
      { name:this.translate('word.ordermanagement'), routerLink:'/email/email-order-management', iconClassName:'icon-basket-loaded'}
    ];
  }

  translate(key:string):string{
    return this.translateService.translate(key);
  }

}
