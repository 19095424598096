
      <main id="storefront" class="main create-storefront">

        <!-- Breadcrumb -->
        <ol class="breadcrumb">
          <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
          <li class="breadcrumb-item">{{ 'sf.storefrontsettings' | translate }}</li>
          <li class="breadcrumb-item active">{{ 'sf.createstorefront' | translate }}</li>
  
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 0%">
            </div>
          </div>
        </ol>
  
        <div class="container-fluid">
          <div class="animated fadeIn">
            <div class="row">
              <div class="col-lg-12">
                <a routerLink="/create-storefront-1" class="card-link">
                  <div class="card border-dashed bg-transparent">
                    <div class="card-table mx-auto" style="height: 300px">
                      <div class="card-table-row">
                        <div class="card-table-cell v-align-middle">
                          <h5 class="card-title">
                            <i class="fa fa-plus-circle mr-2"></i>{{ 'sf.createstorefront' | translate }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-lg-12" *ngFor="let store of storefronts">
                  <div class="card" style="width: 100%;">
                    <div class="row">
                      <div class="col-lg-6 pr-lg-0">
                        <img class="card-img-top" src="{{ store.cover?.cover_url }}" alt="">
                      </div>
    
                      <div class="col-lg-6 pl-lg-0">
                        <table class="table float-right">
                          <tbody>
                          <tr>
                            <td><b>{{ 'word.companyname' | translate }}</b></td>
                            <td>{{ store.storefront_name }}</td>
                          </tr>
                          <tr>
                            <td><b>{{ 'sf.storefronturl' | translate }}</b></td>
                            <td>{{ store.slug }}</td>
                          </tr>
                          <!--<tr>
                            <td><b>Company Logo</b></td>
                            <td><a href="{{ store.logo_url }}" _target="blank"><span class="badge badge-dark">View Logo</span></a></td>
                          </tr>-->
                          <tr>
                            <td><b>{{ 'sf.theme' | translate }}</b></td>
                            <td>
                              {{ store.theme?.theme_name }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>{{ 'sf.sellingproducts' | translate }}</b></td>
                            <td> 
                              
                                {{ store.selling_categories_count["Microsoft Office 365"] }} Microsoft Office 365
                              
                             </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </main>
