import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StepModel } from '../../../../../model/step.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { smoothCollapse } from 'src/app/shared/animations';

@Component({
  selector: 'app-microsoftcsp-user-new-optional',
  templateUrl: './microsoftcsp-user-new-optional.component.html',
  styleUrls: ['./microsoftcsp-user-new-optional.component.css'],
  animations: [
    smoothCollapse
  ]
})
export class MicrosoftCSPUserNewOptionalComponent implements OnInit {

  public isRoleCollapsed = true;
  public isProfileCollapsed = true;

  @Input() step: StepModel;
  @Output() newUserOptionalFormChanged: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() stepChanged: EventEmitter<any> = new EventEmitter<any>();

  newUserOptionalForm: UntypedFormGroup = null;

  tenantId: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      this.createNewUserOptionalForm();
    });
  }

  createNewUserOptionalForm() {
    this.newUserOptionalForm = this.formBuilder.group({

    });
  }

  onCompleteStep() {
    if (this.newUserOptionalForm.valid) {
      this.newUserOptionalFormChanged.emit(this.newUserOptionalForm);
      this.step.isComplete = true;
    }
  }

  onBackStep() {
    this.step.isComplete = false;
    this.stepChanged.emit();
  }

}
