import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, Validators } from '@angular/forms';

@Directive({
    selector: 'input[min]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinNumberValidatorDirective, multi: true }]
})
export class MinNumberValidatorDirective implements Validator {

    @Input('min') min: number;

    validate(control: AbstractControl): { [key: string]: any } {
        return Validators.min(this.min)(control);
    }
}
