export class TokenUtil {

    static getPreferredUsername(token: any) {
        token = token.split(".");
        var decryptedToken = JSON.parse(atob(token[1]));
        return decryptedToken.preferred_username;
    }

    static getRescode(token: any) {
        token = token.split(".");
        var decryptedToken = JSON.parse(atob(token[1]));
        return decryptedToken.reseller.rescode;
    }
}