<body class="app header-fixed sidebar-fixed aside-menu-fixed aside-menu-hidden">
  <div class="app flex-row align-items-center ng-scope">

    <div class="container" *ngIf="ssoLogin">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-9 col-12">
          <div class="card p-4">
            <div class="card-body">
              <div class="text-center">
                <img class="mx-auto mb-4" src="assets/img/webnic-logo-full.png" width="150" height="150" alt="">
              </div>
              <h1 class="text-center">Login</h1>
              <div *ngIf="ssoLoginLoading">
                <p class="text-muted text-center">Signing in to your account... Please wait.</p>
                <p class="text-center"><i class="fa fa-2x fa-cog fa-spin" style="font-size:24px; color: lightseagreen"></i></p>
              </div>
              <div *ngIf="!ssoLoginLoading">
                <p class="text-muted text-center">Failed to login!</p>
                <p class="text-center" style="color: red">{{ errorMessage }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" *ngIf="!ssoLogin">
      <div class="modal fade" id="myModal" tabindex="1" role="dialog">
        <div class="modal-dialog modal-sm modal-dialog-centered">
          <div class="modal-content">

            <div class="modal-header">
              <h4 class="modal-title">Error</h4>
              <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
              Please check your credential again.
            </div>

          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-9 col-12">
          <div class="card p-lg-2 p-md-5 p-2">
            <div class="card-body">
              <div class="text-center">
                <img class="mx-auto mb-4" src="assets/img/webnic-logo-full.png" width="150" height="150" alt="">
              </div>
              <h1 class="text-center">Login</h1>
              <p class="text-muted text-center">Sign in to your account</p>
              <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorMessage">
                <strong>Whoops, something went wrong!</strong>
                <ul style="padding-left: 1.2rem; margin-top: 5px;">
                  <li class="form-not-valid">{{ errorMessage }}</li>
                  <ng-container *ngFor="let validation of validation_messages.username">
                    <div class="error-message" *ngIf="loginForm.get('username').hasError(validation.type)">
                      <li class="form-not-valid">{{ validation.message }}</li>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="let validation of validation_messages.password">
                    <div class="error-message" *ngIf="loginForm.get('password').hasError(validation.type)">
                      <li class="form-not-valid">{{ validation.message }}</li>
                    </div>
                  </ng-container>
                </ul>
                <button type="button" class="close" (click)="refresh()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form [formGroup]="loginForm">
                <div class="input-group mb-3">
                  <div class="input-credential input-group-prepend col-12">
                    <span class="input-group-text">
                      <i class="icon-user"></i>
                    </span>
                    <input class="form-control" placeholder="Username" formControlName="username" [(ngModel)]="data.username" name="username" type="text" required>
                  </div>
                </div>
                <div class="input-group mb-4">
                  <div class="input-credential input-group-prepend col-12">
                    <span class="input-group-text">
                      <i class="icon-key"></i>
                    </span>
                    <input class="form-control" placeholder="Password" formControlName="password" [(ngModel)]="data.password" name="password" type="password" required>
                  </div>
                </div>
                <div *ngIf = "!disableCaptcha" class="input-group mb-3 ">
                  <div class="col-xs-12 text-center" >
                    <div hCaptcha formControlName="captcha" [siteKey]="captchaSiteKey"  (captchaResponse)="onCaptchaResponse($event)"
                     (captchaExpired)="onExpired($event)" (captchaError)="onError($event)">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="btn-login col-12 px-5">
                    <button class="btn btn-primary btn-block" [disabled]="loginForm.invalid || !captchaVerified" type="submit" (click)="login()">Login</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="modal fade" id="myModal">
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">

        <div class="modal-header">
          <h4 class="modal-title">Error</h4>
          <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
        </div>

        <div class="modal-body">
          Please check your credential again.
        </div>
    
      </div>
    </div>
  </div> -->

</body>
