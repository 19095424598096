<div class="row">
  <div class="col-12">
    <h6>{{ 'word.setupuser' | translate }}</h6>
    <p>{{ 'o365.whoadding' | translate }}</p>
    <form [formGroup]="newUserBasicsForm" style="overflow-y: visible">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="group form-group">
            <label for="firstName">
              <span>{{ 'word.firstname' | translate }}</span>
            </label>
            <input id="firstName" name="firstName" type="text" class="form-control" formControlName="firstName" />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="group form-group">
            <label for="lastName">
              <span>{{ 'word.lastname' | translate }}</span>
            </label>
            <input id="lastName" name="lastName" type="text" class="form-control" formControlName="lastName" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="group form-group">
            <label for="displayName">
              <span>{{ 'word.displayname' | translate }}</span>
              <span class="ml-1 mr-1 text-danger">*</span>
            </label>
            <input id="displayName" name="displayName" type="text" class="form-control" formControlName="displayName" (focus)="onFocusDisplayName()">

            <div *ngIf="displayNameFormControl.touched">
              <span class="text-danger" *ngIf="displayNameFormControl.hasError('required')">{{ 'error.providedisplayname' | translate }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="group form-group">
            <label for="username">
              <span>{{ 'word.username' | translate }}</span>
              <span class="ml-1 mr-1 text-danger">*</span>
              <i class="fa fa-spinner fa-spin" *ngIf="usernameLoading"></i>
            </label>
            <input id="username" name="username" type="text" class="form-control" formControlName="username" (keyup)="onUsernameKeyUp()" />

            <div *ngIf="usernameFormControl.touched">
              <span class="text-danger" *ngIf="usernameFormControl.hasError('required')">{{ 'error.username.required' | translate }}</span>
            </div>
            <div *ngIf="usernameFormControl.touched">
              <span class="text-danger" *ngIf="usernameFormControl.hasError('maxlength')">{{ 'error.username.maxlength' | translate }}</span>
            </div>
            <div *ngIf="usernameFormControl.touched">
              <span class="text-danger" *ngIf="usernameFormControl.hasError('pattern')">{{ 'error.username.pattern' | translate }}</span>
            </div>
            <div *ngIf="usernameFormControl.touched">
              <span class="text-danger" *ngIf="usernameFormControl.hasError('availability')">{{ 'error.username.availability1' | translate }} ‎{{ 'error.username.availability3' | translate }}‎ ({{usernameFormControl.value}}@{{domainFormControl.value}}). {{ 'error.username.availability2' | translate }}</span>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="group form-group">
            <label for="domain">
              <span>{{ 'word.domains' | translate }}</span>
              <i class="ml-1 fa fa-spinner fa-spin" *ngIf="domainsLoading"></i>
            </label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">@</span>
              </div>
              <select id="domain" formControlName="domain" [disabled]="domainsLoading" class="form-control" (change)="onDomainChange($event)">
                <option *ngFor="let domain of domains" [selected]="domain.isDefault" [value]="domain.id">{{ domain.id }}</option>
              </select>
            </div>

            <div *ngIf="domainFormControl.touched">
              <span class="text-danger" *ngIf="domainFormControl.hasError('required')">{{ 'error.selectdomain' | translate }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h6>{{ 'word.passwordsetting' | translate }}</h6>
          <div class="group form-group">
            <div class="col-12">
              <input id="autoPassword" name="generatePassword" type="radio" [value]="true" formControlName="generatePassword" (change)="onGeneratePasswordChanged()">
              <label for="autoPassword">
                <span class="ml-2">{{ 'word.autogeneratepass' | translate }}</span>
              </label>
            </div>
            <div class="col-12">
              <input id="manualPassword" name="generatePassword" type="radio" [value]="false" formControlName="generatePassword" (change)="onGeneratePasswordChanged()">
              <label for="manualPassword">
                <span class="ml-2">{{ 'o365.letmecreatepass' | translate }}</span>
              </label>

              <div class="col-12" *ngIf="!generatePasswordFormControl.value">
                <label for="password">
                  <span>{{ 'word.password' | translate }}</span>
                  <span class="ml-1 mr-1 text-danger">*</span>
                </label>
                <div class="input-group">
                  <input type="password" name="password" id="password" class="form-control" [attr.type]="hidePassword?'password':'text'" formControlName="password">
                  <div style="position: relative;">
                    <span class="pwd-show-hide" (click)="hidePassword = !hidePassword">
                      <i *ngIf="hidePassword;else showPassword" class="fa fa-eye"></i>
                      <ng-template #showPassword><i class="fa fa-eye-slash"></i></ng-template>
                    </span>
                  </div>
                </div>

                <div *ngIf="passwordFormControl.touched">
                  <span class="text-danger" *ngIf="passwordFormControl.hasError('required')">{{ 'error.providepassword' | translate }}</span>
                </div>
                <div *ngIf="passwordFormControl.touched">
                  <span class="text-danger" *ngIf="passwordFormControl.hasError('pattern')">{{ 'error.password.pattern' | translate }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input" type="checkbox" name="forceChangePassword" id="forceChangePassword" [checked]="forceChangePasswordFormControl.value" formControlName="forceChangePassword">
            <label class="custom-control-label" for="forceChangePassword">
              <span class="ml-2">{{ 'o365.requiredchangepass' | translate }}</span>
            </label>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-primary m-3" (click)="onCompleteStep()" [disabled]="newUserBasicsForm.invalid || domainsLoading || usernameLoading">
        {{ 'button.next' | translate }}
      </button>

    </form>
  </div>
</div>
