import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StoreService } from './store.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class StoreFrontService {

  apiUrl = environment.apiUrl;
  mainApiUrl = environment.mainApiUrl;

  httpHeaders = {
    headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
  }

  getHeaders() {
    // create new instance for each api call, instead of global static
    const httpHeaders = {
      headers: new HttpHeaders()
        .set("Authorization", "Bearer " + localStorage.getItem('access_token'))
        .set('Content-Type', 'application/json')
    }
    return httpHeaders;
  }

  constructor(
    private http: HttpClient,
    public storeService: StoreService
  ) { }

  getAllStoreFrontByReseller() {
    return this.http.get(this.apiUrl + 'storefront/get-all-storefronts-by-reseller', this.getHeaders())
  }
  getCountries() {
    return this.http.get(`${this.mainApiUrl}countries`);
  }
  getStates(countryId) {
    return this.http.get(
      `${this.mainApiUrl}country/${countryId}/states`
    );
  }
  getCities(cityId) {
    return this.http.get(
      `${this.mainApiUrl}state/${cityId}/cities`
    );
  }

  getAllCovers() {
    return this.http.get(this.apiUrl + 'storefront/get-covers', this.getHeaders())
  }
  getAllThemes() {
    return this.http.get(this.apiUrl + 'storefront/get-themes', this.getHeaders())
  }
  getAllCategories() {
    return this.http.get(this.apiUrl + 'storefront/get-categories', this.getHeaders())
  }

  getAllPaymentMethods() {
    return this.http.get(this.apiUrl + 'storefront/get-payments', this.getHeaders())
  }

  createStorefront() {
    let data = {
      // storefront info
      storefront_name: this.storeService.storefrontName,
      storefront_code: this.storeService.storefrontCode,
      storefront_logo: this.storeService.logo,
      email: this.storeService.email,
      phone_number: this.storeService.phoneNumber,
      department: this.storeService.department,
      address: this.storeService.address,
      state_id: this.storeService.stateId,
      country_id: this.storeService.countryId,

      // theme settings
      theme_id: this.storeService.themeId,

      // cover settings
      selected_cover: this.storeService.selectedCover,
      cover_title: this.storeService.coverTitle,
      cover_text: this.storeService.coverText,
      custom_cover: this.storeService.customCover,

      // about us settings (static html)
      about_content: this.storeService.aboutusValue,
      // contact us settings (static html)
      contact_content: this.storeService.contactusValue,

      // custom page settings
      page_title: this.storeService.pageTitle,
      page_content: this.storeService.pageContent,
      custom_page_status: this.storeService.customPageStatus,

      // payment settings
      selected_payment: this.storeService.selectedPaymentMethodIds,
      paymentOptions: this.storeService.paymentOptions,

      // custom domain settings
      custom_domain: this.storeService.customDomain,

      selectedO365Products: this.storeService.selectedO365Products,
      selectedAlibabaMailProducts: this.storeService.selectedAlibabaMailProducts
    }
    console.log('data sent', data);
    return this.http.post(this.apiUrl + 'storefront/create-store', data, this.getHeaders())
  }

  updateStorefront(id) {
    let data = {
      storefront_id: id,
      storefront_name: this.storeService.storefrontName,
      storefront_code: this.storeService.storefrontCode,
      storefront_logo: this.storeService.logo,
      email: this.storeService.email,
      phone_number: this.storeService.phoneNumber,
      department: this.storeService.department,
      address: this.storeService.address,
      state_id: this.storeService.stateId,
      country_id: this.storeService.countryId,

      theme_id: this.storeService.themeId,

      selected_cover: this.storeService.selectedCover,
      cover_title: this.storeService.coverTitle,
      cover_text: this.storeService.coverText,
      custom_cover: this.storeService.customCover,

      about_content: this.storeService.aboutusValue,
      contact_content: this.storeService.contactusValue,

      page_title: this.storeService.pageTitle,
      page_content: this.storeService.pageContent,
      custom_page_status: this.storeService.customPageStatus,

      // payment
      selected_payment: this.storeService.selectedPaymentMethodIds,
      paymentOptions: this.storeService.paymentOptions,

      custom_domain: this.storeService.customDomain,

      selectedO365Products: this.storeService.selectedO365Products,
      selectedAlibabaMailProducts: this.storeService.selectedAlibabaMailProducts
    }

    return this.http.post(this.apiUrl + 'storefront/update-store', data, this.getHeaders())

  }

  getO365ProductListForResellerPricing() {
    return this.http.get(this.apiUrl + 'storefront/get-o365-products-reseller-pricing', this.getHeaders());
  }

  getO365ProductListForStorePricing(storefrontid:any) {
    return this.http.get(this.apiUrl + `storefront/get-o365-products-retail-pricing/${storefrontid}`, this.getHeaders());
  }

  getProductCategoriesByStorefront(storefrontId:any){
    return this.http.get(this.apiUrl+`storefront/get-categories-by-storefront/${storefrontId}`, this.getHeaders());
  }
  
  updateO365ProductStorePricing(storefrontId:any, data:any){
    return this.http.post(this.apiUrl+`storefront/update-o365-retail-price/${storefrontId}`,data, this.getHeaders());
  }

  getSfActiveO365Products(storefrontId:any){
    return this.http.get(this.apiUrl+`storefront/get-o365-products-by-storefront/${storefrontId}`, this.getHeaders());
  }

  getSfActiveO365Product(storefrontId:any, productId:any){
    return this.http.get(this.apiUrl+`storefront/get-o365-product-by-storefront/${storefrontId}/${productId}`, this.getHeaders());
  }

  getAllO365ProductsActivePromotions(storefrontId:any){
    return this.http.get(this.apiUrl+`storefronts/${storefrontId}/products/promotions/product-ids`, this.getHeaders());
  }

  getO365ProductActivePromotions(storefrontId:any, productId:any){
    return this.http.get(this.apiUrl+`storefronts/${storefrontId}/products/${productId}/promotions`, this.getHeaders());
  }

  saveO365ProductRetailPromotionPrice(storefrontId:any,data:any){
    return this.http.post(this.apiUrl+`storefronts/${storefrontId}/products/promotion-price/save`,data, this.getHeaders());

  }
}
