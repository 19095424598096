export class EmailOrderStatistics {

    TotalOrder: number;
    TotalLicense: number;
    TotalExpiring: number;
    TotalExpired: number;

    constructor() {
        this.TotalOrder = 0;
        this.TotalLicense = 0;
        this.TotalExpiring = 0;
        this.TotalExpired = 0;
    }
}