import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';
import * as moment from 'moment';
import 'datatables.net';
import 'datatables.net-bs4';
import { Router, ActivatedRoute } from '@angular/router';
import { EmailApiService } from 'src/app/service/email-api.service';
import { finalize } from 'rxjs/operators';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';
import { ScrollUtil } from 'src/app/util/scroll-util';

@Component({
  selector: 'app-email-order-management',
  templateUrl: './email-order-management.component.html',
  styleUrls: ['./email-order-management.component.css']
})
export class EmailOrderManagementComponent implements OnInit, AfterViewInit {

  statusFilter: string;
  orderLoading: boolean = true;
  orders: any[]; // currently didn't use 

  dtOptions = {};
  dataTable: any;
  lang = '';

  loadEmailOrders: any = () => {};
  onRowCallback: any = () => {};
  setOrderLoadingFalse = () => {this.orderLoading = false};

  errorCheck : boolean = false;
  errors: any[] = [];

  constructor(
    private emailApiService: EmailApiService,
    private chRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: LanguageTranslateService
  ) { }

  ngOnInit() {
    this.lang = this.translateService.userLanguage$.value;
    this.statusFilter = null;

    this.route.queryParams.subscribe((params) => {
      this.statusFilter = params['filter']; // this also cater for custom status from dashboard 

      if (!this.statusFilter) {
        // no exist, default to 'All'
        this.statusFilter = 'All';
      }

      this.loadDataTableConfig();
    });
  }

  loadDataTableConfig() {
    //const that = this;

    this.loadEmailOrders = (dataTablesParameters: any, callback: any) => {
      this.orderLoading = true;
      this.emailApiService.getEmailOrders(this.statusFilter).pipe(finalize(this.setOrderLoadingFalse))
        .subscribe((res: any) => {
          let orders: any[] = res.orders;
          this.orders = orders; // currently didn't use it

          // return result to datatable
          callback({
            recordsTotal: orders.length,
            recordsFiltered: orders.length,
            data: orders // will use this data for the table
          });
        },err=>{
            this.handleError(err);
            // return empty array to datatable
            callback({
              recordsTotal: 0,recordsFiltered: 0, data: [] 
            });
        });
    }

    // set on row callback for events register
    this.onRowCallback = (row:any, data:any) => {
      $(row).addClass('clickable-row');

      // register click event to navigate detail page
      $(row).on( "click", () => {
        this.router.navigate(['/email/email-order-detail', data.oid]);
      });
    }

    this.dtOptions = {
      ajax: this.loadEmailOrders,
      columns: [
        { data: 'orderid'},
        { data: 'domemail', defaultContent:'-'},
        { data: 'packageName',defaultContent:'-'},
        { data: 'licenses', defaultContent:'-'}, 
        { data: 'statusValue', defaultContent:'-', },
        { data: 'paymentStatusValue', defaultContent:'-', },
        { data: 'storefrontname', defaultContent:'-', className: "max-col-width" },
        { 
          data: "dtorder",
          defaultContent:'-',
          render:function(data){
            if(data && data!=null && data!=''){
              return moment(data).format("YYYY-MM-DD HH:mm:ss");
            }else{
              return '-';
            }
          }
        },
        { 
          data: "dtcomplete",
          defaultContent:'-',
          render:function(data){
            if(data && data!=null && data!=''){
              return moment(data).format("YYYY-MM-DD HH:mm:ss");
            }else{
              return '-';
            }
          }
        }
      ],
      order: [7, "desc"], // default sort by dtorder desc,
      rowCallback: this.onRowCallback
    };
  }

  ngAfterViewInit() {
    this.lang = this.translateService.userLanguage$.value;
    // init datatable here
    const table: any = $('#emailOrderDataTable');
    if(this.lang) {
      this.dtOptions = {
        ...this.dtOptions,
        "language": {
          "url" : "assets/json/datatable_"+ this.lang +".json"
        }
      };
    } else {
      this.dtOptions = {
        ...this.dtOptions,
        "language": {
          "url" : "assets/json/datatable_"+ this.lang +".json"
        }
      };
    }
    
    this.dataTable = table.DataTable(
      this.dtOptions = {
        ...this.dtOptions,
        "language": {
          "url" : "assets/json/datatable_"+ this.lang +".json"
        }
      },
      );
  }

  handleError(err:any) {
    console.error(err);

    this.errors.length = 0;
    if (err.error instanceof ErrorEvent) {
      // client-side error
      let errorMessage = `Error: ${err.error.message}`;
      this.errors.push(errorMessage);
    } else {
      // server-side error
      let responseBodyErrors: any[] = null;
      if(err.error && err.error.errors){ 
        responseBodyErrors = err.error.errors;
      }

      if (responseBodyErrors && responseBodyErrors.length>0) {
        this.errors = responseBodyErrors.map(e=> e.message);
      }else{
        if(err.status == 0) {
          this.errors.push('Connection error. Please try again later.');
        }else{
          this.errors.push(`${err.message}`);
        }
      }
    }
    this.errorCheck = true;
    ScrollUtil.scrollToTop();
  }

  closePressed(){this.errorCheck = false};

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

}
