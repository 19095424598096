import { Component,OnInit } from '@angular/core';
import { Validators,UntypedFormBuilder,UntypedFormGroup } from '@angular/forms';
import { O365HttpService } from '../../service/o365-http.service'
import { Router } from '@angular/router'
import { O365OrderService } from 'src/app/service/o365-order.service';
import { CountriesService } from 'src/app/service/countries.service';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MicrosoftCSPCountryAddressValidation } from 'src/app/model/microsoftcsp/microsoftcsp-address-validation.model';
import { AlertService } from 'src/app/service/alert.service';
import { ScrollUtil } from 'src/app/util/scroll-util';
import { MicrosoftCSPMarketCategory } from 'src/app/model/microsoftcsp/microsoftcsp-market-category.model';

@Component({
  selector: 'app-o365-step-one',
  templateUrl: './o365-step-one.component.html',
  styleUrls: ['./o365-step-one.component.css']
})
export class O365StepOneComponent implements OnInit {

  errors:any[] = [];
  errorCheck : boolean = false;
  errorMessage = '';

  data = {
    username: '',
    password: '',
    company_name: '',
    company_registration_number: '',
    contact_email_address: '',
    alt_email_address: '',
    contact_first_name: '',
    contact_last_name: '',
    contact_phone_no: '',
    default_domain_prefix: '',
    use_transit_tenant: false,
    contact_address_country_id: 0,
    contact_address_state_id: 0,
    contact_address_city_id: 0,
    contact_address_city_other: '',
    contact_address_postcode: '',
    contact_address_street1: '',
    contact_address_street2: '',
    contact_address_street3: '',
    billing_address_country_id: 0,
    billing_address_state_id: 0,
    billing_address_city_id: 0,
    billing_address_city_other: '',
    billing_address_postcode: '',
    billing_address_street1: '',
    billing_address_street2: '',
    billing_address_street3: '',
    is_marketing_promo: false,
    microsoft_market_category_id: 1,
    include_partner: false
  }

  transfer_tenant = false;
  tenantForm: UntypedFormGroup;

  countriesBilling = [];
  countriesContact = [];
  statesBilling = [];
  statesContact = [];
  citiesBilling = [];
  citiesContact = [];

  contactCountryLoading: boolean = false;
  contactStateLoading: boolean = false;
  contactCityLoading: boolean = false;
  billingCountryLoading: boolean = false;
  billingStateLoading: boolean = false;
  billingCityLoading: boolean = false;

  contactOtherCitySelected = false;
  billingOtherCitySelected = false;
  nextBtnPressed = false;

  defaultDomainPrefixLoading: boolean = false;
  defaultDomainPrefixDebounceTimer: any;
  defaultDomainPrefixValidateSuccess: boolean = false;
  defaultDomainPrefixErrorMessage: string = 'Failed to validate domain prefix. Try again later.';

  domainPrefixRegex = '^[0-9a-z]{1,27}$';

  billingCountryIdSubscription: Subscription;
  billingStateIdSubscription: Subscription;
  billingCityIdSubscription: Subscription;

  countryAddressValidations: MicrosoftCSPCountryAddressValidation[] = [];
  selectedContactAddressValidation: MicrosoftCSPCountryAddressValidation;
  selectedBillingAddressValidation: MicrosoftCSPCountryAddressValidation;

  showContactAddressFormInputs: boolean = false;
  showBillingAddressFormInputs: boolean = false;

  useContactAddressAsBillingAddress: boolean = true;

  tenantFormStates:any; // custom object to store form states
  hidePassword:boolean = true;

  marketCategories: MicrosoftCSPMarketCategory[] = [];
  showAcademicQualificationInfoMessage: boolean = false;
  showNonProfitQualificationInfoMessage: boolean = false;
  marketCategoryLoading: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public httpService: O365HttpService,
    public orderService: O365OrderService,
    public countriesService: CountriesService,
    public router: Router,
    private alertService: AlertService
  ) { }

  createTenantForm() {
    this.tenantForm = this.formBuilder.group({
      default_domain_prefix: ['', [Validators.required, Validators.pattern(this.domainPrefixRegex)]],
      microsoft_market_category_id: [1, [Validators.required] ],
      company_name: [''],
      company_registration_number: [''],
      username: ['', Validators.required],
      password: ['', [Validators.required,Validators.minLength(8), Validators.maxLength(20)]],
      contact_email_address: ['', [Validators.required, Validators.email]],
      alt_email_address: ['', [Validators.email]],
      contact_first_name: ['', Validators.required],
      contact_last_name: ['', Validators.required],
      contact_phone_no: ['',  Validators.required],

      contact_address_street1: ['', [Validators.required, Validators.maxLength(70)]],
      contact_address_street2: ['', [Validators.maxLength(70)]],
      contact_address_street3: ['', [Validators.maxLength(70)]],
      contact_address_country_id: [0, [Validators.required,Validators.min(1)] ],
      contact_address_state_id: [0, [Validators.required,Validators.min(1)]],
      contact_address_city_id: [0, [Validators.required,Validators.min(1)]],
      contact_address_city_other: [''],
      contact_address_postcode: [''],

      billing_address_street1: [{value: '',disabled: true}, [Validators.required, Validators.maxLength(70)]],
      billing_address_street2: [{value: '',disabled: true}, [Validators.maxLength(70)]],
      billing_address_street3: [{value: '',disabled: true}, [Validators.maxLength(70)]],
      billing_address_country_id: [{ value: 0,disabled: true}, [Validators.required,Validators.min(1)] ],
      billing_address_state_id: [{ value: 0, disabled: true}, [Validators.required,Validators.min(1)]],
      billing_address_city_id: [{ value: 0, disabled: true}, [Validators.required,Validators.min(1)]],
      billing_address_city_other: [{value: null, disabled: true}],
      billing_address_postcode: [{value: '',disabled: true}],

      is_marketing_promo: [false],

      mcua_agreement: [false,Validators.requiredTrue],

      include_partner: [false]
    });

     // custom store the tenant form state value
     this.tenantFormStates = {};
     // set contact_address_postcode's required to false
     this.tenantFormStates.contactPostcodeRequired = false;
     // set contact_address_postcode's required to false
     this.tenantFormStates.billingPostcodeRequired = false;
     this.tenantFormStates.companyRegistrationNumberRequired = false;
  }

  get defaultDomainPrefixFormControl(){return this.tenantForm.get('default_domain_prefix');}
  get microsoftMarketCategoryIdFormControl(){return this.tenantForm.get('microsoft_market_category_id');}
  get usernameFormControl(){return this.tenantForm.get('username');}
  get passwordFormControl(){return this.tenantForm.get('password');}
  get companyNameFormControl(){return this.tenantForm.get('company_name');}
  get companyRegistrationNumberFormControl(){return this.tenantForm.get('company_registration_number');}
  get contactEmailFormControl(){return this.tenantForm.get('contact_email_address');}
  get contactAltEmailFormControl(){return this.tenantForm.get('alt_email_address');}
  get contactFirstNameFormControl(){return this.tenantForm.get('contact_first_name');}
  get contactLastNameFormControl(){return this.tenantForm.get('contact_last_name');}
  get contactPhoneNoFormControl(){return this.tenantForm.get('contact_phone_no');}

  get contactStreet1FormControl(){return this.tenantForm.get('contact_address_street1');}
  get contactStreet2FormControl(){return this.tenantForm.get('contact_address_street2');}
  get contactStreet3FormControl(){return this.tenantForm.get('contact_address_street3');}
  get contactCountryIdFormControl(){return this.tenantForm.get('contact_address_country_id');}
  get contactStateIdFormControl(){return this.tenantForm.get('contact_address_state_id');}
  get contactCityIdFormControl(){return this.tenantForm.get('contact_address_city_id');}
  get contactOtherCityFormControl(){return this.tenantForm.get('contact_address_city_other');}
  get contactPostcodeFormControl(){return this.tenantForm.get('contact_address_postcode');}

  get billingStreet1FormControl(){return this.tenantForm.get('billing_address_street1');}
  get billingStreet2FormControl(){return this.tenantForm.get('billing_address_street2');}
  get billingStreet3FormControl(){return this.tenantForm.get('billing_address_street3');}
  get billingCountryIdFormControl(){return this.tenantForm.get('billing_address_country_id');}
  get billingStateIdFormControl(){return this.tenantForm.get('billing_address_state_id');}
  get billingCityIdFormControl(){return this.tenantForm.get('billing_address_city_id');}
  get billingOtherCityFormControl(){return this.tenantForm.get('billing_address_city_other');}
  get billingPostcodeFormControl(){return this.tenantForm.get('billing_address_postcode');}

  ngOnInit() {

    this.createTenantForm();

    this.contactCountryLoading = true;
    this.billingCountryLoading = true;
    this.countriesService.getCountriesCsp().subscribe(response => {
      this.countriesBilling = response["data"];
      this.countriesContact = response["data"];

      this.contactCountryLoading = false;
      this.billingCountryLoading = false;
    }, err => {
        console.error(err);
    });

    this.initData().then(()=>{});
  }

  onDefaultDomainPrefixKeyUpEvent(event: any) {
    this.defaultDomainPrefixValidateSuccess = false;

    let domainPrefixFormControl = this.defaultDomainPrefixFormControl;

    if (domainPrefixFormControl.hasError('required') || domainPrefixFormControl.hasError('pattern')) {
      // dont check input if has other errors
      domainPrefixFormControl.markAsTouched();
      return;
    }

    clearTimeout(this.defaultDomainPrefixDebounceTimer);

    this.defaultDomainPrefixDebounceTimer = setTimeout(() => {
      this.defaultDomainPrefixLoading = true;
      let value: string = event.target.value;

      // validate domain prefix
      this.httpService.validateDomainPrefix(value).pipe(finalize(() => {
        this.defaultDomainPrefixLoading = false;
      })).subscribe((res: any) => {
        domainPrefixFormControl.setErrors(null);
        if (res.data.data.message === true) {
          this.defaultDomainPrefixValidateSuccess = true;
        } else {
          this.defaultDomainPrefixValidateSuccess = false;
          domainPrefixFormControl.setErrors({ invalidDomain: true });
        }
      }, err => {
        console.error(err);
        domainPrefixFormControl.setErrors({ validateDomainPrefixError: true })
      });

    }, 1500);

  }

  onUseContactAsBillingChanged(event:any){
    let checked:boolean = event.target.checked;

    // alway disable 1st
    this.tenantForm.get('billing_address_country_id').disable();
    this.tenantForm.get('billing_address_state_id').disable();
    this.tenantForm.get('billing_address_city_id').disable();
    this.tenantForm.get('billing_address_city_other').disable();
    this.tenantForm.get('billing_address_postcode').disable();
    this.tenantForm.get('billing_address_street1').disable();
    this.tenantForm.get('billing_address_street2').disable();
    this.tenantForm.get('billing_address_street3').disable();

    if(!checked){
      this.tenantForm.get('billing_address_country_id').patchValue(0);
      this.tenantForm.get('billing_address_state_id').patchValue(0);
      this.tenantForm.get('billing_address_city_id').patchValue(0);
      this.tenantForm.get('billing_address_city_other').patchValue(null);

      this.tenantForm.get('billing_address_country_id').enable();
      this.tenantForm.get('billing_address_state_id').enable();
      this.tenantForm.get('billing_address_city_id').enable();
      this.tenantForm.get('billing_address_city_other').enable();
      this.tenantForm.get('billing_address_postcode').enable();
      this.tenantForm.get('billing_address_street1').enable();
      this.tenantForm.get('billing_address_street2').enable();
      this.tenantForm.get('billing_address_street3').enable();
    }

    this.useContactAddressAsBillingAddress = checked;
  }

  toggleTransferTenant() {
    this.transfer_tenant = this.transfer_tenant ? false : true;

    if (!this.transfer_tenant) {
      this.tenantForm.controls['default_domain_prefix'].enable();
      this.tenantForm.controls['include_partner'].enable();
    } else {
      this.tenantForm.controls['default_domain_prefix'].disable();
      this.tenantForm.controls['include_partner'].disable();
    }

    // CR253
    let microsoftMarketCategorySelectId:any = document.getElementById('microsoftMarketCategoryId');
    if(microsoftMarketCategorySelectId){
      let categoryId = Number(microsoftMarketCategorySelectId.value);
      // manual trigger update onChange event
      this.onMicrosoftQualificationChanged(categoryId);
    }
  }

  nextPressed() {
    this.nextBtnPressed = true;
    this.errorCheck = false;

    if (this.tenantForm.invalid) {
      console.log('error', this.tenantForm.errors);
    } else {
      console.log('pressed');

      this.data = {
        username: this.tenantForm.value.username,
        password: this.tenantForm.value.password,
        company_name: this.tenantForm.value.company_name,
        company_registration_number: this.tenantForm.value.company_registration_number,
        contact_email_address: this.tenantForm.value.contact_email_address,
        alt_email_address: this.tenantForm.value.alt_email_address,
        contact_first_name: this.tenantForm.value.contact_first_name,
        contact_last_name: this.tenantForm.value.contact_last_name,
        contact_phone_no: this.tenantForm.value.contact_phone_no,
        default_domain_prefix: this.tenantForm.value.default_domain_prefix,
        use_transit_tenant: this.transfer_tenant,
        contact_address_country_id: this.tenantForm.value.contact_address_country_id,
        contact_address_state_id: this.tenantForm.value.contact_address_state_id,
        contact_address_city_id: this.tenantForm.value.contact_address_city_id,
        contact_address_city_other: this.tenantForm.value.contact_address_city_other,
        contact_address_postcode: this.tenantForm.value.contact_address_postcode,
        contact_address_street1: this.tenantForm.value.contact_address_street1,
        contact_address_street2: this.tenantForm.value.contact_address_street2,
        contact_address_street3: this.tenantForm.value.contact_address_street3,
        billing_address_country_id: this.tenantForm.value.billing_address_country_id,
        billing_address_state_id: this.tenantForm.value.billing_address_state_id,
        billing_address_city_id: this.tenantForm.value.billing_address_city_id,
        billing_address_city_other: this.tenantForm.value.billing_address_city_other,
        billing_address_postcode: this.tenantForm.value.billing_address_postcode,
        billing_address_street1: this.tenantForm.value.billing_address_street1,
        billing_address_street2: this.tenantForm.value.billing_address_street2,
        billing_address_street3: this.tenantForm.value.billing_address_street3,
        is_marketing_promo: this.tenantForm.value.is_marketing_promo,
        microsoft_market_category_id: this.tenantForm.value.microsoft_market_category_id,
        include_partner: this.tenantForm.value.include_partner
      };

      // override billing address using contact address
      if (this.useContactAddressAsBillingAddress) {
        this.data.billing_address_country_id = this.data.contact_address_country_id;
        this.data.billing_address_state_id = this.data.contact_address_state_id;
        this.data.billing_address_city_id = this.data.contact_address_city_id;
        this.data.billing_address_city_other = this.data.contact_address_city_other;
        this.data.billing_address_postcode = this.data.contact_address_postcode;
        this.data.billing_address_street1 = this.data.contact_address_street1;
        this.data.billing_address_street2 = this.data.contact_address_street2;
        this.data.billing_address_street3 = this.data.contact_address_street3;
      }

      let phoneNumber = this.data.contact_phone_no["internationalNumber"];
      this.data.contact_phone_no = phoneNumber.replace(" ", ".");

      console.log('all data', this.data);
      console.log('all data2', this.tenantForm);

      if (!this.transfer_tenant) {
        this.httpService.createTenantValidate(this.data).subscribe(resp => {

          console.log('createTenantValidate success', resp);
          this.orderService.setTenantData(this.data);
          this.router.navigate(['/o365-step-two']);
        }, err => {
          console.log('createTenantValidate error', err);
          this.nextBtnPressed = false;

          if (err.error.message == 'The given data was invalid.') {
            var er = [];
            var ers = err.error.errors;
            console.log('form errors found', ers);
            Object.keys(ers).forEach((key) => {
              er.push(ers[key]);
            });
            this.errors = er;
          } else {
            try{
              let er = [];
              let ers = err.error.error;
              ers.forEach((e) => {
                er.push(e.detail);
              });
              this.errors = er;
            }catch(tryError){
              let errMsg = err.error.message;
              this.errors.push(errMsg);
            }
          }

          this.errorCheck = true;
          ScrollUtil.scrollToTop();
        });
      } else {
        this.httpService.transitTenantValidate(this.data).subscribe(resp => {

          console.log('transitTenantValidate success', resp);
          this.orderService.setTenantData(this.data);

          this.router.navigate(['/o365-step-two']);

        }, err => {
          console.log('transitTenantValidate error', err);
          this.nextBtnPressed = false;
          if (err.error.message == 'The given data was invalid.') {
            let er = [];
            let ers = err.error.errors;

            Object.keys(ers).forEach((key) => {
              er.push(ers[key]);
            });

            this.errors = er;
          } else {
            try{
              let er = [];
              let ers = err.error.error;
              ers.forEach((e) => {
                er.push(e.detail);
              });
              this.errors = er;
            }catch(tryError){
              let errMsg = err.error.message;
              this.errors.push(errMsg);
            }
          }
          this.errorCheck = true;
          ScrollUtil.scrollToTop();
        });
      }

    }
  }

  closePressed() {
    this.errorCheck = false;
    this.errors.length = 0;
  }

  async initData() {
    await this.httpService.getAddressValidations().toPromise().then((res: any) => {
      this.countryAddressValidations = res.data;
    }).catch(err => {
      console.error(err);
    });
    this.marketCategoryLoading = true;
    await this.httpService.getMarketCategories().toPromise().then((res:any)=>{
      this.marketCategories = res.data;
      this.marketCategoryLoading = false;
    }).catch(err=>{
      console.error(err);

    })

  }

  onMicrosoftQualificationChanged(value:any){
    if(this.transfer_tenant){
      this.showAcademicQualificationInfoMessage = false;
      this.showNonProfitQualificationInfoMessage = false;
      this.microsoftMarketCategoryIdFormControl.setErrors(null);
      this.microsoftMarketCategoryIdFormControl.markAsTouched();
      this.microsoftMarketCategoryIdFormControl.updateValueAndValidity();
      return;
    }

    this.showAcademicQualificationInfoMessage = false;
    this.showNonProfitQualificationInfoMessage = false;

    let category = this.marketCategories.find(c=>c.id == value);
    if(category){
      if (category.name == 'Academic') {
        this.showAcademicQualificationInfoMessage = true;
        this.microsoftMarketCategoryIdFormControl.setErrors({notAllowed:true});
      } else if (category.name == 'NotForProfit') {
        this.showNonProfitQualificationInfoMessage = true;
        this.microsoftMarketCategoryIdFormControl.setErrors({notAllowed:true});
      } else {
        this.microsoftMarketCategoryIdFormControl.setErrors(null);

        this.microsoftMarketCategoryIdFormControl.markAsTouched();
        this.microsoftMarketCategoryIdFormControl.updateValueAndValidity();
      }
    }
  }

  onCountryChanged(countryId:any, addressType:string){
    if(countryId == undefined || countryId == null || countryId < 1){
      return;
    }

    if(addressType == 'contact'){
      // reset
      this.statesContact.length = 0;
      this.citiesContact.length = 0;
      this.contactStateIdFormControl.patchValue(0);
      this.contactCityIdFormControl.patchValue(0);

      // find contact address validation
      this.selectedContactAddressValidation = this.countryAddressValidations.find(cav=>cav.countryId == countryId);
      // not exists then set error to invalid the form
      if(!this.selectedContactAddressValidation){
        this.contactCountryIdFormControl.setErrors({ invalidAddressValidation: true });
        this.alertService.showError('Something went wrong. Please try again later.')
        return;
      }
      this.updateAddressValidation(addressType);

      // show contact address form
      this.showContactAddressFormInputs = true;



      this.contactStateLoading = true;
      this.countriesService.getStates(countryId).subscribe((res:any) => {
        this.statesContact = res.data;
        this.contactStateLoading = false;

      });
    }else if(addressType == 'billing'){
      // reset
      this.statesBilling.length = 0;
      this.citiesBilling.length = 0;
      this.billingStateIdFormControl.patchValue(0);
      this.billingCityIdFormControl.patchValue(0);

      // find billing address validation
      this.selectedBillingAddressValidation = this.countryAddressValidations.find(cav=>cav.countryId == countryId);
      // not exists then set error to invalid the form
      if(!this.selectedBillingAddressValidation){
        this.billingCountryIdFormControl.setErrors({ invalidAddressValidation: true });
        this.alertService.showError('Something went wrong. Please try again later.')
        return;
      }

      this.updateAddressValidation(addressType);

       // show billing address form
       this.showBillingAddressFormInputs = true;

       this.billingStateLoading = true;
      this.countriesService.getStates(countryId).subscribe((res:any) => {
        this.statesBilling = res.data;
        this.billingStateLoading = false;
      });
    }
  }

  onStateChanged(stateId:any, addressType:string){
    if(stateId == undefined || stateId == null || stateId < 1){
      return;
    }

    if (addressType == 'contact') {
      this.citiesContact.length = 0;
      this.contactCityIdFormControl.patchValue(0);
      this.contactCityLoading = true;
      this.countriesService.getCities(stateId).subscribe((res: any) => {
        this.citiesContact = res.data;
        this.contactCityLoading = false;
      });
    } else if (addressType == 'billing') {
      this.citiesBilling.length = 0;
      this.billingCityIdFormControl.patchValue(0);
      this.billingCityLoading = true;
      this.countriesService.getCities(stateId).subscribe((res: any) => {
        this.citiesBilling = res.data;
        this.billingCityLoading = false;
      });
    }
  }

  onCityChanged(cityId:any, addressType:string){
    if(cityId == undefined || cityId == null || cityId < 1){
      return;
    }

    if (addressType == 'contact') {
      if(Number(cityId) == 50000){
        this.tenantForm.get('contact_address_city_other').setValidators([Validators.required]);
        this.tenantForm.get('contact_address_city_other').updateValueAndValidity();
        this.tenantFormStates.contactOtherCityRequired = true;
        this.contactOtherCitySelected = true;
      }else{
        this.contactOtherCityFormControl.patchValue('');
        this.tenantForm.get('contact_address_city_other').clearValidators();
        this.tenantForm.get('contact_address_city_other').updateValueAndValidity();
        this.tenantFormStates.contactOtherCityRequired = false;
        this.contactOtherCitySelected = false;
      }
    }else if(addressType == 'billing'){
      if(Number(cityId) == 50000){
        this.tenantForm.get('billing_address_city_other').setValidators([Validators.required]);
        this.tenantForm.get('billing_address_city_other').updateValueAndValidity();
        this.tenantFormStates.billingOtherCityRequired = true;
        this.billingOtherCitySelected = true;
      }else{
        this.billingOtherCityFormControl.patchValue(null);
        this.tenantForm.get('billing_address_city_other').clearValidators();
        this.tenantForm.get('billing_address_city_other').updateValueAndValidity();
        this.tenantFormStates.billingOtherCityRequired = false;
        this.billingOtherCitySelected = false;
      }
    }
  }

  updateAddressValidation(addressType:string){
    if(addressType == 'contact'){
      let postcodeFormControl = this.contactPostcodeFormControl;

      // set 'postcode' validation if true
      if(this.selectedContactAddressValidation.isPostCodeRequired){
        let regex = this.selectedContactAddressValidation.postalCodeRegex;
        postcodeFormControl.setValidators([Validators.required,Validators.pattern(regex)]);
        this.tenantFormStates.contactPostcodeRequired = true;
      }else{
        postcodeFormControl.clearValidators();
        this.tenantFormStates.contactPostcodeRequired = false;
      }

      // if 'postcodeFormControl' have value already then mark as touched
      if(postcodeFormControl.value){
        postcodeFormControl.markAsTouched();
      }
      // alway reset it
      postcodeFormControl.patchValue('');

      if(this.selectedContactAddressValidation.isCompanyRegistrationNumberRequired){
        let regex = this.selectedContactAddressValidation.companyRegistrationNumberRegex;
        this.companyRegistrationNumberFormControl.setValidators([Validators.required, Validators.pattern(regex)]);
        this.tenantFormStates.companyRegistrationNumberRequired = true;
      }else{
        this.companyRegistrationNumberFormControl.clearValidators();
        this.tenantFormStates.companyRegistrationNumberRequired = false; 
      }

      if(this.companyRegistrationNumberFormControl.value){
        this.companyRegistrationNumberFormControl.markAsTouched();
      }
      this.companyRegistrationNumberFormControl.patchValue('');
    }
    else if(addressType == 'billing'){
      let postcodeFormControl = this.billingPostcodeFormControl;

      // enable 'postcode' validation if true
      if(this.selectedBillingAddressValidation.isPostCodeRequired){
        let regex = this.selectedBillingAddressValidation.postalCodeRegex;
        postcodeFormControl.setValidators([Validators.required,Validators.pattern(regex)]);
        this.tenantFormStates.billingPostcodeRequired = true;
      }else{
        postcodeFormControl.clearValidators();
        this.tenantFormStates.billingPostcodeRequired = false;
      }

      // if 'postcodeFormControl' have value already then mark as touched
      if(postcodeFormControl.value){
        postcodeFormControl.markAsTouched();
      }
      // alway reset it
      postcodeFormControl.patchValue('');
    }

  }

  goToPartnerConnectGuidelines(event: any) {
    event.preventDefault();
    window.open('/microsoftcsp/tenants/partner-connect/guidelines', '_blank');
  }

}
