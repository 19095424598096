<main class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants">{{ 'word.tenants' | translate }}</li>
    <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants/{{tenantId}}">{{ tenantId }}</li>
    <li class="breadcrumb-item active">{{ 'word.users' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row" *ngIf="errorCheck">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            <button type="button" class="close" (click)="errorClosePressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="card">
                    <a routerLink="/microsoftcsp/tenants/{{tenantId}}/users/new" style="text-decoration: none;">
                      <div class="card-body text-center">
                        <i class="fa fa-plus"></i> {{ 'button.newuser' | translate }}
                      </div>
                    </a>
                  </div>
                </div>
                <!-- <div class="col-lg-4 col-md-4 col-12">
                  <div class="card">
                    <a routerLink="/microsoftcsp/tenants/new" [queryParams]="{type:'transit'}"
                      style="text-decoration: none;">
                      <div class="card-body text-center">
                        <i class="fa fa-arrows-h"></i> Transit Tenant
                      </div>
                    </a>
                  </div>
                </div> -->
              </div>

              <div class="row mb-2">
                <div class="col-12">

                  <div class="d-flex flex-lg-row flex-md-row flex-column justify-content-lg-between
                  justify-content-md-between justify-content-center my-3">
                    <select class="custom-select" style="width: auto" (change)="onPageSizeChanged($event.target.value)">
                      <option *ngFor="let size of pageSizeOptions" [value]="size" [selected]="size == pageSize">
                        {{size}} {{ 'word.itemsperpage' | translate }}
                      </option>
                    </select>
                    <button type="button" class="btn btn-primary mt-lg-0 mt-md-0 mt-2"
                     (click)="openSearchFilterModal(searchFilterModal)">
                     {{ 'button.filter' | translate }} <i class="fa fa-search"></i>
                    </button>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th column="displayName" sortable (sort)="onSort($event)">{{ 'word.name' | translate }}</th>
                          <th column="userPrincipalName" sortable (sort)="onSort($event)">{{ 'word.username' | translate }}</th>
                          <th column="assignedLicenses">{{ 'word.licenses' | translate }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <td colspan="5" style="text-align: center" [hidden]="!tableLoading">
                          {{ 'word.loading' | translate }}...
                        </td>
                        <td colspan="5" style="text-align: center"
                          *ngIf="!tableLoading && totalItems < 1">
                          {{ 'word.nodataavailable' | translate }}
                        </td>
                        <tr *ngFor="let user of users">
                          <td>{{user.displayName}}</td>
                          <td>{{user.userPrincipalName}}</td>
                          <td>{{user.assignedLicenses}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex flex-lg-row flex-md-row flex-column justify-content-lg-between
                   justify-content-md-between justify-content-center align-items-center
                   mt-lg-2 mt-md-2 my-3" *ngIf="!tableLoading && totalItems > 0">
                    <div class="row h-100 mb-lg-0 mb-md-0 mb-2">
                      <div class="col-12">
                        <span>
                          <!-- Showing {{ pagedItemsInfo.startIndex }}
                          - {{ pagedItemsInfo.endIndex }}
                          of {{ pagedItemsInfo.total }} total items -->
                          {{ pagedItemsInfo.total }} {{ 'word.totalitems' | translate }}
                        </span>
                      </div>
                    </div>

                    <!-- <ngb-pagination [collectionSize]="totalItems" [pageSize]="pageSize" [(page)]="page"
                      (pageChange)="onPageChanged($event)" [maxSize]="4" [rotate]="true">
                    </ngb-pagination> -->
                    <button type="button" class="btn btn-light" (click)="onMorePressed()">
                      <span>{{ 'button.more' | translate }}</span>
                      <i class="fa fa-spinner fa-spin" *ngIf="tableLoading"></i>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 text-right">
          <a routerLink="/microsoftcsp/tenants/{{tenantId}}" class="btn btn-dark btn-lg px-5 mb-4">{{ 'button.back' | translate }}</a>
        </div>
      </div>

    </div>
  </div>

  <ng-template #searchFilterModal>
    <div class="modal-header">
      <div class="modal-title">{{ 'button.filter' | translate }}</div>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="min-height: 150px;">
      <form [formGroup]="filterForm" class="form" style="width: 100%;">

        <div class="row align-items-center mb-2">
          <div class="col-4">
            <label class="mb-0">{{ 'word.name' | translate }}</label>
          </div>
          <div class="col-8">
            <input type="text" class="form-control w-100" formControlName="displayName" />
          </div>
        </div>

        <div class="row align-items-center mb-2">
          <div class="col-4">
            <label class="mb-0">{{ 'word.username' | translate }}</label>
          </div>
          <div class="col-8">
            <input type="text" class="form-control w-100" formControlName="userPrincipalName" />
          </div>
        </div>

      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-dark" (click)="clearSearch()">{{ 'button.clear' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="filterSearch()">{{ 'button.search' | translate }}</button>
    </div>
  </ng-template>

</main>
