import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { O365HttpService } from 'src/app/service/o365-http.service';
import { AlertService } from 'src/app/service/alert.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ScrollUtil } from 'src/app/util/scroll-util';
import { MicrosoftGraphService } from 'src/app/service/microsoftcsp/microsoft-graph.service';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';

@Component({
  selector: 'microsoftcsp-tenant-detail',
  templateUrl: './microsoftcsp-tenant-detail.component.html',
  styleUrls: ['./microsoftcsp-tenant-detail.component.css']
})
export class MicrosoftCSPTenantDetailComponent implements OnInit {

  tenantId: number;
  tenant: any;
  isTransit: boolean = false;

  enableSubscriptionManagement: boolean = false;
  enableUserManagement: boolean = false;
  enableDomainManagement: boolean = false;

  enableSendTenantCredentialsEmail: boolean = false;
  enableSendPartnerConnectUrlEmail: boolean = false;
  enableGeneratePartnerConnectUrl: boolean = false;

  sendTenantCredentialsEmailBtnPressed: boolean = false;
  sendPartnerConnectUrlEmailBtnPressed: boolean = false;
  generatePartnerConnectUrlBtnPressed: boolean = false;

  modalRef: BsModalRef;
  generatedPartnerConnectUrl: string = '';

  errorCheck: boolean = false;
  errors: any[] = [];
  successCheck: boolean = false;
  successMessage: string = '';

  tableLoading: boolean = false;

  lang: string = 'eng';
  receipTipLang = {
    en: "Send an email containing the tenant credentials to registered tenant account email. The tenant credentials will not reflect latest changes made in Microsoft 365 Admin Center.",
    cn: "将用户账号资讯电邮给已注册用户电邮。不包含在Microsoft 365 管理员里用户资讯的最新改变。",
    zn: "將用戶賬號資訊電郵給已註冊用戶電郵。不包含在Microsoft 365 管理員裡用戶資訊的最新改變。"
  }
  emailsuccessLang= {
    eng: "Email Sent Successfully",
    cn: "成功发送电邮",
    zh: "成功發送電郵",
  }
  failedmessageLang = {
    eng: "Something went wrong. Try again later.",
    cn: "系統错误，请稍后再尝试。",
    zh: "系統錯誤，請稍後再嘗試。"
  }

  constructor(
    private o365ApiService: O365HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private modalService: BsModalService,
    private microsoftGraphService: MicrosoftGraphService,
    private translateService: LanguageTranslateService,
  ) { }

  ngOnInit() {
    this.lang = this.translateService.userLanguage$.value;

    localStorage.removeItem('tenant');

    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;
      this.getTenantDetails();
    });
  }

  getTenantDetails() {
    this.tableLoading = true;
    this.microsoftGraphService.reset();

    this.o365ApiService.getTenantDetails(this.tenantId).subscribe((res: any) => {
      this.tableLoading = false;
      this.tenant = res.data.data;

      this.microsoftGraphService.wnTenantId = this.tenantId;
      this.microsoftGraphService.adminConsent = this.tenant.admin_consent;

      if (this.tenant.is_transition) {
        this.isTransit = true;
      }
      this.checkAllowedActions();
    }, err => {
      console.error(err);
      this.tableLoading = false;
      let message: string = 'Oops! Something went wrong. Please try again later.';
      if (err.error.message) {
        message = err.error.message;
      }
      this.alertService.showError(message);
      this.handleError(err);
    });
  }

  checkAllowedActions() {
    // reset
    this.enableSubscriptionManagement = false;
    this.enableUserManagement = false;
    this.enableDomainManagement = false;
    this.enableSendTenantCredentialsEmail = false;
    this.enableSendPartnerConnectUrlEmail = false;
    this.enableGeneratePartnerConnectUrl = false;

    // check subscription management can enabled?
    this.enableSubscriptionManagement = true;
    this.enableUserManagement = true;
    this.enableDomainManagement = true;

    // if tenant is transit type
    if (this.tenant.is_transition) {
      this.enableSendPartnerConnectUrlEmail = true;
      this.enableGeneratePartnerConnectUrl = true;
    } else {
      this.enableSendTenantCredentialsEmail = true;
    }
  }

  goToSubscriptionManagement() {
    this.router.navigate(['/microsoftcsp/tenants', this.tenantId, 'subscriptions']);
  }

  goToUserManagement() {
    if (this.tenant.admin_consent === true) {
      this.router.navigate(['/microsoftcsp/tenants', this.tenantId, 'users']);
    } else {
      this.router.navigate(['/microsoftcsp/tenants', this.tenantId, 'adminconsent']);
    }
  }

  goToDomainManagement() {
    if (this.tenant.admin_consent === true) {
      this.router.navigate(['/microsoftcsp/tenants', this.tenantId, 'domains']);
    } else {
      this.router.navigate(['/microsoftcsp/tenants', this.tenantId, 'adminconsent']);
    }
  }

  getMCUA() {
    window.open("https://aka.ms/customeragreement", '_blank');
  }

  sendTenantCredentialsEmail() {
    this.sendTenantCredentialsEmailBtnPressed = true;

    let sendEmailData: any = {
      email_type: 'TenantCredential'
    }
    this.o365ApiService.sendTenantEmail(this.tenantId, sendEmailData).pipe(finalize(() => {
      this.sendTenantCredentialsEmailBtnPressed = false;
    })).subscribe((res: any) => {
      


      if (res.data.message == 'true') {
        this.alertService.showSuccess(this.emailsuccessLang[this.lang]);
      } else {
        this.alertService.showError(this.failedmessageLang[this.lang]);
      }
    }, err => {
      console.error(err);
      let message: string = 'Failed to send email.';
      if (err.error.message) {
        message = err.error.message;
      }
      this.alertService.showError(message);
      this.handleError(err);
    });
  }

  sendPartnerConnectUrlEmail() {
    this.sendPartnerConnectUrlEmailBtnPressed = true;

    let sendEmailData: any = {
      email_type: 'PartnerConnectUrl'
    }
    this.o365ApiService.sendTenantEmail(this.tenantId, sendEmailData).pipe(finalize(() => {
      this.sendPartnerConnectUrlEmailBtnPressed = false;
    })).subscribe((res: any) => {
      if (res.data.message == 'true') {
        this.alertService.showSuccess(this.emailsuccessLang[this.lang]);
      } else {
        this.alertService.showError(this.failedmessageLang[this.lang]);
      }
    }, err => {
      console.error(err);
      let message: string = 'Failed to send email.';
      if (err.error.message) {
        message = err.error.message;
      }
      this.alertService.showError(message);
      this.handleError(err);
    });
  }

  generatePartnerConnectUrlAndOpenModal(template: TemplateRef<any>) {
    this.generatePartnerConnectUrlBtnPressed = true;

    this.o365ApiService.getPartnerConnectUrl(this.tenantId).pipe(finalize(() => {
      this.generatePartnerConnectUrlBtnPressed = false;
    })).subscribe((res: any) => {
      this.generatedPartnerConnectUrl = res.data.partnerConnectUrl;
      this.openModal(template);
    }, err => {
      console.error(err);
      let message: string = 'Failed to get partner connect url.';
      if (err.error.message) {
        message = err.error.message;
      }
      this.alertService.showError(message);
      this.handleError(err);
    });
  }

  openModal(template: TemplateRef<any>, modalSize?: string) {
    if (this.modalRef) {
      this.modalRef.hide();
    }

    let modalConfig: any = {
      backdrop: true,
      ignoreBackdropClick: true
    };

    if (modalSize && modalSize == 'lg') {
      modalConfig.class = 'modal-lg';
    }

    this.modalRef = this.modalService.show(template, modalConfig);
  }


  handleError(err: any) {
    if (err.error && err.error.error) {
      if (err.error.error.error && err.error.error.error.errors) {
        try {
          let errs: any[] = err.error.error.error.errors;
          errs.forEach((e: any) => {
            this.errors.push(e.detail);
          });
        } catch (tryErr) {
          this.errors.push(String(err.error.message));
        }
      } else {
        try {
          let errs: any[] = err.error.error;
          errs.forEach((e: any) => {
            this.errors.push(e.detail);
          });
        } catch (tryErr) {
          this.errors.push(String(err.error.message));
        }
      }
    } else if (err.error && err.error.message) {
      this.errors.push(err.error.message);
    } else {
      this.errors.push(err.message);
    }
    this.errorCheck = true;
    ScrollUtil.scrollToTop();
  }

  errorClosePressed() {
    this.errors.length = 0;
    this.errorCheck = false;
  }
}
