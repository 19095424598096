import { finalize } from 'rxjs/operators';
import { EmailDataService } from 'src/app/service/email-data.service';
import { EmailPlaceOrder } from './../../model/email/email-place-order.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmailApiService } from 'src/app/service/email-api.service';
import { EmailProduct } from 'src/app/model/email/email-product.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailProductPrice } from 'src/app/model/email/email-product-price.model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ScrollUtil } from 'src/app/util/scroll-util';
@Component({
  selector: 'app-email-step-one',
  templateUrl: './email-step-one.component.html',
  styleUrls: ['./email-step-one.component.css']
})
export class EmailStepOneComponent implements OnInit, OnDestroy {

  emailProductForm: UntypedFormGroup;

  emailProductList: EmailProduct[] = [];
  emailProductPriceList: EmailProductPrice[] = []; 
  selectedProduct: EmailProduct;
  selectedProductPrice: EmailProductPrice;

  productIdValueChangeSubscription: Subscription;
  domainValueChangeSubscription: Subscription;

  errorCheck:boolean = false;
  errors: any[] = [];

  nextBtnPressed: boolean = false;
  placeOrderData : EmailPlaceOrder;

  domainPatternReg = /^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/i ;

  loading: boolean = false;
  stopLoading = () =>{this.loading = false};

  constructor(
    private emailApiService: EmailApiService,
    private emailDataService:EmailDataService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) { }


  ngOnInit() {
    this.emailProductForm = this.formBuilder.group({
      productid: [0, [Validators.required,Validators.min(1)]],
      storagesize: [{value: '', disabled: true}],
      sharedsize: [{value: '', disabled: true}],
      personalsize: [{value: '', disabled: true}],
      licenses: [{value: '', disabled: true}],
      terms: [{value: '', disabled: true}],
      domain: [{value: '', disabled: true}],
      price: [{value: 'n/a', disabled: true}]
    });

    this.loading = true;
    this.emailApiService.getEmailProducts().pipe(finalize(this.stopLoading)).subscribe((res: any) => {
      this.emailProductList = res.products;
      if(this.emailProductList.length < 1 ){
        this.errors.push('Fetching product info failed');
        this.errorCheck = true;
        ScrollUtil.scrollToTop();
      }
    }, err => {
        this.handleError(err);
    });

    this.formControlOf('productid').valueChanges.subscribe(v => {
      this.loading = true;

      this.selectedProduct = this.emailProductList.find(e=>e.productid == v );

      this.getProductPlan(this.selectedProduct.productid); 
      this.updateStorageSizeFormControl();
      this.updateSharedSizeFormControl();
      this.updatePersonalSizeFormControl();
      this.updateLicenseFormControl();
      this.updateDomainFormControl();

    });
  }

  getProductPlan(productid:number){
    let termFormControl = this.formControlOf('terms');
    termFormControl.enable();
    termFormControl.setValidators([Validators.required]);
    termFormControl.patchValue(undefined);
    this.selectedProductPrice = undefined;
    
    let priceFormControl = this.formControlOf('price');
    priceFormControl.patchValue('n/a');
    
    this.emailApiService.getProductPlanPrice(productid).pipe(finalize(this.stopLoading)).subscribe((res:any)=>{
        this.emailProductPriceList = res.plans;
        if(this.emailProductPriceList.length < 1 ){
          this.errors.push('Fetching product pricing info failed');
          this.errorCheck = true;
          ScrollUtil.scrollToTop();
        }
    }, err => {
      // reset if error
      this.emailProductPriceList.length = 0;
      this.selectedProductPrice = undefined;

      this.handleError(err);
    });
  }

  onTermValueChanged(terms:number){
    this.selectedProductPrice = this.emailProductPriceList.find(p=>p.terms == terms );
    if(this.selectedProductPrice != undefined){
      this.updatePriceFormControl();
    }
  }

  onStorageSizeValueChanged(){
    if(this.selectedProductPrice != undefined){
      this.updatePriceFormControl();
    }
  }

  onSharedSizeValueChanged(){
    if(this.selectedProductPrice != undefined){
      this.updatePriceFormControl();
    }
  }

  onPersonalSizeValueChanged(){
    if(this.selectedProductPrice != undefined){
      this.updatePriceFormControl();
    }
  }

  onLicenseValueChanged(){
    if(this.selectedProductPrice != undefined){
      this.updatePriceFormControl();
    }
  }
  
  onDomainBlur(domain:string){
    let domainFormControl = this.formControlOf('domain');

    if(domainFormControl.hasError('required') || domainFormControl.hasError('pattern')){
      domainFormControl.markAsTouched();
      return;
    }
    
    domainFormControl.markAsPending();
    this.loading = true;
    this.emailApiService.checkEmailDomainAvailability(domain).pipe(finalize(this.stopLoading)).subscribe((res: any) => {
      let status: boolean = res.status;
      if (status) {
        domainFormControl.setErrors(null);
      } else {
        domainFormControl.setErrors({ notAvailable: true })
      }
    }, err => {
      this.handleError(err);
      domainFormControl.setErrors({ unableToCheck: true })
    }, () => {
      domainFormControl.markAsTouched();
      if(this.selectedProductPrice != undefined){
        this.updatePriceFormControl();
      }
    });
  }
  
  updateStorageSizeFormControl() {
    let storageSizeFormControl = this.formControlOf('storagesize');
    let minStorageSize = this.selectedProduct.minstoragesize;
    let maxStorageSize = this.selectedProduct.maxstoragesize;

    storageSizeFormControl.enable();
    storageSizeFormControl.setValidators([
      Validators.required,
      Validators.min(minStorageSize),
      Validators.max(maxStorageSize)
    ]);
    storageSizeFormControl.patchValue(minStorageSize);
  }

  updateSharedSizeFormControl() {
    let sharedSizeFormControl = this.formControlOf('sharedsize');
    let minSharedSize = this.selectedProduct.minsharedsize;
    let maxSharedSize = this.selectedProduct.maxsharedsize;

    sharedSizeFormControl.enable();
    sharedSizeFormControl.setValidators([
      Validators.required,
      Validators.min(minSharedSize),
      Validators.max(maxSharedSize)
    ]);
    sharedSizeFormControl.patchValue(minSharedSize);
  }

  updatePersonalSizeFormControl() {
    let personalSizeFormControl = this.formControlOf('personalsize');
    let minPersonalSize = this.selectedProduct.minpersonalsize;
    let maxPersonalSize = this.selectedProduct.maxpersonalsize;

    personalSizeFormControl.enable();
    personalSizeFormControl.setValidators([
      Validators.required,
      Validators.min(minPersonalSize),
      Validators.max(maxPersonalSize)
    ]);
    personalSizeFormControl.patchValue(minPersonalSize);
  }

  updateLicenseFormControl() {
    let licenseFormControl = this.formControlOf('licenses');
    let minLicenseQty = this.selectedProduct.minlicenses;
    let maxLicenseQty = this.selectedProduct.maxlicenses;

    licenseFormControl.enable();
    licenseFormControl.setValidators([
      Validators.required,
      Validators.min(minLicenseQty),
      Validators.max(maxLicenseQty)
    ]);
    licenseFormControl.patchValue(minLicenseQty);
  }

  updateDomainFormControl(){
    if(this.domainValueChangeSubscription){
      this.domainValueChangeSubscription.unsubscribe();
    }

    let domainFormControl = this.formControlOf('domain');
    domainFormControl.enable();
    domainFormControl.setValidators([Validators.required,Validators.pattern(this.domainPatternReg)]);
    domainFormControl.patchValue('');

    this.domainValueChangeSubscription = domainFormControl.valueChanges.subscribe(v => {
        if(v != '' && v != undefined){
          domainFormControl.markAsPending();
        }
    });
  }

  updatePriceFormControl(){
    let priceFormControl = this.formControlOf('price');

    if(this.selectedProductPrice == undefined || this.selectedProductPrice == null){
      priceFormControl.patchValue("n/a");
      return;
    }
    if(this.emailProductForm.invalid){
      priceFormControl.patchValue("n/a");
      return;
    }
    
    let price:number = this.selectedProductPrice.newprices;

   
    let isPromotion = this.selectedProductPrice.ispromotion;
    // if is promotion then use promo price
    if(isPromotion){
      price = this.selectedProductPrice.promoprices;
    }
    
    let numOfLicenses:number = this.formControlOf('licenses').value;

    // calcalate price and update it's value if all other inputs are valid
    let totalPrice = numOfLicenses * price;

    // set the price with 2 decimal
    priceFormControl.patchValue(totalPrice.toFixed(2));
  }

  nextPressed() {
    this.nextBtnPressed = true;

    if(this.emailProductForm.invalid){
      console.log('invalid form', this.emailProductForm.errors);
      this.nextBtnPressed = false;
      return;
    }

    if(this.formControlOf('domain').pending){
      // prevent next if domain not yet validated 
      this.nextBtnPressed = false;
      return;
    }

    this.placeOrderData = new EmailPlaceOrder();
    this.placeOrderData.productid = parseInt(this.emailProductForm.value.productid);
    this.placeOrderData.mailstorage = this.emailProductForm.value.storagesize;
    this.placeOrderData.sharedstorage = this.emailProductForm.value.sharedsize;
    this.placeOrderData.personalstorage = this.emailProductForm.value.personalsize;
    this.placeOrderData.licenses = this.emailProductForm.value.licenses;
    this.placeOrderData.terms = parseInt(this.emailProductForm.value.terms);
    this.placeOrderData.emailDomain = this.emailProductForm.value.domain;
    this.placeOrderData.totalprices = Number(this.formControlOf('price').value);

    // CR243, nid implicitly set is not offline payment
    this.placeOrderData.isOfflinePayment = false;

    console.log('placeOrderData:',this.placeOrderData);

    this.emailDataService.setEmailPlaceOrderData(this.placeOrderData);

    this.router.navigate(['/email/email-step-two']);
  }

  closePressed() {
    this.errorCheck = false;
    this.errors.length = 0;
  }
  
  formControlOf(name: string) {return this.emailProductForm.controls[name];}

  // quick access to form control for html
  get storageSizeFormControl() { return this.emailProductForm.get("storagesize");}
  get sharedSizeFormControl() { return this.emailProductForm.get("sharedsize");}
  get personalSizeFormControl() { return this.emailProductForm.get("personalsize");}
  get licensesFormControl() { return this.emailProductForm.get("licenses");}
  get domainFormControl() { return this.emailProductForm.get("domain");}
  

  handleError(err:any) {
    this.errors.length = 0;
    if (err.error instanceof ErrorEvent) {
      // client-side error
      let errorMessage = `Error: ${err.error.message}`;
      this.errors.push(errorMessage);
    } else {
      // server-side error
      let responseBodyErrors: any[] = err.error.errors;

      if (responseBodyErrors && responseBodyErrors.length>0) {
        this.errors = responseBodyErrors.map(e=> e.message);
      }else{
        switch (err.status) {
          case 401:
            if(err.error.error){
              this.errors.push(`${err.error.error}`);
            }else{
              this.errors.push(`${err.message}`);
            }
            break;
          case 0:
            this.errors.push('Connection error. Please try again later.');
            break;
          default:
            this.errors.push(`${err.message}`);
            break;
        }
      }
    }
    this.errorCheck = true;
    ScrollUtil.scrollToTop();
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  ngOnDestroy() {
    if(this.productIdValueChangeSubscription){
      this.productIdValueChangeSubscription.unsubscribe();
    }
    if(this.domainValueChangeSubscription){
      this.domainValueChangeSubscription.unsubscribe();
    }
  }
}
