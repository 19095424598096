import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../service/client.service';
import {ActivatedRoute} from '@angular/router'
import { TranslateUtil } from 'src/app/util/translate-util';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';
import { ScrollUtil } from 'src/app/util/scroll-util';

  declare var $: any;
  declare var jQuery: any;
  declare var appJS: any;
@Component({
	selector: 'app-client-show',
	templateUrl: './client-show.component.html',
	styleUrls: ['./client-show.component.css']
})
export class ClientShowComponent implements OnInit {

	customer ;
	message = "";
	customerId;

	// loading button
	payBtnPressed = false;
	sendVerifyEmailPressed = false;
	sendVerifyEmailSuccess = false;
	sendVerifyEmailFailed = false;
	sendVerifyEmailPressedSpin = false;

	constructor(
		private clientService:ClientService, 
		private route:ActivatedRoute
	) { }

	ngOnInit() {
		this.customerId = this.route.snapshot.paramMap.get('id');
		this.getCustomerbyId(this.customerId);
		// appJS.init();
	}

	getCustomerbyId(customerId){
		this.clientService.getCustomerById(customerId)
		.subscribe(resp=>{
			console.log(resp["data"])
			this.customer = resp["data"]
		},err=>{
			console.log(err)
		})

	}

	sendVerifyEmail(customer){
		this.sendVerifyEmailFailed = false;
		this.sendVerifyEmailPressedSpin = true;
		this.sendVerifyEmailPressed = true;

		let data = {
			customer_id:customer
		}

		this.clientService.sendVerificationEmail(data).subscribe(data=>{
			console.log(data)
			if (data["message"]){
				this.message = data["message"]
			}
			
			this.sendVerifyEmailSuccess = true;
		},err=>{
			this.sendVerifyEmailPressed = false;
			this.sendVerifyEmailFailed = true;
		});

		this.sendVerifyEmailPressedSpin = false;
	}

	submitPressed(){
		this.payBtnPressed=true;

		let data = {
			customer_id:this.customerId,
			access_level_id:this.customer.user.access_level_id,
			status_account:this.customer.user.status_account,
			status_email:this.customer.user.status_email
		}

		this.clientService.updateCustomerData(data).subscribe(resp=>{
			this.payBtnPressed = false;
			this.message ="Succesfully updated user's profile";

			ScrollUtil.scrollToTop();
		}, error => {
			this.payBtnPressed = false;
			ScrollUtil.scrollToTop();
		});
	}

	scrollToTop() {
		(function smoothscroll() {
			var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			if (currentScroll > 0) {
				window.requestAnimationFrame(smoothscroll);
				window.scrollTo(0, currentScroll - (currentScroll / 8));
			}
		})();
	}

	backPressed(){
		window.history.go(-1)
	}
}
