import { Component, OnInit } from '@angular/core';
import { StoreFrontService } from '../../service/store-front.service';

declare var $: any;
declare var jQuery: any;
declare var appJS: any;
@Component({
  selector: 'app-storefront-manage',
  templateUrl: './storefront-manage.component.html',
  styleUrls: ['./storefront-manage.component.css']
})
export class StorefrontManageComponent implements OnInit {

  storefronts:any;

  constructor(
    private sfService:StoreFrontService
  ) { }

  ngOnInit() {
    this.getAllStorefront();
    // appJS.init()
  }

  getAllStorefront() {
    this.sfService.getAllStoreFrontByReseller().subscribe(
      result => {
        this.storefronts = result['data']
        console.log('Storefront Lists : ',this.storefronts)
      }, error => {

      }
    )
  }

}
