<div class="animated fadeIn">
  <div class="row">
    <div class="col-12">
      <h3>{{ 'o365.domainsetupcomplete' | translate }}</h3>
      <p>{{domain.id}} {{ 'o365.domainsetupcomplete.title' | translate }}</p>
    </div>
    <div class="col-12">
      <div class="font-large font-weight-bold"><span>{{ 'word.nextsteps' | translate }}</span></div>
      <a href="#" _target="blank" (click)="goToUsers($event)">{{ 'button.gotousers' | translate }}</a>
    </div>
  </div>
</div>
