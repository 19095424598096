import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-o365-sidebar',
  templateUrl: './o365-sidebar.component.html',
  styleUrls: ['./o365-sidebar.component.css']
})
export class O365SidebarComponent implements OnInit {

  constructor() { 

  }

  ngOnInit() {
    var $body = document.getElementsByTagName("BODY")[0];

    $body.classList.remove("sidebar-minimized");
    $body.classList.remove("brand-minimized");
  }

  toggleDropdown() {
    var $dropdown = document.getElementById("product-nav-dropdown");
    $dropdown.classList.toggle('open');
  }

}
