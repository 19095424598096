
    <main id="storefront" class="main create-storefront">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
        <li class="breadcrumb-item">{{ 'sf.storefrontsettings' | translate }}</li>
        <li class="breadcrumb-item active" *ngIf="!edited">{{ 'sf.createstorefront' | translate }}</li>
        <li class="breadcrumb-item active" *ngIf="edited">{{ 'sf.editstorefront' | translate }}</li>
        <div class="progress">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
               aria-valuemin="0" aria-valuemax="100" style="width: 15%">
          </div>
        </div>
      </ol>

      <div class="container-fluid">
        <div class="animated fadeIn">
          <div class="row">

            <div class="col-lg-12">
              <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorCheck">
                <strong>{{ 'error.fieldsinvalid' | translate }}</strong>
                <ul>
                  <li *ngFor="let error of errors" style="text-transform: capitalize">{{ error }}</li>
                </ul>
                {{ 'word.error.fixerrors' | translate }}
                <button type="button" class="close" (click)="closePressed()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form class="form" #f="ngForm" [formGroup]="storeFrontDetails" style="overflow-y: visible">

                <h2>{{ 'sf.uploadlogo' | translate }}<span class="ml-1 mr-1 text-danger">*</span></h2>
                <div class="mt-4 mb-lg-4 mb-md-3 mx-auto btn-md-container">
                  <span class="card-img-link file-input btn btn-dark btn-width btn-lg btn-file mr-md-2">
                    {{ 'button.upload' | translate }}<input type="file" accept="image/*" ngModel
                           (change)="getFiles($event)">
                  </span>

                </div>

                <div class="mt-4">
                  <p id="selected-logo-label" *ngIf="!logourl">{{ 'label.selectedfile' | translate }}</p>
                  <img class="logo" [src]="logo" alt="" height="50px" *ngIf="logourl && !fileStringShow" id="imageid">
                  <img class="logo" [src]="fileStringShow" alt="" height="50px" *ngIf="fileStringShow">
                </div>

                <!-- <form class="form" [formGroup]="storeFrontDetails"> -->
                <div class="col-lg-12 mt-3">
                  <h2>{{ 'sf.storefrontdetails' | translate }}</h2>

                  <div class="card mt-4 pl-2 pr-2">
                    <div class="card-body">

                      <div class="group form-group mb-4" style="margin-top: 10px">
                        <!-- <input type="text" required> -->

                        <input id="input-storefront-code" data-bs-toggle="tooltip"
                               title="(Alphanumeric and dashes/underscores only)" type="text"
                               class="form-control form-control-lg" formControlName="storefront_code"
                               [(ngModel)]="storefrontCode"
                               (blur)="checkCode()" [attr.disabled]="edited ? '' : null" (keyup)="mouseEvent('enter')"
                               (blur)="mouseEvent('leave'); setSpacer('15', 'storefront_code')"
                               (click)="setSpacer('5', 'storefront_code'); checkOnInput('#input-storefront-code', '#storefront_code');"
                               required />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <h1 [hidden]="manageMode">
                          <div id="storefront_code" style="padding-left: 15px; padding-right: 4px;">{{ 'sf.storefrontcode' | translate }}<span class="ml-1 mr-1 text-danger">*</span></div>
                        </h1>
                        <i class="fa fa-cog fa-spin float-right" style="font-size:24px;color: lightseagreen" *ngIf="checkNameLoading"></i>
                        <p *ngIf="storefrontCheckReplica" style="color: red;">{{ 'error.sfcodeinuse' | translate }}</p>
                        <p *ngIf="showTipsMessage" style="color: dimgrey; position: absolute" [@fadeInOut]>({{ 'error.alphaanddashesonly' | translate }})</p>
                      </div>

                      <div class="group form-group mb-4">
                        <input id="input-storefront-name"
                               (click)="setSpacer('5', 'storefront_name'); checkOnInput('#input-storefront-name', '#storefront_name');"
                               (blur)="setSpacer('15', 'storefront_name')" type="text"
                               class="form-control form-control-lg" formControlName="storefront_name"
                               [(ngModel)]="storefrontName" required />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <h1></h1>
                        <h1>
                          <div id="storefront_name" style="padding-left: 15px; padding-right: 4px;">{{ 'sf.storefrontname' | translate }}<span class="ml-1 mr-1 text-danger">*</span></div>
                        </h1>
                      </div>

                      <div class="group form-group mb-4">
                        <input id="input-department"
                               (click)="setSpacer('5', 'department'); checkOnInput('#input-department', '#department');"
                               (blur)="setSpacer('15', 'department')" type="text" class="form-control form-control-lg"
                               formControlName="department" [(ngModel)]="department" required />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <h1>
                          <div id="department" style="padding-left: 15px; padding-right: 4px;">{{ 'sf.departmentincharge' | translate }}<span class="ml-1 mr-1 text-danger">*</span></div>
                        </h1>
                      </div>

                      <div class=" form-textbox group form-group mb-4">
                        <input id="input-address"
                               (click)="setSpacer('5', 'address'); checkOnInput('#input-address', '#address');"
                               (blur)="setSpacer('15', 'address')" type="text" class="form-control form-control-lg"
                               formControlName="address" [(ngModel)]="address" required />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <h1>
                          <div id="address" style="padding-left: 15px; padding-right: 4px;">{{ 'word.address' | translate }}<span class="ml-1 mr-1 text-danger">*</span></div>
                        </h1>
                      </div>

                      <div class="group form-group mb-4">
                        <select style="color: #7b8388; vertical-align: top"
                                class="form-control form-control-lg country-select" formControlName="selectedCountry"
                                (change)="onCountryChanged($event)">
                          <option value="0" disabled selected>{{ 'word.selectcountry' | translate }}<span class="ml-1 mr-1 text-danger">*</span></option>
                          <option *ngFor="let country of countries" value="{{country.id}}">
                            {{ country.name }}
                          </option>
                        </select>
                      </div>

                      <div class="select-box form-group mb-4">
                        <select style="color: #7b8388; vertical-align: top"
                                class="form-control form-control-lg state-select" formControlName="selectedState">
                          <option value="0" disabled selected>{{ 'word.selectstate' | translate }}<span class="ml-1 mr-1 text-danger">*</span></option>
                          <option *ngFor="let state of states" value="{{state.id}}"
                                  [selected]="state.id == selectedState">
                            {{ state.name }}
                          </option>
                        </select>
                      </div>

                      <div class="group form-group mb-4">
                        <input id="input-email"
                               (click)="setSpacer('5', 'email'); checkOnInput('#input-email', '#email');"
                               (blur)="setSpacer('15', 'email')" type="text" class="form-control form-control-lg"
                               formControlName="email" [(ngModel)]="email" required />
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <h1>
                          <div id="email" style="padding-left: 15px; padding-right: 4px;">{{ 'word.emailaddress' | translate }}<span class="ml-1 mr-1 text-danger">*</span></div>
                        </h1>
                      </div>

                      <div class="group form-group mb-4">
                        <ngx-intl-tel-input
                                            [cssClass]="'form-control form-control-lg'"
                                            [preferredCountries]="['my', 'sg']"
                                            [enablePlaceholder]="true"
                                            [enableAutoCountrySelect]="true"
                                            name="phone_number"
                                            formControlName="phone_number" [(ngModel)]="phoneNumber">
                        </ngx-intl-tel-input>
                        <!-- <input id="input-phone-number" (click)="setSpacer('5', 'phone_number'); checkOnInput('#input-phone-number', '#phone_number');" (blur) ="setSpacer('15', 'phone_number')" type="text" class="form-control form-control-lg" formControlName="phone_number" [(ngModel)]="phoneNumber" required/>
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <h1><div id="phone_number" style="padding-left: 15px; padding-right: 4px;">Phone *</div></h1>  -->
                      </div>

                      <div class="group form-group mb-0">
                        <input id="input-domain"
                               (click)="setSpacer('5', 'custom_domain'); checkOnInput('#input-domain', '#custom_domain');"
                               (blur)="setSpacer('15', 'custom_domain')" (blur)="checkDomainReplica()" type="text" class="form-control form-control-lg"
                               formControlName="custom_domain" [(ngModel)]="customDomain"/>
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <h1>
                          <div id="custom_domain" style="padding-left: 15px; padding-right: 4px;">{{ 'sf.customdomain' | translate }})</div>
                        </h1>
                        <!-- <small id="domainHelp" class="form-text text-muted text-red">Please make sure your domain have only <b>ONE A record pointing to 47.75.244.236 BEFORE</b> clicking "Next".</small> -->
                        <i class="fa fa-cog fa-spin float-right" style="font-size:24px;color: lightseagreen" *ngIf="checkDomainLoading"></i>
                        <p *ngIf="domainCheckReplica" style="color: red;">{{ 'error.domaininuse' | translate }}</p>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="col-lg-12 mt-3">
                  <h2>{{ 'sf.homepageheading' | translate }}</h2>

                  <div class="card mt-4 p-3">
                    <div class="card-body">
                      <div class="group form-group">
                        <select class="form-control form-control-lg mb-4" [(ngModel)]="headingLanguage"
                                [ngModelOptions]="{standalone: true}">
                          <option value="0" disabled selected>{{ 'word.selectlanguage' | translate }}<span class="ml-1 mr-1 text-danger">*</span></option>
                          <option *ngFor="let language of languages" [value]="language.code">
                            {{ language.name }}
                          </option>
                        </select>
                      </div>

                      <div *ngFor="let language of languages; let i = index">

                        <div class="group form-group mb-4" [hidden]="headingLanguage != language.code">
                          <input id="input-cover-title-{{ language.code }}"
                                 (click)="setSpacer('5', 'cover_title');"
                                 (blur)="setSpacer('15', 'cover_title')" type="text" (change)="coverTitleChange($event)"
                                 (keyup)="coverTitleChange($event)" (keydown)="coverTitleChange($event)"
                                 class="form-control form-control-lg" formControlName="cover_title_{{ language.safe_name }}"
                                 [(ngModel)]="coverTitle[language.code]"
                                 [value]="coverTitle[language.code] === undefined ? coverTitle['en'] : coverTitle[language.code]" required />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <h1>
                            <div id="cover_title" style="padding-left: 15px; padding-right: 4px;">{{ 'sf.headingtitle' | translate }} <span class="ml-1 mr-1 text-danger">*</span> ({{ language.name }})</div>
                          </h1>
                        </div>

                        <div class="group form-group mb-0" [hidden]="headingLanguage != language.code">
                          <input id="input-cover-text-{{ language.code }}"
                                 (click)="setSpacer('5', 'cover_text');"
                                 (blur)="setSpacer('15', 'cover_text')" type="textarea" (change)="coverTextChange($event)"
                                 (keyup)="coverTextChange($event)" (keydown)="coverTextChange($event)"
                                 class="form-control form-control-lg" formControlName="cover_text_{{ language.safe_name }}"
                                 [(ngModel)]="coverText[language.code]"
                                 [value]="coverText[language.code] === undefined ? coverText['en'] : coverText[language.code]" required />
                          <span class="highlight"></span>
                          <span class="bar"></span>
                          <h1>
                            <div id="cover_text" style="padding-left: 15px; padding-right: 4px;">{{ 'sf.writecreativeheading' | translate }} <span class="ml-1 mr-1 text-danger">*</span> ({{ language.name }})</div>
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  <button type="submit" (click)="checkButton()" [disabled]="!storeFrontDetails.valid">Check</button>-->
                <div class="col-lg-12">
                  <div class="float-right">
                    <label class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()">
                      {{ 'button.next' | translate }}
                      <i class="fa fa-cog fa-spin" style="font-size:24px;color: lightseagreen"
                         *ngIf="nextPageLoading"></i>
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
