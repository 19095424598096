<div class="animated fadeIn" *ngIf="showPremiumDNSAutoConnectFailedSection">

  <div class="row" *ngIf="errorCheck">
    <div class="col-12">
      <div class="alert alert-danger alert-dismissible fade show" role="alert">
        <strong>Oops!</strong> Something went wrong.
        <ul>
          <li *ngFor="let error of errors">
            {{ error }}
          </li>
        </ul>
        <button type="button" class="close" (click)="errorClosePressed()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h3>Auto connect domain unable to proceed !!</h3>
      <p class="mt-3">Found conflicting DNS records during pre-checking on your domain <strong>{{domain.id}}</strong> </p>
      
      <div *ngIf="conflictRecords && conflictRecords.length > 0">

        <div class="row mb-2">
          <div class="col-12">
            <div class="table-responsive">
              <table class="table table-bordered table-hover">
                <tbody>
                  <tr>
                    <th class="table-info">Subdom</th>
                    <th class="table-info">Record Type</th>
                    <th class="table-info">Value</th>
                  </tr>
                  <tr *ngFor="let record of conflictRecords">
                    <td>{{record.name == '' ? '@' : record.name }}</td>
                    <td>{{record.type}}</td>
                    <td>{{record.rdatas[0].value}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      <form [formGroup]="autoConnectDomainOptionsForm" style="overflow-y: visible">
        <div class="row">
          <div class="col-12">
            <div class="group form-group">

              <div class="col-12 mb-2">
                <input id="autoConnectDomainOptionManual" name="autoConnectDomainOption" type="radio" [value]="'manual_continue'" formControlName="autoConnectDomainOption">
                <label for="connectOptionManual">
                  <span class="ml-2">Connect domain manually</span>
                </label>
                <div>
                </div>
              </div>

              <div class="col-12 mb-2">
                <input id="autoConnectDomainOptionContinue" name="autoConnectDomainOption" type="radio" [value]="'auto_continue'" formControlName="autoConnectDomainOption">
                <label for="connectOptionSkip">
                  <span class="ml-2">Override existing DNS records and proceed auto connect</span>
                </label>
                <div>
                  <span style="margin-left: 20px" class="text-danger font-italic">
                    <strong>Warning!</strong> Your existing DNS records which conflicting with Microsoft services DNS records will be deleted.
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>

        <button type="button" class="btn btn-secondary m-3" (click)="onBackStep()">
          Back
        </button>

        <button type="button" class="btn btn-primary m-3" (click)="onAutoConnectDomainContinueStep()" [disabled]="domain === undefined || domain == null || dnsRecordLoading">
          <span>Continue</span>
          <i class="ml-1 fa fa-spinner fa-spin" *ngIf="dnsRecordLoading"></i>
        </button>

      </form>
    </div>
  </div>
</div>

<!-- Domain connection options section -->
<div class="animated fadeIn" *ngIf="showDomainConnectionOptionsSection">
  <div class="row">
    <div class="col-12">
      <h3>{{ 'o365.howtoconnectdomain' | translate }}</h3>
      <p>{{ 'o365.domainconnect.title1' | translate }} <strong>{{domain.id}}</strong></p>
      <form [formGroup]="domainConnectionOptionsForm" style="overflow-y: visible">
        <div class="row">
          <div class="col-12">
            <div class="group form-group">

              <div class="col-12 mb-2">
                <input id="connectOptionManual" name="connectOption" type="radio" [value]="'manual'" formControlName="connectOption">
                <label for="connectOptionManual">
                  <span class="ml-2">{{ 'o365.addowndnsrecords' | translate }}</span>
                </label>
                <div>
                  <span style="margin-left: 20px">
                    {{ 'o365.complexdnsrecordstruc' | translate }}
                  </span>
                </div>
              </div>

              <!-- TODO remove after integrating to automate default ns -->
              <div class="col-12 mb-2" [hidden]="'true'">
                <input id="connectOptionAuto" name="connectOption" type="radio" [value]="'auto'" formControlName="connectOption">
                <label for="connectOptionAuto">
                  <span class="ml-2">{{ 'o365.setuponlineservice' | translate }}</span>
                </label>
                <div>
                  <span style="margin-left: 20px">
                    {{ 'o365.updatedomainserver.content1' | translate }} ‎{{ 'word.microsoftoffice365' | translate }}‎ {{ 'o365.updatedomainserver.content2' | translate }}
                  </span>
                </div>
              </div>

              <div class="col-12 mb-2">
                <input id="connectOptionSkip" name="connectOption" type="radio" [value]="'skip'" formControlName="connectOption">
                <label for="connectOptionSkip">
                  <span class="ml-2">{{ 'o365.skipanddolater' | translate }} ({{ 'word.notrecommended' | translate }})</span>
                </label>
                <div>
                  <span style="margin-left: 20px">
                    {{ 'o365.servicewontconnect.content1' | translate }} <a href="#">{{ 'word.domains' | translate }}</a> {{ 'o365.servicewontconnect.content2' | translate }}
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>

        <button type="button" class="btn btn-secondary m-3" (click)="onBackStep()">
          {{ 'button.back' | translate }}
        </button>

        <button type="button" class="btn btn-primary m-3" (click)="onContinueStep()" [disabled]="domain === undefined || domain == null || dnsRecordLoading">
          <span>{{ 'button.continue' | translate }}</span>
          <i class="ml-1 fa fa-spinner fa-spin" *ngIf="dnsRecordLoading"></i>
        </button>

      </form>
    </div>
  </div>
</div>

<!-- Manual add DNS records section -->
<div class="animated fadeIn" *ngIf="showAddDNSRecordsSectionManual">
  <div class="row">
    <div class="col-12">
      <h3>{{ 'o365.adddnsrecords' | translate }}</h3>
      <p><strong>{{ 'o365.adddnsrecords.content1' | translate }} {{domain.id}}, {{ 'o365.adddnsrecords.content2' | translate }}</strong></p>
      <p>{{ 'o365.adddnsrecords.content3' | translate }} ‎{{ 'word.microsoftoffice365' | translate }}‎, {{ 'word.select' | translate }} <strong>‎{{ 'o365.exchange' | translate }}‎ {{ 'word.and' | translate }} ‎{{ 'o365.exchangeonlineprotection' | translate }}‎</strong> . {{ 'o365.adddnsrecords.content4' | translate }}, {{ 'word.select' | translate }} <strong>{{ 'button.continue' | translate }}</strong></p>
      <p>{{ 'o365.adddnsrecords.content5' | translate }}</p>
    </div>

    <div class="col-12">
      <form [formGroup]="domainConnectionManualForm" style="overflow-y: visible">

        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" name="exchange" id="manualEmail" formControlName="manualEmail">
          <label class="custom-control-label" for="manualEmail">
            <span class="ml-2">{{ 'o365.exchange' | translate }} {{ 'word.and' | translate }} {{ 'o365.exchangeonlineprotection' | translate }}</span>
          </label>
        </div>

        <div *ngIf="manualEmailFormControl.value === true">
          <div class="col-12">
            <p>{{ 'o365.manualemail.content1' | translate }} ‎{{ 'o365.exchange' | translate }}‎. {{ 'o365.manualemail.content2' | translate }} ‎{{ 'word.outlook' | translate }}‎ {{ 'o365.manualemail.content3' | translate }} ‎{{ 'o365.exchange' | translate }}‎ {{ 'o365.manualemail.content4' | translate }}</p>
          </div>

          <div class="col-12">
            <p>
              {{ 'o365.dontadddnsrecords' | translate }}:
            </p>
            <ul>
              <li>{{ 'o365.customdnsrouting' | translate }}</li>
              <li>{{ 'o365.youalreadyusing' | translate }} ‎{{ 'o365.exchange' | translate }}‎ {{ 'o365.onpremisesaswell' | translate }} ‎{{ 'o365.exchangeonline' | translate }}‎ ({{ 'o365.hybriddeployment' | translate }})</li>
            </ul>
          </div>

          <div class="col-12">
            <p>{{ 'o365.ifappliesneedclear' | translate }} <strong>‎‎{{ 'o365.exchange' | translate }}‎ {{ 'word.and' | translate }} ‎{{ 'o365.exchangeonlineprotection' | translate }}</strong>‎‎ {{ 'o365.setupowncustomdnsrecords' | translate }} ‎{{ 'word.microsoftoffice365' | translate }}‎ {{ 'word.later' | translate }}.</p>
          </div>

          <div class="col-12">
            <p>{{ 'o365.beforeaddingdnsrecords' | translate }} {{domain.id}}‎ {{ 'o365.emailaddressin' | translate }} ‎{{ 'word.microsoftoffice365' | translate }}‎ {{ 'o365.wontsendreceivedemail' | translate }}</p>
          </div>

          <div class="col-12">
            <span>{{ 'o365.mxrecords' | translate }}</span>
            <div class="table-responsive py-2">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ 'word.record' | translate }}</th>
                    <th>{{ 'word.hostname' | translate }}</th>
                    <th>{{ 'o365.pointstoaddressorvalue' | translate }}</th>
                    <th>{{ 'word.priority' | translate }}</th>
                    <th>{{ 'word.ttl' | translate }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let dnsRecord of getServiceDnsRecordsRecordType('Email', 'Mx')">
                    <td class="align-baseline">{{ 'word.expected' | translate }}</td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(domain.isRoot === true ? '@' : dnsRecord.label)">
                        <i class="icon-docs"></i>
                      </button>
                      <span class="ml-1">{{domain.isRoot === true ? '@' : dnsRecord.label}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.mailExchange)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.mailExchange}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.preference)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.preference}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.ttl)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.ttl}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-12">
            <span>{{ 'o365.cnamerecords' | translate }}</span>
            <div class="table-responsive py-2">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ 'word.record' | translate }}</th>
                    <th>{{ 'word.hostname' | translate }}</th>
                    <th>{{ 'o365.pointstoaddressorvalue' | translate }}</th>
                    <th>{{ 'word.ttl' | translate }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let dnsRecord of getServiceDnsRecordsRecordType('Email', 'CName')">
                    <td class="align-baseline">{{ 'word.expected' | translate }}</td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.label, '.'+domain.id, '')">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.label | replace : '.'+domain.id : ''}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.canonicalName)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.canonicalName}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.ttl)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.ttl}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-12">
            <span>{{ 'o365.txtrecords' | translate }}</span>
            <div class="table-responsive py-2">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ 'word.record' | translate }}</th>
                    <th>{{ 'o365.txtname' | translate }}</th>
                    <th>{{ 'o365.txtvalue' | translate }}</th>
                    <th>{{ 'word.ttl' | translate }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let dnsRecord of getServiceDnsRecordsRecordType('Email', 'Txt')">
                    <td class="align-baseline">{{ 'word.expected' | translate }}</td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(domain.isRoot === true ? '@' : dnsRecord.label)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{domain.isRoot === true ? '@' : dnsRecord.label}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.text)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.text}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.ttl)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.ttl}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <hr/>
        <div class="mb-2"><span>{{ 'o365.advancedoptions' | translate }}</span></div>
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" name="skypeForBusiness" id="manualOfficeCommunicationsOnline" formControlName="manualOfficeCommunicationsOnline">
          <label class="custom-control-label" for="manualOfficeCommunicationsOnline">
            <span class="ml-2">{{ 'o365.skypeforbusiness' | translate }}</span>
          </label>
        </div>

        <div *ngIf="manualOfficeCommunicationsOnlineFormControl.value === true">
          <div class="col-12">
            <p>{{ 'o365.skypeforbusiness.content1' | translate }} ‎{{ 'o365.skypeforbusiness' | translate }}‎ {{ 'o365.skypeforbusiness.content2' | translate }} ‎{{ 'word.msteams' | translate }}‎. ‎{{ 'word.skype' | translate }}‎ {{ 'o365.skypeforbusiness.content3' | translate }}</p>
          </div>

          <div class="col-12">
            <span>{{ 'o365.cnamerecords' | translate }}</span>
            <div class="table-responsive py-2">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ 'word.record' | translate }}</th>
                    <th>{{ 'word.hostname' | translate }}</th>
                    <th>{{ 'o365.pointstoaddressorvalue' | translate }}</th>
                    <th>{{ 'word.ttl' | translate }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let dnsRecord of getServiceDnsRecordsRecordType('OfficeCommunicationsOnline', 'CName')">
                    <td class="align-baseline">{{ 'word.expected' | translate }}</td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.label, '.'+domain.id, '')">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.label | replace : '.'+domain.id : ''}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.canonicalName)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.canonicalName}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.ttl)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.ttl}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-12">
            <span>{{ 'o365.srvrecords' | translate }}</span>
            <div class="table-responsive py-2">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ 'word.record' | translate }}</th>
                    <th>{{ 'word.service' | translate }}</th>
                    <th>{{ 'word.protocol' | translate }}</th>
                    <th>{{ 'word.port' | translate }}</th>
                    <th>{{ 'word.weight' | translate }}</th>
                    <th>{{ 'word.priority' | translate }}</th>
                    <th>{{ 'word.ttl' | translate }}</th>
                    <th>{{ 'word.name' | translate }}</th>
                    <th>{{ 'dns.target' | translate }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let dnsRecord of getServiceDnsRecordsRecordType('OfficeCommunicationsOnline', 'Srv')">
                    <td class="align-baseline">{{ 'word.expected' | translate }}</td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.service)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.service}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.protocol)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.protocol}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.port)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.port}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.weight)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.weight}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.priority)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.priority}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.ttl)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.ttl}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(domain.isRoot === true ? '@' : dnsRecord.label)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{domain.isRoot === true ? '@' : dnsRecord.label}}</span>
                    </td>
                    <td class="align-baseline">
                      <button class="btn btn-outline-primary border-0" type="button" placement="auto" triggers="click:blur hover focus" ngbTooltip="Copy record" (click)="copyToClipboard(dnsRecord.nameTarget)">
                        <i class="icon-docs"></i>
                      </button>
                      <span>{{dnsRecord.nameTarget}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </form>
    </div>

    <div class="col-12">
      <button type="button" class="btn btn-secondary m-3" (click)="onAddDNSRecordsBackStep()">
        {{ 'button.back' | translate }}
      </button>

      <button type="button" class="btn btn-primary m-3" (click)="onManualCompleteStep()" [disabled]="dnsRecordLoading || !dnsRecords || dnsRecords.length <= 0">
        <span>{{ 'button.continue' | translate }}</span>
        <i class="ml-1 fa fa-spinner fa-spin" *ngIf="dnsRecordLoading"></i>
      </button>
    </div>
  </div>
</div>

<!-- Auto add DNS records section -->
<div class="animated fadeIn" *ngIf="showAddDNSRecordsSectionAuto">
  <div class="row">
    <div class="col-12">
      <h3>{{ 'o365.chooseonlineservice' | translate }}</h3>
      <p>{{ 'o365.chooseonlineservice.content1' | translate }} <strong>{{domain.id}}</strong> , {{ 'o365.chooseonlineservice.content2' | translate }} ‎{{ 'word.microsoftoffice365' | translate }}‎ {{ 'o365.chooseonlineservice.content3' | translate }}</p>
    </div>

    <form [formGroup]="domainConnectionAutoForm" style="overflow-y: visible">

      <div class="col-12">
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" name="exchange" id="autoEmail" formControlName="autoEmail">
          <label class="custom-control-label" for="autoEmail">
            <span class="ml-2">{{ 'o365.exchange' | translate }} {{ 'word.and' | translate }} {{ 'o365.exchangeonlineprotection' | translate }}</span>
          </label>
        </div>

        <div *ngIf="autoEmailFormControl.value === true">
          <div class="col-12">
            <p>{{ 'o365.manualemail.content1' | translate }} ‎{{ 'o365.exchange' | translate }}‎. {{ 'o365.manualemail.content2' | translate }} ‎Outlook‎ {{ 'o365.manualemail.content3' | translate }} ‎{{ 'o365.exchange' | translate }}‎ {{ 'o365.manualemail.content4' | translate }}</p>
          </div>

          <div class="col-12">
            <p>{{ 'o365.dontadddnsrecords' | translate }}:</p>
            <ul>
              <li>{{ 'o365.customdnsrouting' | translate }}</li>
              <li>{{ 'o365.youalreadyusing' | translate }} ‎{{ 'o365.exchange' | translate }}‎ {{ 'o365.onpremisesaswell' | translate }} ‎{{ 'o365.exchangeonline' | translate }}‎ ({{ 'o365.hybriddeployment' | translate }})</li>
            </ul>
          </div>

          <div class="col-12">
            <p>{{ 'o365.ifappliesneedclear' | translate }} <strong>‎‎{{ 'o365.exchange' | translate }}‎ {{ 'word.and' | translate }} ‎{{ 'o365.exchangeonlineprotection' | translate }}</strong>‎‎ {{ 'o365.setupowncustomdnsrecords' | translate }} ‎{{ 'word.microsoftoffice365' | translate }}‎ {{ 'word.later' | translate }}.</p>
          </div>

          <div class="col-12">
            <p>{{ 'o365.beforeaddingdnsrecords' | translate }} ‎{{domain.id}} {{ 'o365.emailaddressin' | translate }} ‎{{ 'word.microsoftoffice365' | translate }}‎ {{ 'o365.wontsendreceivedemail' | translate }}</p>
          </div>

          <div class="col-12">
            <div class="table-responsive py-2">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ 'word.type' | translate }}</th>
                    <th>{{ 'word.hostname' | translate }}</th>
                    <th>{{ 'o365.pointstoaddressorvalue' | translate }}</th>
                    <th>{{ 'word.ttl' | translate }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let dnsRecord of getServiceDnsRecords('Email')">
                    <td class="align-baseline">{{dnsRecord.recordType | uppercase}}</td>
                    <td class="align-baseline" *ngIf="dnsRecord.recordType === 'Mx' || dnsRecord.recordType === 'Txt'">{{domain.isRoot === true ? '@' : dnsRecord.label}}</td>
                    <td class="align-baseline" *ngIf="dnsRecord.recordType ==='CName'">{{dnsRecord.label | replace : '.'+domain.id : ''}}</td>

                    <td class="align-baseline" *ngIf="dnsRecord.recordType === 'Mx'">{{dnsRecord.preference}} {{dnsRecord.mailExchange}}</td>
                    <td class="align-baseline" *ngIf="dnsRecord.recordType === 'Txt'">{{dnsRecord.text}}</td>
                    <td class="align-baseline" *ngIf="dnsRecord.recordType === 'CName'">{{dnsRecord.canonicalName}}</td>
                    <td class="align-baseline">{{dnsRecord.ttl}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <hr/>
        <div class="mb-2"><span>{{ 'o365.advancedoptions' | translate }}</span></div>
        <div class="custom-control custom-checkbox">
          <input class="custom-control-input" type="checkbox" name="skypeForBusiness" id="autoOfficeCommunicationsOnline" formControlName="autoOfficeCommunicationsOnline">
          <label class="custom-control-label" for="autoOfficeCommunicationsOnline">
            <span class="ml-2">{{ 'o365.skypeforbusiness' | translate }}</span>
          </label>
        </div>

        <div *ngIf="autoOfficeCommunicationsOnlineFormControl.value === true">
          <div class="col-12">
            <p>{{ 'o365.skypeforbusiness.content1' | translate }} ‎{{ 'o365.skypeforbusiness' | translate }}‎ {{ 'o365.skypeforbusiness.content2' | translate }} ‎{{ 'word.msteams' | translate }}‎. ‎{{ 'word.skype' | translate }}‎ {{ 'o365.skypeforbusiness.content3' | translate }}</p>
          </div>

          <div class="col-12">
            <div class="table-responsive py-2">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ 'word.type' | translate }}</th>
                    <th>{{ 'word.hostname' | translate }}</th>
                    <th>{{ 'o365.pointstoaddressorvalue' | translate }}</th>
                    <th>{{ 'word.ttl' | translate }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let dnsRecord of getServiceDnsRecords('OfficeCommunicationsOnline')">
                    <td class="align-baseline">{{dnsRecord.recordType | uppercase}}</td>

                    <td class="align-baseline" *ngIf="dnsRecord.recordType ==='CName'">{{dnsRecord.label | replace : '.'+domain.id : ''}}</td>
                    <td class="align-baseline" *ngIf="dnsRecord.recordType ==='Srv'">{{dnsRecord.service}}.{{dnsRecord.protocol}}</td>

                    <td class="align-baseline" *ngIf="dnsRecord.recordType === 'CName'">{{dnsRecord.canonicalName}}</td>
                    <td class="align-baseline" *ngIf="dnsRecord.recordType ==='Srv'">{{dnsRecord.priority}} {{dnsRecord.weight}} {{dnsRecord.port}} {{dnsRecord.nameTarget}}</td>

                    <td class="align-baseline">{{dnsRecord.ttl}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

    </form>

    <div class="col-12">
      <button type="button" class="btn btn-secondary m-3" (click)="onAddDNSRecordsBackStep()">
        {{ 'button.back' | translate }}
      </button>

      <button type="button" class="btn btn-primary m-3" (click)="onAutoCompleteStep()" [disabled]="dnsRecordLoading || !dnsRecords || dnsRecords.length <= 0">
        <span>{{ 'button.continue' | translate }}</span>
        <i class="ml-1 fa fa-spinner fa-spin" *ngIf="dnsRecordLoading"></i>
      </button>
    </div>
  </div>
</div>
