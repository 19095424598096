import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../component/header/header.component';
import { O365MasterTemplateComponent } from '../component/o365-master-template/o365-master-template.component';
import { O365SidebarComponent } from '../component/o365-sidebar/o365-sidebar.component';
import { SortableTableHeader } from '../directive/sortable.directive';
import { SearchableTableHeader } from '../directive/searchable.directive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MinNumberValidatorDirective } from '../directive/validator/min-num-validator.directive';
import { MaxNumberValidatorDirective } from '../directive/validator/max-num-validator.directive';
import { NumberDecimaLimitDirective } from '../directive/num-decimal-limit.directive';
import { StepTemplateComponent } from '../component/step-template/step-template.component';
import { StepsComponent } from '../component/steps/steps.component';
import { DigitOnlyDirective } from '../directive/digit-only.directive';
import { TranslatePipe } from '../pipe/translate.pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        // import ngx-bootstrap modal module, do remember to import in
        // sub-module too without the .forRoot()
        ModalModule.forRoot(),
     ],
    declarations: [
        HeaderComponent,

        // declared here so that MircosoftCSPModule can use
        O365MasterTemplateComponent,
        O365SidebarComponent,
        StepTemplateComponent,
        StepsComponent,

        // declare custom directives
        SortableTableHeader,
        SearchableTableHeader,

        MinNumberValidatorDirective,
        MaxNumberValidatorDirective,
        NumberDecimaLimitDirective,
        DigitOnlyDirective,
        TranslatePipe
    ],
    exports: [
        HeaderComponent,

        O365MasterTemplateComponent,
        O365SidebarComponent,
        StepTemplateComponent,
        StepsComponent,

        // export custom directives
        SortableTableHeader,
        SearchableTableHeader,

        MinNumberValidatorDirective,
        MaxNumberValidatorDirective,
        NumberDecimaLimitDirective,
        DigitOnlyDirective,
        TranslatePipe
    ]
})
export class SharedModule {
    // this module used to import and export the modules or components for reuse and sharing purpose among multiple modules
    // eg.  Module A and Module B need the WhatSoEverComponent, both Module A and B just require to
    //      import this SharedModule then they can access the WhatSoEverComponent
}
