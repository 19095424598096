<main id="microsoftcsp-tenant-create" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item navigable" routerLink="/microsoftcsp/tenants">{{ 'word.tenants' | translate }}</li>
    <li class="breadcrumb-item active">{{breadcrumbTitle}}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row" *ngIf="errorCheck">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            <button type="button" class="close" (click)="errorClosePressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="successCheck">
        <div class="col-12">
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            {{successMessage}}
            <button type="button" class="close" (click)="successClosePressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="currentTab == 'main'">

        <div class="col-12 mb-3">
          <h3>{{ 'o365.tenantinfo' | translate }}</h3>
        </div>

        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <div class="row">
                <div class="col-12">
                  <div class="card card-body partner-connect-guidelines" *ngIf="tenantType == 'transit'">
                    <h5>{{ 'o365.partnerconnectguide' | translate }}</h5>
                    <p>
                      {{ 'o365.stepone.content1' | translate }}
                      <a href="#" _target="blank" (click)="goToPartnerConnectGuidelines($event)">{{ 'o365.partnerconnectguide' | translate }}</a>
                      .
                    </p>
                  </div>
                </div>
              </div>

              <form class="form" [formGroup]="tenantForm" style="overflow-y: visible">

                <h4 class="pl-0 mb-4" style="font-size: larger;">
                  {{ 'o365.generalinfo' | translate }}
                </h4>

                <div class="row mt-2">

                  <div class="col-lg-6 col-md-6 col-12" *ngIf=" tenantType == 'create' ">
                    <div class="group form-group">
                      <label>
                        <div id="default_domain_prefix">
                          {{ 'o365.defaultdomainprefix' | translate }}<span class="text-danger">&nbsp;*&nbsp;</span>
                          <i *ngIf="defaultDomainPrefixLoading" class="fa fa-spinner fa-spin"></i>
                        </div>
                      </label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">admin@</span>
                        </div>
                        <input id="input-default-domain-prefix" type="text" class="form-control"
                          formControlName="default_domain_prefix" (keyup)="onDefaultDomainPrefixKeyUpEvent($event)" />
                        <div class="input-group-append">
                          <span class="input-group-text">.onmicrosoft.com</span>
                        </div>
                      </div>
                      <div *ngIf="defaultDomainPrefixFormControl.touched && !defaultDomainPrefixLoading">
                        <p class="text-danger" *ngIf="defaultDomainPrefixFormControl.hasError('validateDomainPrefixError')">
                          {{defaultDomainPrefixErrorMessage}}
                        </p>
                        <p class="text-danger" *ngIf="defaultDomainPrefixFormControl.hasError('required')">
                          {{ 'error.form.ddprequired' | translate }}
                        </p>
                        <p class="text-danger" *ngIf="defaultDomainPrefixFormControl.hasError('pattern') || defaultDomainPrefixFormControl.hasError('invalidDomain')">
                          {{ 'error.form.ddpinvalid' | translate }}
                        </p>
                        <p class="text-success" *ngIf="!defaultDomainPrefixFormControl.errors && defaultDomainPrefixValidateSuccess">
                          {{ 'success.domainprefixavailable' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        {{ 'o365.microsofttenantqualification' | translate }} <span class="text-danger">&nbsp;*&nbsp;</span>
                        <i *ngIf="marketCategoryLoading" class="fa fa-spinner fa-spin"></i>
                      </label>
                      <select formControlName="microsoft_market_category_id" id="microsoftMarketCategoryId" class="form-control"
                      (change)="onMicrosoftQualificationChanged($event.target.value)">
                      <option *ngFor="let category of marketCategories" [value]="category.id">{{category.description}}</option>
                      </select>
                      <div class="text-warning" *ngIf="showAcademicQualificationInfoMessage">
                        <i class="fa fa-exclamation-triangle"></i> {{ 'error.academic.createtenant' | translate }}
                        {{ 'error.directcustomer' | translate }} <a href="https://docs.microsoft.com/en-us/microsoft-365/education/deploy/create-your-office-365-tenant" style="text-decoration: none;" target="_blank">{{ 'error.registermicrosoft' | translate }}</a>
                         {{ 'error.usetenantprocess' | translate }}
                      </div>
                      <div class="text-warning" *ngIf="showNonProfitQualificationInfoMessage">
                        <i class="fa fa-exclamation-triangle"></i> {{ 'error.nonprofit.createtenant' | translate }}
                        {{ 'error.directcustomer' | translate }} <a href="https://aka.ms/nonprofitgetstarted" style="text-decoration: none;" target="_blank">{{ 'error.registermicrosoft' | translate }}</a>
                         {{ 'error.usetenantprocess' | translate }}
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        <div id="company_name">{{ 'word.company' | translate }}</div>
                      </label>
                      <input id="input_company_name" type="text" class="form-control" formControlName="company_name" />
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        {{ 'o365.companyregistrationnumber' | translate }}
                        <span class="text-danger" *ngIf="tenantFormStates && tenantFormStates.companyRegistrationNumberRequired == true">&nbsp;*</span>
                      </label>
                      <input id="company_registration_number" type="text" class="form-control" formControlName="company_registration_number"/>
                      <div *ngIf="companyRegistrationNumberFormControl.touched">
                        <p *ngIf="companyRegistrationNumberFormControl.hasError('required')" class="text-danger">
                          {{ 'error.form.crnrequired' | translate }}
                        </p>
                        <p *ngIf="companyRegistrationNumberFormControl.hasError('pattern')" class="text-danger">
                          {{ 'error.form.crninvalid' | translate }}
                         </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        <div id="o365_username">{{ 'word.username' | translate }}
                          <span class="text-danger">&nbsp;*&nbsp;</span>
                          <i class="fa fa-info-circle text-warning" placement="auto" triggers="click:blur hover focus"
                          ngbTooltip={{this.tipLang[this.lang]}}></i>
                        </div>
                      </label>
                      <input id="input_username" type="text" title="(Alphanumeric and dashes/underscores only)"
                      class="form-control" formControlName="username" />
                      <div *ngIf="usernameFormControl.touched">
                        <p class="text-danger" *ngIf="usernameFormControl.hasError('required')">{{ 'error.usernamerequired' | translate }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        <div id="o365_password">{{ 'word.password' | translate }}<span class="text-danger">&nbsp;*</span></div>
                      </label>
                      <div class="input-group">
                        <input id="input_password" [attr.type]="hidePassword?'password':'text'" class="form-control"
                          formControlName="password" style="padding-right: 1.8rem;"/>
                        <div style="position: relative;">
                          <span class="pwd-show-hide" (click)="hidePassword = !hidePassword">
                            <i *ngIf="hidePassword;else showPassword" class="fa fa-eye"></i>
                            <ng-template #showPassword><i class="fa fa-eye-slash"></i></ng-template>
                          </span>
                        </div>
                        <div class="input-group-append">
                          <button class="btn btn-primary" type="button" (click)="generateRandomPassword()">{{ 'button.generatepassword' | translate }}</button>
                        </div>
                      </div>
                      <div *ngIf="passwordFormControl.touched">
                        <p class="text-danger" *ngIf="passwordFormControl.hasError('required')">{{ 'error.passwordrequired' | translate }}</p>
                        <p class="text-danger" *ngIf="passwordFormControl.hasError('minlength')">{{ 'error.password.minlength' | translate }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        <div id="contact_email" >{{ 'o365.contactemail' | translate }}<span class="text-danger">&nbsp;*</span></div>
                      </label>
                      <input id="input_contact_email" type="email" class="form-control"
                       formControlName="contact_email_address" />
                       <div *ngIf="contactEmailFormControl.touched">
                        <p class="text-danger" *ngIf="contactEmailFormControl.hasError('required')">{{ 'error.emailrequired' | translate }}</p>
                        <p class="text-danger" *ngIf="contactEmailFormControl.hasError('email')">{{ 'error.emailinvalid' | translate }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        <div id="alt_contact_email" >{{ 'o365.alternateemail' | translate }}</div>
                      </label>
                      <input id="input_alt_contact_email" type="email" class="form-control"
                       formControlName="alt_email_address" />
                       <div *ngIf="contactAltEmailFormControl.touched">
                        <p class="text-danger" *ngIf="contactAltEmailFormControl.hasError('email')">{{ 'error.emailinvalid' | translate }}</p>
                      </div>
                    </div>
                  </div>


                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        <div id="contact_first_name">{{ 'o365.contactfirstname' | translate }}<span class="text-danger">&nbsp;*</span></div>
                      </label>
                      <input id="input_contact_first_name" type="text" formControlName="contact_first_name"
                      class="form-control" />
                      <div *ngIf="contactFirstNameFormControl.touched">
                        <p class="text-danger" *ngIf="contactFirstNameFormControl.hasError('required')">{{ 'error.firstname.required' | translate }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        <div id="contact_last_name">{{ 'o365.contactlastname' | translate }}<span class="text-danger">&nbsp;*</span></div>
                      </label>
                      <input id="input_contact_last_name" type="text" formControlName="contact_last_name"
                       class="form-control"/>
                       <div *ngIf="contactLastNameFormControl.touched">
                        <p class="text-danger" *ngIf="contactLastNameFormControl.hasError('required')">{{ 'error.lastname.required' | translate }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        <div id="contact_phone_no">{{ 'o365.contactphone' | translate }}<span class="text-danger">&nbsp;*</span></div>
                      </label>
                      <ngx-intl-tel-input id="custom" name="contact_phone_no" formControlName="contact_phone_no"
                      [cssClass]="'form-control'"
                      [preferredCountries]="['my', 'sg']"
                      [enablePlaceholder]="true"
                      [separateDialCode]="true"
                      [enableAutoCountrySelect]="true">
                      </ngx-intl-tel-input>
                      <div *ngIf="contactPhoneNoFormControl.touched">
                        <p class="text-danger" *ngIf="contactPhoneNoFormControl.hasError('validatePhoneNumber')">{{ 'error.phoneinvalid' | translate }}</p>
                      </div>
                    </div>
                  </div>

                </div>

                <hr/>

                <h4 class="pl-0 my-4" style="font-size: larger;">
                  {{ 'o365.contactaddressinfo' | translate }}
                </h4>

                <ng-template #singleContactCountry>
                  <div class="row mt-3">
                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group">
                        <label>{{ 'o365.contactcountry' | translate }}<span class="text-danger">&nbsp;*&nbsp;</span><i class="fa fa-spinner fa-spin" *ngIf="contactCountryLoading"></i></label>
                        <select formControlName="contact_address_country_id" id="contact_address_country_id" class="form-control"
                          (change)="onCountryChanged($event.target.value, 'contact')">
                          <option [value]="0" disabled>{{ 'word.selectcountry' | translate }}...</option>
                          <option *ngFor="let country of countriesContact" [value]="country.id">{{ country.name }}</option>
                        </select>
                        <div *ngIf="contactCountryIdFormControl.touched">
                          <p *ngIf="contactCountryIdFormControl.hasError('required') || contactCountryIdFormControl.hasError('min')"
                            class="text-danger">
                            {{ 'error.countryrequired' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <div class="row mt-3" *ngIf="showContactAddressFormInputs;else singleContactCountry">

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>{{ 'o365.contactaddressline1' | translate }}<span class="text-danger">&nbsp;*</span></label>
                      <input id="contact_address_street1" type="text" class="form-control"
                       formControlName="contact_address_street1" />
                       <div *ngIf="contactStreet1FormControl.touched">
                        <p *ngIf="contactStreet1FormControl.hasError('required')"
                          class="text-danger">
                          {{ 'error.addressrequired' | translate }}
                        </p>
                        <p *ngIf="contactStreet1FormControl.hasError('maxlength')" class="text-danger">
                          {{ 'error.addressline1.invalid' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>{{ 'o365.contactaddressline2' | translate }}</label>
                      <input id="contact_address_street2" type="text" class="form-control"
                      formControlName="contact_address_street2" />
                      <div *ngIf="contactStreet2FormControl.touched">
                        <p *ngIf="contactStreet2FormControl.hasError('maxlength')" class="text-danger">
                          {{ 'error.addressline2.invalid' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>{{ 'o365.contactaddressline3' | translate }}</label>
                      <input id="contact_address_street3" type="text" class="form-control"
                      formControlName="contact_address_street3" />
                      <div *ngIf="contactStreet3FormControl.touched">
                        <p *ngIf="contactStreet3FormControl.hasError('maxlength')" class="text-danger">
                          {{ 'error.addressline3.invalid' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>
                        {{ 'o365.contactpostcode' | translate }}
                        <span class="text-danger" *ngIf="tenantFormStates && tenantFormStates.contactPostcodeRequired == true">&nbsp;*</span>
                      </label>
                      <input id="contact_address_postcode" type="text" class="form-control" formControlName="contact_address_postcode"/>
                      <div *ngIf="contactPostcodeFormControl.touched">
                        <p *ngIf="contactPostcodeFormControl.hasError('required')" class="text-danger">
                          {{ 'error.postcoderequired' | translate }}
                        </p>
                        <p *ngIf="contactPostcodeFormControl.hasError('pattern')" class="text-danger">
                          {{ 'error.postcodeinvalid' | translate }}
                         </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>{{ 'o365.contactpostcode' | translate }}<span class="text-danger">&nbsp;*&nbsp;</span><i class="fa fa-spinner fa-spin" *ngIf="contactCountryLoading"></i></label>
                      <select formControlName="contact_address_country_id" id="contact_address_country_id" class="form-control"
                      (change)="onCountryChanged($event.target.value, 'contact')">
                      <option [value]="0" disabled>{{ 'word.selectcountry' | translate }}...</option>
                      <option *ngFor="let country of countriesContact" [value]="country.id">{{ country.name }}</option>
                    </select>
                    <div *ngIf="contactCountryIdFormControl.touched">
                      <p *ngIf="contactCountryIdFormControl.hasError('required') || contactCountryIdFormControl.hasError('min')"
                        class="text-danger">
                        {{ 'error.countryrequired' | translate }}
                      </p>
                    </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>{{ 'o365.contactstate' | translate }}<span class="text-danger">&nbsp;*&nbsp;</span><i class="fa fa-spinner fa-spin" *ngIf="contactStateLoading"></i></label>
                      <select formControlName="contact_address_state_id" id="contact_address_state_id" class="form-control"
                        (change)="onStateChanged($event.target.value, 'contact')">
                        <option [value]="0" disabled>{{ 'word.selectstate' | translate }}...</option>
                        <option *ngFor="let state of statesContact" [value]="state.id">{{ state.name }}</option>
                      </select>
                      <div *ngIf="contactStateIdFormControl.touched">
                        <p *ngIf="contactStateIdFormControl.hasError('required') || contactStateIdFormControl.hasError('min')"
                          class="text-danger">
                          {{ 'error.staterequired' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="group form-group">
                      <label>{{ 'o365.contactcity' | translate }}<span class="text-danger">&nbsp;*&nbsp;</span><i class="fa fa-spinner fa-spin" *ngIf="contactCityLoading"></i></label>
                      <select formControlName="contact_address_city_id" id="contact_address_city_id" class="form-control"
                        (change)="onCityChanged($event.target.value, 'contact')">
                        <option [value]="0" disabled>{{ 'word.selectcity' | translate }}...</option>
                        <option *ngFor="let city of citiesContact" [value]="city.id">{{ city.name }}</option>
                        <option [value]="50000">Others</option>
                      </select>
                      <div *ngIf="contactCityIdFormControl.touched">
                        <p *ngIf="contactCityIdFormControl.hasError('required') || contactCityIdFormControl.hasError('min')"
                          class="text-danger">
                          {{ 'error.cityrequired' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-12" *ngIf="contactOtherCitySelected">
                    <div class="group form-group">
                      <label>
                        {{ 'o365.othercity' | translate }}
                        <span class="text-danger" *ngIf="tenantFormStates && tenantFormStates.contactOtherCityRequired == true">&nbsp;*</span>
                      </label>
                      <input placeholder="City Name" id="contact_address_city_other" type="text" class="form-control"
                      formControlName="contact_address_city_other" />
                      <div *ngIf="contactOtherCityFormControl.touched">
                        <p *ngIf="contactOtherCityFormControl.hasError('required')"
                          class="text-danger">
                          {{ 'error.othercityrequired' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <div class="group form-group">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="useContactAsBilling" name="useContactAsBilling"
                        [checked]="useContactAddressAsBillingAddress" (change)="onUseContactAsBillingChanged($event)"
                        style="margin-right: 0.5rem;">
                        <label class="form-check-label" for="useContactAsBilling">{{ 'o365.form.checkbox.title1' | translate }}</label>
                      </div>
                    </div>
                  </div>
                </div>


                <div *ngIf="!useContactAddressAsBillingAddress">
                  <hr>

                  <h4 class="pl-0 my-4" style="font-size: larger;">
                    {{ 'o365.billingaddressinfo' | translate }}
                  </h4>

                  <ng-template #singleBillingCountry>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="group form-group">
                          <label>{{ 'o365.billingcountry' | translate }}<span class="text-danger">&nbsp;*</span><i class="fa fa-spinner fa-spin" *ngIf="billingCountryLoading"></i></label>
                          <select formControlName="billing_address_country_id" id="billing_address_country_id" class="form-control"
                          (change)="onCountryChanged($event.target.value, 'billing')">
                            <option [value]="0" disabled>{{ 'word.selectcountry' | translate }}...</option>
                            <option *ngFor="let country of countriesBilling" [value]="country.id">{{ country.name }}</option>
                          </select>
                          <div *ngIf="billingCountryIdFormControl.touched">
                            <p *ngIf="billingCountryIdFormControl.hasError('required') || billingCountryIdFormControl.hasError('min')"
                              class="text-danger">
                              {{ 'error.billingcountryrequired' | translate }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <div class="row" *ngIf="showBillingAddressFormInputs;else singleBillingCountry">

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group">
                        <label>{{ 'o365.billingaddressline1' | translate }}<span class="text-danger">&nbsp;*</span></label>
                        <input id="billing_address_street1" type="text" class="form-control" formControlName="billing_address_street1" />
                        <div *ngIf="billingStreet1FormControl.touched">
                          <p *ngIf="billingStreet1FormControl.hasError('required')"
                            class="text-danger">
                            {{ 'error.billingaddressrequired' | translate }}
                          </p>
                          <p *ngIf="billingStreet1FormControl.hasError('maxlength')" class="text-danger">
                            {{ 'error.billaddressline1.invalid' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group">
                        <label>{{ 'o365.billingaddressline2' | translate }}</label>
                        <input id="billing_address_street2" type="text" class="form-control" formControlName="billing_address_street2" />
                        <div *ngIf="billingStreet2FormControl.touched">
                          <p *ngIf="billingStreet2FormControl.hasError('maxlength')" class="text-danger">
                            {{ 'error.billaddressline2.invalid' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group">
                        <label>{{ 'o365.billingaddressline3' | translate }}</label>
                        <input id="billing_address_street3" type="text" class="form-control" formControlName="billing_address_street3" />
                        <div *ngIf="billingStreet3FormControl.touched">
                          <p *ngIf="billingStreet3FormControl.hasError('maxlength')" class="text-danger">
                            {{ 'error.billaddressline3.invalid' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group">
                        <label>
                          {{ 'o365.billingpostcode' | translate }}
                          <span class="text-danger" *ngIf="tenantFormStates && tenantFormStates.billingPostcodeRequired == true">&nbsp;*</span>
                        </label>
                        <input id="billing_address_postcode" type="text" class="form-control" formControlName="billing_address_postcode" />
                        <div *ngIf="billingPostcodeFormControl.touched">
                          <p *ngIf="billingPostcodeFormControl.hasError('required')" class="text-danger">
                            {{ 'error.billingpostcoderequired' | translate }}
                          </p>
                          <p *ngIf="billingPostcodeFormControl.hasError('pattern')" class="text-danger">
                            {{ 'error.billingpostcodeinvalid' | translate }}
                           </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group">
                        <label>{{ 'o365.billingcountry' | translate }}<span class="text-danger">&nbsp;*</span><i class="fa fa-spinner fa-spin" *ngIf="billingCountryLoading"></i></label>
                        <select formControlName="billing_address_country_id" id="billing_address_country_id"
                          class="form-control" (change)="onCountryChanged($event.target.value, 'billing')">
                          <option [value]="0" disabled>{{ 'word.selectcountry' | translate }}...</option>
                          <option *ngFor="let country of countriesBilling" [value]="country.id">{{ country.name }}</option>
                        </select>
                        <div *ngIf="billingCountryIdFormControl.touched">
                          <p *ngIf="billingCountryIdFormControl.hasError('required') || billingCountryIdFormControl.hasError('min')"
                            class="text-danger">
                            {{ 'error.billingcountryrequired' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group">
                        <label>{{ 'o365.billingstate' | translate }}<span class="text-danger">&nbsp;*</span><i class="fa fa-spinner fa-spin" *ngIf="billingStateLoading"></i></label>
                        <select formControlName="billing_address_state_id" id="billing_address_state_id"
                          class="form-control" (change)="onStateChanged($event.target.value, 'billing')">
                          <option [value]="0" disabled>{{ 'word.selectstate' | translate }}...</option>
                          <option *ngFor="let state of statesBilling" [value]="state.id">{{ state.name }}</option>
                        </select>
                        <div *ngIf="billingStateIdFormControl.touched">
                          <p *ngIf="billingStateIdFormControl.hasError('required') || billingStateIdFormControl.hasError('min')"
                            class="text-danger">
                            {{ 'error.billingstaterequired' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group">
                        <label>{{ 'o365.billingcity' | translate }}<span class="text-danger">&nbsp;*</span><i class="fa fa-spinner fa-spin" *ngIf="billingCityLoading"></i></label>
                        <select formControlName="billing_address_city_id" id="billing_address_city_id"
                          class="form-control" (change)="onCityChanged($event.target.value, 'billing')">
                          <option [value]="0" disabled>{{ 'word.selectcity' | translate }}...</option>
                          <option *ngFor="let city of citiesBilling" [value]="city.id">{{ city.name }}</option>
                          <option [value]="50000">Other City</option>
                        </select>
                        <div *ngIf="billingCityIdFormControl.touched">
                          <p *ngIf="billingCityIdFormControl.hasError('required') || billingCityIdFormControl.hasError('min')"
                            class="text-danger">
                            {{ 'error.billingcityrequired' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12"  *ngIf="billingOtherCitySelected">
                      <div class="group form-group">
                        <label>
                          {{ 'o365.othercity' | translate }}
                          <span class="text-danger" *ngIf="tenantFormStates && tenantFormStates.billingOtherCityRequired == true">&nbsp;*</span>
                        </label>
                        <input placeholder="City Name" id="billing_address_city_other" type="text" class="form-control" formControlName="billing_address_city_other" />
                        <div *ngIf="billingOtherCityFormControl.touched">
                          <p *ngIf="billingOtherCityFormControl.hasError('required')"
                            class="text-danger">
                            {{ 'error.billingothercityrequired' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

                <hr>

                <div class="row mt-4">
                  <div class="col-12">
                    <div class="group form-group">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="isMarketingPromo"
                        formControlName="is_marketing_promo"
                        style="margin-right: 0.5rem;">
                        <label class="form-check-label" for="isMarketingPromo">{{ 'o365.form.checkbox.title2' | translate }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="group form-group">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="includePartner"
                        formControlName="include_partner"
                        style="margin-right: 0.5rem;">
                        <label class="form-check-label" for="includePartner">{{ 'o365.form.checkbox.title3' | translate }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="group form-group">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="mcuaAgreement"
                        formControlName="mcua_agreement"
                        style="margin-right: 0.5rem;">
                        <label class="form-check-label" for="mcuaAgreement">
                          <span class="text-danger ml-1 mr-1">*</span>
                          {{ 'o365.form.checkbox.title4' | translate }} <a href="https://aka.ms/customeragreement" target="_blank">{{ 'o365.form.checkbox.title5' | translate }}</a>.
                        </label>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3 justify-content-end">
                  <div class="col-lg-3 col-md-3 col-12">
                    <button type="button" class="btn btn-dark btn-block" (click)="submit()"
                    [disabled]="tenantForm.invalid || createTenantSubmitBtnPressed || tenantForm.pending">
                    {{ 'button.create' | translate }} <i class="fa fa-spinner fa-spin" *ngIf="createTenantSubmitBtnPressed"></i>
                    </button>
                  </div>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="currentTab == 'partnerConnect'">
        <div class="col-12 mb-3">
          <h3>{{ 'o365.partnerconnect' | translate }}</h3>
        </div>
        <div class="col-12">
          <div class="card" style="min-height: 350px;">
            <div class="card-body">

              <div class="row">
                <div class="col-12">
                  <div class="card card-body border-0 m-0 px-0">
                    <h5>{{ 'o365.tenantassociation' | translate }}</h5>
                    <span>{{ 'o365.instructlogin' | translate }} <strong>{{ 'o365.partnerconnect' | translate }}</strong> {{ 'o365.usingadmincredential' | translate }}</span>
                    <span>{{ 'o365.promptedconfirm' | translate }}</span>
                    <span>{{ 'o365.afterconfirm' | translate }}</span>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-12">
                  <div class="row justify-content-start align-items-center">
                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                      <button class="btn btn-primary btn-block" (click)="openPartnerConnectUrl()" type="button">{{ 'button.gopartnerconnect' | translate }}</button>
                    </div>
                    <div class="col-lg-3 col-md-4 col-12 mb-2">
                      <button class="btn btn-primary btn-block" (click)="goToTenantDetails()" type="button">{{ 'button.gotenantdetails' | translate }}</button>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-xl-12 text-right">
          <a routerLink="/microsoftcsp/tenants" class="btn btn-dark btn-lg px-5 mb-4">{{ 'button.back' | translate }}</a>
        </div>
      </div>

    </div>
  </div>
</main>
