<main id="storefront" class="main create-storefront">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'o365.orderMO365' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.stepone' | translate }}</li>

    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
        aria-valuemin="0" aria-valuemax="100" style="width: 15%">
      </div>
    </div>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">

        <div class="col-lg-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorCheck">
            <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            {{ 'word.error.fixerrors' | translate }}
            <button type="button" class="close" (click)="closePressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <form class="form" [formGroup]="tenantForm" style="overflow-y: visible">
            <div class="col-lg-12 mt-3">
              <h2>{{ 'o365.tenantinfo' | translate }}</h2>

              <div class="card mt-4 pl-2 pr-2">

                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <div class="card card-body partner-connect-guidelines" *ngIf="transfer_tenant">
                        <h5>{{ 'o365.partnerconnectguide' | translate }}</h5>
                        <p>
                          {{ 'o365.stepone.content1' | translate }}
                          <a href="#" _target="blank" (click)="goToPartnerConnectGuidelines($event)">{{ 'o365.partnerconnectguide' | translate }}</a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="group form-group mb-4" style="height: 40px; padding-top: 10px;">
                        <div class="checkbox-container">
                          <input id="transfer_tenant" type="checkbox" name="transfer_tenant"
                            (click)="toggleTransferTenant()">
                          <label for="transfer_tenant" class="bg-checkbox-dark"></label>
                          <h6>
                            {{ 'o365.transfertenant' | translate }}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="row mt-4">

                    <div class="col-lg-6 col-md-6 col-12" *ngIf="!transfer_tenant">
                      <div class="group form-group mb-4">
                        <h4>
                          <div id="default_domain_prefix">
                            {{ 'o365.defaultdomainprefix' | translate }} <span class="text-danger">&nbsp;*&nbsp;</span> <i *ngIf="defaultDomainPrefixLoading"
                              class="fa fa-spinner fa-spin"></i>
                          </div>
                        </h4>
                        <div class="input-group input-group-lg">
                          <div class="input-group-prepend">
                            <span class="input-group-text">admin@</span>
                          </div>
                          <input id="input-default-domain-prefix" type="text" class="form-control form-control-lg"
                            formControlName="default_domain_prefix" (keyup)="onDefaultDomainPrefixKeyUpEvent($event)" />
                          <div class="input-group-append">
                            <span class="input-group-text">.onmicrosoft.com</span>
                          </div>
                        </div>
                        <div *ngIf="defaultDomainPrefixFormControl.touched && !defaultDomainPrefixLoading">
                          <p class="text-danger"
                            *ngIf="defaultDomainPrefixFormControl.hasError('validateDomainPrefixError')">
                            {{defaultDomainPrefixErrorMessage}}
                          </p>
                          <p class="text-danger" *ngIf="defaultDomainPrefixFormControl.hasError('required')">
                            {{ 'error.form.ddprequired' | translate }}
                          </p>
                          <p class="text-danger" *ngIf="defaultDomainPrefixFormControl.hasError('pattern') || defaultDomainPrefixFormControl.hasError('invalidDomain')">
                            {{ 'error.form.ddpinvalid' | translate }}
                          </p>
                          <p class="text-success"
                            *ngIf="!defaultDomainPrefixFormControl.errors && defaultDomainPrefixValidateSuccess">
                            {{ 'success.domainprefixavailable' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          {{ 'o365.microsofttenantqualification' | translate }} <span class="text-danger">&nbsp;*&nbsp;</span>
                          <i *ngIf="marketCategoryLoading" class="fa fa-spinner fa-spin"></i>
                        </h4>
                        <select formControlName="microsoft_market_category_id" id="microsoftMarketCategoryId" class="form-control form-control-lg"
                        (change)="onMicrosoftQualificationChanged($event.target.value)">
                        <option *ngFor="let category of marketCategories" [value]="category.id">{{category.description}}</option>
                        </select>
                        <div class="text-warning" *ngIf="showAcademicQualificationInfoMessage">
                          <i class="fa fa-exclamation-triangle"></i> Create New Tenant process is not allowed for this qualification category. Verification is required for the creation of academic qualified tenant.
                           Please direct your customer to <a href="https://docs.microsoft.com/en-us/microsoft-365/education/deploy/create-your-office-365-tenant" style="text-decoration: none;" target="_blank">register with Microsoft</a>
                            and use the Transit Tenant process after the tenant has been created.
                        </div>
                        <div class="text-warning" *ngIf="showNonProfitQualificationInfoMessage">
                          <i class="fa fa-exclamation-triangle"></i> Create New Tenant process is not allowed for this qualification category. Verification is required for the creation of not-for-profit qualified tenant.
                           Please direct your customer to <a href="https://aka.ms/nonprofitgetstarted" style="text-decoration: none;" target="_blank">register with Microsoft</a>
                            and use the Transit Tenant process after the tenant has been created.
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          <div id="o365_username">{{ 'word.username' | translate }}
                            <span class="text-danger">&nbsp;*&nbsp;</span>
                            <i class="fa fa-info-circle text-warning" placement="auto" triggers="click:blur hover focus"
                            ngbTooltip="{{ 'o365.stepone.content2' | translate }}"></i>
                          </div>
                        </h4>
                        <input id="input-username" data-bs-toggle="tooltip"
                          title="(Alphanumeric and dashes/underscores only)" type="text"
                          class="form-control form-control-lg" formControlName="username" />
                        <div *ngIf="usernameFormControl.touched">
                          <p class="text-danger" *ngIf="usernameFormControl.hasError('required')">{{ 'error.usernamerequired' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          <div id="o365_password">{{ 'word.password' | translate }} <span class="text-danger">&nbsp;*&nbsp;</span></div>
                        </h4>
                        <div class="input-group">
                          <input id="input-password" [attr.type]="hidePassword?'password':'text'" class="form-control form-control-lg"
                            formControlName="password" style="padding-right: 1.8rem;"/>
                          <div style="position: relative;">
                            <span class="pwd-show-hide" (click)="hidePassword = !hidePassword">
                              <i *ngIf="hidePassword;else showPassword" class="fa fa-eye"></i>
                              <ng-template #showPassword><i class="fa fa-eye-slash"></i></ng-template>
                            </span>
                          </div>
                        </div>
                        <div *ngIf="passwordFormControl.touched">
                          <p class="text-danger" *ngIf="passwordFormControl.hasError('required')">{{ 'error.passwordrequired' | translate }}</p>
                          <p class="text-danger" *ngIf="passwordFormControl.hasError('minlength')">{{ 'error.password.minlength' | translate }}</p>
                          <p class="text-danger" *ngIf="passwordFormControl.hasError('maxlength')">{{ 'error.password.maxlength' | translate }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          <div id="company_name">{{ 'word.companyname' | translate }}</div>
                        </h4>
                        <input id="input-company_name" type="text" class="form-control form-control-lg"
                          formControlName="company_name" />
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          {{ 'o365.companyregistrationnumber' | translate }}
                          <span class="text-danger"
                            *ngIf="tenantFormStates && tenantFormStates.companyRegistrationNumberRequired == true">&nbsp;*</span>
                        </h4>
                        <input id="company_registration_number" type="text" class="form-control form-control-lg"
                          formControlName="company_registration_number" />
                        <div *ngIf="companyRegistrationNumberFormControl.touched">
                          <p *ngIf="companyRegistrationNumberFormControl.hasError('required')" class="text-danger">
                            {{ 'error.form.crnrequired' | translate }}
                          </p>
                          <p *ngIf="companyRegistrationNumberFormControl.hasError('pattern')" class="text-danger">
                            {{ 'error.form.crninvalid' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="form-textbox group form-group mb-4">
                        <h4>
                          <div id="address">{{ 'o365.contactemail' | translate }} <span class="text-danger">&nbsp;*&nbsp;</span></div>
                        </h4>
                        <input id="input-contact-email-address" type="email" class="form-control form-control-lg"
                          formControlName="contact_email_address" />
                        <div *ngIf="contactEmailFormControl.touched">
                          <p class="text-danger" *ngIf="contactEmailFormControl.hasError('required')">{{ 'error.emailrequired' | translate }}</p>
                          <p class="text-danger" *ngIf="contactEmailFormControl.hasError('email')">{{ 'error.emailinvalid' | translate }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class=" form-textbox group form-group mb-4">
                        <h4>
                          <div id="address">{{ 'o365.alternateemail' | translate }} </div>
                        </h4>
                        <input id="input-alt-email-address" type="email" class="form-control form-control-lg"
                          formControlName="alt_email_address" />
                          <div *ngIf="contactAltEmailFormControl.touched">
                            <p class="text-danger" *ngIf="contactAltEmailFormControl.hasError('email')">{{ 'error.emailinvalid' | translate }}</p>
                          </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          <div id="contact_first_name">{{ 'o365.contactfirstname' | translate }} <span class="text-danger">&nbsp;*&nbsp;</span>
                          </div>
                        </h4>
                        <input id="input-contact-first-name" type="text" class="form-control form-control-lg"
                          formControlName="contact_first_name" />
                        <div *ngIf="contactFirstNameFormControl.touched">
                          <p class="text-danger" *ngIf="contactFirstNameFormControl.hasError('required')">{{ 'error.firstname.required' | translate }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          <div id="contact_last_name">{{ 'o365.contactlastname' | translate }} <span class="text-danger">&nbsp;*&nbsp;</span>
                          </div>
                        </h4>
                        <input id="input-contact-last-name" type="text" class="form-control form-control-lg"
                          formControlName="contact_last_name" />
                        <div *ngIf="contactLastNameFormControl.touched">
                          <p class="text-danger" *ngIf="contactLastNameFormControl.hasError('required')">{{ 'error.lastname.required' | translate }}</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          <div id="contact_phone_no">{{ 'o365.contactphone' | translate }} <span class="text-danger">&nbsp;*&nbsp;</span>
                          </div>
                        </h4>
                        <ngx-intl-tel-input id="custom" name="contact_phone_no" formControlName="contact_phone_no"
                          [cssClass]="'form-control form-control-lg'" [preferredCountries]="['my', 'sg']"
                          [enablePlaceholder]="true" [separateDialCode]="true" [enableAutoCountrySelect]="true">
                        </ngx-intl-tel-input>
                        <div *ngIf="contactPhoneNoFormControl.touched">
                          <p class="text-danger" *ngIf="contactPhoneNoFormControl.hasError('validatePhoneNumber')">
                           {{ 'error.phoneinvalid' | translate }}</p>
                        </div>
                      </div>
                    </div>

                  </div>

                  <hr>

                  <h6 style="padding-left: 0; font-weight: light; margin-bottom: 1.2em;">
                    {{ 'o365.contactaddressinfo' | translate }}
                  </h6>

                  <ng-template #singleContactCountry>
                    <div class="row mt-3">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="group form-group mb-4">
                          <h4>{{ 'o365.contactcountry' | translate }}<span class="text-danger">&nbsp;*&nbsp;</span><i
                              class="fa fa-spinner fa-spin" *ngIf="contactCountryLoading"></i></h4>
                          <select formControlName="contact_address_country_id" id="contact_address_country_id"
                            class="form-control form-control-lg"
                            (change)="onCountryChanged($event.target.value, 'contact')">
                            <option [value]="0" disabled>Select a Country...</option>
                            <option *ngFor="let country of countriesContact" [value]="country.id">{{ country.name }}
                            </option>
                          </select>
                          <div *ngIf="contactCountryIdFormControl.touched">
                            <p *ngIf="contactCountryIdFormControl.hasError('required') || contactCountryIdFormControl.hasError('min')"
                              class="text-danger">
                              {{ 'error.countryrequired' | translate }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <div class="row" *ngIf="showContactAddressFormInputs;else singleContactCountry">

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>{{ 'o365.contactaddressline1' | translate }}<span class="text-danger">&nbsp;*</span></h4>
                        <input id="contact_address_street1" type="text" class="form-control form-control-lg"
                          formControlName="contact_address_street1" />
                        <div *ngIf="contactStreet1FormControl.touched">
                          <p *ngIf="contactStreet1FormControl.hasError('required')" class="text-danger">
                            {{ 'error.addressrequired' | translate }}
                          </p>
                          <p *ngIf="contactStreet1FormControl.hasError('maxlength')" class="text-danger">
                            {{ 'error.addressline1.invalid' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          {{ 'o365.contactaddressline2' | translate }}
                        </h4>
                        <input id="contact_address_street2" type="text" class="form-control form-control-lg"
                          formControlName="contact_address_street2" />
                        <div *ngIf="contactStreet2FormControl.touched">
                          <p *ngIf="contactStreet2FormControl.hasError('maxlength')" class="text-danger">
                            {{ 'error.addressline2.invalid' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          {{ 'o365.contactaddressline3' | translate }}
                        </h4>
                        <input id="contact_address_street3" type="text" class="form-control form-control-lg"
                          formControlName="contact_address_street3" />
                        <div *ngIf="contactStreet3FormControl.touched">
                          <p *ngIf="contactStreet3FormControl.hasError('maxlength')" class="text-danger">
                            {{ 'error.addressline3.invalid' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>
                          {{ 'o365.contactpostcode' | translate }}
                          <span class="text-danger"
                            *ngIf="tenantFormStates && tenantFormStates.contactPostcodeRequired == true">&nbsp;*</span>
                        </h4>
                        <input id="contact_address_postcode" type="text" class="form-control form-control-lg"
                          formControlName="contact_address_postcode" />
                        <div *ngIf="contactPostcodeFormControl.touched">
                          <p *ngIf="contactPostcodeFormControl.hasError('required')" class="text-danger">
                            {{ 'error.postcoderequired' | translate }}
                          </p>
                          <p *ngIf="contactPostcodeFormControl.hasError('pattern')" class="text-danger">
                            {{ 'error.postcodeinvalid' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>{{ 'o365.contactcountry' | translate }}<span class="text-danger">&nbsp;*&nbsp;</span><i
                            class="fa fa-spinner fa-spin" *ngIf="contactCountryLoading"></i></h4>
                        <select formControlName="contact_address_country_id" id="contact_address_country_id"
                          class="form-control form-control-lg"
                          (change)="onCountryChanged($event.target.value, 'contact')">
                          <option [value]="0" disabled>Select a Country...</option>
                          <option *ngFor="let country of countriesContact" [value]="country.id">{{ country.name }}
                          </option>
                        </select>
                        <div *ngIf="contactCountryIdFormControl.touched">
                          <p *ngIf="contactCountryIdFormControl.hasError('required') || contactCountryIdFormControl.hasError('min')"
                            class="text-danger">
                            {{ 'error.countryrequired' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>{{ 'o365.contactstate' | translate }}<span class="text-danger">&nbsp;*&nbsp;</span><i
                            class="fa fa-spinner fa-spin" *ngIf="contactStateLoading"></i></h4>
                        <select formControlName="contact_address_state_id" id="contact_address_state_id"
                          class="form-control form-control-lg"
                          (change)="onStateChanged($event.target.value, 'contact')">
                          <option [value]="0" disabled>Select a State...</option>
                          <option *ngFor="let state of statesContact" [value]="state.id">{{ state.name }}</option>
                        </select>
                        <div *ngIf="contactStateIdFormControl.touched">
                          <p *ngIf="contactStateIdFormControl.hasError('required') || contactStateIdFormControl.hasError('min')"
                            class="text-danger">
                            {{ 'error.staterequired' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="group form-group mb-4">
                        <h4>{{ 'o365.contactcity' | translate }}<span class="text-danger">&nbsp;*&nbsp;</span><i class="fa fa-spinner fa-spin" *ngIf="contactCityLoading"></i></h4>
                        <select formControlName="contact_address_city_id" id="contact_address_city_id" class="form-control form-control-lg"
                          (change)="onCityChanged($event.target.value, 'contact')">
                          <option [value]="0" disabled>Select a City...</option>
                          <option *ngFor="let city of citiesContact" [value]="city.id">{{ city.name }}</option>
                          <option [value]="50000">{{ 'word.others' | translate }}</option>
                        </select>
                        <div *ngIf="contactCityIdFormControl.touched">
                          <p *ngIf="contactCityIdFormControl.hasError('required') || contactCityIdFormControl.hasError('min')"
                            class="text-danger">
                            {{ 'error.cityrequired' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-12" *ngIf="contactOtherCitySelected">
                      <div class="group form-group mb-4">
                        <h4>
                          {{ 'o365.othercity' | translate }}
                          <span class="text-danger" *ngIf="tenantFormStates && tenantFormStates.contactOtherCityRequired == true">&nbsp;*</span>
                        </h4>
                        <input placeholder="City Name" id="contact_address_city_other" type="text" class="form-control form-control-lg"
                        formControlName="contact_address_city_other" />
                        <div *ngIf="contactOtherCityFormControl.touched">
                          <p *ngIf="contactOtherCityFormControl.hasError('required')"
                            class="text-danger">
                            {{ 'error.othercityrequired' | translate }}
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="group form-group mb-4" style="height: 40px; padding-top: 10px;">
                        <div class="checkbox-container">
                          <input type="checkbox" id="useContactAsBilling" name="useContactAsBilling"
                            [checked]="useContactAddressAsBillingAddress"
                            (change)="onUseContactAsBillingChanged($event)">
                          <label for="useContactAsBilling" class="bg-checkbox-dark"></label>
                          <h6>
                            {{ 'o365.form.checkbox.title1' | translate }}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="!useContactAddressAsBillingAddress">
                    <hr>

                    <h6 style="padding-left: 0; font-weight: light; margin-bottom: 1.2em;">
                      {{ 'o365.billingaddressinfo' | translate }}
                    </h6>

                    <ng-template #singleBillingCountry>
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                          <div class="group form-group mb-4">
                            <h4>{{ 'o365.billingcountry' | translate }}<span class="text-danger">&nbsp;*</span><i class="fa fa-spinner fa-spin" *ngIf="billingCountryLoading"></i></h4>
                            <select formControlName="billing_address_country_id" id="billing_address_country_id" class="form-control form-control-lg"
                            (change)="onCountryChanged($event.target.value, 'billing')">
                              <option [value]="0" disabled>Select a Country...</option>
                              <option *ngFor="let country of countriesBilling" [value]="country.id">{{ country.name }}</option>
                            </select>
                            <div *ngIf="billingCountryIdFormControl.touched">
                              <p *ngIf="billingCountryIdFormControl.hasError('required') || billingCountryIdFormControl.hasError('min')"
                                class="text-danger">
                                {{ 'error.countryrequired' | translate }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>

                    <div class="row" *ngIf="showBillingAddressFormInputs;else singleBillingCountry">

                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="group form-group mb-4">
                          <h4>{{ 'o365.billingaddressline1' | translate }}<span class="text-danger">&nbsp;*</span></h4>
                          <input id="billing_address_street1" type="text" class="form-control form-control-lg" formControlName="billing_address_street1" />
                          <div *ngIf="billingStreet1FormControl.touched">
                            <p *ngIf="billingStreet1FormControl.hasError('required')"
                              class="text-danger">
                              {{ 'error.billingaddressrequired' | translate }}
                            </p>
                            <p *ngIf="billingStreet1FormControl.hasError('maxlength')" class="text-danger">
                              {{ 'error.billaddressline1.invalid' | translate }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="group form-group mb-4">
                          <h4>{{ 'o365.billingaddressline2' | translate }}</h4>
                          <input id="billing_address_street2" type="text" class="form-control form-control-lg" formControlName="billing_address_street2" />
                          <div *ngIf="billingStreet2FormControl.touched">
                            <p *ngIf="billingStreet2FormControl.hasError('maxlength')" class="text-danger">
                              {{ 'error.billaddressline2.invalid' | translate }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="group form-group mb-4">
                          <h4>{{ 'o365.billingaddressline3' | translate }}</h4>
                          <input id="billing_address_street3" type="text" class="form-control form-control-lg" formControlName="billing_address_street3" />
                          <div *ngIf="billingStreet3FormControl.touched">
                            <p *ngIf="billingStreet3FormControl.hasError('maxlength')" class="text-danger">
                              {{ 'error.billaddressline3.invalid' | translate }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="group form-group mb-4">
                          <h4>
                            {{ 'o365.billingpostcode' | translate }}
                            <span class="text-danger" *ngIf="tenantFormStates && tenantFormStates.billingPostcodeRequired == true">&nbsp;*</span>
                          </h4>
                          <input id="billing_address_postcode" type="text" class="form-control form-control-lg" formControlName="billing_address_postcode" />
                          <div *ngIf="billingPostcodeFormControl.touched">
                            <p *ngIf="billingPostcodeFormControl.hasError('required')" class="text-danger">
                              {{ 'error.postcoderequired' | translate }}
                            </p>
                            <p *ngIf="billingPostcodeFormControl.hasError('pattern')" class="text-danger">
                              {{ 'error.postcodeinvalid' | translate }}
                             </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="group form-group mb-4">
                          <h4>{{ 'o365.billingcountry' | translate }}<span class="text-danger">&nbsp;*</span><i class="fa fa-spinner fa-spin" *ngIf="billingCountryLoading"></i></h4>
                          <select formControlName="billing_address_country_id" id="billing_address_country_id"
                            class="form-control form-control-lg" (change)="onCountryChanged($event.target.value, 'billing')">
                            <option [value]="0" disabled>Select a Country...</option>
                            <option *ngFor="let country of countriesBilling" [value]="country.id">{{ country.name }}</option>
                          </select>
                          <div *ngIf="billingCountryIdFormControl.touched">
                            <p *ngIf="billingCountryIdFormControl.hasError('required') || billingCountryIdFormControl.hasError('min')"
                              class="text-danger">
                              {{ 'error.countryrequired' | translate }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="group form-group mb-4">
                          <h4>{{ 'o365.billingstate' | translate }}<span class="text-danger">&nbsp;*</span><i class="fa fa-spinner fa-spin" *ngIf="billingStateLoading"></i></h4>
                          <select formControlName="billing_address_state_id" id="billing_address_state_id"
                            class="form-control form-control-lg" (change)="onStateChanged($event.target.value, 'billing')">
                            <option [value]="0" disabled>Select a State...</option>
                            <option *ngFor="let state of statesBilling" [value]="state.id">{{ state.name }}</option>
                          </select>
                          <div *ngIf="billingStateIdFormControl.touched">
                            <p *ngIf="billingStateIdFormControl.hasError('required') || billingStateIdFormControl.hasError('min')"
                              class="text-danger">
                              {{ 'error.staterequired' | translate }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="group form-group mb-4">
                          <h4>{{ 'o365.billingcity' | translate }}<span class="text-danger">&nbsp;*</span><i class="fa fa-spinner fa-spin" *ngIf="billingCityLoading"></i></h4>
                          <select formControlName="billing_address_city_id" id="billing_address_city_id"
                            class="form-control form-control-lg" (change)="onCityChanged($event.target.value, 'billing')">
                            <option [value]="0" disabled>Select a City...</option>
                            <option *ngFor="let city of citiesBilling" [value]="city.id">{{ city.name }}</option>
                            <option [value]="50000">Other City</option>
                          </select>
                          <div *ngIf="billingCityIdFormControl.touched">
                            <p *ngIf="billingCityIdFormControl.hasError('required') || billingCityIdFormControl.hasError('min')"
                              class="text-danger">
                              {{ 'error.cityrequired' | translate }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6 col-md-6 col-12"  *ngIf="billingOtherCitySelected">
                        <div class="group form-group mb-4">
                          <h4>
                            {{ 'o365.othercity' | translate }}
                            <span class="text-danger" *ngIf="tenantFormStates && tenantFormStates.billingOtherCityRequired == true">&nbsp;*</span>
                          </h4>
                          <input placeholder="City Name" id="billing_address_city_other" type="text" class="form-control form-control-lg" formControlName="billing_address_city_other" />
                          <div *ngIf="billingOtherCityFormControl.touched">
                            <p *ngIf="billingOtherCityFormControl.hasError('required')"
                              class="text-danger">
                              {{ 'error.othercityrequired' | translate }}
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>

                  <hr>

                  <div class="group form-group mb-4" style="height: 40px; padding-top: 10px;">
                    <div class="checkbox-container">
                      <input formControlName="is_marketing_promo" id="is_marketing_promo" type="checkbox"
                        name="is_marketing_promo" [value]="true">
                      <label for="is_marketing_promo" class="bg-checkbox-dark"></label>
                      <h6>
                        {{ 'o365.form.checkbox.title2' | translate }}
                      </h6>
                    </div>
                  </div>

                  <div *ngIf="!transfer_tenant" class="group form-group mb-4" style="height: 40px; padding-top: 10px;">
                    <div class="checkbox-container">
                      <input formControlName="include_partner" id="include_partner" type="checkbox"
                        name="include_partner">
                      <label for="include_partner" class="bg-checkbox-dark"></label>
                      <h6>
                        {{ 'o365.form.checkbox.title3' | translate }}
                      </h6>
                    </div>
                  </div>

                  <div class="group form-group mb-4" style="height: 40px; padding-top: 10px;">
                    <div class="checkbox-container">
                      <input formControlName="mcua_agreement" id="mcua_agreement" type="checkbox" name="mcua_agreement">
                      <label for="mcua_agreement" class="bg-checkbox-dark"></label>
                      <h6 style="width: 85%;">
                        <span class="text-danger ml-1 mr-1">*</span>
                        {{ 'o365.form.checkbox.title4' | translate }} <a
                          href="https://aka.ms/customeragreement" target="_blank">{{ 'o365.form.checkbox.title5' | translate }}
                          </a>.
                      </h6>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div class="col-lg-12">
              <div class="float-right">
                <button class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()"
                  [disabled]="nextBtnPressed || tenantForm.invalid || tenantForm.pristine">
                  {{ 'button.next' | translate }} <i class="fa fa-cog fa-spin" style="font-size:24px;color: lightseagreen"
                    *ngIf="nextBtnPressed"></i>
                </button>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</main>
