import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmailMasterTemplateComponent } from 'src/app/component/email-master-template/email-master-template.component';
import { EmailDashboardComponent } from 'src/app/component/email-dashboard/email-dashboard.component';
import { EmailOrderManagementComponent } from 'src/app/component/email-order-management/email-order-management.component';
import { EmailStepOneComponent } from 'src/app/component/email-step-one/email-step-one.component';
import { EmailStepTwoComponent } from 'src/app/component/email-step-two/email-step-two.component';
import { EmailOrderDetailComponent } from 'src/app/component/email-order-detail/email-order-detail.component';
import { AuthGuard } from 'src/app/guard/auth.guard';

// all path defined here are prefix with /email, see app-routing.module.ts
const routes: Routes = [
  {
    // path with matching {PARENT_PATH}/email will auto redirect to {PARENT_PATH}/email/email-dashboard
    path: '', redirectTo: 'email-dashboard', pathMatch: 'full'
  },
  { 
    path: '', 
    component: EmailMasterTemplateComponent, // <--- always loaded with prefix /email/**
    children: [
      {path: 'email-dashboard', component: EmailDashboardComponent, canActivate: [AuthGuard] },
      {path: 'email-order-management', component: EmailOrderManagementComponent, canActivate: [AuthGuard] },
      {path: 'email-step-one', component: EmailStepOneComponent, canActivate: [AuthGuard] },
      {path: 'email-step-two', component: EmailStepTwoComponent, canActivate: [AuthGuard] },
      {path: 'email-order-detail/:id', component: EmailOrderDetailComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path: '**', redirectTo: 'email-dashboard' // 404 not found
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmailRoutingModule { }
