import { Component, OnInit } from '@angular/core';
declare var appJS: any;

@Component({
  selector: 'app-o365-master-template',
  templateUrl: './o365-master-template.component.html',
  styleUrls: ['./o365-master-template.component.css']
})
export class O365MasterTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    appJS.init();
  }

}
