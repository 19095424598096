import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StepModel } from '../../../../../model/step.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MicrosoftGraphService } from '../../../../../service/microsoftcsp/microsoft-graph.service';

@Component({
  selector: 'app-microsoftcsp-domain-finish',
  templateUrl: './microsoftcsp-domain-finish.component.html',
  styleUrls: ['./microsoftcsp-domain-finish.component.css']
})
export class MicrosoftCSPDomainFinishComponent implements OnInit {

  @Input() step: StepModel;
  @Output() stepChanged: EventEmitter<any> = new EventEmitter<any>();

  tenantId: number;
  domain: any; 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphService: MicrosoftGraphService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      this.domain = this.microsoftGraphService.domain;
    });
  }

  goToUsers(event: any) {
    event.preventDefault();
    this.router.navigate(['/microsoftcsp/tenants', this.tenantId, 'users']);
  }

}
