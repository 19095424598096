import { EmailPlaceOrder } from 'src/app/model/email/email-place-order.model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable() // the injection declaration is in providers of EmailModule
export class EmailApiService {

  emailServiceApiUrl:any = environment.emailService.apiUrl;
  appName:string = environment.emailService.appName;
  
  provider:string = 'Alibaba';
  rerid:string = '1';

  statisticStatus: string[] = ['totalexpiring','totalexpired','totallicense','totalorder'];

  constructor(private httpClient:HttpClient) {
  }

  getHeaders(){
    // create new instance for each api call, instead of global static
    // as token string would change time to time
    const httpHeaders = {
      headers : new HttpHeaders()
      .set("Authorization", "Bearer " + localStorage.getItem('access_token'))
      .set('Content-Type', 'application/json')
    }
    return httpHeaders;
  }

  getOrderStatistics(){
    let endpoint: string = '/order/statisticbox';
    return this.httpClient.get(this.emailServiceApiUrl+endpoint, this.getHeaders() );
  }

  getEmailProducts(){
    let endpoint: string = '/product/list/' + this.provider;
    return this.httpClient.get(this.emailServiceApiUrl+endpoint, this.getHeaders() );
  }

  getEmailProductRule(productid:number){
    let endpoint: string = '/product/rules/' + productid;
    return this.httpClient.get(this.emailServiceApiUrl+endpoint, this.getHeaders() );
  }

  getProductPlanPrice(productid:number){
    let endpoint: string = '/product/plan/list/'+productid;
    return this.httpClient.get(this.emailServiceApiUrl+endpoint, this.getHeaders() );
  }

  checkEmailDomainAvailability(domain:string){
    let endpoint: string = '/emaildomain/check/'+domain+'/availability';
    return this.httpClient.get(this.emailServiceApiUrl+endpoint, this.getHeaders() );
  }

  placeEmailOrder(data: EmailPlaceOrder){
    let endpoint: string = '/order/place';
    return this.httpClient.post(this.emailServiceApiUrl+endpoint,data, this.getHeaders() );
  }

  getEmailOrderDetails(oid:number){
    let endpoint:string = '/order/info/'+this.rerid+'/'+oid;
    return this.httpClient.get(this.emailServiceApiUrl + endpoint,  this.getHeaders() );
  }

  getEmailOrders(status:string){
    let endpoint: string;
    let isDashboardStatisticStatus = this.statisticStatus.includes(status.toLowerCase());
    
    if(isDashboardStatisticStatus){
      endpoint = '/order/statisticlist/'+status;
    }else{
      endpoint = '/order/list/'+status.toUpperCase();
    }
    return this.httpClient.get(this.emailServiceApiUrl + endpoint,  this.getHeaders() );
  }
  
  updateOrderLicenses(oid:number, licenses: number){
    let endpoint: string = '/order/licenses';
    let bodyData = {oid:oid, licenses:licenses};
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, bodyData,  this.getHeaders() );
  }

  updateOrderTerms(oid:number, terms: number){
    let endpoint: string = '/order/terms';
    let bodyData = {oid:oid, terms:terms};
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, bodyData,  this.getHeaders() );
  }

  updateOrderEmailDomain(oid:number, emailDomain: string){
    let endpoint: string = '/order/emaildomain';
    let bodyData = {oid:oid, domemail:emailDomain};
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, bodyData,  this.getHeaders() );
  }

  updateEmailOrderStatus(oid:number, status:string){
    let endpoint: string = '/order/update/status';
    let bodyData = {oid:oid, status:status, appName:this.appName};
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, bodyData,  this.getHeaders() );
  }

  updateEmailOrderReferenceNo(orderUpdateRequest: any) {
    let endpoint: string = '/order/update/referenceno';
    let bodyData = Object.assign({}, orderUpdateRequest);
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, bodyData, this.getHeaders() );
  }

  orderActionSearch(searchObj:any){
    let endpoint: string = '/order/orderaction';
    return this.httpClient.post(this.emailServiceApiUrl + endpoint, searchObj, this.getHeaders() );
  }

  updateOrderActionPaymentReferenceNo(data:any){
    let endpoint: string = '/order/update/orderaction-referenceno';
    return this.httpClient.put(this.emailServiceApiUrl + endpoint, data, this.getHeaders() );
  }
}
