import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class O365HttpService {

  apiUrl:string = environment.apiUrl;
  mainApiUrl:string = environment.mainApiUrl;

  httpHeaders = {
    headers : new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('access_token'))
  }

  getHeaders(){
    // create new instance for each api call, instead of global static
    const httpHeaders = {
      headers : new HttpHeaders()
      .set("Authorization", "Bearer " + localStorage.getItem('access_token'))
      .set('Content-Type', 'application/json')
    }
    return httpHeaders;
  }

  constructor(public httpClient:HttpClient) { }

  createTenant(data){
    return this.httpClient.post(this.apiUrl+'microsoft-csp/tenants', data, this.httpHeaders)
  }

  createTenantValidate(data) {
    return this.httpClient.post(this.apiUrl+'microsoft-csp/tenants/validate', data, this.httpHeaders)
  }

  transitTenant(data){
    return this.httpClient.post(this.apiUrl+'microsoft-csp/tenants/transit',data,this.httpHeaders)
  }

  transitTenantValidate(data) {
    return this.httpClient.post(this.apiUrl+'microsoft-csp/tenants/transit/validate',data,this.httpHeaders)
  }

  getAllProducts() {
    return this.httpClient.get(this.apiUrl+'microsoft-csp/orders/products',this.httpHeaders)
  }

  getAvailableProduct(tenantId){
    return this.httpClient.get(this.apiUrl+'microsoft-csp/tenants/'+tenantId+'/products',this.httpHeaders)
  }

  createOrder(data) {
    return this.httpClient.post(`${this.apiUrl}microsoft-csp/orders`, data, this.httpHeaders);
  }

  getOrders(filter = null) {
    if(filter == null) {
      return this.httpClient.get(`${this.apiUrl}microsoft-csp/orders`, this.httpHeaders);
    } else {
      return this.httpClient.get(`${this.apiUrl}microsoft-csp/orders?filter=${filter}`, this.httpHeaders);
    }
  }

  getOrder(id) {
    return this.httpClient.get(`${this.apiUrl}microsoft-csp/orders/${id}`, this.httpHeaders);
  }

  suspendSubscription(id) {
    return this.httpClient.post(`${this.apiUrl}microsoft-csp/subscriptions/${id}/suspend`, {}, this.httpHeaders);
  }

  resumeSubscription(id) {
    return this.httpClient.post(`${this.apiUrl}microsoft-csp/subscriptions/${id}/resume`, {}, this.httpHeaders);
  }

  renewSubscription(id) {
    return this.httpClient.post(`${this.apiUrl}microsoft-csp/subscriptions/${id}/renew`, {}, this.httpHeaders);
  }

  enableAutoRenewSubscription(id) {
    return this.httpClient.post(`${this.apiUrl}microsoft-csp/subscriptions/${id}/auto-renew/enable`, {}, this.httpHeaders);
  }

  disableAutoRenewSubscription(id) {
    return this.httpClient.post(`${this.apiUrl}microsoft-csp/subscriptions/${id}/auto-renew/disable`, {}, this.httpHeaders);
  }

  updateQuantity(id, quantity) {
    return this.httpClient.post(`${this.apiUrl}microsoft-csp/orders/${id}`, {'quantity': quantity}, this.httpHeaders);
  }

  getDashboardData() {
    return this.httpClient.get(`${this.apiUrl}microsoft-csp/dashboard`, this.httpHeaders)
  }

  getSalesChartData(days) {
    return this.httpClient.get(`${this.apiUrl}microsoft-csp/dashboard/chart?days=${days}`, this.httpHeaders)
  }

  getHistoricalOrder(id) {
    return this.httpClient.get(`${this.apiUrl}microsoft-csp/orders/${id}/history`, this.httpHeaders)
  }


  validateDomainPrefix(domainPrefix:string){
    return this.httpClient.get(this.apiUrl+`microsoft-csp/tenants/validate/domain/${domainPrefix}`, this.getHeaders());
  }

  getProductRules(){
    return this.httpClient.get(this.apiUrl+`microsoft-csp/products/rules`, this.getHeaders());
  }

  getProductRule(productId:any){
    return this.httpClient.get(this.apiUrl+`microsoft-csp/products/${productId}/rules`, this.getHeaders());
  }

  getMarketCategories(){
    return this.httpClient.get(this.mainApiUrl+'microsoftcsp/marketcategories', this.getHeaders());
  }

  getTenants(){
    return this.httpClient.get(this.apiUrl+'microsoft-csp/tenants', this.getHeaders());
  }

  getTenantDetails(tenantId:any){
    return this.httpClient.get(this.apiUrl+'microsoft-csp/tenants/'+tenantId, this.getHeaders());
  }

  getAddressValidations(){
    return this.httpClient.get(this.apiUrl+'microsoft-csp/tenants/countries/address', this.getHeaders());
  }

  sendTenantEmail(tenantId:any, data:any){
    return this.httpClient.post(this.apiUrl+`microsoft-csp/tenants/${tenantId}/email/send`, data, this.getHeaders());
  }

  getPartnerConnectUrl(tenantId:any){
    return this.httpClient.get(this.apiUrl+`microsoft-csp/tenants/${tenantId}/partner-connect-url`, this.getHeaders());
  }

  getTenantSubscriptionProducts(tenantId:any, searchParams?:any){
    let data: any = {};
    if(searchParams){
      data = {...searchParams};
    }
    return this.httpClient.post(this.apiUrl+`microsoft-csp/tenants/${tenantId}/subscription-products`, data, this.getHeaders());
  }

  getTenantSubscriptions(tenantId:any){
    return this.httpClient.get(this.apiUrl+`microsoft-csp/tenants/${tenantId}/subscriptions`, this.getHeaders());
  }

  getSubscriptionsDetails(subscriptionId:any){
    return this.httpClient.get(this.apiUrl+`microsoft-csp/subscriptions/${subscriptionId}`, this.getHeaders());
  }

  updateSubscriptionLicenseQuantity(subscriptionId:any, addNewQuantity:number){
    return this.httpClient.post(this.apiUrl+`microsoft-csp/subscriptions/${subscriptionId}/quantity/${addNewQuantity}`, {}, this.getHeaders());
  }

  //----- V2 API -----//

  getOrdersV2(filter = null) {
    let url = `${this.apiUrl}microsoft-csp/orders/v2`;
    console.log('filter', filter);
    if(filter != null) {

      url += `?filter=${filter}`;
    }

    return this.httpClient.get(url, this.httpHeaders);
  }

  getOrderV2(id) {
    return this.httpClient.get(`${this.apiUrl}microsoft-csp/orders/v2/${id}`, this.httpHeaders);
  }

  updateQuantityV2(id, quantity) {
    let data = {
      'quantity': quantity
    };

    return this.httpClient.post(`${this.apiUrl}microsoft-csp/orders/v2/${id}`, data, this.httpHeaders);
  }

  getAllProductsV2(options:any) {
    return this.httpClient.post(this.apiUrl+'microsoft-csp/orders/products-by-options',options,this.httpHeaders)
  }

  getProductV2ByProductId(options: any) {
    return this.httpClient.post(this.apiUrl+'microsoft-csp/orders/products-by-options',options,this.httpHeaders)
  }

  getAllProductsPromotionsProductIds() {
    return this.httpClient.get(this.apiUrl + 'microsoft-csp/products/promotions/product-ids', this.httpHeaders)
  }

  getProductPromotionsByProductId(productId: any){
    return this.httpClient.get(this.apiUrl + `microsoft-csp/products/${productId}/promotions`, this.httpHeaders);
  }
}
