<main id="email-manage-order" class="main">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item active">{{ 'word.ordermanagement' | translate }}</li>
  </ol>

  <div class="container-fluid">
    <div class="animated fadeIn">

      <div class="row" *ngIf="errorCheck">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissible fade show" role="alert" *ngIf="errorCheck">
            <strong>{{ 'word.error.oops' | translate }}</strong> {{ 'word.error.somethingwrong' | translate }}
            <ul>
              <li *ngFor="let error of errors">
                {{ error }}
              </li>
            </ul>
            <button type="button" class="close" (click)="closePressed()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="table-responsive col">
                  <table id="emailOrderDataTable" class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>{{ 'word.orderid' | translate }}</th>
                        <th>{{ 'word.domains' | translate }}</th>
                        <th>{{ 'word.package' | translate }}</th>
                        <th>{{ 'word.licenses' | translate }}</th>
                        <th>{{ 'word.orderstatus' | translate }}</th>
                        <th>{{ 'word.paymentstatus' | translate }}</th>
                        <th>{{ 'word.storefront' | translate }}</th>
                        <th>{{ 'word.orderdate' | translate }}</th>
                        <th>{{ 'word.completeddate' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody id="table-data">
                      <td colspan="9" style="text-align: center" [hidden]="!orderLoading">{{ 'word.loading' | translate }}...</td>
                      <!-- columns will be rendered by datatable -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
