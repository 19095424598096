import { LanguageTranslateService } from './../../service/language-translate.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { EmailDataService } from 'src/app/service/email-data.service';
import { EmailApiService } from './../../service/email-api.service';
import { EmailPlaceOrder } from 'src/app/model/email/email-place-order.model';
import { ScrollUtil } from 'src/app/util/scroll-util';

@Component({
  selector: 'app-email-step-two',
  templateUrl: './email-step-two.component.html',
  styleUrls: ['./email-step-two.component.css']
})
export class EmailStepTwoComponent implements OnInit {

  data: EmailPlaceOrder;
  placeOrderBtnPressed: boolean = false;

  errorCheck:boolean = false;
  errors: any[] = [];

  successCheck:boolean = false;

  successLang = {
    eng: 'Place order successful',
    cn: '成功下单',
    zh: '成功下單',
  }

  lang = "eng";

  successMessage: string = this.successLang[this.lang];

  constructor(
    private emailDataService: EmailDataService,
    private emailApiService: EmailApiService,
    private router: Router,
    private translator: LanguageTranslateService,
  ) { }

  ngOnInit() {
    this.lang = this.translator.userLanguage$.value;
    this.successMessage = this.successLang[this.lang];
    this.data = this.emailDataService.getEmailPlaceOrderData();
  }

  placeOrderPressed(){
    this.placeOrderBtnPressed = true;

    let orderSuccessLang = {
      eng: 'Order successfully submitter, we are processing it now.',
      cn: '订单成功提交，正在处理中。',
      zh: '訂單成功提交，正在處理中。',
    }

    this.emailApiService.placeEmailOrder(this.data).subscribe((res:any)=>{
      let status: boolean = res.status;
      if(status){
        this.successMessage = orderSuccessLang[this.lang];
        this.successCheck = true;

        setTimeout(() => {
          if(res.oid){
            let oid:number = res.oid;
            this.router.navigate(['/email/email-order-detail', oid]);
          }else{
            this.router.navigate(['/email/email-order-management']);
          }
        }, 3000);
        
      }else{
        let resErrors: any[] = res.errors;
        this.errors = resErrors.map(e=> e.message);
        this.errorCheck = true;
      }
    },err=>{
      this.handleError(err);
      this.placeOrderBtnPressed = false;
    },()=>{
      this.placeOrderBtnPressed = false;
      ScrollUtil.scrollToTop();
    });
  }


  handleError(err:any) {
    this.errors.length = 0;
    if (err.error instanceof ErrorEvent) {
      // client-side error
      let errorMessage = `Error: ${err.error.message}`;
      this.errors.push(errorMessage);
    } else {
      console.log(err);
      // server-side error
      let responseBodyErrors: any[] = err.error.errors;

      if (responseBodyErrors && responseBodyErrors.length > 0) {
        this.errors = responseBodyErrors.map(e => e.message);
      } else {
        switch (err.status) {
          case 401:
            if (err.error.error) {
              this.errors.push(`${err.error.error}`);
            } else {
              this.errors.push(`${err.message}`);
            }
            break;
          case 0:
            this.errors.push('Connection error. Please try again later.');
            break;
          default:
            this.errors.push(`${err.message}`);
            break;
        }
      }
    }
    this.errorCheck = true;
    ScrollUtil.scrollToTop();
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  closePressed(type:string) {
    if(type === 'error') this.errorCheck = false;
    else if( type === 'success') this.successCheck = false;
  }
}
