<main id="storefront" class="main create-storefront">

  <!-- Breadcrumb -->
  <ol class="breadcrumb">
    <li class="breadcrumb-item">{{ 'word.home' | translate }}</li>
    <li class="breadcrumb-item">{{ 'sf.storefrontsettings' | translate }}</li>
    <li class="breadcrumb-item active" *ngIf="!edited">{{ 'sf.createstorefront' | translate }}</li>
    <li class="breadcrumb-item active" *ngIf="edited">{{ 'sf.editstorefront' | translate }}</li>

    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
           aria-valuemin="0" aria-valuemax="100" style="width: 45%">
      </div>
    </div>
  </ol>
  <div class="container-fluid">
    <div class="animated fadeIn">
      <div class="row">
        <div class="col-12">
          <h2>{{ 'sf.customizepage' | translate }}</h2>
        </div>

        <div class="col-xl-2 col-md-12 my-4">
          <div class="checkbox-container row">
            <div class="col-xl-12 col-md-3 col-xs-3">
              <input type="checkbox" name="pages" id="home-checkbox" checked disabled>
              <label for="home-checkbox" class="bg-checkbox-dark"></label>
              <h5>{{ 'word.homepage' | translate }}</h5>
            </div>

            <div class="col-xl-12 col-md-3 col-xs-3">
              <input type="checkbox" name="pages" id="about-checkbox" checked disabled>
              <label for="about-checkbox" class="bg-checkbox-dark"></label>
              <h5>{{ 'word.aboutpage' | translate }}</h5>
            </div>

            <div class="col-xl-12 col-md-3">
              <input type="checkbox" name="pages" id="contact-checkbox" checked disabled>
              <label for="contact-checkbox" class="bg-checkbox-dark"></label>
              <h5>{{ 'word.contactus' | translate }}</h5>
            </div>

            <div class="col-xl-12 col-md-3 col-xs-3">
              <input type="hidden" name="custom_page_enabled" value="0">
              <input type="checkbox" name="custom_page_status" id="custom_page_status" [(ngModel)]="customStatus"
                     (click)="toggleCustomPageButton()">
              <label for="custom_page_status" class="bg-checkbox-dark"></label>
              <h5>{{ 'word.custompage' | translate }}</h5>
            </div>
          </div>
        </div>

        <div class="col-xl-10 col-md-12 pl-xl-5 my-4">
          <ul class="nav nav-pills nav-fill justify-content-end mb-3" id="pills-tab" role="tablist">
            <li class="nav-item">
              <a class="nav-link nav-dark btn-lg" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home"
                 role="tab" aria-controls="pills-home" aria-selected="true">{{ 'word.homepage' | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-dark btn-lg" id="pills-about-tab" data-bs-toggle="pill" href="#pills-about"
                 role="tab" aria-controls="pills-contact" aria-selected="false">{{ 'word.aboutpage' | translate }}</a>
            </li>
            <!--    <li class="nav-item">
                  <a class="nav-link nav-dark btn-lg" id="pills-product-tab" data-bs-toggle="pill" href="#pills-product" role="tab" aria-controls="pills-profile" aria-selected="false">Product Page</a>
                </li>-->
            <li class="nav-item">
              <a class="nav-link nav-dark btn-lg" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-contact"
                 role="tab" aria-controls="pills-contact" aria-selected="false">{{ 'word.contactus' | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav-dark btn-lg active" id="pills-custom-tab" data-bs-toggle="pill"
                 href="#pills-custom" role="tab" aria-controls="pills-contact" aria-selected="false">{{ 'word.custompage' | translate }}</a>
            </li>
          </ul>

          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade p-0" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <!-- home -->
              <app-preview-page [title]="title.en" [text]="text.en" [selectedPreview]="selectedPreview" [logo]="logo"
                                [selectedThemePreview]="selectedThemePreview" [filestring]="filestring"
                                [type]="'home'" [cover]="cover" [aboutusValue]="aboutusValue.en"
                                [contactusValue]="contactusValue.en"></app-preview-page>
            </div>

            <div class="tab-pane p-0 fade" id="pills-about" role="tabpanel" aria-labelledby="pills-about-tab">
              <!-- contact -->
              <div style="min-height: 24px;" class="mb-1">
                <label class="switch switch-3d switch-primary">
                  <input type="checkbox" class="switch-input" id="switcherContact" [(ngModel)]="editAbout">
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
                <label class="col-form-label col-form-label-lg" style="margin-top: -10px; margin-left: 5px;">{{ 'sf.editcontent' | translate }}</label>
              </div>

              <nav class="{{selectedThemePreview}} navbar navbar-expand-lg">
                <div class="container">
                  <a class="navbar-brand" style="cursor: pointer;">
                    <img class="logo" [src]="logo" alt="" height="20px">
                  </a>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto" style="font-size: 10px;">
                      <li class="active">
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.home' | translate }} <span class="sr-only">(current)</span></a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.aboutcompany' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.contactus' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.custompage' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.login' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.signup' | translate }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>

              <div class="jumbotron jumbotron-fluid" style="height:550px; background-size: cover; ">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <h2 class="mb-3" style="color:white">{{ 'word.aboutus' | translate }}</h2>

                      <select class="form-control form-control-lg mb-4" [(ngModel)]="aboutLang"
                              [ngModelOptions]="{standalone: true}">
                        <option value="0" disabled selected>{{ 'word.selectlanguage' | translate }} *</option>
                        <option *ngFor="let language of languages" [value]="language.code">
                          {{ language.name }}
                        </option>
                      </select>

                      <div class="card-body" [innerHTML]="aboutusValue[aboutLang]" *ngIf="!editAbout"></div>
                      <div *ngFor="let language of languages" [hidden]="!editAbout">
                        <ck-editor name="editor1" [(ngModel)]="aboutusValue[language.code]" language="en"
                                   [fullPage]="false" [hidden]="language.code != aboutLang"></ck-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-pane p-0 fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
              <!-- contact -->
              <div style="min-height: 24px;" class="mb-1">
                <label class="switch switch-3d switch-primary">
                  <input type="checkbox" class="switch-input" id="switcherContact" [(ngModel)]="editContact">
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
                <label class="col-form-label col-form-label-lg" style="margin-top: -10px; margin-left: 5px;">{{ 'sf.editcontent' | translate }}</label>
              </div>

              <nav class="{{selectedThemePreview}} navbar navbar-expand-lg">
                <div class="container">
                  <a class="navbar-brand" style="cursor: pointer;">
                    <img class="logo" [src]="logo" alt="" height="20px">
                  </a>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto" style="font-size: 10px;">
                      <li class="active">
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.home' | translate }} <span class="sr-only">(current)</span></a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.aboutcompany' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.contactus' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.custompage' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.login' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.signup' | translate }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>

              <div class="jumbotron jumbotron-fluid" style="height:550px; background-size: cover; ">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <h2 class="mb-3" style="color:white">{{ 'word.contactus' | translate }}</h2>

                      <select class="form-control form-control-lg mb-4" [(ngModel)]="contactusLang"
                              [ngModelOptions]="{standalone: true}">
                        <option value="0" disabled selected>{{ 'word.selectlanguage' | translate }} *</option>
                        <option *ngFor="let language of languages" [value]="language.code">
                          {{ language.name }}
                        </option>
                      </select>

                      <div class="card-body" [innerHTML]="contactusValue[contactusLang]" *ngIf="!editContact"></div>

                      <div *ngFor="let language of languages" [hidden]="!editContact">
                        <ck-editor name="editor1" [(ngModel)]="contactusValue[language.code]" language="en"
                                   [fullPage]="false" [hidden]="language.code != contactusLang"></ck-editor>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane p-0 fade show active" id="pills-custom" role="tabpanel"
                 aria-labelledby="pills-custom-tab">
              <div style="min-height: 24px;" class="mb-1">
                <label class="switch switch-3d switch-primary">
                  <input type="checkbox" class="switch-input" id="switcherContact" [(ngModel)]="editCustom">
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
                <label class="col-form-label col-form-label-lg"
                       style="margin-top: -10px; margin-left: 5px;">{{ 'sf.editcontent' | translate }}</label>
              </div>
              <!-- custom -->
              <nav class="{{selectedThemePreview}} navbar navbar-expand-lg">
                <div class="container">
                  <a class="navbar-brand" style="cursor: pointer;">
                    <img class="logo" [src]="logo" alt="" height="20px">
                  </a>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto" style="font-size: 10px;">
                      <li class="active">
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.home' | translate }} <span class="sr-only">(current)</span> </a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.aboutcompany' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.contactus' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.custompage' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.login' | translate }}</a>
                      </li>
                      <li>
                        <a class="nav-link" style="cursor: pointer;">{{ 'word.signup' | translate }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
              <input type="text" name="custom_title" class="form-control form-control-lg" placeholder="Page Title"
                     [(ngModel)]="pageTitle">
              <div class="jumbotron jumbotron-fluid" style="height:auto; background-size: cover; ">
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <h2 class="mb-3" style="color:white">{{ 'word.custompage' | translate }}</h2>

                      <select class="form-control form-control-lg mb-4" [(ngModel)]="editorLang"
                              [ngModelOptions]="{standalone: true}">
                        <option value="0" disabled selected>{{ 'word.selectlanguage' | translate }} *</option>
                        <option *ngFor="let language of languages" [value]="language.code">
                          {{ language.name }}
                        </option>
                      </select>

                      <div class="card-body" [innerHTML]="editorValue[editorLang]" *ngIf="!editCustom"></div>

                      <div *ngFor="let language of languages" [hidden]="!editCustom">
                        <ck-editor name="editor1" [(ngModel)]="editorValue[language.code]" language="en"
                                   [fullPage]="false" [hidden]="language.code != editorLang"
                                   data="editorValue[language.code] === undefined ? editorValue['en'] : editorValue[language.code]">
                        </ck-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="float-right">
              <label class="btn-primary" class="btn btn-dark btn-lg px-5 mb-4" (click)="nextPressed()">
                {{ 'button.next' | translate }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
