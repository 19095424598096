import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-preview-page',
	templateUrl: './preview-page.component.html',
	styleUrls: ['./preview-page.component.css']
})
export class PreviewPageComponent implements OnInit {

	@Input() title
	@Input() text
	@Input() selectedPreview
	@Input() logo
	@Input() selectedThemePreview
	@Input() filestring
	@Input() type
	@Input() cover
	@Input() aboutusValue
	@Input() contactusValue

	editContact = false
	editAbout = false

	@Input() customCoverPreview: string;

	optionStartup = [
		{
			name: 'Web versions of Word, Excel, and PowerPoint',
			value: 1,
			checked: true,
			// preselect: true,
			preselect: false,
			title: 'Online Cloud Office'
		},
		{
			name: 'Desktop versions of Office 2016 applications: Outlook, Word, Excel, PowerPoint, OneNote (plus Access and Publisher for PC only)',
			value: 2,
			checked: true,
			// preselect: true,
			preselect: false,
			title: 'PC / Desktop Office '
		},
		{
			name: 'Email hosting with 50 GB mailbox and custom email domain address',
			value: 3,
			checked: true,
			// preselect: true,
			preselect: false,
			title: 'Corporate Emails '
		}
	];
	optionEntreprise = [
		{
			name: 'Web versions of Word, Excel, and PowerPoint',
			value: 1,
			checked: true,
			// preselect: true,
			preselect: false,
			title: 'Online Cloud Office'
		},
		{
			name: 'Desktop versions of Office 2016 applications: Outlook, Word, Excel, PowerPoint, OneNote (plus Access and Publisher for PC only)',
			value: 2,
			checked: true,
			// preselect: true,
			preselect: false,
			title: 'PC / Desktop Office '
		},
		{
			name: 'Email hosting with 50 GB mailbox and custom email domain address',
			value: 3,
			checked: true,
			// preselect: true,
			preselect: false,
			title: 'Corporate Emails '
		}];

	constructor(private sanitizer: DomSanitizer) { }

	ngOnInit() {
		this.customCoverPreview = `url('data:image/jpeg;base64, ${this.filestring}')`;
		if (this.filestring == "url('data:image/jpeg;base64, ')"){
			this.filestring=''
		}
	}

	sanitize(url:string){ // if needed
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}
	onContactChange(){
		this.editContact = !this.editContact
	}

}
