import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { TokenUtil } from '../util/token-util';

@Injectable({
  providedIn: 'root'
})
export class LanguageTranslateService {

  languageApiUrl = environment.translateService.apiUrl;
  resellerApiUrl = environment.reseller.apiUrl;
  
  languageProperties$ = new BehaviorSubject<any>({});
  userLanguage$ = new BehaviorSubject<any>('eng');

  constructor(
    private http: HttpClient
    ) { }
    
  getHeaders(){
    // create new instance for each api call, instead of global static
    // as token string would change time to time
    const httpHeaders = {
      headers : new HttpHeaders()
      .set("Authorization", "Bearer " + localStorage.getItem('access_token'))
      .set('Content-Type', 'application/json')
    };
    return httpHeaders;
  }

  getLanguageProperties() {
    return this.http.get(this.languageApiUrl);
  }

  getUserInfo() {
    let username = TokenUtil.getPreferredUsername(localStorage.getItem("access_token"));
    let apiUrl = `${this.resellerApiUrl}/resellers/resuser/${username}`;
    return this.http.get(apiUrl, this.getHeaders());
  }

  async initUserLang() {
    await this.getUserInfo().toPromise().then((userInfo: any) => {
      this.userLanguage$.next(userInfo.lang);

    }).catch(err => {
        console.log('Get User Language error' , err);
    });
  }

  async initLanguageProperties() {
    await this.getLanguageProperties().toPromise().then((fullWordList: any) => {
      this.languageProperties$.next(fullWordList); 

    }).catch(err => {
      console.log('GetLanguageServlet error' , err);
    });

    return new Promise<any>((resolve) => {
      resolve(this.languageProperties$.value);
    });
  }

  translate(key: string, lang?: string) {
    let wordlist = this.languageProperties$.value;

    if(!lang) {
      lang = this.userLanguage$.value;
    }
    
    if(wordlist[key]) {
      if(wordlist[key][lang])
        return wordlist[key][lang];
      else if(wordlist[key]['eng'])
        return wordlist[key]['eng'];
      else
        return key;
    }

    return key;
  }

}
