import {Directive, Input} from '@angular/core';


@Directive({
  selector: 'th[searchable]',
})
export class SearchableTableHeader {

  @Input() column: string = '';
  @Input() enabledForFullText: boolean = true;

}