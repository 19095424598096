import { Statics } from 'src/app/util/statics';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  errorMessage = '';
  payBtnPressed = false;
  ssoLogin = false;
  ssoLoginLoading = true;

  captchaSiteKey:string = Statics.captchaSiteKey;
  disableCaptcha = environment.disableCaptcha;
  captchaVerified: boolean = false;

  validation_messages = {
    'username': [
      { type: 'required', message: 'Username is required' }
    ],
    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password must be at least 6 characters' }
    ],
    'captcha': [
      {type: 'required', message: 'Captcha is required'}
    ]
  };

  data = {
    username: '',
    password: ''
  }

  returnUrl: string = null;

  ssoLoginRetryAttempt: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdf: ChangeDetectorRef,
  ) { }

  ngOnInit() {

    localStorage.clear();

    if (this.disableCaptcha){
      this.loginForm = new UntypedFormGroup({
        'username': new UntypedFormControl('', Validators.compose([
          Validators.required
        ])),
        'password': new UntypedFormControl('', Validators.compose([
          Validators.required,
          Validators.minLength(6)
        ])),
      });

      // explicitly flag verified flag to bypass it 
      this.captchaVerified = true;
    }else{
      this.loginForm = new UntypedFormGroup({
        'username': new UntypedFormControl('', Validators.compose([
          Validators.required
        ])),
        'password': new UntypedFormControl('', Validators.compose([
          Validators.required,
          Validators.minLength(6)
        ])),
        'captcha': new UntypedFormControl('', Validators.compose([
          Validators.required,
        ]))
      });
    }


    this.activatedRoute.queryParams.subscribe(params => {
      let rescode = null;
      let accessToken = null;


      rescode = params['rescode'];
      this.returnUrl = params['returnUrl'] || null ;

      // keycloak token
      accessToken =  params['accessToken'];

      if(accessToken != null && rescode != null) {
        this.ssoLogin = true;
        this.ssoLoginRetryAttempt = 3;

        let data: any = {
          'accessToken': accessToken,
          'rescode': rescode
        };

        if (this.returnUrl && this.returnUrl != null) {

          let fragment: string = this.activatedRoute.snapshot.fragment;
          if (fragment) {
            this.returnUrl = this.returnUrl + '#' + fragment;
          }

          data.returnUrl = this.returnUrl;
        }
  
        this.attemptSSOLogin(data);

      }else{
        // default manual login
        console.log('default login');
      }
    });
  }

  get loginCaptchaFormControl() {
    return this.loginForm.get('captcha');
  }

  attemptSSOLogin(data:any){
    this.authService.ssoLogin(data).subscribe(
      response => {},
      (error: any) => {

        if(this.ssoLoginRetryAttempt > 1){
          this.ssoLoginRetryAttempt--;
          setTimeout(() => {
            this.attemptSSOLogin(data);
          }, 1000);
          return;
        }

        this.payBtnPressed = false;
        this.errorMessage = error['error']['message'];
        this.ssoLoginLoading = false;

        console.log('Please capture and contact WebNIC support with info below.');
        console.log('rescode: '+data.rescode);
        console.log('token: '+data.accessToken);

        // 503 Service Unavailable
        if (error.status === 500) {
          this.errorMessage = "Service is currently unavailable. Please try again later.";
        }
      }
    );
  }

  login() {
    this.payBtnPressed = true;
    
    // for adding returnUrl
    let data:any = this.loginForm.value;

    if(this.returnUrl && this.returnUrl != null){

      let fragment: string = this.activatedRoute.snapshot.fragment;

      if (fragment) {
        this.returnUrl = this.returnUrl + '#' + fragment;
      }
      
      data.returnUrl = this.returnUrl;
    }
       
    this.authService.postLogin(data).subscribe(
      result => {
        this.payBtnPressed = false;
    }, (error: any) => {
      this.resetCaptcha();
      this.payBtnPressed = false;
      this.errorMessage = error['error']['message'];

      // 503 Service Unavailable
      if (error.status === 500) {
        this.errorMessage = "Service is currently unavailable. Please try again later.";
      }
    });
  }

  onCaptchaResponse(token:string){
    this.captchaVerified = false;

    this.authService.checkHcaptchaToken(token).subscribe((res:any)=>{

      let verified:boolean = res.valid;
      if(verified){
        this.captchaVerified = true;
      }
      this.cdf.detectChanges();

    }, err=>{
        console.log('captcha verification failed', err);
    });
  }

  resetCaptcha() {
    this.captchaVerified = false;

    if(this.loginCaptchaFormControl){
      this.loginCaptchaFormControl.patchValue(null);
    }
  }

  onExpired(response: any) {
    this.resetCaptcha();
  }

  onError(error: any) {
    this.resetCaptcha();
  }

  refresh() {
    this.errorMessage = '';
  }
}
