// tslint:disable: max-line-length
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { MicrosoftCSPServiceModule } from './microsoftcsp-service.module';
import { MicrosoftCSPRoutingModule } from './microsoftcsp-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MicrosoftCSPTenantManagementComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/tenant-management/microsoftcsp-tenant-management.component';
import { MicrosoftCSPTenantCreateComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/tenant-create/microsoftcsp-tenant-create.component';
import { MicrosoftCSPTenantDetailComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/tenant-detail/microsoftcsp-tenant-detail.component';
import { MicrosoftCSPSubscriptionManagementComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-subscription/subscription-management/microsoftcsp-subscription-management.component';
import { MicrosoftCSPSubscriptionCreateComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-subscription/subscription-create/microsoftcsp-subscription-create.component';
import { MicrosoftCSPSubscriptionDetailComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-subscription/subscription-detail/microsoftcsp-subscription-detail.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxIntlTelInputModule } from 'src/app/library/ngx-intl-tel-input/ngx-intl-tel-input.module';
// import { CdkStepperModule } from '@angular/cdk/stepper';
import { MicrosoftCSPProductPricingComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-products/product-pricing/microsoftcsp-product-pricing/microsoftcsp-product-pricing.component';
import { MicrosoftCSPProductPromotionStepOneComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-products/product-promotion/microsoftcsp-product-promotion-step-one/microsoftcsp-product-promotion-step-one.component';
import { MicrosoftCSPProductPromotionStepTwoComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-products/product-promotion/microsoftcsp-product-promotion-step-two/microsoftcsp-product-promotion-step-two.component';
import { PartnerConnectGuidelinesComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/partner-connect-guidelines/partner-connect-guidelines.component';
import { MicrosoftCSPUserComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-user/microsoftcsp-user.component';
import { MicrosoftCSPUserNewComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-user/microsoftcsp-user-new/microsoftcsp-user-new.component';
import { MicrosoftCSPUserNewBasicsComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-user/microsoftcsp-user-new/microsoftcsp-user-new-basics/microsoftcsp-user-new-basics.component';
import { MicrosoftCSPUserNewLicensesComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-user/microsoftcsp-user-new/microsoftcsp-user-new-licenses/microsoftcsp-user-new-licenses.component';
import { MicrosoftCSPUserNewOptionalComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-user/microsoftcsp-user-new/microsoftcsp-user-new-optional/microsoftcsp-user-new-optional.component';
import { MicrosoftCSPUserDetailsComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-user/microsoftcsp-user-details/microsoftcsp-user-details.component';
import { MicrosoftCSPDomainComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-domain/microsoftcsp-domain.component';
import { MicrosoftCSPUserNewFinishComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-user/microsoftcsp-user-new/microsoftcsp-user-new-finish/microsoftcsp-user-new-finish.component';
import { AdminConsentComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/admin-consent/admin-consent.component';
import { AdminConsentAuthComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-tenant/admin-consent-auth/admin-consent-auth.component';
import { MicrosoftCSPDomainNewComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-domain/microsoftcsp-domain-new/microsoftcsp-domain-new.component';
import { MicrosoftCSPDomainNewDomainNameComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-domain/microsoftcsp-domain-new/microsoftcsp-domain-new-domain-name/microsoftcsp-domain-new-domain-name.component';
import { MicrosoftCSPDomainVerificationComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-domain/microsoftcsp-domain-new/microsoftcsp-domain-verification/microsoftcsp-domain-verification.component';
import { MicrosoftCSPDomainConnectComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-domain/microsoftcsp-domain-new/microsoftcsp-domain-connect/microsoftcsp-domain-connect.component';
import { MicrosoftCSPDomainFinishComponent } from 'src/app/component/o365-microsoftcsp/microsoftcsp-domain/microsoftcsp-domain-new/microsoftcsp-domain-finish/microsoftcsp-domain-finish.component';
import { ReplacePipe } from 'src/app/pipe/replace.pipe';

@NgModule({
  declarations: [
    // declare all MicrosoftCSP related components here
    MicrosoftCSPTenantManagementComponent,
    MicrosoftCSPTenantCreateComponent,
    MicrosoftCSPTenantDetailComponent,
    MicrosoftCSPSubscriptionManagementComponent,
    MicrosoftCSPSubscriptionCreateComponent,
    MicrosoftCSPSubscriptionDetailComponent,
    MicrosoftCSPProductPricingComponent,
    MicrosoftCSPProductPromotionStepOneComponent,
    MicrosoftCSPProductPromotionStepTwoComponent,
    PartnerConnectGuidelinesComponent,
    MicrosoftCSPUserComponent,
    MicrosoftCSPUserNewComponent,
    MicrosoftCSPUserNewBasicsComponent,
    MicrosoftCSPUserNewLicensesComponent,
    MicrosoftCSPUserNewOptionalComponent,
    MicrosoftCSPUserDetailsComponent,
    MicrosoftCSPDomainComponent,
    MicrosoftCSPUserNewFinishComponent,
    AdminConsentComponent,
    AdminConsentAuthComponent,
    MicrosoftCSPDomainNewComponent,
    MicrosoftCSPDomainNewDomainNameComponent,
    MicrosoftCSPDomainVerificationComponent,
    MicrosoftCSPDomainConnectComponent,
    MicrosoftCSPDomainFinishComponent,
    ReplacePipe,

  ],
  imports: [
    // take note to import the Angular Core Modules for this child module to use angular core features
    // eg. CommonModule, FormsModule,...
    CommonModule, // this import all angular common directives eg. *ngIf, *ngFor, ngClass,...
    MicrosoftCSPRoutingModule, // this routing module define the path of this child module's routing
    MicrosoftCSPServiceModule, // to import all services injected to this module
    FormsModule,
    ReactiveFormsModule,
    SharedModule, // import all sharing module or components that exposed for reuse

    NgbModule,
    ModalModule,
    NgxIntlTelInputModule,
    // CdkStepperModule,


  ],
  providers: [
  ]
})
export class MicrosoftCSPModule {
  // this module will be lazy-loaded as specified in app-routing.module.ts
}
