import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { LanguageTranslateService } from '../service/language-translate.service';
import { TokenUtil } from '../util/token-util';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private translateService: LanguageTranslateService,
    private location: Location
  ) {}

  allowAccess: boolean = true;

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (!localStorage.getItem('access_token')) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    } else {
      try {
        const authResponse = await this.authService.checkAuth(localStorage.getItem('access_token')).toPromise();

        if (authResponse['data'] !== true) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          return false;
        } else {
          this.initRescode();

          if (!this.allowAccess) {
            console.log('Access not allowed');
            this.location.back(); // Navigate back to the previous page
            return false;
          }
        }

        // Access token is still valid, then init user lang
        await this.translateService.initUserLang();
        return true;
      } catch (err) {
        console.error('Error during authentication check:', err);
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        localStorage.removeItem('access_token');
        return false;
      }
    }
  }

  initRescode() {
    let rescode: string = TokenUtil.getRescode(localStorage.getItem('access_token'));

    if (rescode.toLowerCase().startsWith('namewrap')) {
      this.allowAccess = false;
    }
  }
}