import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StepModel } from '../../../../model/step.model';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StepsService } from '../../../../service/steps.service';
import { MicrosoftGraphService } from '../../../../service/microsoftcsp/microsoft-graph.service';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';

@Component({
  selector: 'app-microsoftcsp-domain-new',
  templateUrl: './microsoftcsp-domain-new.component.html',
  styleUrls: ['./microsoftcsp-domain-new.component.css'],
  providers: [StepsService]
})
export class MicrosoftCSPDomainNewComponent implements OnInit {

  currentStep: StepModel;
  currentStepSub: Subscription;

  tenantId: number;
  domain: any;
  tenantDomains: any[] = []; 

  domainName: string;

  constructor(
    private stepsService: StepsService,
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService,
    private microsoftGraphService: MicrosoftGraphService
  ) { }

  ngOnInit() {
    this.currentStepSub = this.stepsService.getCurrentStep().subscribe((step: StepModel) => {
      this.currentStep = step;
    });

    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;
      this.domainName = params['domainName'] || null;

      if (!this.microsoftGraphService.adminConsent === true) {
        this.goToAdminConsentPage();
      } else {
        if (this.domainName != null) {
          let domainObj = this.microsoftGraphService.domain;

          if (domainObj && domainObj.id == this.domainName) {
            this.domain = domainObj;

            if (this.domain.isVerified == true) {
              this.stepsService.moveToStep(3);
            } else {
              this.stepsService.moveToStep(2);
            }
          }
        } 

      }
    });
  }

  onNextStep() {
    if (this.validateWizardStepsValidity()) {
      if (!this.stepsService.isLastStep()) {
        this.stepsService.moveToNextStep();
      } else {
      }
    }
  }

  onPreviousStep() {
    this.stepsService.moveToPreviousStep();
  }

  onCustomStep(stepIndex: number) {
    this.stepsService.moveToStep(stepIndex);
  }

  async validateWizardStepsValidity() {
    await this.stepsService.getCurrentStep().toPromise().then((step: StepModel) => {
      const currentStep = step.stepIndex;
      switch (currentStep) {
        default:
          return false;
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks and unexpected angular errors
    if (this.currentStepSub) {
      this.currentStepSub.unsubscribe();
    }
  }

  goToAdminConsentPage() {
    this.router.navigate(['/microsoftcsp/tenants/', this.tenantId, 'adminconsent']);
  }

  onNewDomainNameFormChanged(_domain: any) {
    this.microsoftGraphService.domain = _domain;
    this.onNextStep();
  }

  onDomainVerified(_domain: any) {
    this.microsoftGraphService.domain = _domain;
    this.onNextStep();
  }

  onServiceConfigChanged() {
    this.onNextStep();
  }

}
