import { StoreService } from './../../service/store.service';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { StoreFrontService } from '../../service/store-front.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-storefront-create-four',
  templateUrl: './storefront-create-four.component.html',
  styleUrls: ['./storefront-create-four.component.css']
})
export class StorefrontCreateFourComponent implements OnInit {

  categoryForm: UntypedFormGroup;
  userSelectedProducts: any;

  categories = [];
  pickedCatagory: any[] = [];
  edited = false;
  storefrontId;

  constructor(
    public storeFrontService: StoreFrontService,
    public storeService: StoreService,
    public router: Router,
    public route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.storeFrontService.getAllCategories().subscribe(resp => {
      this.categories = resp['data'];
      this.createCategoryForm();

      if (this.route.snapshot.paramMap.get('id')) {
        this.storefrontId = this.route.snapshot.paramMap.get('id');
        this.edited = true;

        this.storeService.getStorefront(this.route.snapshot.paramMap.get('id')).subscribe(Response => {
          console.log('Storefront To Update Details : ', Response['data'])
          this.userSelectedProducts = Response['data']['products'];
          this.userSelectedProducts.forEach(product => {
            const category_id = product['category']['id'];
            this.categoriesFormGroup.controls[category_id].patchValue(true);

            const inputTag = document.getElementById('category' + category_id);
            const labelTag = this.getParentNodeByName(inputTag, 'label');
            this.renderer.addClass(labelTag, 'image-checkbox-checked');
            this.categoriesFormGroup.controls[category_id].disable();

            this.categoriesFormGroup.controls[category_id].markAsTouched();
          });
        });
      }
    });

    $('#close').click(function () {
      $('.alert').hide();
    });
  }

  nextPressed() {
    this.pickedCatagory = this.categoriesFormGroupSelectedIds;

    // TODO fix categoryForm to use .valid or .invalid
    if (this.pickedCatagory.length > 0) {
      this.storeService.setupCategories(this.pickedCatagory);
      if (this.edited) {
        this.router.navigate(['/manage-storefront-5/' + this.storefrontId]);
      } else {
        this.router.navigate(['/create-storefront-5']);
      }
    } else {
      $(".alert").show();
    }
  }

  get f() { return this.categoryForm && this.categoryForm.controls; }
  get categoriesFormGroup(): UntypedFormGroup { return this.f && <UntypedFormGroup>this.f.categoriesFormGroup; }

  get categoriesFormGroupSelectedIds(): string[] {
    let ids: string[] = [];
    for (let key in this.categoriesFormGroup.controls) {
      if (this.categoriesFormGroup.controls[key].value) {
        ids.push(key);
      }
    }
    return ids;
  }

  createCategoryForm() {
    this.categoryForm = this.formBuilder.group({

    });
    this.categoryForm.addControl('categoriesFormGroup', this.buildCategoryFormGroup(this.categories));
  }

  buildCategoryFormGroup(categories: any[], selectedCategoryIds: string[] = []): UntypedFormGroup {
    let group = this.formBuilder.group({}, {
      validators: (formGroup: UntypedFormGroup) => {
        let minRequired = 1;
        let checked = 0;

        Object.keys(formGroup.controls).forEach(key => {
          const control = formGroup.controls[key];

          if (control.value === true) {
            checked++;
          }
        });

        if (checked < minRequired) {
          return {
            requireCheckboxToBeChecked: true,
          };
        }

        return null;
      }
    });
    categories.forEach(category => {
      const isSelected = selectedCategoryIds.some(id => id === category.id);
      group.addControl(category.id, this.formBuilder.control(isSelected));
    });
    return group;
  }

  onClickCheckbox(event: any) {
    const target = event.currentTarget;
    const labelTag = this.getParentNodeByName(target, 'label');

    if (event.target.checked) {
      this.renderer.addClass(labelTag, 'image-checkbox-checked');
    } else {
      this.renderer.removeClass(labelTag, 'image-checkbox-checked');
    }
  }

  getParentNodeByName(target, nodeName) {
    let actualNode = target.parentElement;
    while (actualNode.nodeName !== nodeName.toUpperCase()) {
      actualNode = actualNode.parentElement;
    }
    return actualNode;
  }

}
