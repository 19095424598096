import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { StepModel } from '../../../../../model/step.model';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { UserBasics } from '../../../../../model/microsoftcsp/new-user/user-basics.model';
import { UserLicenses } from '../../../../../model/microsoftcsp/new-user/user-licenses.model';
import { UserOptional } from '../../../../../model/microsoftcsp/new-user/user-optional.model';
import { AssignedLicense } from '../../../../../model/microsoftcsp/new-user/assigned-license.model';
import { AlertService } from 'src/app/service/alert.service';
import { TranslateUtil } from 'src/app/util/translate-util';
import { LanguageTranslateService } from 'src/app/service/language-translate.service';

@Component({
  selector: 'app-microsoftcsp-user-new-finish',
  templateUrl: './microsoftcsp-user-new-finish.component.html',
  styleUrls: ['./microsoftcsp-user-new-finish.component.css']
})
export class MicrosoftCSPUserNewFinishComponent implements OnInit {

  @Input() step: StepModel;
  @Input() newUserBasicsForm: UntypedFormGroup;
  @Input() newUserLicensesForm: UntypedFormGroup;
  @Input() newUserOptionalForm: UntypedFormGroup;
  @Output() stepChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() editStepChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFinishPageChanged: EventEmitter<any> = new EventEmitter<any>();

  tenantId: number;
  productLicenses: string;

  createUserLoading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;
      this.productLicenses = '';

      if (this.newUserLicensesForm.get('createUserWithLicense').value) {
        const addLicenses = this.newUserLicensesForm.get('addLicenses').value;

        this.productLicenses = addLicenses.map(addLicense => {
          if (this.productLicenses === '') {
            return addLicense.friendlyName;
          } else {
            return ','.concat(addLicense.friendlyName);
          }

        });
      }
    });
  }

  onCompleteStep() {
    if (this.newUserBasicsForm.valid && this.newUserLicensesForm.valid && this.newUserOptionalForm.valid) {
      this.step.isComplete = true;
      this.createUserAndAssignLicense();
    }
  }

  onBackStep() {
    this.step.isComplete = false;
    this.stepChanged.emit();
  }

  onEditUserBasicsClicked() {
    this.step.isComplete = false;
    this.editStepChanged.emit(1);
  }

  onEditUserLicensesClicked() {
    this.step.isComplete = false;
    this.editStepChanged.emit(2);
  }

  onEditUserOptionalClicked() {
    this.step.isComplete = false;
    this.editStepChanged.emit(3);
  }

  createUserAndAssignLicense() {
    this.createUserLoading = true;
    const userBasics: UserBasics = { ...this.newUserBasicsForm.value };
    let userLicenses: UserLicenses = { ...this.newUserLicensesForm.value };
    if (userLicenses.createUserWithLicense) {
      userLicenses.addLicenses = userLicenses.addLicenses.map(addLicense => {
        delete addLicense['friendlyName'];
        return addLicense;
      });
    }
    // const userOptional: UserOptional = { ...this.newUserOptionalForm.value };

    const createUserRequest: any = {
      firstName: userBasics.firstName,
      lastName: userBasics.lastName,
      displayName: userBasics.displayName,
      username: userBasics.username,
      domain: userBasics.domain,
      generatePassword: userBasics.generatePassword,
      password: userBasics.password,
      forceChangePassword: userBasics.forceChangePassword,

      createUserWithLicense: userLicenses.createUserWithLicense,
      usageLocation: userLicenses.usageLocation,
      addLicenses: userLicenses.addLicenses
    };

    this.microsoftGraphApiService.createUserAndAddLicenses(this.tenantId.toString(), createUserRequest)
      .subscribe((res: any) => {
        this.createUserLoading = false;
        this.showSuccess(userBasics.displayName);
      }, err => {
        console.error(err);
        this.createUserLoading = false;
        this.showFail();
      });
  }

  showSuccess(displayName: string) {
    const message = displayName + ' added to active users';

    const callback = () => {
      this.onFinishPageChanged.next();
    };

    this.alertService.showSuccessWithHandler({ message: message }, callback);
  }

  showFail() {
    const message = 'Something went wrong. Please try again later';

    const callback = () => {
      this.onFinishPageChanged.next();
    };

    this.alertService.showErrorWithHandler({ message: message }, callback);
  }

}
