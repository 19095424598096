import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StepModel } from '../../../../../model/step.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MicrosoftGraphService } from '../../../../../service/microsoftcsp/microsoft-graph.service';
import { MicrosoftGraphApiService } from 'src/app/service/microsoft-graph-api.service';
import { O365DnsService } from 'src/app/service/o365-dns.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-microsoftcsp-domain-connect',
  templateUrl: './microsoftcsp-domain-connect.component.html',
  styleUrls: ['./microsoftcsp-domain-connect.component.css']
})
export class MicrosoftCSPDomainConnectComponent implements OnInit {

  @Input() step: StepModel;
  @Output() stepChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() serviceConfigChanged: EventEmitter<any> = new EventEmitter<any>();

  autoConnectDomainOptionsForm: UntypedFormGroup;

  domainConnectionOptionsForm: UntypedFormGroup;
  domainConnectionManualForm: UntypedFormGroup;
  domainConnectionAutoForm: UntypedFormGroup;

  tenantId: number;
  showPremiumDNSAutoConnectFailedSection: boolean = false;
  showDomainConnectionOptionsSection: boolean = false;
  showAddDNSRecordsSectionManual: boolean = false; // Add DNS records
  showAddDNSRecordsSectionAuto: boolean = false; // Service selection
  dnsRecords: any[] = [];
  domain: any;

  dnsRecordLoading: boolean = false;

  errorCheck: boolean = false;
  errors: any[] = [];

  conflictRecords: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private microsoftGraphApiService: MicrosoftGraphApiService,
    private microsoftGraphService: MicrosoftGraphService,
    private o365DnsApiService: O365DnsService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.tenantId = +params['tenantId'] || null;

      this.createDomainConnectionOptionsForm();
      this.domain = this.microsoftGraphService.domain;

      this.showSection();
    }); 
  }

  showSection(){
    if(this.microsoftGraphService.createServiceRecordsRequest && this.microsoftGraphService.hasConflictRecords){
      this.conflictRecords = this.microsoftGraphService.conflictRecords;
      
      this.createAutoConnectDomainOptionsForm();
      this.showPremiumDNSAutoConnectFailedSection = true;
    }else{
      this.showDomainConnectionOptionsSection = true;
    }
  }

  onManualCompleteStep() {
    this.step.isComplete = true;
    this.serviceConfigChanged.emit();
  }

  onAutoCompleteStep() {
    this.step.isComplete = true;
    this.serviceConfigChanged.emit();
  }

  onSkipCompleteStep() {
    this.step.isComplete = true;
    this.serviceConfigChanged.emit();
  }

  createAutoConnectDomainOptionsForm() {
    this.autoConnectDomainOptionsForm = this.formBuilder.group({
      autoConnectDomainOption: ['manual_continue', [Validators.required]],
    })
  }

  createDomainConnectionOptionsForm() {
    this.domainConnectionOptionsForm = this.formBuilder.group({
      connectOption: ['manual', [Validators.required]],
    })
  }

  createDomainConnectionManualForm() {
    this.domainConnectionManualForm = this.formBuilder.group({
      manualEmail: [true],
      manualOfficeCommunicationsOnline: [false]
    })
  }

  createDomainConnectionAutoForm() {
    this.domainConnectionAutoForm = this.formBuilder.group({
      autoEmail: [true],
      autoOfficeCommunicationsOnline: [false]
    })
  }

  onBackStep() {
    this.step.isComplete = false;
    this.stepChanged.emit();
  }

  async onContinueStep() {
    this.dnsRecordLoading = true;
    await this.microsoftGraphApiService.getDomainServiceConfigurationRecords(this.tenantId.toString(), this.domain.id)
      .toPromise()
      .then((res: any) => {
        this.dnsRecordLoading = false;

        this.dnsRecords = res.value;

        if (this.connectOptionFormControl.value === 'manual') {
          this.showAddDNSRecordsSectionManual = true;
          this.showAddDNSRecordsSectionAuto = false;
          this.createDomainConnectionManualForm();
        } else if (this.connectOptionFormControl.value === 'auto') {
          this.showAddDNSRecordsSectionManual = false;
          this.showAddDNSRecordsSectionAuto = true;
          this.createDomainConnectionAutoForm();
        } else {
          this.showAddDNSRecordsSectionManual = false;
          this.showAddDNSRecordsSectionAuto = false;
          this.showDomainConnectionOptionsSection = false;
          this.onSkipCompleteStep();
          return;
        }

        this.showDomainConnectionOptionsSection = false;
      })
      .catch(err => {
        this.dnsRecordLoading = false;
        this.showAddDNSRecordsSectionManual = false;
        this.showAddDNSRecordsSectionAuto = false;
        this.showDomainConnectionOptionsSection = true;
        console.error('getDomainServiceConfigurationRecords error', err);
      });
  }

  async onAutoConnectDomainContinueStep(){
    this.dnsRecordLoading = true;

    if(this.autoConnectDomainOptionFormControl.value === 'auto_continue'){

      let conflictRecordRRSet: any[] = this.microsoftGraphService.conflictRecords;
      let deleteDnsRequest = { rrset: conflictRecordRRSet};

      let deleteSuccessful = false;

      await this.o365DnsApiService.deleteDnsRecord(deleteDnsRequest, this.domain.id)
        .toPromise()
        .then((res: any) => {
          deleteSuccessful = true;
        })
        .catch(err => {
          this.dnsRecordLoading = false;
          console.error('deleteDnsRecord error', err);
          this.handleDnsRestError(err);
        });

      if(!deleteSuccessful){
        //this.errors.push(`Delete conflicting DNS records failed.`);
        //this.errorCheck = true;
        //this.dnsRecordLoading = false;
        return;
      }

      let createDnsRequest:any = this.microsoftGraphService.createServiceRecordsRequest;

      let createSuccessful = false;

      await this.o365DnsApiService.createDnsRecord(createDnsRequest, this.domain.id)
        .toPromise()
        .then((res: any) => {
          createSuccessful = true;
        })
        .catch(err => {
          this.dnsRecordLoading = false;
          console.error('createDnsRecord error', err);
          this.handleDnsRestError(err);
        });

      if (!createSuccessful) {
        //this.errors.push(`Create DNS records failed.`);
        //this.errorCheck = true;
        //this.dnsRecordLoading = false;
        return;
      }

      this.dnsRecordLoading = false;
      this.onAutoCompleteStep();

    }else{
      this.dnsRecordLoading = false;
      this.showPremiumDNSAutoConnectFailedSection = false;
      this.showDomainConnectionOptionsSection = true;
    }

  }

  onAddDNSRecordsBackStep() {
    this.showAddDNSRecordsSectionManual = false;
    this.showAddDNSRecordsSectionAuto = false;
    this.showDomainConnectionOptionsSection = true;
  }

  getServiceDnsRecordsRecordType(supportedService: string, recordType: string) {
    return this.dnsRecords.filter(dnsRecord => dnsRecord.recordType === recordType && dnsRecord.supportedService === supportedService);
  }

  getServiceDnsRecords(supportedService: string) {
    return this.dnsRecords.filter(dnsRecord => dnsRecord.supportedService === supportedService);
  }

  errorClosePressed() {
    this.errorCheck = false;
    this.errors.length = 0;
  }

  private handleDnsRestError(httpErrorResponse: HttpErrorResponse) {

    this.errors.length = 0;
    let errorResponse = httpErrorResponse.error;

    if (errorResponse && errorResponse.error) {

      let dnsApiError: any = errorResponse.error;
      let subCode: string = dnsApiError.subCode;
      let message: any = dnsApiError.message;

      if (subCode == 'DNS4000') {
        let validationErrors: any[] = errorResponse.validationErrors;
        this.errors.push(validationErrors.map(err => err.message));
        this.errorCheck = true;
        return;
      }

      if (subCode == 'DNS2500') {
        if(message.changes){
          this.errors.push(message.changes.map(err => JSON.stringify(err)))
          this.errorCheck = true;
          return;
        }

        this.errors.push(JSON.stringify(message));
        this.errorCheck = true;
        return;
      }

      this.errors.push(message);
      this.errorCheck = true;
      return;
    }

    // default flag true to indicate error
    this.errorCheck = true;
  }

  copyToClipboard(text: string, strToReplace?: string, replacementStr?: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';

    if (!strToReplace === undefined && !strToReplace == null && strToReplace && !replacementStr === undefined && !replacementStr == null) {
      selBox.value = text.replace(strToReplace, replacementStr);
    } else {
      selBox.value = text;
    }

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  get autoConnectDomainOptionFormControl() { return this.autoConnectDomainOptionsForm.get('autoConnectDomainOption'); }
  get connectOptionFormControl() { return this.domainConnectionOptionsForm.get('connectOption'); }

  get manualEmailFormControl() { return this.domainConnectionManualForm.get('manualEmail'); }
  get manualOfficeCommunicationsOnlineFormControl() { return this.domainConnectionManualForm.get('manualOfficeCommunicationsOnline'); }

  get autoEmailFormControl() { return this.domainConnectionAutoForm.get('autoEmail'); };
  get autoOfficeCommunicationsOnlineFormControl() { return this.domainConnectionAutoForm.get('autoOfficeCommunicationsOnline'); }

}
