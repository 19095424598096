import { SearchableTableHeader } from '../directive/searchable.directive';
import { QueryList } from "@angular/core";

export class DatatableUtil {

    static filterMatching(item: any, matchText: string, columns?: string[]): boolean {
        let isMatched: boolean = false;
        if( item == null || matchText == null ){
            return isMatched;
        }
    
        // filter by the provided columns
        if(columns){
          for (let col of columns) {
            let itemValue:any = item[col];
            if(itemValue && itemValue != null){
                let valueAsString = String(itemValue);
                isMatched = valueAsString.toLowerCase().includes(matchText.toLowerCase());
                if(isMatched){
                  break;
                }
            }
          }
          return isMatched;
        }
        
        // here is the actual full text searching which search all the item's field values
        for (let key of Object.keys(item)) {
            let itemValue:any = item[key];
            if(itemValue && itemValue != null){
                let valueAsString = String(itemValue);
                isMatched = valueAsString.toLowerCase().includes(matchText.toLowerCase());
                if(isMatched){
                  break;
                }
            }
        }
        return isMatched;
    }

    static getFullTextSearchableColumns(searchableHeaders: QueryList<SearchableTableHeader>){
       return [...searchableHeaders.filter(h=>h.enabledForFullText == true && h.column != '')
        .map(header=>header.column)];
    }

    static sort(items: any[], column: string, direction: string): any[] {
        if (direction === '' || column === '') {
            return items;
        } else {
            return [...items].sort((a, b) => {
                const res = this.compare(`${a[column]||'-'}`, `${b[column]||'-'}`);
                if(res == 0) {return 0;}
                return direction === 'asc' ? res : -res;
            });
        }
    }

    static compare = (a:any, b:any) => a.localeCompare(b, 'en', { numeric: true });

}